import { ViewOptions } from 'ol/View';
import { fromLonLat } from 'ol/proj';

export const getBrandViewOptions = ({ center, ...args }: ViewOptions): ViewOptions => {
  return {
    enableRotation: false,
    center: fromLonLat(center || [-0.0008805681, -0.0010858992]),
    extent: [-200, -210, 200, 210],
    zoom: 10,
    maxZoom: 22,
    ...args,
  };
};
