import parse from 'html-react-parser';
import { TChatMessage } from 'shared/types/request.types';
import { useAppSelector } from 'shared/hooks/useAppSelector';

type TPropsWithChildren = {
  msg?: TChatMessage;
  children?: JSX.Element;
};

export const MessageItem = ({ children, msg }: TPropsWithChildren) => {
  const userData = useAppSelector((state) => state.user.userData);

  const classNameSource =
    String(msg?.from?.id) === String(userData?.id) ? 'chat-body__incoming' : 'chat-body__outgoing';

  return (
    <div className={`chat-body__message ${classNameSource}`}>
      {!!msg && <div className='chat-body__text'>{parse(msg.text, { trim: true })}</div>}
      {children}
    </div>
  );
};
