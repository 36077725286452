import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import brandLayer from 'assets/images/map/brand-map-layer.png';
import osmLayer from 'assets/images/map/osm-map-layer.png';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { FiltersManageButtons } from 'features/shared';
import { BaseSwitcher } from 'shared/ui/BaseSwitcher/BaseSwitcher';

type TCurrentMap = 'brand' | 'osm';

export type TTourDesignerMapLayersProps = {
  show: boolean;
  currentMap: TCurrentMap;
  onChangeLayer: (layer: TCurrentMap) => void;
  isActiveTerritory: boolean;
  onToggleTerritory: (isShow: boolean) => void;
  onClose: () => void;
};

export const TourDesignerMapLayers = ({
  show,
  currentMap,
  onChangeLayer,
  isActiveTerritory,
  onToggleTerritory,
  onClose,
}: TTourDesignerMapLayersProps) => {
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);
  const [selectLayer, setSelectLayer] = useState(currentMap);
  const [isShowTerritory, setIsShowTerritory] = useState(false);

  const handleChangeLayer = (layer: TCurrentMap) => {
    isMobile ? setSelectLayer(layer) : onChangeLayer(layer);
  };

  const handleToggleTerritory = (checked: boolean) => {
    setIsShowTerritory(checked);
    !isMobile && onToggleTerritory(checked);
  };

  const handleApplyLayers = () => {
    onChangeLayer(selectLayer);
    onToggleTerritory(isShowTerritory);
    onClose();
  };

  useEffect(() => {
    setSelectLayer(currentMap);
  }, [currentMap]);

  useEffect(() => {
    setIsShowTerritory(isActiveTerritory);
  }, [isActiveTerritory]);

  useEffect(() => {
    if (!show) {
      setSelectLayer(currentMap);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      {isMobile && <div className='designer-map-layers__overlay' onClick={onClose}></div>}
      <div className='designer-map-layers'>
        {isMobile && (
          <div className='designer-map-layers__header'>
            <div className='designer-map-layers__head'>Параметры карты</div>
            <CloseIcon className='designer-map-layers__close' onClick={onClose} />
          </div>
        )}
        <div className='designer-map-layers__content'>
          <div>
            <div className='designer-map-layers__title'>Картографическая основа</div>
            <ul className='designer-map-layers__list'>
              <li
                className={classNames('designer-map-layers__item', { active: selectLayer === 'brand' })}
                onClick={() => handleChangeLayer('brand')}
              >
                <img className='designer-map-layers__image' src={brandLayer} alt='brand map' />
                <div className='designer-map-layers__text'>ТРК “Арктический”</div>
              </li>
              <li
                className={classNames('designer-map-layers__item', { active: selectLayer === 'osm' })}
                onClick={() => handleChangeLayer('osm')}
              >
                <img className='designer-map-layers__image' src={osmLayer} alt='osm map' />
                <div className='designer-map-layers__text'>OpenStreetMap</div>
              </li>
            </ul>
          </div>
          <div>
            <div className='designer-map-layers__title'>Туристические кластеры</div>
            <div className='designer-map-layers__territory'>
              <BaseSwitcher
                label='Показать границы кластеров'
                isActive={isShowTerritory}
                onChange={handleToggleTerritory}
                id='designer-map-layers__switch'
                className='designer-map-layers__text'
                type={isMobile ? 'light' : 'dark'}
              />
            </div>
          </div>
        </div>
        {isMobile && (
          <FiltersManageButtons clearFilters={() => {}} applyFilters={handleApplyLayers} isActiveClearButton={false} />
        )}
      </div>
    </>
  );
};
