import { PropsWithChildren } from 'react';

type TParamsFilterGroupProps = {
  title: string;
};

export const ParamsFilterGroup = ({ children, title }: PropsWithChildren<TParamsFilterGroupProps>) => {
  return (
    <div className='params-filters-group'>
      <div className='params-filters-group__name'>{title}</div>
      <div className='params-filters-group__list'>{children}</div>
    </div>
  );
};
