import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLocation, setCity, setNeedShowLocation } from 'manageStore/designer/designer.slice';
import { BrendMap } from 'widgets/shared/Maps/lib/BrendMap';
import { OsmMap } from 'widgets/shared/Maps/lib/OsmMap';
import { TCity, TClusterData, TLocation } from 'shared/types/location.types';
import { ReactComponent as LayersIcon } from 'assets/images/map/layers-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/images/map/info-icon.svg';
import {
  selectActiveCity,
  selectActiveLocation,
  selectNeedShowLocation,
  selectLocationsToDisplay,
  selectOrderLocations,
  selectSearchedLocations,
  selectSortedLocations,
} from 'manageStore/designer/designer.select';
import { useOsmMap } from 'widgets/shared/Maps/hooks/useOsmMap';
import { getBrandViewOptions } from 'widgets/shared/Maps/helpers/getBrandViewOptions';
import { getOsmViewOptions } from 'widgets/shared/Maps/helpers/getOsmViewOptions';
import { useAddPoints } from 'widgets/shared/Maps/hooks/useAddPoints';
import { TourDesignerMapLegend } from '../TourDesignerMapLegend/TourDesignerMapLegend';
import { TourDesignerMapLayers } from '../TourDesignerMapLayers/TourDesignerMapLayers';
import classNames from 'classnames';
import { TourDesignerManagerBox } from '../TourDesignerManagerBox/TourDesignerManagerBox';
import { usePointer } from 'widgets/shared/Maps/hooks/usePointer';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import VectorLayer from 'ol/layer/Vector';
import { Cluster } from 'ol/source';
import Feature from 'ol/Feature';
import { moveMap } from 'widgets/shared/Maps/helpers/moveMap';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { useTerritoryMap } from 'widgets/shared/Maps/hooks/useTerritoryMap';
import { createEmpty, extend } from 'ol/extent';
import Map from 'ol/Map';

type TTagMap = 'brand' | 'osm';
type TTagControl = 'legend' | 'layers' | null;

const viewBrandOptions = getBrandViewOptions({});
const viewOsmOptions = getOsmViewOptions({});

export const TourDesignerMap = () => {
  const dispatch = useDispatch();

  const orderLocations = useSelector(selectOrderLocations);
  const locations = useSelector(selectLocationsToDisplay);
  const sortedLocations = useSelector(selectSortedLocations);
  const searchedLocations = useSelector(selectSearchedLocations);
  const activeCity = useSelector(selectActiveCity);
  const activeLocation = useSelector(selectActiveLocation);
  const needShowLocation = useSelector(selectNeedShowLocation);

  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const [currentList, setCurrentList] = useState<'designer' | 'order'>('designer');
  const [currentMap, setCurrentMap] = useState<TTagMap>('brand');
  const [showControl, setShowControl] = useState<TTagControl | null>(null);
  const [pointsBrandMap, setPointsBrandMap] = useState<TClusterData[]>([]);
  const [pointsOsmMap, setPointsOsmMap] = useState<TClusterData[]>([]);

  const [brandMap, refBrandContainer] = useOsmMap('brand-map', viewBrandOptions, {
    withOverviewMapControl: !isMobile,
  });
  const [osmMap] = useOsmMap('osm-map', viewOsmOptions, { withOverviewMapControl: !isMobile });
  const [brandMapClusters, brandMapFeatures] = useAddPoints(brandMap, pointsBrandMap, 'designer');
  const [osmMapClusters, osmMapFeatures] = useAddPoints(osmMap, pointsOsmMap, 'designer');
  const [territoryLayer] = useTerritoryMap(brandMap);

  const setClusterAttribute = useCallback(
    (attributeName: string, getAttributeValue: (infoLoc: TLocation) => unknown) => {
      const features = currentMap === 'brand' ? brandMapFeatures : osmMapFeatures;
      if (!features || features.length === 0) return;
      features.forEach((cluster) => {
        const infoLoc: TLocation = cluster.get('infoLoc');
        cluster.set(attributeName, getAttributeValue(infoLoc));
      });

      if (currentMap === 'brand') {
        refreshMap(brandMap);
      } else {
        refreshMap(osmMap);
      }
    },
    [brandMapFeatures, osmMapFeatures, currentMap]
  );

  const handleClickCluster = (event: MapBrowserEvent<any>, clusters: VectorLayer<Cluster>) => {
    clusters.getFeatures(event.pixel).then((features) => {
      if (features.length === 0) return;

      const clusterMembers: Feature[] = features[0].get('features');
      const extent = createEmpty();

      if (brandMap && clusters) {
        clusterMembers.forEach((feature) => {
          const newExtent = feature?.getGeometry()?.getExtent();
          if (newExtent) {
            extend(extent, newExtent);
          }
        });

        if (clusterMembers.length) {
          brandMap.getView().fit(extent, { duration: 500 });
        }
      }

      if (clusterMembers.length === 1) {
        const infoLoc: TLocation = clusterMembers[0].get('infoLoc');
        if (infoLoc) {
          const city = 'city' in infoLoc ? infoLoc.city : infoLoc;
          dispatch(setLocation(infoLoc));
          dispatch(setCity(city));
        }
      } else {
        const infoLoc: TCity | TLocation | undefined = clusterMembers[0].get('infoLoc');
        if (infoLoc) {
          const city = 'city' in infoLoc ? infoLoc.city : infoLoc;
          dispatch(setCity(city));
          if (currentMap === 'brand' && city) {
            changeMap();
          }
        }
      }
    });
  };

  const changeMap = useCallback(() => {
    if (currentMap === 'osm' && brandMap) {
      setCurrentMap('brand');
      let center = null;
      if (activeLocation && activeLocation.map2DLat && activeLocation.map2DLng) {
        center = [activeLocation.map2DLng, activeLocation.map2DLat];
      } else if (activeCity && activeCity.map2DLat && activeCity.map2DLng) {
        center = [activeCity.map2DLng, activeCity.map2DLat];
      }
      refreshMap(brandMap);
      center
        ? moveMap(brandMap, { center, zoom: 21.5 })
        : moveMap(brandMap, { center: [-0.0008805681, -0.0010858992], zoom: 10 });
    } else if (osmMap) {
      setCurrentMap('osm');
      let center = null;
      if (activeLocation && activeLocation.lat && activeLocation.lng) {
        center = [activeLocation.lng, activeLocation.lat];
      } else if (activeCity && activeCity.lat && activeCity.lng) {
        center = [activeCity.lng, activeCity.lat];
      }
      center ? moveMap(osmMap, { center, zoom: 13 }) : moveMap(osmMap, { center: [88.224851, 69.346734], zoom: 10 });
      refreshMap(osmMap);
    }
  }, [currentMap, activeCity, activeLocation, osmMap, brandMap]);

  const refreshMap = (linkMap: Map | null) => {
    if (!linkMap) return;
    linkMap.updateSize();
    setTimeout(() => {
      linkMap.updateSize();
    }, 1000);
  };

  usePointer(brandMap, brandMapClusters, handleClickCluster);
  usePointer(osmMap, osmMapClusters, handleClickCluster);

  const handleClickControl = (control: TTagControl) => {
    setShowControl((prev) => (prev === control ? null : control));
  };

  const closeControl = () => {
    setShowControl(null);
  };

  const handleChangeLayer = (layer: TTagMap) => {
    if (layer !== currentMap) {
      changeMap();
    }
  };

  const handleToggleTerritory = (isShow: boolean) => {
    territoryLayer?.setVisible(isShow);
  };

  const handleChangeBoxList = (list: typeof currentList) => {
    if (list === currentList) return;
    setCurrentList(list);
  };

  useEffect(() => {
    if (activeCity && activeLocation && !activeLocation.city) {
      dispatch(setLocation(null));
    }
  }, [activeCity, activeLocation]);

  useEffect(() => {
    if (needShowLocation && brandMap && needShowLocation.map2DLng && needShowLocation.map2DLat) {
      const center: number[] = [needShowLocation.map2DLng, needShowLocation.map2DLat];
      moveMap(brandMap, { center, zoom: 21.5 });
      dispatch(setNeedShowLocation(null));
    }
  }, [brandMap]);

  useEffect(() => {
    if (!locations && currentList === 'designer') return;
    const pointsBrand: TClusterData[] = [];
    const pointsOsm: TClusterData[] = [];
    const cityLocation: Record<string, TClusterData> = {};
    locations.forEach((loc) => {
      if ('city' in loc && loc.city && loc.city.map2DLat && loc.city.map2DLng) {
        cityLocation[loc.city.name] = { coords: [loc.city.map2DLng, loc.city.map2DLat], infoLoc: loc.city };
        pointsBrand.push({ coords: [loc.city.map2DLng, loc.city.map2DLat], infoLoc: loc });
        pointsOsm.push({ coords: [loc.lng || 0, loc.lat || 0], infoLoc: loc });
      } else if (loc.map2DLat && loc.map2DLng) {
        const coords = [loc.map2DLng, loc.map2DLat];
        pointsBrand.push({ coords, infoLoc: loc });
        pointsOsm.push({ coords: [loc.lng || 0, loc.lat || 0], infoLoc: loc });
      }
    });
    setPointsBrandMap([...pointsBrand, ...Object.values(cityLocation)]);
    setPointsOsmMap(pointsOsm);
  }, [locations]);

  useEffect(() => {
    if (activeLocation) {
      const getValueAttribute = (infoLoc: TLocation) => {
        return infoLoc.id === activeLocation.id;
      };
      setClusterAttribute('isActive', getValueAttribute);
      setClusterAttribute('inFilter', getValueAttribute);
    } else if (searchedLocations?.length) {
      const getValueAttribute = (infoLoc: TLocation) => {
        return searchedLocations.some((loc) => loc.id === infoLoc.id);
      };
      setClusterAttribute('isActive', getValueAttribute);
      setClusterAttribute('inFilter', getValueAttribute);
    } else {
      const getValueAttribute = (infoLoc: TLocation) => {
        return sortedLocations.some((loc) => loc.id === infoLoc.id);
      };
      setClusterAttribute('isActive', () => false);
      setClusterAttribute('inFilter', getValueAttribute);
    }
  }, [searchedLocations, sortedLocations, activeLocation, setClusterAttribute]);

  useEffect(() => {
    const getAttribute = (infoLoc: TLocation) => {
      if (currentList === 'designer') return;
      const orderLocation = orderLocations[infoLoc.name];
      if (!orderLocation) return;
      return 'citySequence' in orderLocation && orderLocation.citySequence
        ? `${orderLocation.citySequence}.${orderLocation.sequence}`
        : orderLocation.sequence;
    };
    setClusterAttribute('sequence', getAttribute);
  }, [currentList, orderLocations, setClusterAttribute]);

  return (
    <div className='designer-map'>
      <div className='designer-map__container'>
        <div className={classNames('designer-map__wrapper', { hidden: currentMap === 'brand' })}>
          <OsmMap map={osmMap} />
        </div>
        <div className={classNames('designer-map__wrapper', { hidden: currentMap === 'osm' })}>
          <BrendMap map={brandMap} refContainer={refBrandContainer} />
        </div>

        <div className='designer-map-controls'>
          <button
            className={showControl === 'layers' ? 'active' : ''}
            type='button'
            onClick={() => handleClickControl('layers')}
          >
            <LayersIcon />
          </button>
          <button
            className={showControl === 'legend' ? 'active' : ''}
            type='button'
            onClick={() => handleClickControl('legend')}
          >
            <InfoIcon />
          </button>
        </div>
      </div>
      <TourDesignerMapLegend show={showControl === 'legend'} onClose={closeControl} />
      <TourDesignerMapLayers
        show={showControl === 'layers'}
        currentMap={currentMap}
        onChangeLayer={handleChangeLayer}
        isActiveTerritory={territoryLayer?.getVisible() || false}
        onToggleTerritory={handleToggleTerritory}
        onClose={closeControl}
      />
      <TourDesignerManagerBox
        nowActiveMap={currentMap}
        currentList={currentList}
        setCurrentList={handleChangeBoxList}
        onChangeMap={changeMap}
      />
    </div>
  );
};
