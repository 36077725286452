import { cva } from "class-variance-authority";
import clsx from "clsx";
import styles from './index.module.scss'
import React from "react";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  noMobile?: boolean;
};

const cvaContainer = cva([styles.container]);

export default function ContainerContent({
     children,
     className,
     noMobile,
   }: Props) {
  return (
    <div
      className={clsx(cvaContainer(), className, noMobile && styles.noMobile)}
    >
      {children}
    </div>
  );
}
