import { TStepItem } from '../../../../../shared/types/aboutUs.types';
import { ReactComponent as DocIcon } from 'assets/images/trk/trk-doc.svg';

type TStepCardProps = {
  stepItem: TStepItem;
};
export const StepCardMobile = ({ stepItem }: TStepCardProps) => {
  const handleDownloadFile = (filePatch: string) => {
    const link = document.createElement('a');
    link.download = stepItem.fileName;

    link.href = filePatch;

    link.click();
  };
  return (
    <div className='step-sm'>
      <span className='privileges-card-sm__icon privileges-step__order'>{stepItem.id}</span>
      {stepItem.label}

      {stepItem.btnLabel && stepItem.link && (
        <button
          onClick={() => handleDownloadFile(stepItem.link ? stepItem.link : '')}
          type='button'
          className='download-btn'
        >
          <DocIcon />
          <span>{stepItem.btnLabel}</span>
        </button>
      )}
    </div>
  );
};
