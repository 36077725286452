import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { LocationIcon } from '../LocationIcon/LocationIcon';

type TTourDesignerMapLegendProps = {
  show: boolean;
  onClose: () => void;
};

export const TourDesignerMapLegend = ({ show, onClose }: TTourDesignerMapLegendProps) => {
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  if (!show) {
    return null;
  }
  return (
    <>
      {isMobile && <div className='designer-map-legend__overlay' onClick={onClose}></div>}
      <div className='designer-map-legend'>
        {isMobile && (
          <div className='designer-map-legend__header'>
            <div className='designer-map-legend__head'>информация</div>
            <CloseIcon className='designer-map-legend__close' onClick={onClose} />
          </div>
        )}
        <ul className='designer-map-legend__list'>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='NATURE' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Природные локации</div>
          </li>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='WATER' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Водные локации</div>
          </li>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='MOUNT' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Горные локации</div>
          </li>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='SPECIAL_TRANSFER' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Локации для специального транспорта</div>
          </li>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='DEVELOP' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Промышленные локации</div>
          </li>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='HELICOPTER' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Локации для вертолета</div>
          </li>
          <li className='designer-map-legend__item'>
            <LocationIcon icon='CITY' className='designer-map-legend__icon' />
            <div className='designer-map-legend__text'>Города и населенные пункты</div>
          </li>
        </ul>
        {isMobile && (
          <div className='filters-manage'>
            <button className='filters-button dark full' type='button' onClick={onClose}>
              <span>закрыть</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};
