import React from 'react';
import s from './index.module.scss';
import Popup from 'newProject/features/popup/ui';
import Button from 'newProject/shared/ui/button';
import RequestService from 'services/request.service';
import Notification from 'shared/lib/notification';
import { REQUEST_STATUSSES } from 'shared/constants/const';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { getRequestByIdThunk } from '../../../../manageStore/request/request.thunk';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

type Props = {
  onDelete?: () => void;
  onClose: () => void;
  id: number;
};

export default function DeleteReqPopup({ onClose, onDelete, id }: Props) {
  const userData = useAppSelector((state) => state.user.userData);
  const dispatch = useAppDispatch();

  const changeStatus = async () => {
    if (!id || !userData) return;
    try {
      await RequestService.changeStatus({ id: id, user: userData, status: 'DELETED' });
      await dispatch(getRequestByIdThunk(Number(id)));
      onDelete && onDelete();
      onClose();
    } catch {
      Notification.error('Произошла ошибка');
    }
  };

  return (
    <Popup classNameInner={s.delete} onClose={onClose} withClose>
      <div className='h1'>Подтвердите отмену запроса</div>
      <div className={s.buttons}>
        <Button onClick={changeStatus} stretched>
          Отменить запрос
        </Button>
        <Button intent={'secondary'} onClick={onClose} stretched>
          Не отменять
        </Button>
      </div>
    </Popup>
  );
}
