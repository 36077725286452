import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TLocation,
  TOrder,
  TCity,
  TTerritoryLocation,
  TLocationOrder,
  TDesignerLocation,
  TCityOrder,
  TFilterLocations,
} from 'shared/types/location.types';
import { filterForLocations } from "../../helpers/toursFilters";

type TOrderLocations = Record<string, TCityOrder | TLocationOrder>;

export type TInitialState = {
  locations: TLocation[];
  locationsToDisplay: TLocation[];
  popularLocations: TLocation[];
  sortedLocations: TLocation[];
  searchedLocations: TLocation[] | null;
  filteredLocations: TLocation[] | null;
  filterForm: TFilterLocations;
  searchValue: string;
  orderLocations: TOrderLocations;
  order: TOrder;
  activeLocation: TLocation | null;
  needShowLocation: TLocation | null;
  activeCity: TCity | null;
  paramsOrder: { date: string; groupSize: string; };
  isReadyOrder: boolean;
  bottomBox: boolean;
  isLoading: boolean;
};

export const initialState: TInitialState = {
  isLoading: false,
  locations: [],
  locationsToDisplay: [],
  filterForm: {
    season: [],
    types: [],
    territory: [],
    transport: [],
  },
  popularLocations: [],
  sortedLocations: [],
  searchedLocations: null,
  filteredLocations: null,
  searchValue: '',
  orderLocations: {},
  order: {},
  activeLocation: null,
  needShowLocation: null,
  activeCity: null,
  isReadyOrder: false,
  paramsOrder: { date: String(new Date()), groupSize: '1' },
  bottomBox: true,
};

const reSequenceOrder = (order: TOrder, orderLocations: TOrderLocations) => {
  let sequence = 1;
  const isUpdateLocation = new Set<string>([]); //это костыль по сути на осм карте и на брендовой два одинаковых обьекта по сути.
  const setSequenceOrderLocations = (locationName: string, sequence: number, citySequence?: number) => {
    if (isUpdateLocation.has(locationName)) return;
    const orderLocation = orderLocations[locationName];
    if (orderLocation) {
      orderLocation.sequence = sequence;
      if (citySequence && 'city' in orderLocation) {
        orderLocation.citySequence = citySequence;
      }
    }
    isUpdateLocation.add(locationName);
  };

  Object.values(order).forEach((territory) => {
    territory.forEach((locationOrder) => {
      locationOrder.sequence = sequence;
      setSequenceOrderLocations(locationOrder.name, sequence);

      if ('items' in locationOrder) {
        locationOrder.items.forEach((location, index) => {
          const locSequence = index + 1;
          location.sequence = locSequence;
          location.citySequence = sequence;
          setSequenceOrderLocations(location.name, locSequence, sequence);
        });
      }
      sequence++;
    });
  });
};

export const slice = createSlice({
  name: 'designer',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setLocations: (state, { payload }: PayloadAction<TLocation[]>) => {
      state.locations = payload;
      state.locationsToDisplay = payload;
      state.popularLocations = payload.filter((location) => location.popular);

      const orderLocations: TOrderLocations = {};
      try {
        Object.values(state.order).forEach((territory) => {
          territory.forEach((location) => {
            orderLocations[location.name] = { ...location };
            if ('items' in location && location.items.length) {
              location.items.forEach((item) => {
                orderLocations[item.name] = item;
              });
            }
          });
        });
      } catch {
        state.order = {};
      }
      state.orderLocations = orderLocations;
    },

    setFilteredLocations: (
      state,
      {
        payload,
      }: PayloadAction<{
        filter: TFilterLocations;
        locations: TLocation[];
      }>
    ) => {
      state.filterForm = payload.filter;
      state.locationsToDisplay = payload.locations.filter((loc) => filterForLocations(loc, payload.filter));
    },

    setSortedLocations: (state, { payload }: PayloadAction<TLocation[]>) => {
      state.sortedLocations = payload;
      if (state.searchValue) {
        state.searchedLocations = payload.filter((item) =>
          item.name.toLowerCase().includes(state.searchValue.toLowerCase())
        );
      }
    },

    setSearchedLocations: (state, { payload }: PayloadAction<TLocation[] | null>) => {
      state.searchedLocations = payload;
    },

    setSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.searchValue = payload;
    },

    setOrder: (state, { payload }: PayloadAction<TOrder>) => {
      state.order = payload;
    },

    clearOrder: (state) => {
      state.order = {};
      state.orderLocations = {};
    },

    addItemToOrder: (state, { payload }: PayloadAction<TLocation | TCity>) => {
      if (!!state.orderLocations[payload.name]) return;
      const newOrder = { ...state.order };
      const newOrderLocations = { ...state.orderLocations };
      const territoryName = payload.cluster?.name || 'Прочее';
      const territoryOrder = newOrder[territoryName] || [];
      if ('city' in payload && payload.city) {
        const city = territoryOrder.find((city) => city.name === payload.city?.name);
        if (city && 'items' in city) {
          city.items.push({ ...payload, sequence: 0, citySequence: 0 });
        } else {
          newOrder[territoryName] = [
            ...territoryOrder,
            { ...payload.city, sequence: 0, items: [{ ...payload, sequence: 0, citySequence: 0 }] },
          ];
        }
        newOrderLocations[payload.city.name] = { ...payload.city, sequence: 0 };
      } else if ('city' in payload) {
        newOrder[territoryName] = [...territoryOrder, { ...payload, sequence: 0, citySequence: 0 }];
      } else {
        newOrder[territoryName] = [...territoryOrder, { ...payload, sequence: 0, items: [] }];
      }
      newOrderLocations[payload.name] = { ...payload, sequence: 0, citySequence: 0 };
      reSequenceOrder(newOrder, newOrderLocations);
      state.order = newOrder;
      state.orderLocations = newOrderLocations;
    },

    removeItemFromOrder: (state, { payload }: PayloadAction<{ loc: TDesignerLocation, cityIndex?: number; }>) => {
      const newOrder = { ...state.order };
      const newOrderLocations = { ...state.orderLocations };
      let territoryName = payload.loc.cluster?.name || 'Прочее';
      let territoryOrder = newOrder[territoryName];
      if ('city' in payload.loc && payload.loc.city) {
        // @ts-ignore
        const addedCity = territoryOrder?.find((city) => payload.loc.city?.name === city.name);
        if (addedCity && 'items' in addedCity) {
          if (payload.cityIndex === 0) {
            addedCity.items.shift();
          }

          if (payload.cityIndex && payload.cityIndex !== 0) {
            addedCity.items.splice(payload.cityIndex, 1);
          }
        }
      } else {
        newOrder[territoryName] = territoryOrder?.filter((loc) => {
          const isFind = payload.loc.name === loc.name;
          if (isFind && 'items' in loc && loc.items.length) {
            loc.items.forEach(({ name }) => delete newOrderLocations[name]);
          }
          return !isFind;
        });
      }
      if (!newOrder[territoryName]?.length) {
        delete newOrder[territoryName];
      }
      delete newOrderLocations[payload.loc.name];
      reSequenceOrder(newOrder, newOrderLocations);
      state.order = newOrder;
      state.orderLocations = newOrderLocations;
    },

    replaceItemOrder: (
      state,
      {
        payload,
      }: PayloadAction<{ oldIndex: number; newIndex: number; territory: TTerritoryLocation; cityIndex?: number; }>
    ) => {
      const { oldIndex, newIndex, cityIndex, territory } = payload;
      const newOrder = { ...state.order };
      const newOrderLocations = { ...state.orderLocations };
      const locationsTerritory = newOrder[territory];
      if (cityIndex === undefined) {
        const [replacingLocation] = locationsTerritory.splice(oldIndex, 1);
        locationsTerritory.splice(newIndex, 0, replacingLocation);
        newOrder[territory] = locationsTerritory;
        reSequenceOrder(newOrder, newOrderLocations);
        state.order = newOrder;
        state.orderLocations = newOrderLocations;
      } else {
        const city = locationsTerritory[cityIndex];
        if ('items' in city) {
          const [replacingLocation] = city.items.splice(oldIndex, 1);
          city.items.splice(newIndex, 0, replacingLocation);
          newOrder[territory] = locationsTerritory;
          reSequenceOrder(newOrder, newOrderLocations);
          state.order = newOrder;
          state.orderLocations = newOrderLocations;
        }
      }
    },

    setParamsOrder: (state, { payload }: PayloadAction<{ date?: string; groupSize?: string; }>) => {
      if (payload.date) {
        state.paramsOrder = { ...state.paramsOrder, date: payload.date };
      }
      if (payload.groupSize) {
        state.paramsOrder = { ...state.paramsOrder, groupSize: payload.groupSize };
      }
    },

    setReadyOrder: (state, { payload }: PayloadAction<boolean>) => {
      state.isReadyOrder = payload;
    },

    setCity: (state, { payload }: PayloadAction<TCity | null>) => {
      state.activeCity = payload;
    },

    setLocation: (state, { payload }: PayloadAction<TLocation | null>) => {
      state.activeLocation = payload;
    },

    setNeedShowLocation: (state, { payload }: PayloadAction<TLocation | null>) => {
      state.needShowLocation = payload;
    },

    resetStore: (state) => {
      state.activeLocation = null;
      state.activeCity = null;
      state.isReadyOrder = false;
      state.sortedLocations = state.locations.length ? [...state.locations] : [];
      state.searchedLocations = null;
      state.searchValue = '';
    },

    toggleBottomBox: (state) => {
      state.bottomBox = !state.bottomBox;
    },
  },
});

export const {
  setNeedShowLocation,
  setLoading,
  setLocations,
  setSortedLocations,
  setSearchedLocations,
  setSearchValue,
  setFilteredLocations,
  setOrder,
  clearOrder,
  addItemToOrder,
  removeItemFromOrder,
  replaceItemOrder,
  setParamsOrder,
  setReadyOrder,
  setCity,
  setLocation,
  resetStore,
  toggleBottomBox,
} = slice.actions;

export default slice.reducer;
