import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import Notification from 'shared/lib/notification';
import { USER_TYPES, REQUEST_STATUSSES } from 'shared/constants/const';
import { TUser } from 'shared/types/types';
import { TRequestFetch } from 'shared/types/request.types';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { selectCurrentRequest } from 'manageStore/request/request.select';
import RequestService from 'services/request.service';
import denyImg from 'assets/images/account/chat/deny.svg';
import doneImg from 'assets/images/account/chat/done.svg';
import workImg from 'assets/images/account/chat/work.svg';
import paidImg from 'assets/images/account/chat/paid.svg';

const getShowItemsMenu = (userData: TUser | null, request: TRequestFetch | null) => {
  return {
    processing: userData?.type === USER_TYPES.operator,
    paid: userData?.type === USER_TYPES.operator && !request?.isPaid,
    done: userData?.type === USER_TYPES.operator,
    denied: true,
  };
};

export const StatussesMenu = () => {
  const history = useHistory();

  const userData = useAppSelector((state) => state.user.userData);
  const request = selectCurrentRequest();

  const extraActions = useRef<HTMLUListElement>(null);
  const openExtraBtn = useRef<HTMLButtonElement>(null);

  const [tour, setTour] = useState(request);
  const [openExtra, setOpenExtra] = useState(false);

  const showItemsMenu = getShowItemsMenu(userData, tour);

  const handleOpenExtra = () => {
    setOpenExtra((prev) => !prev);
  };

  const changeStatus = (status: string) => {
    if (!request || !userData) return;
    const statusLink = status === REQUEST_STATUSSES.deleted ? REQUEST_STATUSSES.denied : status;
    RequestService.changeStatus({ id: request.id, user: userData, status })
      .then(({ data }) => {
        if (data === 'Статус успешно изменен!') {
          history.push(`/account/${userData.type.toLowerCase()}/${statusLink.toLowerCase()}-requests/${request.id}`);
          history.go(0);
        } else {
          Notification.warning('Что-то пошло не так...');
        }
      })
      .catch((error) => {
        Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
      });
  };

  const paidOrder = () => {
    if (!request) return;
    RequestService.paidOrder(request.id)
      .then(() => {
        setTour((prev) => (prev ? { ...prev, isPaid: true } : prev));
      })
      .catch((error) => {
        Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
      });
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      openExtraBtn.current &&
      extraActions.current &&
      !e.composedPath().includes(openExtraBtn.current) &&
      !e.composedPath().includes(extraActions.current)
    )
      setOpenExtra(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className='chat-bottom__extra'>
      <button className='chat-bottom__extra-btn' type='button' ref={openExtraBtn} onClick={handleOpenExtra}></button>
      <ul className={cn('chat-bottom__extra-actions', { open: openExtra })} ref={extraActions}>
        {showItemsMenu.processing && (
          <li className='extra-action'>
            <img src={workImg} alt='Карандаш' />
            <button className='extra-action__button' type='button' onClick={changeStatus.bind(this, 'PROCESSING')}>
              В работу
            </button>
          </li>
        )}
        {showItemsMenu.paid && (
          <li className='extra-action'>
            <img src={paidImg} alt='Кошелек' />
            <button className='extra-action__button' type='button' onClick={paidOrder.bind(this, 'PAID')}>
              Оплачен
            </button>
          </li>
        )}
        {showItemsMenu.done && (
          <li className='extra-action'>
            <img src={doneImg} alt='Галочка' />
            <button className='extra-action__button' type='button' onClick={changeStatus.bind(this, 'DONE')}>
              Завершить тур
            </button>
          </li>
        )}
        {showItemsMenu.denied && (
          <li className='extra-action'>
            <img src={denyImg} alt='Крест' />
            <button
              className='extra-action__button'
              type='button'
              onClick={
                userData?.type === 'OPERATOR' ? changeStatus.bind(this, 'DENIED') : changeStatus.bind(this, 'DELETED')
              }
            >
              Отменить тур
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};
