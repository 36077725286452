import React from 'react'
import { Edit } from 'react-admin'
import NewsForm from './NewsForm'

const NewsEdit = () => (
  <Edit>
    <NewsForm />
  </Edit>
)

export default NewsEdit
