import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { ArrayInput, Labeled, ReferenceInput, SelectInput, SimpleFormIterator, required } from 'react-admin';
import Box from '@mui/material/Box';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideTours = () => {
  return (
    <Labeled label={TRAVEL_GUIDE_FIELDS.tours} fullWidth>
      <Box sx={styleInBlock}>
        <ArrayInput source='tours' label='' fullWidth>
          <SimpleFormIterator disableReordering>
            <ReferenceInput source='id' reference='tours/published' perPage={Infinity} fullWidth>
              <SelectInput
                optionText='title'
                optionValue='id'
                label='Select...'
                fullWidth
                format={(v) => (v && v !== 'new' ? v : '')}
                validate={required()}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </Labeled>
  );
};
