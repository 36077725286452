import Layer from 'ol/layer/Layer';
import { composeCssTransform } from 'ol/transform';

export const getBrandMapLayer = (svgContainerEl: HTMLDivElement) => {
  const width = 130;
  const height = 137;
  const svgResolution = 400 / width;
  svgContainerEl.style.width = width + 'px';
  svgContainerEl.style.height = height + 'px';
  svgContainerEl.style.transformOrigin = 'top left';
  svgContainerEl.className = 'svg-layer';
  const brendMapLayer = new Layer({
    render: function (frameState) {
      const scale = svgResolution / frameState.viewState.resolution;
      const center = frameState.viewState.center;
      const size = frameState.size;
      const cssTransform = composeCssTransform(
        size[0] / 2,
        size[1] / 2,
        scale,
        scale,
        frameState.viewState.rotation,
        -center[0] / svgResolution - width / 2,
        center[1] / svgResolution - height / 2
      );
      svgContainerEl.style.transform = cssTransform;
      svgContainerEl.style.position = 'absolute';
      return svgContainerEl;
    },
  });
  return brendMapLayer;
};
