import { NewsItemIntro } from './NewsItemIntro';
import { TCategoryNews } from '../../../shared/types/news.types';
import { TNew } from '../../../shared/types/types';
import {useEffect, useRef, useState} from 'react';
import Slider, { Settings } from 'react-slick';
import { ButtonSlider } from '../../../shared/lib/ButtonSlider/ButtonSlider';
import BlogService from "../../../services/blog.service";
import {convertBlogToNews} from "../../../helpers/blog/convertorBlogToNews";
import {getUrlImg} from "../../../helpers/getUrlImg";
import {useSelector} from "react-redux";
import {selectImportantBlogs} from "../../../manageStore/news/newsSlice";

type TNews = {
  news: TNew[];
};

const settingsSlider: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  slidesToScroll: 1,
  autoplaySpeed: 10000,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};
export const NewsIntroSlider = ({ news }: TNews) => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const type: TCategoryNews = 'важно';
  const importantBlog = useSelector(selectImportantBlogs);

  const slickTrackRef = useRef<{
    node: HTMLDivElement | null;
  }>({ node: null });

  const handleChangeSlide = (prev: number, next: number) => {
    setCurrentSlider(next);
  };

  const handleInitSlider = () => {
    slickTrackRef.current.node = document.querySelector('.news-item__slider .slick-track');
    handleChangeSlide(0, 0);
  };

  return (
      <div className='news-item '>
        <Slider {...settingsSlider} className='news-item__slider' onInit={handleInitSlider}>
          {importantBlog.map((newItem) => (
              <NewsItemIntro isBlog={true} key={newItem.id} tag={type} id={newItem.id} title={newItem.title} backImage={newItem.preview?getUrlImg(newItem.preview?.path):''} className={type} />
          ))}
        </Slider>
      </div>
  );
};
