import { HomeFindTour } from 'features';
import { useTranslation } from 'react-i18next';

export const HomeIntro = () => {
  const { t } = useTranslation();

  return (
    <section className='home-intro'>
      <div className='home-intro__background'>
        <div className='home-intro__background__image'></div>
      </div>
      <div className='container home-intro__container'>
        <div className='home-intro__inner'>
          <h1 className='home-intro__title'>
            Таймыр <span>меняет</span> тебя
          </h1>
          <h4 className='home-intro__subtitle'>{t('home-intro.subtitle')}</h4>
        </div>
        <HomeFindTour />
      </div>
    </section>
  );
};
