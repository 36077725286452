import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';
import { TRANSFORM_TRAVEL_GUIDE } from 'shared/constants/travelGuide.constants';
import {
  TFiltersTravelsGuide,
  TNamesParamsTravelsGuideFilter,
  TThemeTravelGuide,
} from 'shared/types/travelGuide.types';

const itemsTheme = [
  { value: null, label: 'Все' },
  {
    value: TRANSFORM_TRAVEL_GUIDE.theme.attractions.value,
    label: TRANSFORM_TRAVEL_GUIDE.theme.attractions.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.theme.gastronomy.value,
    label: TRANSFORM_TRAVEL_GUIDE.theme.gastronomy.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.theme.industry.value,
    label: TRANSFORM_TRAVEL_GUIDE.theme.industry.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.theme.ethnicity.value,
    label: TRANSFORM_TRAVEL_GUIDE.theme.ethnicity.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.theme.history.value,
    label: TRANSFORM_TRAVEL_GUIDE.theme.history.label,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.mountains.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.mountains.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.water.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.water.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.touristArea.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.touristArea.value,
  },
];

type TTravelsGuideFiltersByThemeProps = {
  filters: TThemeTravelGuide | null;
  onClick: <TName extends TNamesParamsTravelsGuideFilter>(name: TName, value: TFiltersTravelsGuide[TName]) => void;
};

export const TravelsGuideFiltersByTheme = ({ filters, onClick }: TTravelsGuideFiltersByThemeProps) => {
  const checkActive = (value: string | null) => {
    return filters === value;
  };

  return (
    <ParamsFilterGroup title='Темы'>
      {itemsTheme.map((theme) => (
        <ParamsFilterItem
          key={theme.label}
          active={checkActive(theme.value)}
          onClick={() => onClick('theme', theme.value)}
          label={theme.label}
        />
      ))}
    </ParamsFilterGroup>
  );
};
