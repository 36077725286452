import {TTour} from "../../../shared/types/tours.types";
import parse from "html-react-parser";

export type TDopLinkProps = {
    intresting :string |null
}


export const ListDopLink = ( {intresting=''}:TDopLinkProps ) => {
    return (
        <div className='listdoplink'>
            {parse(intresting || '', { trim: true })}
        </div>
    );
};
