import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useFirstView } from 'shared/hooks/useFirstView';
import { operatorNavs, mobileOperatorNavs } from './navigation';
import Aside from 'components/Account/Aside';
import MainReviews from 'components/Account/Reviews/MainReviews';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { getTouristReviewsListThunk } from 'manageStore/reviews/reviews.thunk';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { USER_TYPES } from 'shared/constants/const';
import AlternativeHeader from 'components/Header/AlternativeHeader';

const ReviewsPage = () => {
  const history = useHistory();

  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);

  const dispatch = useAppDispatch();

  const firstView = useFirstView(userData);

  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    } else if (userData.type !== USER_TYPES.operator) {
      history.push('/account');
    }
  }, [isAuth, userData, history]);

  useEffect(() => {
    dispatch(getTouristReviewsListThunk());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Отзывы - Туркластер Арктический</title>
      </Helmet>
      <section className='account'>
        <AlternativeHeader />
        <Aside navs={operatorNavs} mobileNavs={mobileOperatorNavs} unreadStatus={firstView} />
        <section className='Amain'>
          <MainReviews />
          <MobileBottom />
        </section>
      </section>
    </>
  );
};

export default ReviewsPage;
