import {TourCard} from "../../tour/TourCard/TourCard";
import {FactBlogDTO} from "../../../shared/types/types";
import {TTour} from "../../../shared/types/tours.types";
export type TToursBlogBlockProps = {
    tours :TTour[] |null
}

export const ToursBlock = ({tours}: TToursBlogBlockProps ) => {
    return (
        <div className='blogTour'>
            <h4>Туры и экскурсии</h4>
            <div className='tours__list-wrapper'>
                <div className='tours-list'>
                    {tours && tours?.length > 0 ? tours.map((tour) => (
                        <TourCard key={tour.id} tour={tour} />
                    )):<></>}
                </div>
            </div>
        </div>
    );
};
