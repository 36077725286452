type TCookieNotificationProps = {
  handleClickAccept: () => void;
};

const CookieNotification = ({ handleClickAccept }: TCookieNotificationProps) => {
  return (
    <div className='cookie-notification'>
      <p className='cookie-notification__text text'>
        Для улучшения Вашего взаимодействия с сайтом мы используем файлы cookie. Оставаясь на сайте, Вы принимаете наши
        условия использования, политику конфиденциальности и соглашаетесь со сбором данных посредством файлов cookie.
      </p>
      <div className='cookie-notification__accept'>
        <button className='cookie-notification__btn btn' onClick={handleClickAccept}>
          Принять
        </button>
      </div>
    </div>
  );
};

export default CookieNotification;
