import { Show, SimpleShowLayout, TextField, FunctionField, RaRecord } from 'react-admin';
import { CITY_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';
import CoordsShow from '../CoordsShow';

export const ClustorShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={CITY_FIELDS.title} />
        <FunctionField
          label={CITY_FIELDS.preview}
          render={(record: RaRecord) => {
            return record.photo ? (
              <img
                className='admin-image admin-image__single'
                src={`${URL_FOR_IMG}/${record.photo.path}`}
                alt='preview'
              />
            ) : null;
          }}
        />
        <TextField source='description' label={CITY_FIELDS.description} />
        <CoordsShow bothMaps />
      </SimpleShowLayout>
    </Show>
  </div>
);
