export const TourItemBenefits = ({ benefits }: { benefits: string | null }) => {
  const benefitsArr = benefits?.split('\n');

  if (!benefitsArr) {
    return null;
  }

  return (
    <div className='benefits-tour'>
      <div className='benefits-tour__title'>Преимущества:</div>
      <ul className='benefits-tour__list'>
        {benefitsArr.map((benefit, index) => (
          <li className='benefits-tour__item' key={index}>
            {benefit}
          </li>
        ))}
      </ul>
    </div>
  );
};
