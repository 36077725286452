import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { registrationOperator } from 'manageStore/user/userSlice';
import DatePicker from 'react-datepicker';

type TRegFormGidProps = {
  typeUser: string;
  setOpenPortal: (isOpen: boolean) => void;
};

const RegFormGid = ({ typeUser, setOpenPortal }: TRegFormGidProps) => {
  const [saving, setSaving] = useState(false);
  const handleSaving = () => setSaving((prev) => !prev);
  const formik = useFormik({
    initialValues: {
      type: typeUser,
      registryNumber: '',
      lastName: '',
      firstName: '',
      patronymic: '',
      passportSeries: '',
      passportNumber: '',
      passportIssueDate: null,
      passportIssuedBy: '',
      inn: '',
      languages: '',
      phone: '',
      email: '',
      certificateSeries: '',
      certificateNumber: '',
      certificateIssuedBy: '',
      certificateIssueDate: null,
      certificateExpiry: null,
      policy: false,
      personal: false,
    },
    validationSchema: Yup.object({
      registryNumber: Yup.string().required('Заполните поле'),
      lastName: Yup.string().required('Заполните поле'),
      firstName: Yup.string().required('Заполните поле'),
      patronymic: Yup.string().required('Заполните поле'),
      passportSeries: Yup.string().required('Заполните поле'),
      passportNumber: Yup.string().required('Заполните поле'),
      passportIssueDate: Yup.string().nullable().required('Заполните поле'),
      passportIssuedBy: Yup.string().required('Заполните поле'),
      languages: Yup.string().required('Заполните поле'),
      certificateSeries: Yup.string().required('Заполните поле'),
      certificateNumber: Yup.string().required('Заполните поле'),
      certificateIssuedBy: Yup.string().required('Заполните поле'),
      certificateIssueDate: Yup.string().nullable().required('Заполните поле'),
      certificateExpiry: Yup.string().nullable().required('Заполните поле'),
      inn: Yup.string().required('Заполните поле'),
      phone: Yup.string().required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      policy: Yup.boolean().oneOf([true], 'Политика должна быть принята'),
      personal: Yup.boolean().oneOf([true], 'Соглашение должно быть принято'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        await registrationOperator({ ...values });
        setOpenPortal(true);
      } catch (e) {
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <label>
        <input
          className='login-input'
          id='registryNumber'
          type='text'
          placeholder='Номер реестровой записи'
          {...formik.getFieldProps('registryNumber')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.registryNumber && formik.errors.registryNumber ? formik.errors.registryNumber : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='firstName'
          type='text'
          placeholder='Имя'
          {...formik.getFieldProps('firstName')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='lastName'
          type='text'
          placeholder='Фамилия'
          {...formik.getFieldProps('lastName')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='patronymic'
          type='text'
          placeholder='Отчество'
          {...formik.getFieldProps('patronymic')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.patronymic && formik.errors.patronymic ? formik.errors.patronymic : ''}
      </p>
      <label>
        <input className='login-input' id='inn' type='text' placeholder='ИНН' {...formik.getFieldProps('inn')} />
      </label>
      <p className='login-form-error'>{formik.touched.inn && formik.errors.inn ? formik.errors.inn : ''}</p>
      <label>
        <input
          className='login-input'
          id='phone'
          type='text'
          placeholder='Номер телефона'
          {...formik.getFieldProps('phone')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}</p>
      <label>
        <input
          className='login-input'
          id='email'
          type='email'
          placeholder='E-mail'
          {...formik.getFieldProps('email')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.email && formik.errors.email ? formik.errors.email : ''}</p>
      <label>
        <textarea
          className='login-input registration-input__textarea'
          id='languages'
          placeholder='Иностранный язык (иностранные языки)'
          {...formik.getFieldProps('languages')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.languages && formik.errors.languages ? formik.errors.languages : ''}
      </p>
      <p className='registration-text'>Документ, удостоверяющий личность</p>
      <label>
        <input
          className='login-input'
          id='passportSeries'
          type='text'
          placeholder='Серия'
          {...formik.getFieldProps('passportSeries')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.passportSeries && formik.errors.passportSeries ? formik.errors.passportSeries : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='passportNumber'
          type='text'
          placeholder='Номер'
          {...formik.getFieldProps('passportNumber')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.passportNumber && formik.errors.passportNumber ? formik.errors.passportNumber : ''}
      </p>
      <label>
        <DatePicker
          selected={formik.values.passportIssueDate}
          className='login-input'
          placeholderText='Дата выдачи'
          dateFormat='dd-MM-yyyy'
          onChange={(date) => formik.setFieldValue('passportIssueDate', date)}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.passportIssueDate && formik.errors.passportIssueDate ? formik.errors.passportIssueDate : ''}
      </p>
      <label>
        <textarea
          className='login-input registration-input__textarea'
          id='passportIssuedBy'
          placeholder='Кем выдан'
          {...formik.getFieldProps('passportIssuedBy')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.passportIssuedBy && formik.errors.passportIssuedBy ? formik.errors.passportIssuedBy : ''}
      </p>
      <p className='registration-text'>Аттестат экскурсовода или инструктора-проводника</p>
      <label>
        <input
          className='login-input'
          id='certificateSeries'
          type='text'
          placeholder='Серия'
          {...formik.getFieldProps('certificateSeries')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.certificateSeries && formik.errors.certificateSeries ? formik.errors.certificateSeries : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='certificateNumber'
          type='text'
          placeholder='Регистрационный номер'
          {...formik.getFieldProps('certificateNumber')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.certificateNumber && formik.errors.certificateNumber ? formik.errors.certificateNumber : ''}
      </p>
      <label>
        <DatePicker
          selected={formik.values.certificateIssueDate}
          className='login-input'
          placeholderText='Дата выдачи'
          dateFormat='dd-MM-yyyy'
          onChange={(date) => formik.setFieldValue('certificateIssueDate', date)}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.certificateIssueDate && formik.errors.certificateIssueDate
          ? formik.errors.certificateIssueDate
          : ''}
      </p>
      <label>
        <textarea
          className='login-input registration-input__textarea'
          id='passportIscertificateIssuedBysuedBy'
          placeholder='Кем выдан'
          {...formik.getFieldProps('certificateIssuedBy')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.certificateIssuedBy && formik.errors.certificateIssuedBy
          ? formik.errors.certificateIssuedBy
          : ''}
      </p>
      <label>
        <DatePicker
          selected={formik.values.certificateExpiry}
          className='login-input'
          placeholderText='Срок действия'
          dateFormat='dd-MM-yyyy'
          onChange={(date) => formik.setFieldValue('certificateExpiry', date)}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.certificateExpiry && formik.errors.certificateExpiry ? formik.errors.certificateExpiry : ''}
      </p>
      <label className='registration-label-policy'>
        <input className='registration-policy' id='policy' type='checkbox' {...formik.getFieldProps('policy')} />
        <span></span>
        <p>
          Согласен с{' '}
          <Link className='registration-policy-link' to='/privacy'>
            политикой конфиденциальности
          </Link>{' '}
          и{' '}
          <Link className='registration-policy-link' to='/useragreement'>
            пользовательским соглашением
          </Link>
        </p>
      </label>
      <p className='login-form-error'>{formik.touched.policy && formik.errors.policy ? formik.errors.policy : ''}</p>
      <label className='registration-label-policy'>
        <input className='registration-policy' id='personal' type='checkbox' {...formik.getFieldProps('personal')} />
        <span></span>
        <p>
          Согласен на{' '}
          <a className='registration-policy-link' href='/docs/personal_consent.pdf' target='_blank'>
            обработку персональных данных
          </a>
        </p>
      </label>
      <p className='login-form-error'>
        {formik.touched.personal && formik.errors.personal ? formik.errors.personal : ''}
      </p>
      <button
        className='login-form-btn btn'
        type='submit'
        disabled={saving}
        onClick={(event) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        Зарегистироваться
      </button>
    </>
  );
};

export default RegFormGid;
