import { ReactComponent as PlayIcon } from 'assets/images/common/play.svg';

type TYoutubeCoverProps = {
  link: string;
  onClick: () => void;
};

export const YoutubeCover = ({ link, onClick }: TYoutubeCoverProps) => {
  return (
    <>
      <picture>
        <source srcSet={`https://i.ytimg.com/vi_webp/${link}/sddefault.webp`} type='image/webp' />
        <img className='yvideo-app__media' src={`https://i.ytimg.com/vi/${link}/sddefault.jpg`} alt='Превью видео' />
      </picture>
      <button className='yvideo-app__button' aria-label='Запустить видео' onClick={onClick}>
        <PlayIcon />
      </button>
    </>
  );
};
