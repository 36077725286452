import { useState, useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import { createEmpty, extend } from 'ol/extent';
import { Cluster, OSM } from 'ol/source';
import { Tile as TileLayer } from 'ol/layer';
import PointDesignBalloon from '../BalloonsMap/PointDesignBalloon';
import PointReadyTourBalloon from '../BalloonsMap/PointReadyTourBalloon';
import PointTouristBalloon from '../BalloonsMap/PointTouristBalloon';
import { ReactComponent as BalloonArrow } from 'assets/images/map/balloon-arrow.svg';
import { generateClusters, generateOverlay, transformCoords } from '../../helpers/map.helper';
import { TCity, TLocation, TPointOsmMap } from 'shared/types/location.types';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Feature from 'ol/Feature';

type TSortedLocation = TLocation | (TCity & { items: TLocation[] });

type TOsmMapProps = {
  center: number[];
  locations: TPointOsmMap[];
  handleClickMap?: () => void;
  showMore?: (location: TLocation) => void;
  showBrendMap?: () => void;
  tourist?: boolean;
  admin?: boolean;
};

type TBackButtonMapProps = {
  showBrendMap: () => void;
};

const BackButtonMap = ({ showBrendMap }: TBackButtonMapProps) => {
  return (
    <button className='osm-back-btn btn' onClick={showBrendMap}>
      Назад
    </button>
  );
};

const OsmMap = ({ center, locations, handleClickMap, showMore, showBrendMap, tourist, admin }: TOsmMapProps) => {
  const zoom = 13;
  const popup = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [overlay, setOverlay] = useState<Overlay | null>(null);
  const [clusters, setClusters] = useState<VectorLayer<Cluster> | null>(null);
  const [pointDesignInfo, setPointDesignInfo] = useState<TLocation | null>(null);
  const [pointReadyTourInfo, setPointReadyTourInfo] = useState<TLocation | null>(null);
  const [pointTouristInfo, setPointTouristInfo] = useState<TLocation | null>(null);

  const setPointer = (event: MapBrowserEvent<any>, newClusters: VectorLayer<Cluster>) => {
    if (!map) return;
    newClusters.getFeatures(event.pixel).then((features) => {
      map.getTargetElement().style.cursor = features[0] ? 'pointer' : '';
    });
  };

  const closeBalloon = () => {
    setPointDesignInfo(null);
    setPointReadyTourInfo(null);
    setPointTouristInfo(null);
    if (overlay) {
      overlay.setPosition(undefined);
    }
  };

  const handleClickCluster = (event: MapBrowserEvent<any>, newClusters: VectorLayer<Cluster>) => {
    closeBalloon();
    if (admin || !overlay || !map) return;
    newClusters.getFeatures(event.pixel).then((features) => {
      if (features.length === 0) return;
      const clusterMembers: Feature[] = features[0].get('features');
      const infoLoc: TLocation = clusterMembers[0].get('infoLoc');
      const extent = createEmpty();
      const coords = features[0].getGeometry()?.getExtent();
      if (clusterMembers.length > 1) {
        clusterMembers.forEach((feature) => {
          const newExtent = feature?.getGeometry()?.getExtent();
          if (newExtent) {
            extend(extent, newExtent);
          }
        });
        const view = map.getView();
        if (view.getZoom() !== view.getMaxZoom()) {
          view.fit(extent, { duration: 500, padding: [100, 50, 50, 50] });
        }
      } else if (showMore) {
        setPointDesignInfo(infoLoc);
        overlay.setPosition(coords);
      } else if (tourist) {
        setPointTouristInfo(infoLoc);
        overlay.setPosition(coords);
      } else {
        setPointReadyTourInfo(infoLoc);
        overlay.setPosition(coords);
      }
    });
  };

  const addToOrder = (loc: TSortedLocation) => {
    // dispatch(addItemToOrder(loc));
  };

  const showDescLoc = (location: TLocation) => {
    if (showMore) {
      showMore(location);
    }
  };

  useEffect(() => {
    if (!map) return;
    const transformCenter = transformCoords(center);
    const mapView = map.getView();
    mapView.setCenter(transformCenter);
  }, [map, center]);

  useEffect(() => {
    if (!map) return;
    closeBalloon();
    const newClusters = generateClusters(locations);
    if (clusters) map.removeLayer(clusters);
    setClusters(newClusters);
    map.getLayers().insertAt(1, newClusters);
    const handleSingleClick = (event: MapBrowserEvent<any>) => handleClickCluster(event, newClusters);
    const handlePointerMove = (event: MapBrowserEvent<any>) => setPointer(event, newClusters);
    if (!handleClickMap) {
      if (window.innerWidth > 768) {
        map.on('pointermove', (event) => setPointer(event, newClusters));
      }
      map.on('singleclick', handleSingleClick);
    }
    return () => {
      map.un('pointermove', handlePointerMove);
      map.un('singleclick', handleSingleClick);
    };
  }, [map, locations]);

  // useEffect(() => {
  //   if (!map) return;
  //   setTimeout(() => {
  //     map.updateSize();
  //   }, 1000);
  // }, [map]);

  useEffect(() => {
    const raster = new TileLayer({
      source: new OSM(),
    });
    const overlayPopup = generateOverlay(popup.current);
    const map = new Map({
      target: 'osm-map',
      layers: [raster],
      overlays: [overlayPopup],
      view: new View({
        center: transformCoords(center),
        minZoom: 10,
        maxZoom: 17,
        zoom,
      }),
    });
    setMap(map);
    setOverlay(overlayPopup);
    if (handleClickMap) {
      map.on('singleclick', handleClickMap);
    }
    setTimeout(() => {
      map.updateSize();
    }, 1000);
    return () => {
      if (handleClickMap) {
        map.un('singleclick', handleClickMap);
      }
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id='osm-map' style={{ width: '100%', height: '100%' }}></div>
      {showBrendMap && <BackButtonMap showBrendMap={showBrendMap} />}
      <div className='balloon' ref={popup}>
        <div className='balloon-wrapper'>
          {pointDesignInfo && (
            <PointDesignBalloon loc={pointDesignInfo} addToOrder={addToOrder} showDescLoc={showDescLoc} />
          )}
          {pointReadyTourInfo && <PointReadyTourBalloon loc={pointReadyTourInfo} />}
          {pointTouristInfo && <PointTouristBalloon loc={pointTouristInfo} />}
          <div className='balloon__close'>
            <div className='balloon__close-button' onClick={closeBalloon}></div>
          </div>
        </div>
        <div className='balloon-arrow'>
          <BalloonArrow className='balloon-arrow__svg' />
        </div>
      </div>
    </div>
  );
};

export default OsmMap;
