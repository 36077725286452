import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { ArrayInput, FormDataConsumer, Labeled, SimpleFormIterator, TextInput, required } from 'react-admin';
import Box from '@mui/material/Box';
import FileInputAdmin from '../FileInputAdmin';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideFaces = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Labeled label={TRAVEL_GUIDE_FIELDS.faces} fullWidth>
          <Box sx={styleInBlock}>
            <ArrayInput source='faces' label='' fullWidth>
              <SimpleFormIterator disableReordering disableAdd={formData?.faces?.length > 4}>
                {/* <TextInput source='title' label='Заголовок' fullWidth format={(v) => (v ? v : '')} /> */}
                <TextInput
                  source='description'
                  label='Информация'
                  fullWidth
                  multiline={true}
                  minRows='3'
                  maxRows='3'
                  format={(v) => (v ? v : '')}
                  validate={required()}
                />
                <FileInputAdmin source='photo' label='Фото' multiple={false} validate={[required()]} />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </Labeled>
      )}
    </FormDataConsumer>
  );
};
