import {TourItemPath} from "../../../widgets/lib/tourItemMain/TourItemPath/TourItemPath";
import {TLocation} from "../../../shared/types/location.types";

export const PlaceOnMap = ({locations}: { locations: TLocation[] | null }) => {
    return (
        <div className='guideBlock placeOnMap' >
            <h4>Место на карте</h4>
            <TourItemPath locations={locations} />
        </div>
    );
};
