import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registration } from 'manageStore/user/userSlice';
import PasswordStrengthBar from 'react-password-strength-bar';
import { ReactComponent as PasswordHideIcon } from 'assets/icons/password-hide.svg';
import {ReactComponent as EyeIcon} from 'assets/icons/eye.svg'

const RegFormTourist = () => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const handleSaving = () => setSaving((prev) => !prev);
  const formik = useFormik({
    initialValues: {
      login: '',
      email: '',
      phone: '',
      password: '',
      password2: '',
      policy: false,
      personal: false,
    },
    validationSchema: Yup.object({
      login: Yup.string().min(3, 'Длина должна быть не менее 3 символов').required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      phone: Yup.string()
        .matches(/('+7'|7|8)([0-9]{10})$/, 'Некорректный номер телефона')
        .required('Заполните поле'),
      password: Yup.string()
        .min(5, 'Длина пароля должна быть не менее 5 символов')
        .max(20, 'Длина пароля должна быть не более 20 символов')
        .required('Заполните поле'),
      password2: Yup.string()
        .min(5, 'Длина пароля должна быть не менее 5 символов')
        .max(20, 'Длина пароля должна быть не более 20 символов')
        .oneOf([Yup.ref('password')], 'Пароли должны совпадать')
        .required('Заполните поле'),
      policy: Yup.boolean().oneOf([true], 'Политика должна быть принята'),
      personal: Yup.boolean().oneOf([true], 'Соглашение должно быть принято'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        // @ts-ignore
        dispatch(registration({ ...values }));
      } catch (e) {
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <label>
        <input
          className='login-input'
          id='login'
          type='text'
          placeholder='Имя Фамилия'
          {...formik.getFieldProps('login')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.login && formik.errors.login ? formik.errors.login : ''}</p>
      <label>
        <input className='login-input' id='email' type='email' placeholder='Email' {...formik.getFieldProps('email')} />
      </label>
      <p className='login-form-error'>{formik.touched.email && formik.errors.email ? formik.errors.email : ''}</p>
      <label>
        <input
          className='login-input'
          id='phone'
          type='phone'
          placeholder='Номер телефона'
          {...formik.getFieldProps('phone')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}</p>
      <label className="password-field">
        <input
          className="login-input"
          id="password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Пароль"
          {...formik.getFieldProps('password')}
        />
        <button type="button" onClick={() => setShowPassword(prev => !prev)} className="password-field_eye">
          {!showPassword ? <EyeIcon /> : <PasswordHideIcon />}
        </button>
      </label>

      <p className="login-form-error">
        {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
      </p>
      {formik.values.password.length > 4 && (
        <PasswordStrengthBar
          password={formik.getFieldProps('password').value}
          minLength={5}
          shortScoreWord={'слишком короткий'}
          scoreWords={['очень простой', 'простой', 'нормальный', 'хороший', 'сложный']}
          style={{ position: 'relative', marginBottom: '10px', top: '-23px' }}
        />
      )}
      <label className="password-field">
        <input
          className="login-input"
          id="password2"
          type={showPasswordRepeat ? 'text' : 'password'}
          placeholder="Пароль повторно"
          {...formik.getFieldProps('password2')}
        />
        <button type="button" onClick={() => setShowPasswordRepeat(prev => !prev)} className="password-field_eye">
          {!showPasswordRepeat ? <EyeIcon /> : <PasswordHideIcon />}
        </button>
      </label>
      <p className="login-form-error">
        {formik.touched.password2 && formik.errors.password2 ? formik.errors.password2 : ''}
      </p>
      <label className='registration-label-policy'>
        <input className='registration-policy' id='policy' type='checkbox' {...formik.getFieldProps('policy')} />
        <span></span>
        <p>
          Согласен с{' '}
          <Link className='registration-policy-link' to='/privacy'>
            политикой конфиденциальности
          </Link>{' '}
          и{' '}
          <Link className='registration-policy-link' to='/useragreement'>
            пользовательским соглашением
          </Link>
        </p>
      </label>
      <p className='login-form-error'>{formik.touched.policy && formik.errors.policy ? formik.errors.policy : ''}</p>
      <label className='registration-label-policy'>
        <input className='registration-policy' id='personal' type='checkbox' {...formik.getFieldProps('personal')} />
        <span></span>
        <p>
          Согласен на{' '}
          <a className='registration-policy-link' href='/docs/personal_consent.pdf' target='_blank'>
            обработку персональных данных
          </a>
        </p>
      </label>
      <p className='login-form-error'>
        {formik.touched.personal && formik.errors.personal ? formik.errors.personal : ''}
      </p>
      <button
        className='login-form-btn btn'
        type='submit'
        disabled={saving}
        onClick={(event) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        Зарегистироваться
      </button>
    </>
  );
};

export default RegFormTourist;
