import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useFirstView } from 'shared/hooks/useFirstView';
import Notification from 'shared/lib/notification';
import RequestService from 'services/request.service';
import Aside from 'components/Account/Aside';
import MainRequests from 'components/Account/MainRequests';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import Loading from 'components/Loading/Loading';
import { REQUEST_STATUSSES, USER_TYPES } from 'shared/constants/const';
import { NoRequests } from '../../components/Account/NoRequests';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { TProfileLink } from 'shared/types/common.types';
import { TRequestFetch } from 'shared/types/request.types';
import { TUserType } from 'shared/types/types';
import AlternativeHeader from 'components/Header/AlternativeHeader';

type TRequestsPageProps = {
  navs: TProfileLink[][];
  mobileNavs: TProfileLink[];
  type: TUserType;
};

const RequestsPage = ({ navs, mobileNavs, type }: TRequestsPageProps) => {
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();
  const firstView = useFirstView(userData);

  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    } else if (userData.type !== type) {
      history.push('/account');
    }
  }, [isAuth, userData, history, type]);

  const [requests, setRequests] = useState<TRequestFetch[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userData) return;
    const pathname = history.location.pathname.split('/');
    const requestsType = pathname[pathname.length - 1].split('-')[0].toUpperCase();
    const status =
      requestsType === REQUEST_STATUSSES.denied ? [REQUEST_STATUSSES.denied, REQUEST_STATUSSES.deleted] : requestsType;
    const reqData = {
      status,
      id: userData.id,
      type: userData.type.toLowerCase(),
    };

    setLoading(true);
    RequestService.fetchRequests(reqData)
      .then(({ data }) => {
        setRequests(data);
      })
      .catch((error) => {
        setRequests([]);
        Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history.location.pathname, userData]);

  return (
    <>
      <Helmet>
        <title>Заявки - Туркластер Арктический</title>
      </Helmet>
      <section className='account'>
        <AlternativeHeader />
        <Aside navs={navs} mobileNavs={mobileNavs} unreadStatus={firstView} />
        <section className='Amain'>
          {loading ? (
            <Loading />
          ) : requests && requests.length ? (
            <MainRequests requests={requests} type={type} />
          ) : (
            <NoRequests
              title={`У вас нет активных ${userData?.type === USER_TYPES.tourist ? 'заказов' : 'заявок'}`}
              type={type}
            />
          )}
          <MobileBottom />
        </section>
      </section>
    </>
  );
};

export default RequestsPage;
