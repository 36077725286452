import React from 'react'
import { Edit } from 'react-admin'
import ParticipantsForm from './ParticipantsForm'

const ParticipantsEdit = () => (
  <Edit>
    <ParticipantsForm />
  </Edit>
)

export default ParticipantsEdit
