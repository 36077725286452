import * as React from 'react';
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
  ChipField,
  ImageField,
  Pagination,
} from 'react-admin';
import { MENU_ADMIN_LIST, NEWS_FIELDS } from '../../../shared/constants/const';

const NewsList = () => (
  <List
    title={MENU_ADMIN_LIST.locations}
    exporter={false}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField
        source="title"
        sortable={false}
        label={NEWS_FIELDS.title}
      />
      <ChipField
        source="important"
        sortable={false}
        label={NEWS_FIELDS.important}
      />
      <ChipField
        source="isPublished"
        sortable={false}
        label={NEWS_FIELDS.isPublished}
      />
      <TextField
        source="author"
        sortable={false}
        label={NEWS_FIELDS.author}
      />
      <TextField
        source="date"
        sortable={false}
        label={NEWS_FIELDS.date}
      />
      <ImageField
        source="preview"
        sortable={false}
        label={NEWS_FIELDS.preview}
      />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default NewsList;
