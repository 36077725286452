import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import {
  ArrayInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  SimpleFormIterator,
  TextInput,
  required,
} from 'react-admin';
import Box from '@mui/material/Box';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideHistory = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <Labeled label={TRAVEL_GUIDE_FIELDS.history} fullWidth>
            <Box sx={styleInBlock}>
              <ArrayInput source='history' label='' fullWidth>
                <SimpleFormIterator disableReordering disableAdd={formData?.history?.length > 9}>
                  <NumberInput
                    source='year'
                    label='Дата'
                    type='number'
                    fullWidth
                    format={(v) => (v ? v : '')}
                    validate={required()}
                  />
                  <TextInput
                    source='text'
                    label='Дополнительная иформация'
                    fullWidth
                    format={(v) => (v ? v : '')}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Labeled>
        );
      }}
    </FormDataConsumer>
  );
};
