import { useHistory } from 'react-router-dom';
import { ReactComponent as EyesViews } from 'assets/images/eyes.svg';
import { TNew } from '../../../shared/types/types';
import getArrayFromString from '../../../shared/utils/getArrayFromString';
import { TagEntity } from '../../../shared/lib/TagEntity/TagEntity';
import {URL_FOR_IMG} from "../../../shared/constants/const";
import {TExtendNews} from "../../../helpers/blog/convertorBlogToNews";
import {convertEnumTerritoryToLabel} from "../../../helpers/blog/convertTagTeritoryToEnumTerritory";
import { getFormattedDate } from '../../../shared/utils/getFormattedDate';


type TNewItemCardProps = {
  newsItem: TExtendNews|TNew;
  endSwipe?: () => void;
  isSwipe?: boolean;
  isBlog?: boolean;
};

export const NewsCard = ({ newsItem, endSwipe, isSwipe, isBlog=false }: TNewItemCardProps) => {
  const history = useHistory();

  const { title, desc, date, views, metaKeywords, preview } = newsItem;
  // @ts-ignore
  let typeTags: string[] =  newsItem?.typeTags ? newsItem.typeTags:  []
  let territoryTag = (newsItem as TExtendNews)?.territoryTag
  let convertTagTerritory = convertEnumTerritoryToLabel(territoryTag);


  const imageUrl = `${URL_FOR_IMG}/${preview && preview.path ? preview.path : null}`;

  const formattedDate = getFormattedDate(date);

  const openNewItem = () => {
    if (!isSwipe) {
      if(isBlog) {
        history.push(`/blog/${newsItem.engName}`);
      } else {
        history.push(`/news/${newsItem.id}`);
      }
    }
  };

  return (
    <div className='news-card' key={newsItem.id} onClick={openNewItem} onMouseDown={endSwipe}>
      <div className='news-card__content'>
        <div className='news-card__preview'>
          <img className='news-card__img' src={imageUrl} alt={title} />
          <div className='news-card__type'>
            <div className='news-card__type__wrapper'>
              {territoryTag && <TagEntity  className='news-item__type tag-entity red news-item__type location-tag  ' tag={convertTagTerritory}  /> }
              {metaKeywords &&
                  getArrayFromString(metaKeywords)?.map((metaKey) => (
                      <TagEntity key={metaKey} className='news-item__type location-tag' tag={metaKey}  />
                  ))}
              {typeTags && typeTags.map((tag)=>(<TagEntity key={tag} className='news-item__type location-tag' tag={tag}  />))}

            </div>
          </div>
        </div>
        <div className='news-card__description'>
          <div className='news-card__description__name'>
            <h5 className=''>{title}</h5>
            <div className='news-card__description__views'>
              <EyesViews className='news-card__eyes' />
              <p>{views}</p>
            </div>
          </div>
          <time className='news-card__text'>
            {formattedDate}
          </time>
          <p className='news-card__text'>{desc}</p>
        </div>
      </div>
    </div>
  );
};
