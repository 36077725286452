import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button-no-color.svg';
import {URL_FOR_IMG} from "../../../../shared/constants/const";
import {TNew} from "../../../../shared/types/types";

type TProps = {
  newsItem: TNew;
};
export const AboutClusterNewsCardMobile = ({ newsItem }: TProps) => {
  const history = useHistory();

  const { id,title, desc, preview } = newsItem;

  const imageUrl = `${URL_FOR_IMG}/${preview && preview.path ? preview.path : null}`;
  const isRedirectBtn = (id: number) => id !== 0;
  const openNewItem = () => {
    history.push(`/news/${id}`);
  };

  return (
    <div className='about-news-card-sm' onClick={openNewItem}>
      <div className='about-news-card-sm__content'>
        <div className='about-news-card-sm__preview'>
          {preview && imageUrl && <img className='about-news-card-sm__img' src={imageUrl} alt='news' />}
        </div>
        <div className='about-news-card-sm__content__desc'>
          {isRedirectBtn(id) ? (
            <>
              <h5>{desc} </h5>
              <Link className='about-news-card__btn' to='/designer'>
                <span>Подробнее</span>
                <ArrowButton />
              </Link>
            </>
          ) : (
            <>
              <p>{title} </p>
              <Link className='about-news-card__go-to-btn' to='/designer'>
                <span>Перейти</span>
                <ArrowButton />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
