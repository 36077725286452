type TPointInfo = {
  name: string;
};

type TPointTourBalloonProps = {
  pointInfo: TPointInfo;
};

export const TourBalloon = ({ pointInfo }: TPointTourBalloonProps) => {
  return (
    <div className='tour-balloon'>
      <p className='tour-balloon__text'>{pointInfo.name}</p>
    </div>
  );
};
