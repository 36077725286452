import { createAsyncThunk } from '@reduxjs/toolkit';
import { filterForLocations } from 'helpers/toursFilters';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import TourService from 'services/tour.service';
import { TRootState } from 'store';
import { TFilterLocations } from 'shared/types/location.types';
import {setFilteredLocations, setLoading, setLocations, setSortedLocations} from './designer.slice';

export const fetchLocations = createAsyncThunk('fetchLocations', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await TourService.getLocations();
    dispatch(setLocations(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});

export const sortLocations = createAsyncThunk<unknown, TFilterLocations, { state: TRootState }>(
  'sortLocations',
  async (params, { dispatch, getState }) => {
    try {
      const sortedLocations = getState().designer.locations.filter((loc) => filterForLocations(loc, params));
      dispatch(setSortedLocations(sortedLocations));
    } catch (e) {
      const errorMessage = handleError(e);
      Notification.error(errorMessage);
      throw e;
    }
  }
);
