import classNames from 'classnames';
import { TCategoryPartner, TFiltersPartners, TNamesTypePartnersFilter } from 'shared/types/partner.types';

type TItemFilters = {
  id: string;
  label: string;
  value: TCategoryPartner | null;
  icon: JSX.Element;
  fieldFilters: TNamesTypePartnersFilter;
};

type TPartnersTypesProps = {
  filters: TFiltersPartners;
  clickFilter: (filter: TCategoryPartner, fieldFilters: TNamesTypePartnersFilter) => void;
  itemsFilters: TItemFilters[];
};

export const PartnersTypesRow = ({ filters, clickFilter, itemsFilters }: TPartnersTypesProps) => {
  const checkActiveFilter = (filter: TCategoryPartner) => {
    return filter ? filters.types.includes(filter) : !filters.types.length;
  };

  return (
    <div className='partners-filters__types-slider'>
      {itemsFilters.map((filter) => (
        <div
          key={filter.id}
          className='partners-filters__wrapper'
          onClick={() => clickFilter(filter.value as TCategoryPartner, filter.fieldFilters)}
        >
          <div
            className={classNames('partners-filters__type', {
              'partners-filters__type_active': checkActiveFilter(filter.value as TCategoryPartner),
            })}
          >
            {filter.icon}
            <div className='partners-filters__type__name'>{filter.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
