import Slider, { Settings } from 'react-slick';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { TTravelGuideCityFaces } from 'shared/types/travelGuide.types';
import { TextSplitParagraph } from 'widgets/shared/TextSplitParagraph/TextSplitParagraph';

type TTravelGuideItemFacesProps = {
  faces: TTravelGuideCityFaces[];
};

const settingsSlider: Settings = {
  className: 'travel-guide-item-faces__slider',
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1123,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const TravelGuideItemFaces = ({ faces }: TTravelGuideItemFacesProps) => {
  return (
    <section className='travel-guide-item-faces'>
      <div className='container travel-guide-item__container'>
        <TitleSectionMain title='История в лицах' className='travel-guide-item-faces__title' />
        <Slider {...settingsSlider}>
          {faces.map(({ id, photo, description }) => (
            <div className='travel-guide-item-faces__content' key={id}>
              <div className='travel-guide-item-faces__image'>
                <img src={`${URL_FOR_IMG}/${photo?.path}`} alt='advantage' />
              </div>
              <div className='travel-guide-item-faces__description'>
                <TextSplitParagraph text={description}></TextSplitParagraph>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
