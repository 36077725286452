import React from 'react'
import { Create } from 'react-admin'
import NewsForm from './NewsForm'

const NewsCreate = () => (
  <Create>
    <NewsForm />
  </Create>
)

export default NewsCreate
