import { MenuItemLink, useSidebarState, Menu } from 'react-admin';
import LabelIcon from '@mui/icons-material/Label';
import { MENU_ADMIN_LIST } from '../../../shared/constants/const';
import RequestService from '../../../services/request.service';
import Notification from '../../../shared/lib/notification';
import cn from 'classnames';

const marginMenuTitle = { margin: '20px 0 0 20px' };
const marginMenuItem = { marginLeft: '20px' };

const downloadAnalytics = async () => {
    try {
        const res = await RequestService.getDownloadExcel();
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${MENU_ADMIN_LIST.analytics}.xls`);
        document.body.appendChild(link);
        link.click();
    } catch {
        Notification.error('Произошла ошибка при загрузке документов');
    }
};

const DownloadAnalytics = () => (
  <button onClick={downloadAnalytics} className='download-analytics'>
    {MENU_ADMIN_LIST.analyticsButton}
  </button>
);

const AdminMenu = () => {
  const [open] = useSidebarState();

  return (
    <Menu>
      <p style={marginMenuTitle}>Администрирование</p>
      <MenuItemLink
        to='/user'
        primaryText={MENU_ADMIN_LIST.administration}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/operator/unapproved'
        primaryText={MENU_ADMIN_LIST.operatorRequest}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/tours'
        primaryText={MENU_ADMIN_LIST.controlTours}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/partners'
        primaryText={MENU_ADMIN_LIST.partners}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/questions'
        primaryText={MENU_ADMIN_LIST.questions}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
          to='/page-settings'
          primaryText={MENU_ADMIN_LIST.pageSettings}
          leftIcon={<LabelIcon/>}
          sidebarIsOpen={open}
          sx={marginMenuItem}
      />
      <MenuItemLink
        to='/feedback'
        primaryText={MENU_ADMIN_LIST.feedback}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <p style={marginMenuTitle}>Карты</p>
      <MenuItemLink
        to='/locations'
        primaryText={MENU_ADMIN_LIST.locations}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/cluster'
        primaryText={MENU_ADMIN_LIST.cluster}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />

      <MenuItemLink to='/guidebook' primaryText={MENU_ADMIN_LIST.guide} sidebarIsOpen={open} />
      <MenuItemLink to='/news' primaryText={MENU_ADMIN_LIST.news} sidebarIsOpen={open} />
      <MenuItemLink to='/blog' primaryText={MENU_ADMIN_LIST.blog} sidebarIsOpen={open} />
      <MenuItemLink to='/tape-participants' primaryText={MENU_ADMIN_LIST.participants} sidebarIsOpen={open} />
      <MenuItemLink to='/beauty' primaryText={MENU_ADMIN_LIST.beauty} sidebarIsOpen={open} />
      <MenuItemLink to='/gallery' primaryText={MENU_ADMIN_LIST.gallery} sidebarIsOpen={open} />
      <MenuItemLink to='/trk-member' primaryText={MENU_ADMIN_LIST.members} sidebarIsOpen={open} />

      <p style={marginMenuTitle}>Для туриста</p>
      <MenuItemLink
        to='/lodging'
        primaryText={MENU_ADMIN_LIST.lodging}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/food'
        primaryText={MENU_ADMIN_LIST.food}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/attraction'
        primaryText={MENU_ADMIN_LIST.attraction}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <MenuItemLink
        to='/city'
        primaryText={MENU_ADMIN_LIST.city}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <p style={marginMenuTitle}>Индустрия</p>
      <MenuItemLink
        to='/inddoc'
        primaryText={MENU_ADMIN_LIST.npa}
        leftIcon={<LabelIcon />}
        sidebarIsOpen={open}
        sx={marginMenuItem}
      />
      <DownloadAnalytics />
    </Menu>
  );
};

export default AdminMenu;
