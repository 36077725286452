import { VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import s from './index.module.scss';

type Props = {
  href?: string;
  children: ReactNode;
  type?: 'submit';
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
} & VariantProps<typeof cvaButton>;

const cvaButton = cva(s.button, {
  variants: {
    intent: {
      primary: [s.primary],
      dark: [s.dark],
      secondary: [s.secondary],
      outlined: [s.outlined],
    },
    size: {
      xs: [s.xs],
      s: [s.s],
      sm: [s.sm],
      m: [s.m],
    },
    text: {
      base: [s.base],
      uppercase: [s.uppercase],
    },
    stretched: {
      true: [s.stretched],
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'm',
    text: 'uppercase',
  },
});

export default function Button({
  href,
  stretched,
  intent,
  className,
  children,
  type,
  text,
  size,
  onClick,
  ...props
}: Props) {
  return href ? (
    <Link to={href} className={clsx(cvaButton({ intent, text, size }), className)}>
      {children}
    </Link>
  ) : (
    <button
      type={type}
      onClick={onClick}
      className={clsx(cvaButton({ intent, text, size, stretched }), className)}
      {...props}
    >
      {children}
    </button>
  );
}
