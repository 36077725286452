import { TravelGuideCard } from 'entities/travelGuide/TravelGuideCard/TravelGuideCard';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { TTravelGuide, TTravelGuideCity, TTravelGuideFlora } from 'shared/types/travelGuide.types';

type TTravelGuideItemSimilarPlacesProps = {
  places: (TTravelGuideFlora | TTravelGuideCity)[];
};

export const TravelGuideItemSimilarPlaces = ({ places }: TTravelGuideItemSimilarPlacesProps) => {
  return (
    <section className='travel-guide-item-similar'>
      <div className='container travel-guide-item__container'>
        <TitleSectionMain title='Похожие места' className='travel-guide-item-similar__title' isSmall />
        <div className='travel-guide-item-similar__list'>
          {(places as TTravelGuide[]).map((item) => (
            <TravelGuideCard travelGuide={item} key={item.id} />
          ))}
        </div>
      </div>
    </section>
  );
};
