import { useCallback, useState } from 'react';
import Slider from 'react-slick';

export const useAutoSlide = () => {
  const [sliderEl, setSliderEl] = useState<Slider | null>(null);

  const sliderRef = useCallback((el) => {
    if (!el) return;
    setSliderEl(el);
  }, []);

  const startAutoSlide = () => {
    sliderEl?.slickPlay();
  };

  const stopAutoSlide = () => {
    sliderEl?.slickPause();
  };

  return [startAutoSlide, stopAutoSlide, sliderRef];
};
