import { Helmet } from 'react-helmet';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import { PrivilegesStepsSection } from '../../features/lib/aboutUs/PrivilegesStepsSection/PrivilegesStepsSection';
import { AboutMembers } from '../../features/lib/aboutUs/Members/AboutMembers';
import { ClusterMetrics } from '../../features/lib/aboutUs/ClusterMetrics/ClusterMetrics';
import { ClusterMemberNews } from '../../features/lib/aboutUs/ClusterMembersNews/ClusterMemberNews';
import { AboutMainSection } from '../../features/lib/aboutUs/AboutMainSection';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';
import React from 'react';

const TrkInfoPage = () => {

  return (
    <>
      <Helmet>
        <title>Общая Информация - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isFixed isWightText />
      <AuthPortal />
      <AboutMainSection />
      <AboutMembers />
      <PrivilegesStepsSection />
      <ClusterMemberNews />
      <ClusterMetrics />
      <Footer />
    </>
  );
};

export default TrkInfoPage;
