import { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { logout } from 'manageStore/user/userSlice';
import ProfileLink from './ProfileLink';
import Portal from 'components/Portal/Portal';
import ProfileCard from 'components/Account/ProfileCard';
import { TProfileLink } from 'shared/types/common.types';

type TAsideProps = {
  navs: TProfileLink[][];
  mobileNavs: TProfileLink[];
  unreadStatus: boolean;
};

type TMenuProps = {
  list: TProfileLink[];
};

type TListItemProps = {
  item: TProfileLink;
};

const Aside = ({ navs, mobileNavs, unreadStatus = false }: TAsideProps) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const history = useHistory();
  const [openPortal, setOpenPortal] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handlePortal = () => setOpenPortal(!openPortal);
  const handleMobileMenu = () => setMobileMenu(!mobileMenu);
  const mobileMenuRef = useRef<HTMLButtonElement | null>(null);

  const clickLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (mobileMenuRef.current && !e.composedPath().includes(mobileMenuRef.current)) {
      setMobileMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleResize = (event: UIEvent) => {
      const target = event.target as Window;
      setScreenWidth(target.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ListItem = ({ item }: TListItemProps) => (
    <li
      className={cn('Aaside__list-item', {
        unread: unreadStatus && item && item.link.includes('new-requests'),
      })}
    >
      <img
        className='Aaside__list-item__icon'
        src={item && item.icon ? item.icon : undefined}
        alt={item && item.text ? item.text : ''}
      />
      <NavLink className='Aaside__list-link' to={item && item.link ? item.link : ''}>
        {item && item.text ? item.text : null}
      </NavLink>
    </li>
  );

  const Menu = ({ list }: TMenuProps) => (
    <nav className='Aaside__menu'>
      {list && list.length ? (
        <ul className='Aaside__list'>
          {list.map((item, index) => (
            <ListItem item={item} key={index} />
          ))}
        </ul>
      ) : null}
    </nav>
  );

  return (
    <aside className='Aaside'>
      {screenWidth > 900 && (
        <>
          <div className='Aaside__top'>
            {navs && navs.length ? navs.map((list, index) => <Menu list={list} key={index} />) : null}
          </div>
          <div className='Aaside__bottom'></div>
        </>
      )}
      {screenWidth < 901 && (
        <>
          <div className='Aaside-mobile'>
            <div className='Aaside-mobile__head'>
              <h4 className='Aaside-mobile__title'>Личный кабинет</h4>
              <button className='Aaside-mobile__head-btn' onClick={handleMobileMenu} ref={mobileMenuRef}></button>
              {mobileMenu && (
                <nav className='Aaside-nav'>
                  <button className='Aaside-nav__item' onClick={handlePortal}>
                    Настройки профиля
                  </button>
                  <button className='Aaside-nav__item' onClick={clickLogout}>
                    Выйти
                  </button>
                </nav>
              )}
            </div>
            <div className='Aaside-mobile__links'>
              {mobileNavs &&
                mobileNavs.length &&
                mobileNavs.map((item, index) => (
                  <ProfileLink icon={item.icon} text={item.text} link={item.link} key={index} />
                ))}
            </div>
          </div>
        </>
      )}
      <Portal
        isOpen={openPortal}
        onClose={handlePortal}
        styles={{
          padding: '15px 15px 20px',
          maxWidth: '600px',
          boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
          border: '1px solid #dedede',
        }}
      >
        <ProfileCard />
      </Portal>
    </aside>
  );
};

export default Aside;
