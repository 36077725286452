import Slider, { Settings } from 'react-slick';
import { TTravelGuideCityHistory } from 'shared/types/travelGuide.types';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';

type TTravelGuideItemHistoryProps = {
  history: TTravelGuideCityHistory[];
};

const settingsSlider: Settings = {
  className: 'travel-guide-item-history__slider',
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right dark />,
  prevArrow: <ButtonSlider left dark />,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const TravelGuideItemHistory = ({ history }: TTravelGuideItemHistoryProps) => {
  const sortHistory = history.sort((a,b)=>{
    try {
      const year1 = parseInt(a.year);
      const year2 = parseInt(b.year);
      if(year1>year2) return 1;
      if(year1<year2) return -1;
      return 0
    } catch (e) {
      console.error(e)
      return 0
    }
  })

  return (
    <section className='travel-guide-item-history'>
      <div className='container travel-guide-item__container'>
        <h3 className='travel-guide-item-history__title'>История</h3>
        <Slider {...settingsSlider}>
          {history.map(({ id, year, text }) => (
            <div className='travel-guide-item-history__group' key={id}>
              <div className='travel-guide-item-history__date'>{year}</div>
              <div className='travel-guide-item-history__description'>{text}</div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
