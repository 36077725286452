import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import vk from 'assets/images/footer/vk.svg';
import logo from 'assets/images/header/logo_top.svg';
import youtube from 'assets/images/footer/youtube.svg';
import designer from 'assets/images/footer/designer.png';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <div className='container footer__container'>
        <div className='footer__inner'>
          <Link className='footer__logo' to='/'>
            <img src={logo} alt='Логотип' />
          </Link>

          <div className='footer__box'>
            <ul className='footer__links'>
              <li className='footer__links-item'>
                <Link to='/trk-info' className='footer__link'>
                  О нас
                </Link>
              </li>
              <li className='footer__links-item'>
                <Link to='/tours' className='footer__link'>
                  Туры и экскурсии
                </Link>
              </li>
              <li className='footer__links-item'>
                <Link to='/designer' className='footer__link'>
                  Конструктор путешествий
                </Link>
              </li>
              <li className='footer__links-item'>
                <Link to='/partners' className='footer__link'>
                  Организаторы путешествий
                </Link>
              </li>
              <li className='footer__links-item'>
                <Link to='/travel-guides' className='footer__link'>
                  {t('headerMenu.travelGuides')}
                </Link>
              </li>
              <li className='footer__links-item'>
                <Link to='/blog' className='footer__link'>
                  {t('headerMenu.news')}
                </Link>
              </li>
            </ul>
            <ul className='footer__social'>
              <li className='footer__social-item'>
                <a
                  className='footer__social-link'
                  target='_blank'
                  href='https://vk.com/public221529092'
                  rel='noreferrer'
                >
                  <img src={vk} alt='vk' />
                </a>
              </li>
              <li className='footer__social-item'>
                <a
                  className='footer__social-link'
                  target='_blank'
                  href='https://www.youtube.com/channel/UClT5dwhrtYdG-g-BY0cogUg/featured'
                  rel='noreferrer'
                >
                  <img src={youtube} alt='youtube' />
                </a>
              </li>
            </ul>
          </div>
          <div className='footer__deer'></div>
          <div className='footer__rectangle'></div>
        </div>
        <div className='footer__bottom'>
          <div className='footer__copyright'>© 2024 Все права защищены</div>
          <div className='footer__info'>
            <div>
              <p className='info__text'>
                Платформа Discover Taimyr разработана в целях популяризации туризма на Таймыре и поддержки субъектов
                МСП, входящим в туристско-рекреационный кластер "Арктический". Использование, либо копирование
                материалов сайта, элементов дизайна допускается с разрешения правообладателя и с использованием ссылки
                на источник: discover-taimyr.ru
              </p>
              <p className='info__text'>
              <br/>
              </p>
              <p className='info__text'>
              <em><sup>*1</sup> - Не является публичной офертой. Точные условия по предложению предоставит Туроператор после оформления заявки.</em></p>

              <ul className='info__policy-links'>
                <li>
                  <Link to='/privacy'>Политика конфиденциальности</Link>
                </li>
                <li>
                  <Link to='/useragreement'>Пользовательское cоглашение</Link>
                </li>
              </ul>
            </div>
            <div className='info__designer'>
              <span className='designer__label'>Дизайн</span>
              <a className='designer__link' href='https://magia.team/?utm_source=taimyr&utm_medium=banner&utm_campaign=promo' target='_blank' rel='noreferrer'>
                <img className='designer__image' src={designer} alt=''></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
