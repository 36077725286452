import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { QUESTIONS_FIELDS } from '../../../shared/constants/const';

export const QuestionsShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='title' label={QUESTIONS_FIELDS.title} />
        <TextField source='body' label={QUESTIONS_FIELDS.body} />
      </SimpleShowLayout>
    </Show>
  </div>
);
