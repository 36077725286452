import { FC, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import s from './index.module.scss'

interface IPortalProps {
  children: ReactNode;
  className?: string;
}

const Portal: FC<IPortalProps> = ({ children, className }) => {
  const [container, _] = useState(() => {
    const el = document.createElement("div");
    className && el.classList.add(className);
    return el;
  });

  useEffect(() => {

    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export default Portal