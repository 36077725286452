import classNames from 'classnames';

type TBaseSwitcherProps = {
  isActive: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  id?: string;
  className?: string;
  type: 'light' | 'dark';
};

export const BaseSwitcher = ({ isActive, onChange, id, label, className, type }: TBaseSwitcherProps) => {
  return (
    <div className='switcher'>
      <input
        className='switcher__input'
        type='checkbox'
        id={id || 'switcher'}
        onChange={(event) => onChange(event.target.checked)}
        checked={isActive}
      />
      <label className={classNames('switcher__label', className, type)} htmlFor={id || 'switcher'}>
        <span className='switcher__text'>{label}</span>
      </label>
    </div>
  );
};
