import {StepsSection} from "./StepsSection/StepsSection";
import {PrivilegesSection} from "./PrivilegesSection/PrivilegesSection";

export const PrivilegesStepsSection = () => {

  return (
    <div className='privileges-content'>
      <StepsSection/>
      <PrivilegesSection/>
    </div>
  );
};
