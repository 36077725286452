import { useDispatch, useSelector } from 'react-redux';
import { selectSearchedLocations } from 'manageStore/designer/designer.select';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { setSearchValue, setSearchedLocations } from 'manageStore/designer/designer.slice';
import { TourDesignerLocationsList } from '../TourDesignerLocationsList/TourDesignerLocationsList';
import { declensionNoun } from 'helpers/declensionNoun';

export const TourDesignerSearchList = () => {
  const dispatch = useDispatch();
  const searchedLocations = useSelector(selectSearchedLocations);

  const count = searchedLocations?.length || 0;

  const title = `${declensionNoun(count, 'search')} ${count} ${declensionNoun(count, 'object')}`;

  const handleClose = () => {
    dispatch(setSearchValue(''));
    dispatch(setSearchedLocations(null));
  };

  if (!searchedLocations) return null;

  return (
    <div className='designer-search-list'>
      <div className='designer-search-list__header'>
        <CloseIcon className='designer-search-list__close' onClick={handleClose} />
        <h3 className='designer-search-list__title'>{title}</h3>
      </div>
      <div className='designer-search-list__main'>
        <TourDesignerLocationsList locations={searchedLocations} />
      </div>
    </div>
  );
};
