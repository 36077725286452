import { createSelector } from '@reduxjs/toolkit';
import { TFiltersTours } from 'shared/types/tours.types';
import { TRootState } from 'store';
import { customFilterForTours } from 'helpers/toursFilters';

export const toursMap = (state: TRootState) => state.tours;
export const selectLoading = (state: TRootState) => state.tours.isLoading;
export const selectTours = (state: TRootState) => state.tours.tours;
export const selectFiltersTours = (state: TRootState) => state.tours.filtersTours;

export const selectSortedTours = createSelector(
  selectTours,
  (state: TRootState, filters: TFiltersTours) => filters,
  (tours, filters) => {
    return tours.filter((tour) => customFilterForTours(tour, filters));
  }
);

export const selectTourItem = createSelector(
  selectTours,
  (state: TRootState, id: string) => id,
  (tours, id) => {
    const idNumber = Number(id);
    return idNumber ? tours.find((tour) => tour.id === idNumber) : null;
  }
);
