import { cva } from 'class-variance-authority';
import React from 'react';
import Avatar from '../../../entities/avatar/ui';
import clsx from 'clsx';
import s from './index.module.scss';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../shared/hooks/useAppSelector';
import { imagePrefix } from 'shared/utils/imagePrefix';

const cvaProfile = cva([s.profile]);
const cvaName = cva([s.name]);
const cvaSettings = cva([s.settings]);

export const getFirstLetter = (i: number, str?: string) => {
  return str?.split(' ')[i] ?? '';
};
export default function ProfilePreview() {
  const user = useAppSelector((state) => state.user.userData);
  return (
    <div className={cvaProfile()}>
      <Avatar
        alt=''
        src={imagePrefix(user?.photo?.path)}
        name={getFirstLetter(0, user?.login)}
        surname={getFirstLetter(1, user?.login)}
      />
      <span className={clsx(cvaName(), 'truncate-two-lines')}>{user?.login}</span>
      <Link to={'/account'} className={cvaSettings()}>
        <SettingsIcon />
      </Link>
    </div>
  );
}
