import { Link } from 'react-router-dom';

export const UseragreementMain = () => {
  return (
    <main className='policy'>
      <div className='container policy__container'>
        <h1 className='policy__title'>пользовательское соглашение</h1>
        <section>
          <h4 className='policy__title-section'>1. Общие положения</h4>
          <p className='policy__text'>
            Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем сайта{' '}
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> (далее или Администрация) с
            одной стороны и пользователем сети Интернет (далее – Пользователь) с другой. Сайт «
            <Link to='/' className='policy__link'>
              https://discover-taimyr.ru/
            </Link>
            » (далее – Сайт) не является средством массовой информации.
          </p>
          <p className='policy__text'>
            Администрация предлагает Пользователю использовать Сайт на условиях, изложенных в настоящем Соглашении.
          </p>
          <p className='policy__text'>
            Администрация предлагает Пользователям доступ к разделам Сайта, содержащим форму регистрации (личный кабинет
            Пользователя), форму заявки на путешествие, чат для коммуникаций, и форму обратной связи. Все существующие
            на данный момент разделы, а также любое развитие их и/или добавление новых является предметом настоящего
            Соглашения.
          </p>
          <p className='policy__text'>
          Администрация предоставляет техническую возможность использования Сайта Пользователями, не участвует в формировании содержания персональных страниц Пользователей, не контролирует и не несет ответственности за действия или бездействие любых лиц в отношении использования Сайта или формирования и использования содержания персональных страниц Пользователей на Сайте.
          </p>
          <p className='policy__text'>
          Администрация не занимается предварительной модерацией или цензурой информации Пользователей и предпринимает действия по защите прав и интересов лиц и обеспечению соблюдения требований законодательства Российской Федерации только после обращения заинтересованного лица к Администрации.
          </p>
          <p className='policy__text'>
            Использование Сайта регулируется настоящим Соглашением, Политикой конфиденциальности, а также условиями
            использования отдельных разделов Сайта. Соглашение может быть изменено Администрацией без какого-либо
            специального уведомления, новая редакция вступает в силу с момента ее размещения в сети интернет по
            указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая
            редакция Соглашения всегда находится на странице по адресу{' '}
            <Link to='/useragreement' className='policy__link'>
              https://discover-taimyr.ru/useragreement
            </Link>
            .
          </p>
          <p className='policy__text'>
            Начиная использовать какой-либо раздел Сайта, Пользователь считается принявшим условия Соглашения в полном
            объеме, без всяких оговорок и исключений, принимает на себя указанные в нем права и обязанности, связанные с использованием и функционированием Сайта. В случае несогласия Пользователя с каким-либо из положений
            Соглашения, Пользователь не вправе использовать разделы Сайта. В случае, если Администрацией были внесены
            изменения в Соглашение, с которыми Пользователь не согласен, он обязан прекратить использование Сайта.
          </p>
        </section>
        <section>
          <h4 className='policy__title-section'>2. Условия использования Сайта</h4>
          <p className='policy__text'>
            Персональная информация Пользователя, содержащаяся в формах Сайта, хранится и обрабатывается Администрацией
            в соответствии с условиями{' '}
            <Link to='/privacy' className='policy__link'>
              Политики конфиденциальности
            </Link>{' '}
            и Политики обработки и защиты персональных данных.
          </p>
          <p className='policy__text'>
            Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с
            использованием Сайта.
          </p>
          <p className='policy__text'>
            Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с
            использованием Сайта, в том числе, если такие действия приведут к нарушению прав и законных интересов
            третьих лиц, а также за соблюдение законодательства при использовании Сайта.
          </p>
          <p className='policy__text'>
          Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству Российской Федерации, предоставленной им на Сайте информации и ее чистоту от претензий третьих лиц.
          </p>
          <p className='policy__text'>
          Нарушение настоящего Соглашения и действующего законодательства Российской Федерации влечет за собой гражданско-правовую, административную и уголовную ответственность.
          </p>
          <p className='policy__text'>
          Пользователь, как обладатель информации, размещённой на собственной персональной странице, осознаёт, что за исключением случаев, установленных настоящим Соглашением и действующим законодательством Российской Федерации, Администрация не принимает участие в формировании и использовании содержания и контроле доступа других пользователей к персональной странице Пользователя.
          </p>
          <p className='policy__text'>
          Пользователь несет личную ответственность за любой Контент или иную информацию, которые он загружает или иным образом доводит до всеобщего сведения (публикует) на Сайте или с его помощью. Пользователь не имеет права загружать, передавать или публиковать Контент на Сайте, если он не обладает соответствующими правами на совершение таких действий, приобретенными или переданными ему в соответствии с законодательством Российской Федерации.
          </p>
          <p className='policy__text'>
          При использовании Сайта Пользователь обязан:
          <li>- соблюдать положения действующего законодательства Российской Федерации, настоящего Соглашения и иных специальных документов Администрации;</li>
          <li>- предоставлять достоверные, полные и актуальные данные, следить за их актуализацией; </li>
          <li>- не размещать на персональной странице информацию и объекты (включая ссылки на них), которые могут нарушать права и интересы других лиц; </li>
          <li>- перед размещением информации и объектов (включая, но не ограничиваясь, изображениями других лиц, чужими текстами различного содержания, аудиозаписями и видеофильмами) предварительно оценивать законность их размещения.</li>
          </p>
          <p className='policy__text'>При использовании Сайта Пользователь не вправе:</p>
          <ul className='policy__text'>
            <li>2.1. Нарушать права третьих лиц и/или причинять им вред в любой форме;</li>
            <li>
              2.2. Выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на
              то прав, в том числе за Администрацию Сайта, а также применять любые другие формы и способы незаконного
              представительства других лиц в сети, а также вводить Пользователей или Администрацию в заблуждение
              относительно свойств и характеристик каких-либо субъектов или объектов;
            </li>
            <li>
              2.3. Посылать, передавать или любым другим способом размещать и/или распространять контент, при отсутствии
              прав на такие действия согласно законодательству или каким-либо договорным отношениям;
            </li>
            <li>
              2.4. Загружать, посылать, передавать или любым другим способом размещать и/или распространять не
              разрешенную специальным образом рекламную информацию, спам, списки чужих адресов электронной почты, схемы
              «пирамид», многоуровнего (сетевого) маркетинга, системы интернет-заработка и e-mail-бизнесов, «письма
              счастья», а также использовать разделы Сайта исключительно для перенаправления пользователей на страницы
              других доменов;
            </li>
            <li>2.5. Не санкционированно собирать и хранить персональные данные других пользователей;</li>
            <li>2.6. Нарушать нормальную работу Сайта;</li>
            <li>
              2.7. Содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых соглашением;
            </li>
            <li>2.8. Другим образом нарушать нормы законодательства, в том числе нормы международного права.</li>
          </ul>
        </section>
        <section>
          <h4 className='policy__title-section'>3. Отсутствие гарантий, ограничение ответственности</h4>
          <p className='policy__text'>
            Пользователь использует Сайт на свой собственный риск. Сайт предоставляется «как есть». Администрация не
            принимает на себя никакой ответственности, в том числе за соответствие Сайта целям Пользователя.
          </p>
          <p className='policy__text'>
            Администрация не гарантирует, что: 
            <li>- Сайт соответствует/будет соответствовать требованиям Пользователя;</li>
            <li>- разделы будут предоставляться непрерывно, быстро, надежно и без ошибок;</li>
            <li>- результаты, которые будут получены с использованием разделов, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве;</li>
            <li>- качество какого-либо продукта, услуги, информации и пр., полученных с использованием сервисов, будет соответствовать ожиданиям Пользователя.</li>
          </p>
          <p className='policy__text'>
            Любая информация и/или материалы, доступ к которым Пользователь получает с использованием Сайта,
            Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за
            возможные последствия использования указанной информации и/или материалов, в том числе за ущерб, который это
            может причинить компьютеру Пользователя или третьим лицам, за потерю данных или любой другой вред.
          </p>
          <p className='policy__text'>
            Администрация может, но не обязана, просматривать Сайт на наличие запрещенного Контента и может удалять или перемещать (без предупреждения) любой Контент или пользователей по своему личному усмотрению, по любой причине или без причины, включая без всяких ограничений перемещение или удаление Контента, который, по личному мнению Администрации, нарушает настоящее Соглашение, законодательство Российской Федерации и/или может нарушать права, причинить вред или угрожать безопасности других Пользователей или третьих лиц.
          </p>
          <p className='policy__text'>
            Администрация не несет ответственности за любые виды убытков, произошедшие вследствие использования
            Пользователем Сайта или отдельных частей/функций/разделов Сайта.
          </p>
        </section>
        <section>
          <h4 className='policy__title-section'>4. Иные положения</h4>
          <p className='policy__text'>
            Настоящее Соглашение представляет собой договор между Пользователем и Администрацией относительно порядка
            использования Сайта и заменяет собой все предыдущие соглашения между Пользователем и Администрацией.
          </p>
          <p className='policy__text'>
            Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
            Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
            Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
            разрешаются в порядке, установленном, действующим законодательством Российской Федерации, по нормам
            российского права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином
            «законодательство» понимается как законодательство Российской Федерации.
          </p>
          <p className='policy__text'>
            Ввиду безвозмездности услуг, оказываемых в рамках настоящего Соглашения, нормы о защите прав потребителей,
            предусмотренные законодательством Российской Федерации, не могут быть применимыми к отношениям между
            Пользователем и Администрацией.
          </p>
          <p className='policy__text'>
            Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
            недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или
            применимость остальных положений Соглашения.
          </p>
          <p className='policy__text'>
            Бездействие со стороны Администрации в случае нарушения Пользователем положений Соглашения не лишает
            Администрацию права предпринять соответствующие действия в защиту своих интересов позднее, а также не
            означает отказа Администрации от своих прав в случае совершения в последующем подобных либо сходных
            нарушений.
          </p>
        </section>
      </div>
    </main>
  );
};
