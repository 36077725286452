import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { Labeled, TextInput } from 'react-admin';
import Box from '@mui/material/Box';
import FileInputAdmin from '../FileInputAdmin';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideDigits = () => (
  <Labeled label={TRAVEL_GUIDE_FIELDS.digits} fullWidth>
    <Box sx={{ ...styleInBlock, paddingTop: '1em' }}>
      <FileInputAdmin source='factPhoto' label='Фото' multiple={false} />
      {/* <TextInput source='factTitle' label='Заголовок' fullWidth format={(v) => (v ? v : '')} /> */}
      <TextInput
        source='factDescription'
        label='Информация'
        fullWidth
        multiline={true}
        minRows='3'
        maxRows='3'
        format={(v) => (v ? v : '')}
      />
    </Box>
  </Labeled>
);
