import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { ArrayInput, Labeled, ReferenceInput, SelectInput, SimpleFormIterator, required } from 'react-admin';
import Box from '@mui/material/Box';
import {BLOG_FIELDS} from "../../../shared/constants/const";

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const BlogGuidline= () => {
  return (
    <Labeled label={BLOG_FIELDS.guidebooks} fullWidth>
      <Box sx={styleInBlock}>
        <ArrayInput source='guidebooks' label='' fullWidth>
          <SimpleFormIterator disableReordering>
            <ReferenceInput source='id' reference='guidebook' perPage={Infinity} fullWidth>
              <SelectInput
                optionText='name'
                optionValue='id'
                label='Select...'
                fullWidth
                format={(v) => (v && v !== 'new' ? v : '')}
                validate={required()}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </Labeled>
  );
};
