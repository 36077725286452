import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { setLoading, setTours } from './tours.slice';
import TourService from 'services/tour.service';

export const fetchTours = createAsyncThunk('fetchTours', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await TourService.getTours();
    dispatch(setTours(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});
