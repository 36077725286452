import { cva } from 'class-variance-authority';
import React from 'react';
import ProfilePreview, { getFirstLetter } from '../../../features/profile-preview/ui';
import CustomLink from '../custom-link/ui/index';
import Separator from '../separator';
import s from './index.module.scss';
import { ReactComponent as PayIcon } from '../assets/menu-1.svg';
import { ReactComponent as ReviewIcon } from '../assets/menu-2.svg';
import { ReactComponent as StarIcon } from '../assets/menu-3.svg';
import { ReactComponent as HomeIcon } from '../assets/home.svg';
import { ReactComponent as ExitIcon } from '../assets/exit.svg';
import { ReactComponent as MainIcon } from '../assets/main.svg';
import Avatar from '../../../entities/avatar/ui';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '../../../../manageStore/user/userSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../shared/hooks/useAppSelector';
import {ReactComponent as LogoutIcon} from 'assets/icons/logout.svg';
import {useState} from 'react';
import Popup from 'newProject/features/popup/ui';
import Button from 'newProject/shared/ui/button';
import Portal from 'newProject/features/portal/ui';
const cvaSidebar = cva([s.sidebar]);
const cvaMenu = cva([s.menu]);
const cvaSeparator = cva([s.separator]);

const cvaMobileMenu = cva([s.mobileMenu]);

const Sidebar = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const history = useHistory()
  const [isOpenLogout, setIsOpenLogout] = useState(false)

  const clickLogout = () => {
    dispatch(logout());
    history.push('/')
  }
  
  return (
    <>
    <div className={s.sidebarWrapper}>
      {isOpenLogout && 
        <Portal>
          <Popup classNameInner={s.popup} withClose minClose onClose={() => setIsOpenLogout(false)}>
            <h2 className={s.titlePopup}>Что вы хотите сделать?</h2>
            <div className={s.buttons}>
              <Button intent={'outlined'} href='/'>Вернуться на главную</Button>
              <Button onClick={clickLogout} intent={'outlined'}>выйти из учетной записи</Button>
            </div>
          </Popup>
        </Portal>}
      {/*DEKSTOP*/}
      <div className={cvaSidebar()}>
        <ProfilePreview />
        <Separator className={cvaSeparator()} />
        <div className={cvaMenu()}>
          <div>
            <CustomLink icon={<PayIcon />} href='/account/tourist/requests'>
              Мои заявки
            </CustomLink>
            <CustomLink icon={<ReviewIcon />} href='/account/tourist/my-reviews'>
              Отзывы на туры
            </CustomLink>
            {/* 
            TODO MAGIA-19: добавить при доработке маршрутов
            <CustomLink icon={<StarIcon />} href="/account/tourist/routes">
              Избранные маршруты
            </CustomLink> */}
          </div>
          <div>
            <Separator className={cvaSeparator()} />
            <CustomLink isDefaultLink href={'/'} icon={<HomeIcon />}>
              Вернуться на главную
            </CustomLink>
            <CustomLink onClick={clickLogout} icon={<ExitIcon />}>
              Выход из учетной записи
            </CustomLink>
          </div>
        </div>
      </div>
      {/*MOBILE*/}
      <div className={cvaMobileMenu()}>
        <CustomLink onClick={() => setIsOpenLogout(true)} isDefaultLink icon={<LogoutIcon />}>
          Выйти
        </CustomLink>
        <CustomLink icon={<PayIcon className={s.icon} viewBox='0 0 18 18' />} href='/account/tourist/requests'>
          Мои заявки
        </CustomLink>
        <CustomLink icon={<ReviewIcon className={s.icon} viewBox='0 0 18 18' />} href='/account/tourist/my-reviews'>
          Отзывы
        </CustomLink>
        {/* 
         TODO MAGIA-19: добавить при доработке маршрутов
        <CustomLink
          icon={<StarIcon className={s.icon} viewBox="0 0 18 18" />}
          href="/account/tourist/routes"
        >
          избранное
        </CustomLink> */}
        <CustomLink
          active={location.pathname === '/account'}
          isDefaultLink
          icon={
            <Avatar
              size={22}
              intent={location.pathname === '/account' ? 'active' : undefined}
              surname={getFirstLetter(1, user?.login)}
              name={getFirstLetter(0, user?.login)}
            />
          }
          href='/account'
        >
          Профиль
        </CustomLink>
      </div>
      </div>
    </>
  );
};

export default Sidebar;
