import Overlay from 'ol/Overlay';

export const generateOverlay = (element: HTMLElement) => {
  const overlayPopup = new Overlay({
    element,
    autoPan: {
      animation: {
        duration: 250,
      },
    },
    positioning: 'bottom-left',
    offset: [-35, -35],
  });
  return overlayPopup;
};
