import classNames from 'classnames';
import { useState, MouseEvent } from 'react';
import Slider, { Settings } from 'react-slick';
import { URL_FOR_IMG } from 'shared/constants/const';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { TSingleFile } from 'shared/types/common.types';

type TPopupSliderProps = {
  images: TSingleFile[];
  initialSlide: number;
  onClose: () => void;
};

const settingsMainSlider: Settings = {
  className: 'main-slider-guide',
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
};

const settingsDoubleSlider: Settings = {
  dots: false,
  infinite: true,
  centerMode: true,
  centerPadding: '10px',
  speed: 500,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
  variableWidth: true,
  swipeToSlide: true,
  focusOnSelect: true,
};

export const PopupImagesSlider = ({ images, initialSlide, onClose }: TPopupSliderProps) => {
  const [mainNav, setMainNav] = useState<Slider | undefined>();
  const [doubleNav, setDoubleNav] = useState<Slider | undefined>();

  const handleMainSliderClick = ({ target }: MouseEvent<HTMLDivElement>) => {
    if (target instanceof HTMLElement && target.tagName !== 'IMG') {
      onClose();
    }
  };

  const handleDoubleSliderClick = ({ target, currentTarget }: MouseEvent<HTMLDivElement>) => {
    if (target instanceof HTMLElement && target === currentTarget) {
      onClose();
    }
  };

  return (
    <div className="popup-images-slider">
      <div onClick={handleMainSliderClick}>
        <Slider
          {...settingsMainSlider}
          asNavFor={doubleNav}
          ref={(mainSlider) => setMainNav(mainSlider || undefined)}
          initialSlide={initialSlide}
        >
          {images.map(({ id, path }) => (
            <div className="main-slider-guide__image" key={id}>
              <button className="portal-close portal-close_slider close-button" onClick={() => handleMainSliderClick} />
              <img
                src={`${URL_FOR_IMG}/${path}`}
                alt="slide" />
              <button className="close-button" />
            </div>
          ))}
        </Slider>
      </div>
      <div onClick={handleDoubleSliderClick}>
        <Slider
          asNavFor={mainNav}
          ref={(doubleSlider) => setDoubleNav(doubleSlider || undefined)}
          {...settingsDoubleSlider}
          slidesToShow={images.length < 3 ? images.length : 3}
          className={classNames('double-slider', { 'double-slider_flex': images.length < 4 })}
        >
          {images.map(({ id, path }) => (
            <div className="double-slider__image" key={id}>
              <img
                src={`${URL_FOR_IMG}/${path}`}
                alt="slide" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
