import React, { ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import Sidebar from '../../sidebar/ui';
import clsx from 'clsx';
import s from './index.module.scss';
import 'app/styles/null.scss';
import { useMedia } from 'react-use';
import '../../../shared/styles/_typography.css';

const cvaMain = cva([s.main]);

type LayoutProps = {
  children: ReactNode;
  hideMenu?: boolean;
  classNameMain?: string;
};

export default function Layout({ classNameMain, children, hideMenu = false }: LayoutProps) {
  const isMd = useMedia('(max-width: 1023px)');

  return (
    <div className={s.wrapper}>
      {(!isMd || !hideMenu) && <Sidebar />}
      <main className={clsx(cvaMain(), classNameMain, hideMenu && s.hideMenu)}>{children}</main>
    </div>
  );
}
