import { useState, ChangeEvent } from 'react';
import RegFormTourist from './RegFormTourist';
import RegFormTouroperator from './RegFormTouroperator';
import RegFormGid from './RegFormGid';
import Portal from 'components/Portal/Portal';
import { useHistory } from 'react-router-dom';
import PopupInfo from 'components/PopupInfo/PopupInfo';

const typeUserOptions = [
  { value: 'Турист', label: 'Турист' },
  { value: 'Туроператор', label: 'Туроператор / Экскурсионное бюро' },
  { value: 'Экскурсовод / гид-проводник', label: 'Экскурсовод / Инструктор-проводник' },
];

const RegForm = () => {
  const history = useHistory();
  const [typeUser, setTypeUser] = useState(typeUserOptions[0].value);
  const [openPortal, setOpenPortal] = useState(false);

  const handleTypeUser = (event: ChangeEvent<HTMLSelectElement>) => setTypeUser(event.target.value);
  const closePortal = () => {
    setOpenPortal(false);
    history.push('/');
  };
  return (
    <div className='registration-wrapper'>
      <form className='login-form'>
        <label>
          <select id='role' className='login-input' name='role' value={typeUser} onChange={handleTypeUser}>
            {typeUserOptions.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </label>
        <p className='login-form-error'></p>
        {typeUser === typeUserOptions[0].value && <RegFormTourist />}
        {typeUser === typeUserOptions[1].value && (
          <RegFormTouroperator typeUser={typeUser} setOpenPortal={setOpenPortal} />
        )}
        {typeUser === typeUserOptions[2].value && <RegFormGid typeUser={typeUser} setOpenPortal={setOpenPortal} />}
      </form>
      <Portal
        isOpen={openPortal}
        onClose={closePortal}
        styles={{
          padding: '15px 15px 20px',
          maxWidth: '600px',
          boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
          border: '1px solid #dedede',
        }}
      >
        <PopupInfo title='Заявка на регистрацию принята!'>
          <div className='profile-user__info'>
            <p className='profile-user__name'>Подождите подтверждения администратора.</p>
          </div>
          <div className='profile-bottom'>
            <button className='profile-btn btn red' onClick={closePortal}>
              Закрыть
            </button>
          </div>
        </PopupInfo>
      </Portal>
    </div>
  );
};

export default RegForm;
