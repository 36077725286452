import { useCallback, useEffect, useState } from 'react';

export const useOutsideClick = (callback: () => void, isListen: boolean) => {
  const [el, setEl] = useState<HTMLDivElement | null>(null);

  const refEl = useCallback((node) => {
    if (node) {
      setEl(node);
    }
  }, []);

  const handleOutsideClick = (e: MouseEvent) => {
    if (el && e.composedPath() && !e.composedPath().includes(el)) {
      callback();
    }
  };

  useEffect(() => {
    if (isListen) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isListen]);

  return refEl;
};
