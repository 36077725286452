import { TTapeParticipant } from '../shared/types/common.types';

export type TSerializedTapeParticipants = {
  first: string[];
  second: string[];
};
export function splitAndSerializedArrayInTwo(dataToProcess: TTapeParticipant[]): TSerializedTapeParticipants {
  let readyDate: TSerializedTapeParticipants = {
    first: [],
    second: [],
  };

  const midIndex = Math.floor(dataToProcess.length / 2);
  const firstPart = normalizeData(dataToProcess.slice(0, midIndex));
  const secondPart = normalizeData(dataToProcess.slice(midIndex));

  readyDate.first = firstPart;
  readyDate.second = secondPart;

  return readyDate;
}

function normalizeData(unNormalizedData: TTapeParticipant[]) {
  const normalizeData: string[] = [];
  unNormalizedData.forEach((item: TTapeParticipant, index) => {
    normalizeData.push(item.name);
  });

  return normalizeData;
}
