import { PropsWithChildren, useEffect } from 'react';
import BrendMapSvg from './BrendMapSvg';
import Map from 'ol/Map';

type TBrendMap = {
  map: Map | null;
  refContainer: (el: any) => void;
};

export const BrendMap = ({ children, map, refContainer }: PropsWithChildren<TBrendMap>) => {
  useEffect(() => {
    if (!map) return;
    setTimeout(() => {
      map.updateSize();
    }, 1000);
  }, [map]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id={'brand-map'} style={{ width: '100%', height: '100%' }}></div>
      {children}
      <div ref={refContainer}>{map && <BrendMapSvg />}</div>
    </div>
  );
};
