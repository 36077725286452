import { ReactComponent as TouristIcon } from 'assets/images/trk/trk-tourist.svg';
import { ReactComponent as TreeIcon } from 'assets/images/trk/tree.svg';
import { ReactComponent as HeartIcon } from 'assets/images/trk/trk-heart.svg';
import { ReactComponent as SpaceIcon } from 'assets/images/trk/space.svg';
import { ReactComponent as ProjectIcon } from 'assets/images/trk/project.svg';
import { ReactComponent as DevelopmentIcon } from 'assets/images/trk/development.svg';
import classNames from 'classnames';
import { useMediaQuery } from '../../../../shared/hooks/useMatchMedia';
import Portal from "../../../../components/Portal/Portal";
import BecomeMemberForm from "./BecomeMemberForm";
import {useDispatch, useSelector} from "react-redux";
import {selectIsBecomeMemberOpen, setIsBecomeMemberOpen} from "../../../../manageStore/common/common.slice";

export type TMemberItem = {
  id: number;
  label: string;
  icon: JSX.Element;
  otherClass: string;
};

export type TClusterItem = {
  id: number;
  label: JSX.Element;
  description: string;
};

const membersItems: TMemberItem[] = [
  {
    id: 1,
    label: `Развитие экспедиционного туризма\n  в отдаленные поселки Таймыра\n  и на арктическое побережье`,
    icon: <TouristIcon className='members-content__grid-3__item__icon' />,
    otherClass: 'members-content__grid-3__item__first',
  },
  {
    id: 2,
    label: 'Продвижение уникального объекта всемирного наследия ЮНЕСКО — Плато Путорана',
    icon: <TreeIcon className='members-content__grid-3__item__icon' />,
    otherClass: 'members-content__grid-3__item__second',
  },
  {
    id: 3,
    label: 'Формирование сообщества влюбленных в туризм и развивающих его на Таймыре',
    icon: <HeartIcon className='members-content__grid-3__item__icon' />,
    otherClass: 'members-content__grid-3__item__third',
  },
  {
    id: 4,
    label: 'Самый большой по площади туристический кластер',
    icon: <SpaceIcon className='members-content__grid-3__item__icon' />,
    otherClass: 'members-content__grid-3__item__fourth',
  },
  {
    id: 5,
    label: 'Объединение предпринимателей в совместных проектах',
    icon: <ProjectIcon className='members-content__grid-3__item__icon' />,
    otherClass: 'members-content__grid-3__item__fifth',
  },
  {
    id: 6,
    label: 'Развитие промышленного туризма',
    icon: <DevelopmentIcon className='members-content__grid-3__item__icon' />,
    otherClass: 'members-content__grid-3__item__sixth',
  },
];

const clusterItems: TClusterItem[] = [
  {
    id: 7,
    label: (
      <h4>
        Предприятие, деятельность которого направленна <br />
        на удовлетворение туристского спроса
      </h4>
    ),
    description:
      'Рестораны, сектор размещения (гостиницы, отели базы отдыха), туристские агентства, транспортные услуги и т.д.',
  },
  {
    id: 8,
    label: (
      <h4>
        Предприятие, деятельность которого направленна <br />
        на изготовление сувенирной продукции
      </h4>
    ),
    description: 'Комплекс туристских ресурсов, привлекающих внешних туристов',
  },
  {
    id: 9,
    label: <h4>Компании и институты</h4>,
    description: 'Обеспечивающие необходимую квалификацию кадрам, информационную поддержку и финансовый капитал',
  },
];

export const AboutMembers = () => {
  const dispatch = useDispatch()
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const isOpen = useSelector(selectIsBecomeMemberOpen)
  const handelPortal = () =>{
    dispatch(setIsBecomeMemberOpen(!isOpen))
  }

  return (
    <>
      <div className='container members-content'>
        <h1 className='members-content__title'>
          ТРК «Арктический» повышает туристическую конкурентоспособность территории Таймыра на глобальном рынке с
          помощью своего потенциала:
        </h1>

        <div className='members-content__grid-3'>
          {membersItems.map((memberItem) =>
            !isMobile ? (
              <div key={memberItem.id} className={classNames('members-content__grid-3__item', memberItem.otherClass)}>
                {memberItem.icon}

                <p>{memberItem.label}</p>
              </div>
            ) : (
              <div key={memberItem.id} className='members-cards-sm'>
                {memberItem.icon}
                <p>{memberItem.label}</p>
              </div>
            )
          )}
        </div>

        <div className='members-content__cluster'>
          <div>
            <h1 className='members-content__title cl-sm'>Участником кластера <br/> могут стать</h1>
            {!isMobile && (
              <button onClick={handelPortal} className='first-block__become-member btn'>
                стать участником
              </button>
            )}
          </div>


            <Portal
              isOpen={isOpen}
              onClose={handelPortal}
              styles={{
                padding: '24px',
                maxWidth: '440px',
                border: '1px solid #dedede',
              }}
              title='стать участником'
            >
              <BecomeMemberForm />
            </Portal>


          <div>
            {clusterItems.map((clusterItem) => (
              <div key={clusterItem.id} className='members-content__cluster__item'>
                <span></span>
                <div className='members-content__cluster__item__desc'>
                  {clusterItem.label}
                  <p>{clusterItem.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
