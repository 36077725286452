import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  ShowButton,
  EmailField,
  FunctionField,
} from 'react-admin';
import { MENU_ADMIN_LIST, OPERATOR_FIELDS } from '../../../shared/constants/const';
const OperatorList = () => (
  <List
    title={MENU_ADMIN_LIST.administration}
    exporter={false}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source="type" label={OPERATOR_FIELDS.type} />
      <TextField source="registryNumber" label={OPERATOR_FIELDS.registryNumber} />
      <TextField source="shortName" label={OPERATOR_FIELDS.org} />
      <FunctionField label={OPERATOR_FIELDS.fio} render={record => {
        const firstName = record.contactFirstName || record.firstName || '';
        const lastName = record.contactLastName || record.lastName || '';
        const patronymic = record.contactPatronymic || record.patronymic || '';
        return `${lastName} ${firstName} ${patronymic}`;
      }} />
      <TextField source="phone" label={OPERATOR_FIELDS.phone} />
      <EmailField source="email" label={OPERATOR_FIELDS.email} />
      <ShowButton />
    </Datagrid>
  </List>
);

export default OperatorList;
