import { AxiosResponse } from 'axios';
import { handleError } from 'http/handleError';
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config: {} = {
  position: 'top-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  draggable: true,
  transition: Flip,
  style: {
    fontSize: '14px',
  },
  progressStyle: {
    background: 'linear-gradient(90deg,#338fd1,#db2947)',
  },
};

class Notification {
  static default = (message: string) => {
    toast(message, { ...config, type: toast.TYPE.DEFAULT });
  };
  static info = (message: string, autoClose = 3000) => {
    toast(message, { ...config, type: toast.TYPE.INFO, autoClose });
  };
  static success = (message = 'Успешно!') => {
    toast(message, { ...config, type: toast.TYPE.SUCCESS });
  };
  static error = (message: string) => {
    toast(message, { ...config, type: toast.TYPE.ERROR });
  };
  static warning = (message: string) => {
    toast(message, { ...config, type: toast.TYPE.WARNING });
  };
  static promise = async <T>(fn: Promise<AxiosResponse<T>>, checkErr?: boolean, autoClose = 3000, success='Успешно!') => {
    return toast.promise(
      fn,
      {
        pending: 'В работе...',
        success: success,
        error: {
          render({ data }) {
            const errMessage = handleError(data);
            return checkErr && errMessage ? errMessage : 'Ошибка!';
          },
        },
      },
      { ...config, autoClose }
    );
  };
}

export default Notification;
