import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import {setTapeParticipants} from "./common.slice";
import TapeParticipantsService from "../../services/tape_participants.service";

export const fetchTapeParticipants = createAsyncThunk('fetchLocations', async (_, { dispatch }) => {

  try {
    const { data } = await TapeParticipantsService.getTapeParticipants();
    if(data && data.length > 0)
       dispatch(setTapeParticipants(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  }
});

