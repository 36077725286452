import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Slider, { Settings } from 'react-slick';
import { useSwipe } from 'shared/hooks/useSwipe';
import { useClientRect } from 'shared/hooks/useClientRect';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { TFiltersTours } from 'shared/types/tours.types';
import { useAppSelector } from '../../../shared/hooks/useAppSelector';
import { selectFilterMeta } from '../../../manageStore/news/newsSlice';

const settings: Settings = {
  dots: false,
  infinite: false,
  slidesToScroll: 6,
  variableWidth: true,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};

type TItemFilters = {
  id: string;
  label: string;
  value: string;
};

type TToursTypesSliderProps = {
  filters: TFiltersTours;
  clickFilter: (filter: string) => void;
  itemsFilters: TItemFilters[];
};

type TFilterItemProps = {
  active: boolean;
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
};

const FilterItem = ({ active, onClick, label, icon }: TFilterItemProps) => {
  return (
    <div className={classNames('news-filter-item', { active })} onClick={onClick}>
      {icon}
      <span>{label}</span>
    </div>
  );
};

export const NewsTypesSlider = ({ filters, clickFilter, itemsFilters }: TToursTypesSliderProps) => {
  const [slidesToShow, setSlidesToShow] = useState(7);
  const [widthLastElements, setWidthLastElements] = useState(0);

  const slidesRef = useRef<Array<HTMLDivElement | null>>([]);
  const slickTrackRef = useRef<{ node: HTMLDivElement | null }>({ node: null });

  const [startSwipe, endSwipe, isSwipe] = useSwipe();
  const [rectSection, refSection] = useClientRect();

  const currentTag = useAppSelector(selectFilterMeta);

  const changeSlider = (prev: number, next: number) => {
    const slickTrackNode = slickTrackRef.current.node;
    const isEndSlider = next + slidesToShow === itemsFilters.length;
    if (!slickTrackNode || !rectSection) return;
    if (isEndSlider) {
      setTimeout(() => {
        const newDeltaTransform = rectSection.width - widthLastElements;
        slickTrackNode.style.left = `${newDeltaTransform}px`;
      }, 0);
    } else {
      slickTrackNode.style.left = '0';
    }
  };

  const getSlickTrackNode = () => {
    slickTrackRef.current.node = document.querySelector('.tours-filters__types-slider .slick-track');
  };

  useEffect(() => {
    const slides = slidesRef.current;
    if (rectSection && slides.length === itemsFilters.length) {
      let numbersSlides = 0;
      let accWidth = 0;
      for (let i = slides.length - 1; i > 0; i--) {
        const slide = slides[i];
        if (!slide) {
          break;
        }
        const rect = slide.getBoundingClientRect();
        const newAccWidth = accWidth + Math.ceil(rect.width * 100) / 100;
        if (newAccWidth > rectSection.width) {
          break;
        }
        ++numbersSlides;
        accWidth = newAccWidth;
      }
      setSlidesToShow(numbersSlides || 7);
      setWidthLastElements(accWidth);
    }
  }, [rectSection]);

  return (
    <div ref={refSection}>
      <Slider
        {...settings}
        className={classNames('tours-filters__types-slider')}
        slidesToShow={slidesToShow}
        swipeEvent={startSwipe}
        beforeChange={changeSlider}
        onInit={getSlickTrackNode}
      >
        {itemsFilters.map((filter, index) => (
          <div
            key={filter.id}
            className='tours-filters__wrapper'
            onMouseDown={endSwipe}
            ref={(el) => (slidesRef.current[index] = el)}
          >
            <FilterItem
              active={filter.value === currentTag}
              onClick={() => !isSwipe && clickFilter(filter.value)}
              label={`#${filter.label}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
