import { useEffect, useState } from 'react';
import {
  SimpleForm,
  SelectInput,
  TextInput,
  required,
  Labeled,
  WrapperField,
  FormDataConsumer,
  useRecordContext,
  ReferenceInput,
  useChoicesContext,
  NumberInput,
  SelectArrayInput,
} from 'react-admin';
import FileInputAdmin from '../FileInputAdmin';
import CheckBoxControl from '../CheckBoxControl';
import { CITY_FIELDS } from '../../../shared/constants/const';
import { LOCATIONS_FIELDS, TRANSFORM_LOCATIONS } from 'shared/constants/locations.constants';
import TinyMCE from '../TinyMCE';
import { useFormContext } from 'react-hook-form';
import OsmMapForm from '../OsmMapForm/OsmMapForm';
import BrendMapForm from '../BrendMapForm/BrendMapForm';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
  marginBottom: '20px',
};

const choicesLocationActive = [
  {
    name: TRANSFORM_LOCATIONS.active.city,
  },
  {
    name: TRANSFORM_LOCATIONS.active.industry,
  },
  {
    name: TRANSFORM_LOCATIONS.active.jeeps,
  },
  {
    name: TRANSFORM_LOCATIONS.active.expedition,
  },
  {
    name: TRANSFORM_LOCATIONS.active.northernLights,
  },
  {
    name: TRANSFORM_LOCATIONS.active.birdwatching,
  },
  {
    name: TRANSFORM_LOCATIONS.active.quadBikes,
  },
  {
    name: TRANSFORM_LOCATIONS.active.sapsurfing,
  },
  {
    name: TRANSFORM_LOCATIONS.active.photo,
  },
  {
    name: TRANSFORM_LOCATIONS.active.mountaineering,
  },
];

const choicesLocationType = [
  {
    label: TRANSFORM_LOCATIONS.type.NATURE.label,
    value: TRANSFORM_LOCATIONS.type.NATURE.value,
  },
  {
    label: TRANSFORM_LOCATIONS.type.WATER.label,
    value: TRANSFORM_LOCATIONS.type.WATER.value,
  },
  {
    label: TRANSFORM_LOCATIONS.type.MOUNT.label,
    value: TRANSFORM_LOCATIONS.type.MOUNT.value,
  },
  {
    label: TRANSFORM_LOCATIONS.type.SPECIAL_TRANSFER.label,
    value: TRANSFORM_LOCATIONS.type.SPECIAL_TRANSFER.value,
  },
  {
    label: TRANSFORM_LOCATIONS.type.DEVELOP.label,
    value: TRANSFORM_LOCATIONS.type.DEVELOP.value,
  },
  {
    label: TRANSFORM_LOCATIONS.type.HELICOPTER.label,
    value: TRANSFORM_LOCATIONS.type.HELICOPTER.value,
  },
];

const choicesLocationTransport = [
  {
    label: TRANSFORM_LOCATIONS.transport.OFFROAD.label,
    value: TRANSFORM_LOCATIONS.transport.OFFROAD.value,
  },
  {
    label: TRANSFORM_LOCATIONS.transport.HELICOPTER.label,
    value: TRANSFORM_LOCATIONS.transport.HELICOPTER.value,
  },
  {
    label: TRANSFORM_LOCATIONS.transport.HIKING.label,
    value: TRANSFORM_LOCATIONS.transport.HIKING.value,
  },
  {
    label: TRANSFORM_LOCATIONS.transport.AUTO.label,
    value: TRANSFORM_LOCATIONS.transport.AUTO.value,
  },
  {
    label: TRANSFORM_LOCATIONS.transport.WATER.label,
    value: TRANSFORM_LOCATIONS.transport.WATER.value,
  },
];

const SelectCity = ({ isCity }: { isCity: boolean }) => {
  const { selectedChoices } = useChoicesContext();
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const isCoords = record?.lat && record?.lng;
  const [center, setCenter] = useState(isCoords ? [record.lat, record.lng] : [69.34398, 88.21039]);
  const [idCity, setIdCity] = useState(null);

  useEffect(() => {
    const [city] = selectedChoices;
    if (!city || city.id === idCity) return;
    const { id, name, lat, lng, map2DLng, map2DLat } = city;
    if (idCity) {
      const coords = [lat, lng];
      setCenter(coords);
    }
    setIdCity(id);
    setValue('city.name', name);
    setValue('city.lat', lat);
    setValue('city.lng', lng);
    setValue('map2DLat', map2DLat);
    setValue('map2DLng', map2DLng);
  }, [selectedChoices, idCity]);

  return (
    <>
      {isCity && (
        <Labeled label={CITY_FIELDS.title} fullWidth>
          <SelectInput
            optionText='name'
            optionValue='id'
            label='Select...'
            fullWidth
            format={(v) => (v ? v : '')}
            validate={[required()]}
          />
        </Labeled>
      )}
      <OsmMapForm outCenter={center} required />
    </>
  );
};

const SelectTerritory = () => {
  return (
    <Labeled label={LOCATIONS_FIELDS.territory} fullWidth isRequired>
      <ReferenceInput source='cluster.id' reference='cluster' perPage={Infinity} fullWidth>
        <SelectInput
          label='Select...'
          optionText='name'
          optionValue='id'
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : '')}
        />
      </ReferenceInput>
    </Labeled>
  );
};

export const LocationsForm = () => {
  return (
    <SimpleForm>
      <Labeled label={LOCATIONS_FIELDS.title} fullWidth>
        <TextInput source='name' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={LOCATIONS_FIELDS.popular}>
        <CheckBoxControl name='popular' />
      </Labeled>
      <FileInputAdmin source='preview' label={LOCATIONS_FIELDS.preview} multiple={false} validate={[required()]} />
      <FileInputAdmin source='gallery' label={LOCATIONS_FIELDS.gallery} multiple={true} validate={[required()]} />
      <Labeled label={LOCATIONS_FIELDS.locationActive} fullWidth>
        <SelectArrayInput
          source='locationType'
          label='Select...'
          optionText='name'
          optionValue='name'
          choices={choicesLocationActive}
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : '')}
        />
      </Labeled>
      <Labeled label={LOCATIONS_FIELDS.locationType} fullWidth>
        <SelectInput
          source='locationEnum'
          label='Select...'
          optionText='label'
          optionValue='value'
          choices={choicesLocationType}
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : '')}
        />
      </Labeled>
      <FormDataConsumer>
        {({ formData }) => (!formData.locationType?.includes('Город') ? <SelectTerritory /> : null)}
      </FormDataConsumer>

      <Labeled label={LOCATIONS_FIELDS.transport} fullWidth>
        <SelectArrayInput
          source='availableTransfers'
          label='Select...'
          optionText='label'
          optionValue='value'
          choices={choicesLocationTransport}
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : '')}
        />
      </Labeled>
      <Labeled label={LOCATIONS_FIELDS.season}>
        <>
          <CheckBoxControl name='season.winter' label='Зима' />
          <CheckBoxControl name='season.spring' label='Весна' />
          <CheckBoxControl name='season.autumn' label='Осень' />
          <CheckBoxControl name='season.summer' label='Лето' />
        </>
      </Labeled>
      <Labeled label={LOCATIONS_FIELDS.groupSize} fullWidth>
        <WrapperField>
          <div style={styleInBlock}>
            <Labeled label='Минимально' fullWidth>
              <NumberInput source='groupSize.min' label='' />
            </Labeled>
            <Labeled label='Максимально' fullWidth>
              <NumberInput source='groupSize.max' label='' />
            </Labeled>
          </div>
        </WrapperField>
      </Labeled>
      <div style={{ width: '100%' }}>
        <FormDataConsumer>
          {({ formData }) => {
            const isCity = formData.locationType?.includes('Город');

            return (
              <>
                <ReferenceInput source='city.id' reference='city' perPage={Infinity} fullWidth>
                  <SelectCity isCity={isCity} />
                </ReferenceInput>
                {!isCity && <BrendMapForm required />}
              </>
            );
          }}
        </FormDataConsumer>
      </div>
      <Labeled label={LOCATIONS_FIELDS.shortDesc} fullWidth>
        <TextInput source='description' validate={[required()]} multiline={true} minRows='3' maxRows='3' label='' />
      </Labeled>
      <Labeled label={LOCATIONS_FIELDS.desc} fullWidth>
        <TinyMCE property='fullDescription' />
      </Labeled>
      <Labeled label={LOCATIONS_FIELDS.guidebook} fullWidth>
        <ReferenceInput source='guidebookId' reference='guidebook' perPage={Infinity} fullWidth>
          <SelectInput
            optionText='name'
            optionValue='id'
            label='Select...'
            fullWidth
            format={(v) => (v && v !== 'new' ? v : '')}
          />
        </ReferenceInput>
      </Labeled>
    </SimpleForm>
  );
};
