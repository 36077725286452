import { HTMLAttributes } from 'react';
import classNames from 'classnames';

type TTravelGuideParamsGroupProps = {
  title?: string;
  description: string;
} & HTMLAttributes<HTMLDivElement>;

export const TravelGuideParamsGroup = ({ title, description, className }: TTravelGuideParamsGroupProps) => {
  return (
    <div className='travel-guide-item-params__group'>
      {title && <div className={classNames('travel-guide-item-params__name', className)}>{title}</div>}
      <div className='travel-guide-item-params__value'>{description}</div>
    </div>
  );
};
