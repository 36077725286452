import { useState, useEffect } from 'react';

import Notification from 'shared/lib/notification';
import RequestService from 'services/request.service';

export function useFirstView(user) {
  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    function getStatus() {
      if (!user) return;
      RequestService.getUnread({ id: user.id, type: user.type.toLowerCase() })
        .then(({ data }) => {
          setViewed(data.unRead);
        })
        .catch((error) => {
          Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
        });
    }
    getStatus();
    const interval = setInterval(getStatus, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [user]);

  return viewed;
}
