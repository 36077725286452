import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import {
  ArrayInput,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  required,
} from 'react-admin';
import Box from '@mui/material/Box';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideSimilarPlaces = () => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <Labeled label={TRAVEL_GUIDE_FIELDS.similarPlaces} fullWidth>
            <Box sx={styleInBlock}>
              <ArrayInput source='related' label='' fullWidth>
                <SimpleFormIterator disableReordering disableAdd={formData?.similarPlaces?.length > 2}>
                  <ReferenceInput source='id' reference='guidebook' perPage={Infinity} fullWidth>
                    <SelectInput
                      optionText='name'
                      optionValue='id'
                      label='Select...'
                      fullWidth
                      format={(v) => (v && v !== 'new' ? v : '')}
                      validate={required()}
                    />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Labeled>
        );
      }}
    </FormDataConsumer>
  );
};
