import { useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';

type TBaseAccordionProps = {
  children: JSX.Element;
  question: string;
};

export const BaseAccordion = ({ children, question }: TBaseAccordionProps) => {
  const [isOpen, setOpen] = useState(false);
  const refAnswer = useRef<HTMLDivElement | null>(null);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classNames('base-accordion', { open: isOpen })} onClick={toggleOpen}>
      <div className='base-accordion__question'>
        <h4 className='base-accordion__head'>{question}</h4>
        <CloseIcon className='base-accordion__icon' />
      </div>
      <div
        className='base-accordion__answer'
        ref={refAnswer}
        style={{ height: isOpen ? refAnswer.current?.scrollHeight + 'px' : 0 }}
      >
        {children}
      </div>
    </div>
  );
};
