import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../../shared/hooks/useAppSelector";

const useCheckRegister = (type?: string) => {
  const history = useHistory()
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);


  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    }
  }, [isAuth, userData, history, type]);
};

export default useCheckRegister;