import { api as apiNoAuth } from 'http/commonApi';
import { TTravelGuide } from 'shared/types/travelGuide.types';

export default class TravelGuideService {
  static async getTravelsGuide() {
    return await apiNoAuth().get<TTravelGuide[]>('/guidebook');
  }
  static async getTravelGuideById(id: string) {
    return await apiNoAuth().get<TTravelGuide>(`/guidebook/${id}`);
  }
}
