import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {BaseTextarea} from "../../../../shared/ui/BaseTextarea/BaseTextArea";
import Notification from "../../../../shared/lib/notification";
import CommonService from "../../../../services/common.service";
import {handleError} from "../../../../http/handleError";
import {setIsBecomeMemberOpen} from "../../../../manageStore/common/common.slice";

const BecomeMemberForm = ( ) => {

  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const handleSaving = () => setSaving((prev) => !prev);

  const formik = useFormik({
    initialValues:   { name: '', email:'', message: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        await Notification.promise(CommonService.becomeMember(values));
        formik.resetForm();
        dispatch(setIsBecomeMemberOpen(false))
      } catch (e) {
        Notification.error(handleError(e));
        throw e;
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className=''>
      <form className='login-form become-member-content' onSubmit={formik.handleSubmit}>
        <p className='become-member_desc'>
          Оставьте заявку на консультацию, мы свяжемся с вами в ближайшее время! Обратите внимание, чтобы стать
          участником Кластера нужно работать на территории Таймыра в сфере туризма.
        </p>
        <label htmlFor='name_input'>
          <input
            className='become-member-input'
            id='name_input'
            type='text'
            placeholder='Имя'
            {...formik.getFieldProps('name')}
          />
        </label>
        <p className='login-form-error'>{formik.touched.name && formik.errors.name ? formik.errors.name : ''}</p>
        <label htmlFor='email'>
          <input
            className='become-member-input'
            id='phone'
            type='email'
            placeholder='Email'
            {...formik.getFieldProps('email')}
          />
        </label>
        <p className='login-form-error'>{formik.touched.email && formik.errors.email ? formik.errors.email : ''}</p>{' '}
        <BaseTextarea
            placeholder='Текст сообщения'
            id='comment'
            maxLength={500}
            {...formik.getFieldProps('message')}
        />
        <div className=''>
          <button className='become-member-content__send-btn btn' type='submit' disabled={saving}>
            отправить
          </button>
        </div>

      </form>
    </div>
  );
};

export default BecomeMemberForm;
