import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TLocation } from 'shared/types/location.types';
import { selectSortedLocations } from 'manageStore/designer/designer.select';
import { URL_FOR_IMG } from 'shared/constants/const';
import { setLocation, setSearchValue, setSearchedLocations } from 'manageStore/designer/designer.slice';
import { useOutsideClick } from 'shared/hooks/useOutsideClick';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { ReactComponent as SearchButton } from 'assets/images/common/search.svg';

export const TourDesignerSearch = () => {
  const dispatch = useDispatch();
  const sortedLocations = useSelector(selectSortedLocations);

  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const [searchValue, setValue] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [locations, setLocations] = useState<TLocation[] | null>(null);
  const [heightKeyboardMobile, setHeightKeyboardMobile] = useState(0);

  const ref = useOutsideClick(() => setShowSearch(false), showSearch);
  const mainInputRef = useRef<HTMLInputElement | null>(null);
  const innerInputRef = useCallback((el) => {
    if (el instanceof HTMLInputElement) {
      el.focus();
    } else {
    }
  }, []);

  const handleSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const handleClickCard = (location: TLocation) => {
    dispatch(setLocation(location));
    setShowSearch(false);
  };

  const handleShowTempSearch = () => {
    setShowSearch(true);
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!locations?.length) return;
    dispatch(setSearchedLocations(locations));
    dispatch(setSearchValue(searchValue));
    setValue('');
  };

  const handleClose = () => {
    setValue('');
    setShowSearch(false);
  };

  useEffect(() => {
    const value = searchValue.trim();
    if (searchValue.length === 1) {
      mainInputRef.current?.focus();
    }
    if (value.length > 1) {
      setLocations(sortedLocations.filter((loc) => loc.name.toLowerCase().includes(value.toLowerCase())));
    } else {
      setLocations(null);
    }
  }, [searchValue, sortedLocations]);

  useEffect(() => {
    const handleResize = () => {
      const { height } = window.visualViewport || { height: window.innerHeight };
      const screenHeight = window.innerHeight;

      if (screenHeight - height > 100) {
        setHeightKeyboardMobile(screenHeight - height);
      } else {
        setHeightKeyboardMobile(0);
      }
    };

    if (isMobile) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return (
    <div className='search-locations' ref={ref}>
      <form onSubmit={handleSubmit} className='search-locations__form'>
        <input
          className='search-locations__input'
          placeholder='Поиск локаций и объектов'
          value={searchValue}
          onChange={handleSearch}
          onFocus={handleShowTempSearch}
          ref={mainInputRef}
        />
        <button className='search-locations__submit' disabled={!locations?.length}>
          <SearchButton />
        </button>
      </form>
      {showSearch && locations && (
        <div className='search-locations__result'>
          {isMobile && (
            <div className='search-locations__header'>
              <form
                onSubmit={handleSubmit}
                id='search-form'
                className='search-locations__form search-locations__form_inner'
              >
                <input
                  className='search-locations__input search-locations__input_inner'
                  placeholder='Поиск локаций и объектов'
                  value={searchValue}
                  onChange={handleSearch}
                  ref={innerInputRef}
                />
              </form>
              <CloseIcon className='search-locations__close' onClick={handleClose} />
            </div>
          )}
          {locations.length ? (
            locations.slice(0, 3).map((location) => (
              <div className='search-locations__card' onClick={() => handleClickCard(location)} key={location.id}>
                <img
                  className='search-locations__image'
                  src={`${URL_FOR_IMG}/${location.preview?.path}`}
                  alt={location.name}
                />
                <div className='search-locations__title'>{location.name}</div>
              </div>
            ))
          ) : (
            <div className='search-locations__nothing'>Ничего не найдено</div>
          )}
          {isMobile && !!locations.length && (
            <button
              type='submit'
              form='search-form'
              className='search-locations__button'
              style={{ bottom: `${heightKeyboardMobile + 24}px` }}
            >
              <span>Показать все</span>
              <ArrowButton />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
