import { useController } from 'react-hook-form';
import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { ArrayInput, Labeled, SimpleFormIterator, TextInput, required } from 'react-admin';
import Box from '@mui/material/Box';
import FileInputAdmin from '../FileInputAdmin';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideAdvantageLocation = () => {
  useController({ name: 'reasons', rules: { required: 'Required' } });

  return (
    <Labeled label={TRAVEL_GUIDE_FIELDS.advantageLocation} fullWidth isRequired>
      <Box sx={styleInBlock}>
        <ArrayInput source='reasons' label='' fullWidth>
          <SimpleFormIterator disableReordering>
            <FileInputAdmin source='photo' label='Фотография' multiple={false} validate={[required()]} />
            <TextInput
              source='title'
              label='Заголовок'
              fullWidth
              validate={[required()]}
              format={(v) => (v ? v : '')}
            />
            <TextInput
              source='description'
              label='Описание'
              fullWidth
              validate={[required()]}
              format={(v) => (v ? v : '')}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </Labeled>
  );
};
