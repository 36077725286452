import { Link } from 'react-router-dom';

export const PrivacyMain = () => {
  return (
    <main className='policy'>
      <div className='container policy__container'>
        <h1 className='policy__title'>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
        <section>
          <p className='policy__text'>
            Политика конфиденциальности персональной информации (далее – Политика) действует в отношении всей
            информации, которую <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> может
            получить о посетителях Сайта – пользователей сети Интернет (далее – Пользователь) во время использования им
            сайта «
            <Link to='/' className='policy__link'>
              https://discover-taimyr.ru
            </Link>
            » (далее – Сайт), в ходе исполнения{' '}
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> любых соглашений и договоров с
            Пользователем.
          </p>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> с уважением относится к правам
            Пользователей Сайта. Мы безоговорочно признаем важность конфиденциальности персональной информации, в т.ч.
            персональных данных (Политика в отношении обработки и защиты персональных данных).
          </p>
          <p className='policy__text'>
            Использование Сайта означает безоговорочное согласие Пользователя с{' '}
            <Link className='policy__link' to='/useragreement'>
              Пользовательским соглашением
            </Link>
            , настоящей Политикой и указанными в них условиями обработки его персональной информации; в случае
            несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>
            1. Персональная информация Пользователей, которую обрабатывает АНО «Агентство развития Норильска»
          </h4>
          <p className='policy__text'>
            В рамках настоящей Политики под «персональной информацией пользователя» понимаются:
          </p>
          <ul className='policy__text'>
            <li>
              1.1 Персональные данные, осознано предоставленные Пользователем во время процедуры заполнения и отправки
              форм Сайта.
            </li>
            <li>
              1.2 Данные для авторизации в личном кабинете, осознано предоставленные Пользователем для авторизации в
              системе Сайта.
            </li>
            <li>
              1.3 Техническая информация, автоматически собираемая программным обеспечением Сайта во время его
              посещения.
            </li>
          </ul>
          <p className='policy__text'>
            Персональная информация, предоставляемая Пользователем при заполнении форм на Сайте, проверке на
            достоверность не подвергается.
          </p>
          <p className='policy__text'>
            Во время посещения вами Сайта, администрация Сайта автоматически становится доступна информация из
            стандартных журналов регистрации сервера (server logs). Сюда включается IP-адрес компьютера (или
            прокси-сервера, если он используется для выхода в Интернет), имя Интернет-провайдера, имя домена, тип
            браузера и операционной системы, информация о сайте, с которого Пользователь совершил переход на Сайт,
            страницах Сайта, которые Пользователь посещает, дате и времени этих посещений, файлах, которые Пользователь
            загружает. Эта информация анализируется в агрегированном (обезличенном) виде для анализа посещаемости Сайта
            и используется при разработке предложений по его улучшению и развитию.
          </p>
          <p className='policy__text'>
            Связь между IP-адресом Пользователя и его персональной информацией никогда не раскрывается третьим лицам, за
            исключением тех случаев, когда этого требует законодательство.
          </p>
          <p className='policy__text'>
            Информация о посещении Сайта фиксируется установленными статистическими счетчиками Сайта и сервисом
            «Яндекс.Метрика».
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>2. Цели обработки персональной информации Пользователей</h4>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> собирает и хранит только ту
            персональную информацию, которая необходима для предоставления информации на Сайте или исполнения соглашений
            и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное
            хранение информации в течение определенного законом срока.
          </p>
          <p className='policy__text'>
            Персональную информацию Пользователя Сайт{' '}
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> обрабатывает в целях
            информационно-справочного обслуживания.
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>
            3. Условия обработки персональной информации Пользователей, удаления и ее передачи третьим лицам
          </h4>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> обрабатывает персональную
            информацию Пользователей в соответствии с внутренними регламентами.
          </p>
          <p className='policy__text'>
            Персональные данные Пользователей, предоставленные через формы Сайта, не хранятся на Сайте в сети Интернет,
            используются исключительно для связи с Пользователем.
          </p>
          <p className='policy__text'>
            В отношении персональной информации Пользователя сохраняется ее конфиденциальность, исключен общий доступ
            неограниченному кругу лиц.
          </p>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> не предоставляет персональную
            информацию Пользователей Сайта третьим лицам, кроме случаев, когда этого прямо может требовать
            законодательство (например, по запросу суда).
          </p>
          <p className='policy__text'>
            Удаление персональной информации происходит по письменному обращению Пользователя или его законного
            представителя в <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span>
          </p>
          <p className='policy__text'>
            При обработке персональных данных Пользователей{' '}
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> руководствуется Федеральным
            законом 152-ФЗ «О персональных данных».
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>
            4. Обработка персональной информации при помощи файлов Cookie и счетчиков
          </h4>
          <p className='policy__text'>
            Файлы cookie, передаваемые Сайтом оборудованию Пользователя и оборудованием Пользователя Сайту, могут
            использоваться <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> в
            статистических и исследовательских целях, а также для улучшения разделов Сайта.
          </p>
          <p className='policy__text'>
            Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения Сайта в
            сети Интернет, могут обладать функцией запрета операций с файлами cookie (для любых сайтов или для
            определенных сайтов), а также удаления ранее полученных файлов cookie.
          </p>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> вправе установить, что
            предоставление определенного раздела Сайта возможно лишь при условии, что прием и получение файлов cookie
            разрешены Пользователем.
          </p>
          <p className='policy__text'>
            Структура файлов cookie, его содержание и технические параметры определяются АНО «Агентство развития
            Норильска» и могут изменяться без предварительного уведомления Пользователя.
          </p>
          <p className='policy__text'>
            Счетчики, размещенные <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> на
            Сайте, могут использоваться для анализа файлов cookie Пользователя, для сбора и обработки статистической
            информации об использовании Сайта, а также для обеспечения работоспособности Сайта в целом или их отдельных
            функций в частности. Технические параметры работы счетчиков определяются{' '}
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> и могут изменяться без
            предварительного уведомления Пользователя.
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>
            5. Меры, применяемые для защиты персональной информации Пользователя
          </h4>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> принимает необходимые и
            достаточные организационные и технические меры для защиты персональной информации Пользователя от
            неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а
            также от иных неправомерных действий с ней третьих лиц.
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>
            6. Изменение Политики конфиденциальности. Применимое законодательство
          </h4>
          <p className='policy__text'>
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span> имеет право вносить изменения
            в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата
            последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не
            предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на странице по адресу{' '}
            <Link className='policy__link' to='/privacy'>
              https://discover-taimyr.ru/privacy
            </Link>
            .
          </p>
          <p className='policy__text'>
            К настоящей Политике и отношениям между Пользователем и{' '}
            <span className='policy__text_bold'>АНО «Агентство развития Норильска»</span>, возникающим в связи с
            применением Политики конфиденциальности, подлежит применению право Российской Федерации.
          </p>
        </section>
        <section className='policy__text'>
          <h4 className='policy__title-section'>7. Обратная связь. Вопросы и предложения</h4>
          <p className='policy__text'>
            Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять по адресу{' '}
            <a className='policy__link' href='mailto:privacy@discover-taimyr.ru'>
              privacy@discover-taimyr.ru
            </a>
          </p>
        </section>
      </div>
    </main>
  );
};
