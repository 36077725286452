import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Stomp, { Client } from 'stompjs';
import Notification from 'shared/lib/notification';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import TextareaAutosize from 'react-textarea-autosize';
import { StatussesMenu } from './StatussesMenu';
import SockJS from 'sockjs-client';
import { SOCKET_URL } from 'shared/constants/const';
import { selectCurrentRequest } from 'manageStore/request/request.select';
import { requestActions } from 'manageStore/request/request.slice';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { TRequestFetch } from 'shared/types/request.types';
import RequestService from 'services/request.service';

export const MessageForm = () => {
  const { id } = useParams<{ id: string }>();

  const userData = useAppSelector((state) => state.user.userData);
  const request = selectCurrentRequest();

  const { setNewMessage } = requestActions;

  const dispatch = useAppDispatch();

  const textArea = useRef<HTMLTextAreaElement>(null);

  const [typed, setTyped] = useState('');
  const [socket, setSocket] = useState<Client | null>(null);

  const handleTyping = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTyped(e.target.value);
  };

  const onMessageReceived = (msg: Stomp.Message) => {
    dispatch(setNewMessage(JSON.parse(msg.body)));
  };

  const sendMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (typed.trim()) {
        const message = {
          text: typed,
          userId: userData?.id,
          requestId: id,
        };
        socket?.send('/app/chat', {}, JSON.stringify(message));
        setTyped('');
      } else {
        Notification.warning('Введите текст!');
      }
    },
    [socket, typed]
  );

  useEffect(() => {
    let textAreaVariable: HTMLTextAreaElement | null = null;
    const textAreaListener = (e: KeyboardEvent) => {
      if (e.code === 'Enter' && e.getModifierState('Shift')) {
        sendMessage(e);
      }
    };
    if (textArea.current) {
      textAreaVariable = textArea.current;
      textAreaVariable.addEventListener('keypress', textAreaListener);
    }
    return () => {
      if (textAreaVariable) {
        textAreaVariable.removeEventListener('keypress', textAreaListener);
      }
    };
  }, [sendMessage]);

  useEffect(() => {
    var sockjs = new SockJS(SOCKET_URL);
    let stompClient = Stomp.over(sockjs);
    stompClient.debug = () => {};
    stompClient.connect({}, function () {
      stompClient.subscribe('/request/' + id + '/queue/messages', onMessageReceived);
    });
    setSocket(stompClient);
    return () => sockjs.close();
  }, [setSocket, id, userData]);

  useEffect(() => {
    if (!userData) return;
    const userType = userData?.type.toLowerCase() as keyof TRequestFetch['firstView'];
    if (request && (!request.firstView || !request.firstView[userType])) {
      RequestService.setRead({ id: Number(id), type: userType })
        .then()
        .catch((error) => Notification.error(error?.response?.data?.message || 'Произошла ошибка!'));
    }
  }, [request, id, userData]);

  return (
    <form className='chat-bottom'>
      <TextareaAutosize
        className='chat-textarea'
        value={typed}
        minRows={1}
        maxRows={4}
        placeholder='Введите текст сообщения...'
        onChange={handleTyping}
        ref={textArea}
      />
      <StatussesMenu />
      <button className='chat-bottom__send-btn' onClick={sendMessage}></button>
    </form>
  );
};
