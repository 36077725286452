import classNames from 'classnames';
import { TagEntity } from '../TagEntity/TagEntity';
import { TThemeTravelGuide } from '../../types/travelGuide.types';

type TOpacityContentCardProps = {
  children?: JSX.Element;
  srcImage: string;
  altImage: string;
  className: string;
  tag?: string;
  locationTags?: TThemeTravelGuide[];
};

export const OpacityContentCard = ({
  children,
  srcImage,
  altImage,
  className,
  tag,
  locationTags,
}: TOpacityContentCardProps) => {
  return (
    <div className={classNames('opacity-content-card', className)}>
      <div className='travel-guide-item__tags-list travel-guide-item__tags-inner'>
          {!!tag && <TagEntity className='travel-guide-item__type location-tag' tag={tag} isRed />}
        {locationTags?.map((tag) => (
          <TagEntity className='travel-guide-item__type location-tag' tag={tag} key={tag} />
        ))}
      </div>
      <img
        className={classNames('opacity-content-card__background', `${className}__background`)}
        src={srcImage}
        alt={altImage}
      />
      {!!children && (
        <div className={classNames('opacity-content-card__content', `${className}__content`)}>{children}</div>
      )}
    </div>
  );
};
