import Slider, { Settings } from 'react-slick';
import { usePortal } from 'shared/hooks/usePortal';
import { OpacityContentCard } from 'shared/lib/OpacityContentCard/OpacityContentCard';
import { Portal } from 'shared/lib/Portal/Portal';
import { PopupImagesSlider } from 'widgets/shared/PopupSliders/PopupImagesSlider/PopupImagesSlider';
import { useSwipe } from 'shared/hooks/useSwipe';
import { useState } from 'react';
import { TSingleFile } from 'shared/types/common.types';
import { URL_FOR_IMG } from 'shared/constants/const';

type TTravelGuideItemGalleryProps = {
  photos: TSingleFile[];
};

const settingsSlider: Settings = {
  className: 'travel-guide-item-gallery__slider',
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1123,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const TravelGuideItemGallery = ({ photos }: TTravelGuideItemGalleryProps) => {
  const [initialSlide, setInitialSlide] = useState(0);
  const [isOpenPortal, togglePortal] = usePortal(false);
  const [startSwipe, endSwipe, isSwipe] = useSwipe();

  const openImage = (index: number) => {
    setInitialSlide(index);
    togglePortal();
  };

  return (
    <section className='travel-guide-item-gallery'>
      <div className='container travel-guide-item__container'>
        <h3 className='travel-guide-item-gallery__title'>Галерея</h3>
        <Slider {...settingsSlider} swipeEvent={startSwipe}>
          {photos.map(({ id, path }, index) => (
            <div onClick={() => !isSwipe && openImage(index)} onMouseDown={endSwipe} key={id}>
              <OpacityContentCard
                srcImage={`${URL_FOR_IMG}/${path}`}
                altImage='fact'
                className='travel-guide-item-gallery__image'
                key={id}
              />
            </div>
          ))}
        </Slider>
      </div>
      <Portal isOpen={isOpenPortal} onClose={togglePortal}>
        <PopupImagesSlider images={photos} initialSlide={initialSlide} onClose={togglePortal} />
      </Portal>
    </section>
  );
};
