import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  ChipField,
} from 'react-admin';
import { USERS_FIELDS } from '../../../shared/constants/const';

const UserShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source="login" label={USERS_FIELDS.login} />
        <Labeled label={USERS_FIELDS.role}>
          <ChipField source="role" />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  </div>
);

export default UserShow;
