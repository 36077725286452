import React from 'react'
import { Edit } from 'react-admin'
import TrKMemberForm from './TrKMemberForm'

const TrKMemberEdit = () => (
  <Edit>
    <TrKMemberForm />
  </Edit>
)

export default TrKMemberEdit
