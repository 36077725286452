import { useFormik } from 'formik';
// import * as Yup from 'yup';
import classnames from 'classnames';
import { TReviewFetchRequest } from 'shared/types/review.types';
import { useEffect } from 'react';
import { selectIsUpoadingReviews } from 'manageStore/reviews/reviews.select';
import { ReviewRating } from './ReviewRating';

type TReviewFormProps = {
  review?: TReviewFetchRequest | null;
  closeForm: () => void;
  handleSubmit: (newReview: TReviewFetchRequest) => void;
  isNewReview?: boolean;
};

export const ReviewForm = ({ review, closeForm, handleSubmit }: TReviewFormProps) => {
  const isUploading = selectIsUpoadingReviews();

  const formik = useFormik({
    initialValues: {
      advantages: review?.advantages ? review.advantages : '',
      disadvantages: review?.disadvantages ? review.disadvantages : '',
      comment: review?.comment ? review.comment : '',
      rating: review?.rating ? review.rating : 0,
      mostliked: review?.mostliked ? review.mostliked : [],
    },
    // validationSchema: Yup.object({
    //   advantages: Yup.string().required('Заполните поле'),
    //   disadvantages: Yup.string().required('Заполните поле'),
    //   comment: Yup.string().required('Заполните поле'),
    // }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    return () => closeForm();
  }, []);

  return (
    <div className='Amain-review__body'>
      <form onSubmit={formik.handleSubmit}>
        <div className='Amain-review__top'>
          <div className='Amain-review__subtitle'>
            <ReviewRating
              rating={formik.values.rating}
              label='Общая оценка'
              setRating={(rating: number) => formik.setFieldValue('rating', rating)}
            />
          </div>
          <div>
            <p className='Amain-review__subtitle'>Больше всего понравилось:</p>
            <ul className='Amain-review__mostliked-list'>
              <li
                className={classnames('Amain-review__mostliked-item', {
                  active: formik.values.mostliked.includes('Маршрут'),
                })}
                onClick={() =>
                  formik.setFieldValue(
                    'mostliked',
                    formik.values.mostliked.includes('Маршрут')
                      ? formik.values.mostliked.filter((item) => item !== 'Маршрут')
                      : [...formik.values.mostliked, 'Маршрут']
                  )
                }
              >
                Маршрут
              </li>
              <li
                className={classnames('Amain-review__mostliked-item', {
                  active: formik.values.mostliked.includes('Гиды'),
                })}
                onClick={() =>
                  formik.setFieldValue(
                    'mostliked',
                    formik.values.mostliked.includes('Гиды')
                      ? formik.values.mostliked.filter((item) => item !== 'Гиды')
                      : [...formik.values.mostliked, 'Гиды']
                  )
                }
              >
                Гиды
              </li>
              <li
                className={classnames('Amain-review__mostliked-item', {
                  active: formik.values.mostliked.includes('Сервис'),
                })}
                onClick={() =>
                  formik.setFieldValue(
                    'mostliked',
                    formik.values.mostliked.includes('Сервис')
                      ? formik.values.mostliked.filter((item) => item !== 'Сервис')
                      : [...formik.values.mostliked, 'Сервис']
                  )
                }
              >
                Сервис
              </li>
              <li
                className={classnames('Amain-review__mostliked-item', {
                  active: formik.values.mostliked.includes('Жилье'),
                })}
                onClick={() =>
                  formik.setFieldValue(
                    'mostliked',
                    formik.values.mostliked.includes('Жилье')
                      ? formik.values.mostliked.filter((item) => item !== 'Жилье')
                      : [...formik.values.mostliked, 'Жилье']
                  )
                }
              >
                Жилье
              </li>
            </ul>
          </div>
        </div>
        <p className='Amain-review__subtitle'>Достоинства:</p>
        <textarea className='Amain-review__textarea' id='advantages' {...formik.getFieldProps('advantages')}></textarea>
        <p className='Amain-review__subtitle'>Недостатки:</p>
        <textarea
          className='Amain-review__textarea'
          id='disadvantages'
          {...formik.getFieldProps('disadvantages')}
        ></textarea>
        <p className='Amain-review__subtitle'>Комментарий:</p>
        <textarea className='Amain-review__textarea' id='comment' {...formik.getFieldProps('comment')}></textarea>
        <div className='Amain-review__edit'>
          <button className='Amain-review__edit-btn btn' type='submit' disabled={!formik.dirty || isUploading}>
            Отправить
          </button>
          <button className='Amain-review__edit-btn btn blue' type='button' onClick={closeForm}>
            Отменить
          </button>
          <p className='Amain-review__edit-text'>
            Нажимая кнопку “Отправить” вы соглашаетесь с условиями{' '}
            <a href='/' target='_blank'>
              политики конфиденциальности
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};
