import { TRootState } from 'store';

export const selectLoading = ({ designer }: TRootState) => designer.isLoading;
export const selectOrder = ({ designer }: TRootState) => designer.order;
export const selectOrderLocations = ({ designer }: TRootState) => designer.orderLocations;
export const selectActiveLocation = ({ designer }: TRootState) => designer.activeLocation;
export const selectNeedShowLocation = ({ designer }: TRootState) => designer.needShowLocation;
export const selectActiveCity = ({ designer }: TRootState) => designer.activeCity;
export const selectParamsOrder = ({ designer }: TRootState) => designer.paramsOrder;
export const selectShowOrder = ({ designer }: TRootState) => !!designer.order.length;
export const selectReadyOrder = ({ designer }: TRootState) => designer.isReadyOrder;
export const selectBottomBox = ({ designer }: TRootState) => designer.bottomBox;
export const selectLocations = ({ designer }: TRootState) => designer.locations;
export const selectLocationsToDisplay = ({ designer }: TRootState) => designer.locationsToDisplay;
export const selectPopularLocations = ({ designer }: TRootState) => designer.popularLocations;
export const selectSortedLocations = ({ designer }: TRootState) => designer.sortedLocations;
export const selectSearchedLocations = ({ designer }: TRootState) => designer.searchedLocations;

export const selectLocationInOrder = ({ designer }: TRootState, locationName: string) => {
  return !!designer.orderLocations[locationName];
};
