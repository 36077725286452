import classNames from 'classnames';
import { URL_FOR_IMG } from '../../shared/constants/const';

const CityBalloon = ({ loc, addToOrder, showOsmMap }) => {
  return (
    <div className='balloon-content'>
      <h4 className='balloon-title'>{loc.name}</h4>
      {loc.photo?.path ? <img src={`${URL_FOR_IMG}/${loc.photo.path}`} alt='Изображение локации' /> : null}
      <p className='balloon-text'>{loc.description}</p>
      <div className='balloon-controlls'>
        {addToOrder && (
          <button
            className={classNames('balloon-controlls-btn', { red: !!loc.inOrder })}
            onClick={() => addToOrder(loc)}
          >
            {loc.inOrder ? 'Добавлено' : 'Добавить'}
          </button>
        )}
        <button className='balloon-controlls-btn red' onClick={() => showOsmMap(loc)}>
          Что здесь?
        </button>
      </div>
    </div>
  );
};

export default CityBalloon;
