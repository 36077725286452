import React from 'react'
import { Create } from 'react-admin'
import TrKMemberForm from './TrKMemberForm'

const TrKMemberCreate = () => (
  <Create>
    <TrKMemberForm />
  </Create>
)

export default TrKMemberCreate
