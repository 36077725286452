import parse from 'html-react-parser';
import { Show, SimpleShowLayout, TextField, WrapperField, Labeled, ChipField, FunctionField } from 'react-admin';
import { URL_FOR_IMG } from '../../../shared/constants/const';
import { LOCATIONS_FIELDS } from 'shared/constants/locations.constants';
import CoordsShow from '../CoordsShow';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
};

const LocationsShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={LOCATIONS_FIELDS.title} />
        <CoordsShow />
        <TextField source='description' label={LOCATIONS_FIELDS.shortDesc} />
        <FunctionField
          label={LOCATIONS_FIELDS.desc}
          render={(record) => {
            return <div>{parse(record?.fullDescription || '')}</div>;
          }}
        />
        <FunctionField
          label={LOCATIONS_FIELDS.preview}
          render={(record) => {
            return record.preview ? <img src={`${URL_FOR_IMG}/${record.preview.path}`} alt='preview' /> : null;
          }}
        />
        <FunctionField
          label={LOCATIONS_FIELDS.season}
          render={(record) => (
            <div style={styleInBlock}>
              <Labeled label='Зима'>
                <ChipField
                  source='season.winter'
                  record={{
                    season: { winter: record.season.winter ? 'Да' : 'Нет' },
                  }}
                />
              </Labeled>
              <br />
              <Labeled label='Весна'>
                <ChipField
                  source='season.spring'
                  record={{
                    season: { spring: record.season.spring ? 'Да' : 'Нет' },
                  }}
                />
              </Labeled>
              <br />
              <Labeled label='Лето'>
                <ChipField
                  source='season.summer'
                  record={{
                    season: { summer: record.season.summer ? 'Да' : 'Нет' },
                  }}
                />
              </Labeled>
              <br />
              <Labeled label='Осень'>
                <ChipField
                  source='season.autumn'
                  record={{
                    season: { autumn: record.season.autumn ? 'Да' : 'Нет' },
                  }}
                />
              </Labeled>
            </div>
          )}
        />
        <WrapperField label={LOCATIONS_FIELDS.groupSize}>
          <div style={styleInBlock}>
            <Labeled label='Минимально' fullWidth>
              <TextField source='groupSize.min' />
            </Labeled>
            <Labeled label='Максимально' fullWidth>
              <TextField source='groupSize.max' />
            </Labeled>
          </div>
        </WrapperField>
        <TextField source='locationType' label={LOCATIONS_FIELDS.locationActive} />
      </SimpleShowLayout>
    </Show>
  </div>
);

export default LocationsShow;
