import { useController } from 'react-hook-form';

type TSwitchControlProps = {
  name: string;
  title: string;
  disabled?: boolean;
};

const SwitchControl = ({ name, title, disabled }: TSwitchControlProps) => {
  const control = useController({ name });
  return (
    <label className='tours__checkbox-label admin'>
      {title}
      <input
        {...control.field}
        value={!!control.field.value ? control.field.value : false}
        checked={!!control.field.value ? control.field.value : false}
        disabled={!!disabled}
        type='checkbox'
      />
      <span></span>
    </label>
  );
};

export default SwitchControl;
