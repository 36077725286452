import { Helmet } from 'react-helmet';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import { ReactComponent as FileIcon } from 'assets/images/file_icon.svg';
import { useEffect, useState } from 'react';
import CommonService from 'services/common.service';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { TDocFetch } from 'shared/types/common.types';

export const NpaPage = () => {
  const [docs, setDocs] = useState<TDocFetch[]>([]);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const { data } = await CommonService.getNpaDocs();
        setDocs(data);
      } catch (e) {
        const errorMessage = handleError(e);
        Notification.error(errorMessage);
        throw e;
      }
    };
    getDocs();
  }, []);

  return (
    <>
      <Helmet>
        <title>НПА туриндустрии - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <div className='content'>
        <Header />
        <section className='npa'>
          <div className='container'>
            <article className='npa__inner ornament ornament_big'>
              <h1 className='npa__title title'>НПА туриндустрии</h1>
              <div className='npa__content'>
                <ul className='npa__file-list'>
                  {docs.map((doc) => (
                    <li className='npa__file' key={doc.id}>
                      <a href={doc.url} target='_blank' className='npa__file_link' rel='noreferrer'>
                        <FileIcon className='npa__file_icon' />
                        <span className='npa__file_name'>{doc.caption}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </article>
          </div>
          <MobileBottom />
        </section>
      </div>
      <Footer />
    </>
  );
};
