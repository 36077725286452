import {ArrowFilterIcon} from "./ArrowFilterIcon";
import {useRef, useState} from "react";
import {SimpleFilterList} from "./SimpleFilterList";
import {deepCopy} from "../../../helpers/deepCopy";


export type TFilterList = {
    value: string;
    label: string;
    select: boolean;
}

export type TpropsSimpleFilter ={
    filters: TFilterList[],
    setFilter: Function;
    countVariant?: number;
    appleEvent?: Function;
}


export const SimpleFilter = ({ filters, setFilter, countVariant=0, appleEvent=()=>{} }: TpropsSimpleFilter) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectVariant = filters.filter(el=>el.select)
    const showElement = 2
    const still = selectVariant.length - showElement;
    const stillText = still > 0 ? `+${still}` :''
    const result = selectVariant && selectVariant.length >0?
        selectVariant.splice(0, showElement).map(el=>el.label).join(',') + stillText:'';

    let [tempFilterBeforeOpen, setTempData] = useState<TFilterList[]>([])

    const preOpen = ()=>{
        setTempData([...deepCopy(filters)]);
        setIsOpen(true)
    }

    const preAppleFilter = ()=> {
        setIsOpen(false);
        appleEvent();
    }

    const preClose = ()=>{
        setFilter([...tempFilterBeforeOpen]);
        setIsOpen(false)
    }

    return (
        <div className='filterBody'>
            <div className='simpleFilterInput' onClick={()=>preOpen()}>
                <span  className='simpleFilterInput__value'>{result?result:'Все теги'}</span>
                <ArrowFilterIcon/>
            </div>
            {isOpen && <SimpleFilterList
                preAppleEvent={setFilter}
                countVariant={countVariant}
                filters={filters}
                closeEvent={()=>preClose()}
                appleEvent={()=>preAppleFilter()}  />}
        </div>
    );
};
