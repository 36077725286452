import { useHistory } from 'react-router-dom';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import { TPartner } from 'shared/types/partner.types';
import Slider, { Settings } from 'react-slick';
import { useAutoSlide } from 'shared/hooks/useAutoSlide';

type TPartnerCardProps = {
  partner: TPartner;
  openPartnerOut?: (id: number | string) => void;
  endSwipe?: () => void;
};

const settingsSlider: Settings = {
  className: 'partner-card__slider',
  dots: false,
  infinite: true,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  autoplaySpeed: 2000,
  pauseOnHover: false,
  draggable: false,
  swipe: false,
  touchMove: false,
};

export const PartnerCard = ({ partner, openPartnerOut, endSwipe }: TPartnerCardProps) => {
  const history = useHistory();
  const [startAutoSlide, stopAutoSlide, sliderRef] = useAutoSlide();

  const { id, name, engName, tag, partnerPhotos, description } = partner;

  const categoryLabel = TRANSFORM_TEXT.partners[tag].label;

  const openPartner = () => {
    openPartnerOut ? openPartnerOut(engName || id) : history.push(`/partners/${engName}`);
  };

  return (
    <div
      className='partner-card'
      onClick={openPartner}
      onMouseEnter={startAutoSlide}
      onMouseLeave={stopAutoSlide}
      onMouseDown={endSwipe}
    >
      <div className='partner-card__content'>
        <Slider {...settingsSlider} ref={sliderRef}>
          {partnerPhotos.map((photo) => (
            <div className='partner-card__preview' key={photo.path}>
              <img className='partner-card__img' src={`${URL_FOR_IMG}/${photo.path}`} alt={name} />
            </div>
          ))}
        </Slider>
        <div className='partner-card__type'>{categoryLabel}</div>
        <div className='partner-card__description'>
          <h5 className='partner-card__name'>{name}</h5>
          <p className='partner-card__text'>{description}</p>
        </div>
      </div>
    </div>
  );
};
