import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import CommonService from 'services/common.service';
import { TRootState } from 'store';
import {TBlog, TNew} from 'shared/types/types';
import {convertBlogToNews} from "../../helpers/blog/convertorBlogToNews";
import {convertTagTeritoryToEnumTerritory} from "../../helpers/blog/convertTagTeritoryToEnumTerritory";
import {allItem} from "../../features/lib/NewsFilter/NewsFiltersByType";


type TInitialState = {
  newsList: TNew[];
  important: TNew[];
  filterMeta: string;
  selectedTag: string[];
  isLoading: boolean;
  isLoadingBlog: boolean;
  isOpen: boolean;
  blogList: TBlog[];
  filterByTag: string[];
};

const initialState: TInitialState = {
  newsList: [],
  important: [],
  selectedTag: [],
  filterMeta: '',
  isLoading: false,
  isLoadingBlog: true,
  isOpen: false,
  blogList: [],
  filterByTag: []
};

export const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setFilterTag(state, { payload }: PayloadAction<string[]>){
      state.filterByTag = payload;
    },
    setNewsList: (state, { payload }: PayloadAction<TNew[]>) => {
      state.newsList = payload;
    },
    setImportantNews: (state, { payload }: PayloadAction<TNew[]>) => {
      state.important = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    /**
     * @deprecated
     * @param state
     * @param payload
     */
    setFilterMeta: (state, { payload }: PayloadAction<string>) => {
      state.filterMeta = payload;
    },
    setFilteredNews: (state, { payload }: PayloadAction<TNew[]>) => {
      state.newsList = payload;
    },
    setSelectedTag: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedTag = payload;
    },
    setBlogsList:  (state, { payload }: PayloadAction<TBlog[]>) => {
      state.blogList = payload;
      state.isLoadingBlog = false;
    },
  },
});

export const {setFilterTag, setBlogsList, setNewsList, setImportantNews, setIsLoading, setFilteredNews, setFilterMeta, setSelectedTag } =
  slice.actions;

export const getPublishedNews = createAsyncThunk('getPublishedNews', async (_, { dispatch }) => {
  dispatch(setIsLoading(true));
  try {
    const { data } = await CommonService.getPublishedNews();
    const importantNews = data.filter((item) => !!item.important);
    if(data && data.length > 0) {
      dispatch(setNewsList(data));
      dispatch(setImportantNews(importantNews));
    }
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setIsLoading(false));
  }
});

export const selectNewsList = (state: TRootState) => state.news.newsList;
export const selectImportant = (state: TRootState) => state.news.important;
export const selectIsLoading = (state: TRootState) => state.news.isLoading;
export const selectIsLoadingBlog = (state: TRootState) => state.news.isLoadingBlog
export const selectFilterMeta = (state: TRootState) => state.news.filterMeta;
export const selectSelectedTag = (state: TRootState) => state.news.selectedTag;
export const selectBlogList = (state: TRootState) => {
   const convertToNews = state.news.blogList.map(  item => convertBlogToNews(item))
    return convertToNews }

export const selectfilterBlogs = (state: TRootState) => {
  let nowBlogList = state.news.blogList;
  const currentSelectTag  = state.news.filterMeta;
  const filterTerritory = state.news.selectedTag;
  const isAllSelectTerritory = filterTerritory.includes(allItem.label);
  nowBlogList = nowBlogList.filter((item)=>{
    return currentSelectTag && item.typeTags?item.typeTags.includes(currentSelectTag):true
  })
  if(filterTerritory && filterTerritory.length > 0) {
    const convertToEnumTerritory = filterTerritory.map(el=>convertTagTeritoryToEnumTerritory(el))

    nowBlogList = nowBlogList.filter(item=>{
      const nowTeritory = item.territoryTag;
      return isAllSelectTerritory || convertToEnumTerritory.includes(nowTeritory)
    })
  }
  const convertToNews = nowBlogList.map(item => convertBlogToNews(item))
  return convertToNews
}

export const tempSelectfilterBlogs = (state: TRootState) => {

  let nowBlogList = state.news.blogList;
  const currentSelectTag  = state.news.filterMeta;
  const filterTerritory = state.news.selectedTag;
  const isAllSelectTerritory = filterTerritory.includes(allItem.label);
  nowBlogList = nowBlogList.filter((item)=>{
    return currentSelectTag && item.typeTags?item.typeTags.includes(currentSelectTag):true
  })
  if(filterTerritory && filterTerritory.length > 0) {
    const convertToEnumTerritory = filterTerritory.map(el=>convertTagTeritoryToEnumTerritory(el))

    nowBlogList = nowBlogList.filter(item=>{
      const nowTeritory = item.territoryTag;
      return isAllSelectTerritory ||  convertToEnumTerritory.includes(nowTeritory)
    })
  }

  const convertToNews = nowBlogList.map(item => convertBlogToNews(item))
  return filterTerritory && filterTerritory.length > 0 ? convertToNews : []
}

export const selectImportantBlogs = (state: TRootState) => {
  let nowBlogList = state.news.blogList;
  nowBlogList = nowBlogList.filter(item=>item.isImportant)
  const convertToNews = nowBlogList.map(item => convertBlogToNews(item))
  return convertToNews
}

export const selectFilterBlogs_v2 = (state:TRootState)=> {
  let nowBlogList = state.news.blogList;
  const currentFilterByTag  = state.news.filterByTag;
  const filterTerritory = state.news.selectedTag;
  const isAllSelectTerritory = filterTerritory.includes(allItem.label);

  if(currentFilterByTag && currentFilterByTag.length >0) {
    nowBlogList = nowBlogList.filter((item)=>{
      const isAll = currentFilterByTag.includes('');
      const someHAs = currentFilterByTag.some(el=>  item.typeTags.includes(el))
      return isAll || someHAs
    })
  }
  if(filterTerritory && filterTerritory.length > 0) {
    const convertToEnumTerritory = filterTerritory.map(el=>convertTagTeritoryToEnumTerritory(el))

    nowBlogList = nowBlogList.filter(item=>{
      const nowTeritory = item.territoryTag;
      return isAllSelectTerritory || convertToEnumTerritory.includes(nowTeritory)
    })
  }
  const convertToNews = nowBlogList.map(item => convertBlogToNews(item))
  return convertToNews
}


export default slice.reducer;

//тут жеский костыль тут две сушности надо переписать это
