import React from 'react'
import { Create } from 'react-admin'
import GalleryForm from './GalleryForm'

const GalleryCreate = () => (
  <Create>
    <GalleryForm />
  </Create>
)

export default GalleryCreate
