import phoneIcon from 'assets/images/Phone_icon.svg';
import calendarIcon from 'assets/images/calendar_icon.svg';
import { ReactComponent as MailIcon } from 'assets/images/email-input.svg';
import { URL_FOR_IMG } from '../../shared/constants/const';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { touristActions } from 'manageStore/tourist/tourist.slice';

const PointTouristBalloon = ({ loc }) => {
  const dispatch = useAppDispatch();
  const { setTouristInfo } = touristActions;

  const showInfo = () => {
    dispatch(setTouristInfo({ name: loc.name, description: loc.description }));
  };

  return (
    <>
      <div className='balloon-content'>
        <h4 className='balloon-title'>{loc.name}</h4>
        {loc.preview?.path ? (
          <img className='balloon-preview' src={`${URL_FOR_IMG}/${loc.preview.path}`} alt='Изображение локации' />
        ) : (
          ''
        )}
        <p className='balloon-adress'>{loc.address}</p>
        <div className='balloon-contact'>
          <div className='balloon-contact-icon'>
            <img src={phoneIcon} alt='Контакт' />
          </div>
          <div className='balloon-contact-value'>{loc.phone}</div>
        </div>
        <div className='balloon-contact'>
          <MailIcon className='balloon-contact-icon' />
          <div className='balloon-contact-value'>{loc.email}</div>
        </div>
        <div className='balloon-contact'>
          <div className='balloon-contact-icon'>
            <img src={calendarIcon} alt='Контакт' />
          </div>
          <div className='balloon-contact-value'>{loc.time}</div>
        </div>
        <div className='balloon-controlls'>
          {loc.site && (
            <a href={loc.site} className='balloon-controlls-btn' target='_blank' rel='noreferrer'>
              К сайту
            </a>
          )}
          {loc.description && (
            <span onClick={showInfo} className='balloon-controlls-btn red' style={{ gridColumnStart: 2 }}>
              Подробнее
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default PointTouristBalloon;
