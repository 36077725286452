import { TTour } from 'shared/types/tours.types';

type TMethod = (json: any) => any;

export const transformItemData: Record<string, TMethod> = {
  tours(json: TTour): TTour {
    const data = structuredClone(json);
    data.recommendations = data.recommendations ? data.recommendations : [];
    data.recommendedTours = data.recommendedTours ? data.recommendedTours : [];
    return data;
  },
};
