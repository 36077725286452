import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { selectUserData } from 'manageStore/user/userSlice';
import logo from 'assets/images/header/logo_top.svg';
import NavigationList from 'components/Header/NavigationList';
import classnames from 'classnames';
import { logout } from 'manageStore/user/userSlice';
import Portal from 'components/Portal/Portal';
import ProfileCard from 'components/Account/ProfileCard';

const AlternativeHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const userData = useSelector(selectUserData);
  const isAccount = pathname.includes('/account');
  const isAdmin = pathname.includes('/admin');

  const profileRef = useRef<HTMLLIElement | null>(null);

  const [profile, setProfile] = useState(false);

  const [openPortal, setOpenPortal] = useState(false);
  const handlePortal = () => setOpenPortal(!openPortal);

  const clickLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  const handlerClick = (e: MouseEvent) => {
    if (profileRef.current && !e.composedPath().includes(profileRef.current)) {
      setProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handlerClick);
    return () => {
      document.removeEventListener('click', handlerClick);
    };
  }, []);

  return (
    <header className={classnames('tours-header', { 'tours-header__admin': isAdmin })}>
      <Link className='tours-header__logo' to='/' style={{ boxShadow: 'none' }}>
        <img src={logo} alt='Туркластер Арктический' />
      </Link>
      <div className='tours-header__inner'>
        <NavigationList />
        {isAccount ? (
          <li
            className={classnames('Aheader__links-item', { open: profile })}
            ref={profileRef}
            onClick={() => setProfile((prev) => !prev)}
          >
            <button className='tours-header__lk'>{userData ? userData.login : 'Личный кабинет'}</button>
            <ul className='Aheader__links-tosite right'>
              <li className='Aheader__tosite-itemtitle'>Управление профилем</li>
              <li className='Aheader__tosite-item profile' onClick={handlePortal}>
                <button className='Aheader__tosite-link'>Информация о пользователе</button>
              </li>
              <li className='Aheader__tosite-item logout' onClick={clickLogout}>
                <button className='Aheader__tosite-link'>Выйти</button>
              </li>
            </ul>
          </li>
        ) : (
          <Link className='tours-header__lk' to='/account'>
            {userData && userData.login ? userData.login : 'Личный кабинет'}
          </Link>
        )}
      </div>
      <Portal
        isOpen={openPortal}
        onClose={handlePortal}
        styles={{
          padding: '15px 15px 20px',
          maxWidth: '600px',
          boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
          border: '1px solid #dedede',
        }}
      >
        <ProfileCard />
      </Portal>
    </header>
  );
};

export default AlternativeHeader;
