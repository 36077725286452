import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TProfileLink } from 'shared/types/common.types';

const ProfileLink = ({ icon, text, link }: TProfileLink) => {
  return (
    <NavLink className='profile-link' to={link}>
      <div className='profile-link__icon'>{icon ? <img src={icon} alt={text} /> : null}</div>
      <p className='profile-link__text'>{text}</p>
    </NavLink>
  );
};

ProfileLink.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

ProfileLink.defaultProps = {
  icon: null,
  text: '',
  link: '/account',
};

export default ProfileLink;
