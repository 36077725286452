import { VariantProps, cva } from "class-variance-authority";
import React, { ReactNode } from "react";
import ContainerContent from "../container/index";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { Link } from "react-router-dom";
import s from './index.module.scss';

type Props = {
  children: ReactNode;
  hrefBack?: string;
  onBack?: () => void;
} & VariantProps<typeof cvaPageHead>;

const cvaPageHead = cva([s.pageHead], {
  variants: {
    isFixed: {
      true: [
        s.isFixed,
      ],
      false: [s.isNotFixed],
    },
  },
  defaultVariants: {
    isFixed: true,
  },
});

const cvaMargin = cva([s.margin]);

export default function PageHead({
  hrefBack,
  onBack,
  children,
  isFixed = true,
}: Props) {
  return (
    <div>
      <div className={cvaPageHead({ isFixed })}>
        <ContainerContent>
          {hrefBack || onBack ? (
            <div className={s.flexWrapper}>
              {hrefBack ? (
                <Link
                  className={s.back}
                  to={hrefBack}
                >
                  <BackIcon />
                </Link>
              ) : (
                <button
                  className={s.back}
                  onClick={onBack}
                >
                  <BackIcon />
                </button>
              )}
              <h1 className="grow">{children}</h1>
            </div>
          ) : (
            <h1>{children}</h1>
          )}
        </ContainerContent>
      </div>
      {isFixed && <div className={cvaMargin()}></div>}
    </div>
  );
}
