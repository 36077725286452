import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Notification from 'shared/lib/notification';
import { TCreateRouteData } from 'shared/types/routes.types';
import RoutesService from 'services/routes.service';
import { selectOrderLocations } from 'manageStore/designer/designer.select';
import { ReactComponent as FavouritesIcon } from 'assets/images/common/favourites.svg';
import { selectIsAuth } from 'manageStore/user/userSlice';
import { Portal } from 'shared/lib/Portal/Portal';
import { AuthUser } from '../AuthUser/AuthUser';
import { TourDesignerPopup } from 'features/shared/TourDesignerPopup/TourDesignerPopup';

export const TourDesignerFavorites = () => {

  const [isShowAuth, setIsShowAuth] = useState(false);

  const handleClosePortal = () => {
    setIsShowAuth(false);
  };

  return (
    <>
      <Portal isOpen={isShowAuth} onClose={handleClosePortal}>
        <TourDesignerPopup
          title='Добавление в избранное'
          annotation=' Чтобы сохранить маршрут авторизуйтесь в вашем личном кабинете.'
          onClose={handleClosePortal}
        >
          <AuthUser handleSuccess={handleClosePortal} />
          <div className='tour-designer-application__login'>
            У вас нет личного кабинета? <Link to='/registration'>Зарегистрироваться</Link>
          </div>
        </TourDesignerPopup>
      </Portal>
    </>
  );
};
