import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import AuthService from 'services/auth.service';
import { TRootState } from 'store';
import { TRestorePassRequest, TUser, TUserLoginRequest, TUserRegistrationRequest } from 'shared/types/types';
import { setIsOpen } from 'manageStore/common/common.slice';

type TInitialState = {
  userData: TUser | null;
  isAuth: boolean;
  isLoading: boolean;
};

const initialState: TInitialState = {
  userData: null,
  isAuth: false,
  isLoading: false,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuth = payload;
    },
    setUserData: (state, { payload }: PayloadAction<TUser | null>) => {
      state.userData = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const { setAuth, setUserData, setLoading } = slice.actions;

export const login = createAsyncThunk('login', async (sendParams: TUserLoginRequest, { dispatch }) => {
  try {
    const t = await AuthService.login(sendParams);
    const { data } = t;
    localStorage.setItem('token', data.accessToken);
    dispatch(setUserData(data.user));
    dispatch(setAuth(true));
    dispatch(setIsOpen(false));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  }
});

export const logout = createAsyncThunk('logout', async (_, { dispatch }) => {
  try {
    await AuthService.logout();
    localStorage.removeItem('token');
    dispatch(setAuth(false));
    dispatch(setUserData(null));
  } catch (e) {
    localStorage.removeItem('token');
    dispatch(setAuth(false));
    dispatch(setUserData(null));
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  }
});

export const registration = createAsyncThunk(
  'registration',
  async (sendData: TUserRegistrationRequest, { dispatch }) => {
    try {
      const { data } = await Notification.promise(AuthService.registration(sendData), true);
      localStorage.setItem('token', data.accessToken);
      dispatch(setUserData(data.user));
      dispatch(setAuth(true));
    } catch (e) {
      throw e;
    }
  }
);

export const registrationOperator = async <T>(params: T) => {
  try {
    const { data } = await Notification.promise(AuthService.registrationOperator(params));
    return data;
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  }
};

export const refreshTokens = createAsyncThunk('refreshTokens', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await AuthService.refreshToken();
    // const { data } = await axios.get(`${API_URL}/user/refresh`, {
    //   withCredentials: true,
    // });
    localStorage.setItem('token', data.accessToken);
    dispatch(setUserData(data.user));
    dispatch(setAuth(true));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    dispatch(logout());
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});

export const restorePass = createAsyncThunk('restorePass', async ({ email }: TRestorePassRequest) => {
  try {
    await Notification.promise(AuthService.restorePass(email));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
  } finally {
  }
});

export const selectUserData = (state: TRootState) => state.user.userData;
export const selectIsAuth = (state: TRootState) => state.user.isAuth;

export default slice.reducer;
