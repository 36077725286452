import React from 'react'
import { Create } from 'react-admin'
import FoodForm from './FoodForm'

const FoodCreate = () => (
  <Create>
    <FoodForm />
  </Create>
)

export default FoodCreate
