import React from 'react';
import { SimpleForm, required } from 'react-admin';
import { GALLERY_FIELDS } from '../../../shared/constants/const';
import FileInputAdmin from '../FileInputAdmin';

const GaleryForm = () => (
  <SimpleForm>
    <FileInputAdmin
      source='uploadedFiles'
      label={GALLERY_FIELDS.uploadedFiles}
      multiple={true}
      validate={[required()]}
    />
  </SimpleForm>
);

export default GaleryForm;
