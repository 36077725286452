import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { YoutubeBlock } from 'shared/lib/YoutubeVideo/YoutubeBlock';

type TPartnerItemVideoProps = {
  link: string;
  isRectangleBefore?: boolean;
  isRectangleAfter?: boolean;
};

export const TravelGuideItemVideo = ({ link, isRectangleBefore, isRectangleAfter }: TPartnerItemVideoProps) => {
  return (
    <>
      {isRectangleBefore && <div className='travel-guide-item-video__before-rectangle'></div>}
      <section className='travel-guide-item-video'>
        <div className='container travel-guide-item__container'>
          <TitleSectionMain className='travel-guide-item-video__title' title='видео' isSmall />
          <YoutubeBlock link={link} />
        </div>
      </section>
      {isRectangleAfter && <div className='travel-guide-item-video__after-rectangle'></div>}
    </>
  );
};
