import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import { useMediaQuery } from '../../shared/hooks/useMatchMedia';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';
// import MobileBottom from 'components/MobileBottom/MobileBottom';

const FeedbackPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);
  const handleScroll = () => {
    const needScroll = isMobile ? 50 : 500;
    if (window.scrollY > needScroll) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://forms.yandex.ru/_static/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Блог - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isWhiteLink={isMobile && !scrolled} isFixed />
      <AuthPortal />
      <div className="container feedback_container">
        <iframe
          src="https://forms.yandex.ru/cloud/66a8dc1e3e9d0810bb0e6a64/?iframe=1"
          frameBorder="0"
          name="ya-form-66a8dc1e3e9d0810bb0e6a64"
          width="650"
          style={{ border: 0, width: '100%', height: '500px' }}
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default FeedbackPage;
