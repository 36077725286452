import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { TPartner } from 'shared/types/partner.types';
import { declensionNoun } from 'helpers/declensionNoun';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import Slider, { Settings } from 'react-slick';
import { useAutoSlide } from 'shared/hooks/useAutoSlide';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';

type TPartnerItemTopSectionProps = {
  partnerItem: TPartner;
};

const settingsSlider: Settings = {
  dots: true,
  infinite: true,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
  autoplaySpeed: 5000,
  pauseOnHover: false,
};

export const PartnerItemTopSection = ({ partnerItem }: TPartnerItemTopSectionProps) => {
  const { name, contactPhone, contactEmail, contactSite, partnerPhotos, experience, tag, moreLink } = partnerItem;
  const history = useHistory();
  const [startAutoSlide, stopAutoSlide, sliderRef] = useAutoSlide();

  const categoryLabel = TRANSFORM_TEXT.partners[tag].label;

  const formatMoreLink = moreLink ? ( /^https?:\/\//i.test(moreLink) ? moreLink : `http://${moreLink}` ) : '';


  const backHandler = () => {
    history.push('/partners');
  };

  const experienceText = experience ? `${experience} ${declensionNoun(experience, 'year')}` : '';

  return (
    <div className='partner-item__header-wrapper'>
      <section className='partner-item__header' onMouseEnter={startAutoSlide} onMouseLeave={stopAutoSlide}>
        <div className='partner-item__image'>
          <Slider {...settingsSlider} ref={sliderRef}>
            {partnerPhotos.map((photo) => {
              return (
                <img className='partner-card__img' src={`${URL_FOR_IMG}/${photo.path}`} alt={name} key={photo.path} />
              );
            })}
          </Slider>
        </div>
        <button type='button' className='partner-item__back' onClick={backHandler}>
          <ArrowButton className='partner-item__back__icon' />
          Назад
        </button>
        <div className='partner-item__type'>{categoryLabel}</div>
        <h1 className='partner-item__title'>
          <span>{name}</span>
        </h1>
      </section>
      <section className='partner-item__operator'>
        <div className='container partner-item__operator__container'>
          <div className='partner-item__operator-content'>
            <div className='partner-item__operator-name'>Контакты</div>
            <div className='partner-item__operator-phone'>{contactPhone}</div>
            <div className='partner-item__operator-email'>{contactEmail}</div>
            {!!contactSite && <a href={contactSite} target='_blank' rel="noreferrer">{contactSite}</a>}
            {!!formatMoreLink &&
              <a href={formatMoreLink} target='_blank' className='partner-item__operator-more' rel="noreferrer">
                Связаться
              </a>}
          </div>
        </div>
      </section>
      <section className='partner-item__params'>
        <div className='container partner-item__params__container'>
          <div className='partner-item__params-content'>
            {experience && (
              <div className='partner-item__params-group'>
                <div className='partner-item__params-name'>Опыт работы</div>
                <div className='partner-item__params-value'>{experienceText}</div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
