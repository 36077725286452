import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Overlay from 'ol/Overlay';
import LineString from 'ol/geom/LineString';
import homeIcon from 'assets/images/map/home-icon.svg';
import foodIcon from 'assets/images/map/food-icon.svg';
import museumIcon from 'assets/images/map/museum-icon.svg';
import cityIcon from 'assets/images/locations/city.svg';
import birdIcon from 'assets/images/locations/bird.svg';
import expeditionIcon from 'assets/images/locations/expedition.svg';
import industryIcon from 'assets/images/locations/fabric.svg';
import jeepIcon from 'assets/images/locations/jeep.svg';
import mountainIcon from 'assets/images/locations/mountain.svg';
import northernLightsIcon from 'assets/images/locations/northern_lights.svg';
import photoIcon from 'assets/images/locations/photo.svg';
import quadBikeIcon from 'assets/images/locations/quad_bike.svg';
import surfIcon from 'assets/images/locations/surf.svg';
import mapArrow from 'assets/images/map/map-arrow.png';
import { PLACE_TYPES } from 'shared/constants/const';
import { TLocation, TPointBrendMap, TPointBrendMapDesigner, TPointOsmMap } from 'shared/types/location.types';
import { StyleFunction } from 'ol/style/Style';
import { TRANSFORM_LOCATIONS } from 'shared/constants/locations.constants';
import Map from "ol/Map";

export const transformCoords = (coords: number[]) => {
  return fromLonLat([...coords].reverse());
};

const getIconPoint = (type: string) => {
  if (!type) {
    return null;
  }
  switch (type) {
    case PLACE_TYPES.lodging:
      return homeIcon;
    case PLACE_TYPES.food:
      return foodIcon;
    case PLACE_TYPES.attraction:
      return museumIcon;
    case TRANSFORM_LOCATIONS.active.city:
      return cityIcon;
    case TRANSFORM_LOCATIONS.active.birdwatching:
      return birdIcon;
    case TRANSFORM_LOCATIONS.active.expedition:
      return expeditionIcon;
    case TRANSFORM_LOCATIONS.active.industry:
      return industryIcon;
    case TRANSFORM_LOCATIONS.active.jeeps:
      return jeepIcon;
    case TRANSFORM_LOCATIONS.active.mountaineering:
      return mountainIcon;
    case TRANSFORM_LOCATIONS.active.northernLights:
      return northernLightsIcon;
    case TRANSFORM_LOCATIONS.active.photo:
      return photoIcon;
    case TRANSFORM_LOCATIONS.active.quadBikes:
      return quadBikeIcon;
    case TRANSFORM_LOCATIONS.active.sapsurfing:
      return surfIcon;
    default:
      return null;
  }
};

const getColorCluster = (inOrder = false) => {
  return new Style({
    image: new CircleStyle({
      radius: 18.5,
      stroke: new Stroke({
        color: '#ffffff',
        width: 3,
      }),
      fill: new Fill({
        color: inOrder ? '#DB2947' : '#338FD2',
      }),
    }),
  });
};

const getIconCluster = (locationType: string) => {
  const styles = [];
  const iconPoint = getIconPoint(locationType);
  if (iconPoint) {
    const iconStyles = new Style({
      image: new Icon({
        src: iconPoint,
      }),
    });
    styles.push(iconStyles);
  }
  return styles;
};

const groupPoint = (size: number) => {
  return [
    new Style({
      text: new Text({
        text: size < 100 ? size.toString() : '99+',
        font: '13px Arial, sans-serif',
        offsetY: 1,
        fill: new Fill({
          color: '#ffffff',
        }),
      }),
    }),
  ];
};

const clusterStyle = () => {
  const stylesCash: Record<string, any> = {};
  return (feature: Feature) => {
    const clusterMembers = feature.get('features');
    const featuresLength = clusterMembers.length;
    if (featuresLength === 1) {
      const infoLoc = clusterMembers[0].get('infoLoc');
      const key = infoLoc?.locationType ? infoLoc?.locationType[0] : infoLoc?.items.length;
      const colorCluster = getColorCluster(!!infoLoc?.inOrder);
      if (!key) {
        return [colorCluster];
      }
      if (!stylesCash[key]) {
        const iconCluster = typeof key === 'string' ? getIconCluster(key) : groupPoint(key);
        stylesCash[key] = iconCluster;
      }
      return [colorCluster, ...stylesCash[key]];
    } else {
      if (!stylesCash[featuresLength]) {
        const style = groupPoint(featuresLength);
        stylesCash[featuresLength] = style;
      }
      const colorCluster = getColorCluster();
      return [colorCluster, ...stylesCash[featuresLength]];
    }
  };
};

export const generateClusters = (locations: Array<TPointOsmMap | TPointBrendMapDesigner>) => {
  const features = locations.map((loc) => {
    const { infoLoc } = loc;
    let coords: number[] = [];
    if ('map2DLat' in loc && loc.map2DLat && loc.map2DLng) {
      coords = [loc.map2DLng, loc.map2DLat];
    } else if ('lng' in loc) {
      coords = [loc.lng, loc.lat];
    }
    return new Feature({
      geometry: new Point(fromLonLat(coords)),
      infoLoc,
    });
  });

  const clusterSource = new Cluster({
    distance: parseInt('25', 10),
    minDistance: parseInt('25', 10),
    source: new VectorSource({ features }),
  });

  return new VectorLayer({
    source: clusterSource,
    style: clusterStyle() as StyleFunction,
    zIndex: 10,
  });
};

export const generatePath = (routes: Array<TPointBrendMapDesigner | TLocation>, zoom: number = 0) => {
  const points = routes.reduce((acc, loc) => {
    const { map2DLat, map2DLng } = loc;
    let coords: number[] = [];
    if ('city' in loc && loc.city) {
      coords = [loc.city.map2DLng, loc.city.map2DLat];
    } else if (map2DLat && map2DLng) {
      coords = [map2DLng, map2DLat];
    }
    const transformCoords = fromLonLat(coords);
    const addedLoc = acc.find((accLoc) => {
      return accLoc[0] === transformCoords[0] && accLoc[1] === transformCoords[1];
    });
    return addedLoc ? acc : [...acc, transformCoords];
  }, [] as Array<number[]>);
  const lineFeature = new Feature(new LineString(points));
  const pathSource = new VectorSource({
    features: [lineFeature],
  });

  const stylePath = (feature: any, resolution: number) => {
    const geometry = feature.getGeometry();
    const styles = [
      new Style({
        stroke: new Stroke({
          color: '#f28888',
          width: 4,
          lineDash: [20, 25],
          lineCap: 'square',
          lineJoin: 'miter',
        }),
      }),
    ];

    geometry.forEachSegment((start: number[], end: number[]) => {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      if (Math.abs(dx) / resolution > 50 || Math.abs(dy) / resolution > 50) {
        const rotation = Math.atan2(dy, dx);
        styles.push(
          new Style({
            geometry: new Point(end),
            image: new Icon({
              src: mapArrow,
              anchor: [1.35, 0.65],
              rotateWithView: true,
              rotation: -rotation,
              scale: zoom / 30,
            }),
          })
        );
      }
    });
    return styles;
  };

  const newPath = new VectorLayer({
    source: pathSource,
    style: stylePath,
    zIndex: 0,
  });
  return newPath;
};

export const generateOverlay = (element: any) => {
  const overlayPopup = new Overlay({
    element,
    autoPan: {
      animation: {
        duration: 250,
      },
    },
    positioning: 'bottom-center',
    offset: [0, -75],
  });
  return overlayPopup;
};

const clusterStyleTourItem = (locations: Array<TPointBrendMap>) => {
  const stylesCash: Record<string, any> = {};
  return (feature: Feature) => {
    const clusterMembers = feature.get('features');
    const infoLoc = clusterMembers[0].get('infoLoc');
    const indexRoute = locations.findIndex((loc) => loc.infoLoc.name === infoLoc.name);
    if (!stylesCash[indexRoute]) {
      const outerCircleFill = new Fill({
        color: 'rgba(162, 22, 45, 0.8)',
      });
      const innerCircleFill = new Fill({
        color: '#DB2947',
      });
      const innerCircle = new CircleStyle({
        radius: 20,
        fill: innerCircleFill,
      });
      const outerCircle = new CircleStyle({
        radius: 24,
        fill: outerCircleFill,
      });

      const style = [
        new Style({
          image: outerCircle,
        }),
        new Style({
          image: innerCircle,
          text: new Text({
            text: (indexRoute + 1).toString(),
            font: '500 22px AkzidenzGroteskPro, sans-serif',
            fill: new Fill({
              color: '#ffffff',
            }),
          }),
        }),
      ];

      stylesCash[indexRoute] = style;
    }
    return stylesCash[indexRoute];
  };
};

export const generatePathTourItem = (routes: Array<TPointBrendMap>) => {
  const points = routes.map((loc) => {
    const { map2DLat, map2DLng } = loc;
    return fromLonLat([map2DLng, map2DLat]);
  });

  const lineFeature = new Feature(new LineString(points));
  const pathSource = new VectorSource({
    features: [lineFeature],
  });

  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(219, 41, 71, 0.4)',
      width: 5,
    }),
  });

  const newPath = new VectorLayer({
    source: pathSource,
    style,
    zIndex: 0,
  });
  return newPath;
};

export const generateClustersTourItem = (locations: Array<TPointBrendMap>) => {
  const features = locations.map((loc) => {
    const { infoLoc } = loc;
    let coords: number[] = [loc.map2DLng, loc.map2DLat];
    return new Feature({
      geometry: new Point(fromLonLat(coords)),
      infoLoc,
    });
  });

  const clusterSource = new Cluster({
    source: new VectorSource({ features }),
  });

  const newClusters = new VectorLayer({
    source: clusterSource,
    style: clusterStyleTourItem(locations) as StyleFunction,
    zIndex: 10,
  });

  return newClusters;
};

export const generateOverlayTourItem = (element: any) => {
  const overlayPopup = new Overlay({
    element,
    autoPan: {
      animation: {
        duration: 250,
      },
    },
    positioning: 'bottom-left',
    offset: [-35, -35],
  });
  return overlayPopup;
};



export const refreshMap = (linkMap:Map | null) => {
  if(!linkMap) return;
  linkMap.updateSize();
  setTimeout(()=>{
    linkMap.updateSize();
  }, 500)
}
