import { cva } from 'class-variance-authority';
import React from 'react';
import Button from '../../../shared/ui/button';
import Rating from '../../../entities/rating/ui';
import Separator from '../../sidebar/separator';
import Tag from '../../../entities/tag';
import { convertDate } from '../../../shared/utils/convert-date';
import { useMedia } from 'react-use';
import s from './index.module.scss';
import { TReviewFetchResponse } from '../../../../shared/types/review.types';

type Props = {
  review: TReviewFetchResponse;
};

export const cvaReview = cva([s.review]);
const cvaTop = cva([s.top]);
export const cvaTitle = cva([s.title]);
const cvaTime = cva([s.time]);
export const cvaSeparator = cva([s.separator]);
export const cvaSubtitle = cva([s.subtitle]);

export default function ReviewItem({ review }: Props) {
  const isDekstop = useMedia('(min-width: 1023px)');
  return (
    <div className={cvaReview()}>
      <div className={cvaTop()}>
        <div>
          <h3 className={cvaTitle()}>{review.tour.title}</h3>
          <Rating rating={review.rating} />
        </div>
        <Button href='/' size={'s'} text={'base'} className={s.btn} intent={'outlined'}>
          Вернуться на главную
        </Button>
      </div>
      {isDekstop && <Separator className={cvaSeparator()} />}
      {review.mostliked && review.mostliked.length > 0 && (
        <div className={s.group}>
          <div className={cvaSubtitle()}>Больше всего понравилось:</div>
          <div className={s.tags}>
            {review.mostliked.map((e, i) => (
              <Tag key={i}>{e}</Tag>
            ))}
          </div>
        </div>
      )}
      <div className={s.group}>
        <div className={cvaSubtitle()}>Больше всего понравилось:</div>
        <div>{review.advantages}</div>
      </div>
      <div className={s.group}>
        <div className={cvaSubtitle()}>Недостатки</div>
        <div>{review.disadvantages}</div>
      </div>
      {review.comment && (
        <div className={s.group}>
          <div className={cvaSubtitle()}>Комментарий:</div>
          <div>{review.comment}</div>
        </div>
      )}
      <div className={cvaTime()}>
        {convertDate(review.createdDate, {
          withComma: true,
          withTime: true,
          withSeconds: true,
        })}
      </div>
    </div>
  );
}
