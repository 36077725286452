import { useEffect, useRef, useState } from 'react';
import { selectTouristInfo } from 'manageStore/tourist/tourist.select';
import { ReactComponent as HeadIcon } from 'assets/images/subtitle.svg';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { touristActions } from 'manageStore/tourist/tourist.slice';
import parse from 'html-react-parser';

type TViewInfo = { name: string; description: string | JSX.Element | JSX.Element[] };

export const TouristInfo = () => {
  const [viewInfo, setViewInfo] = useState<TViewInfo | null>(null);
  const touristInfo = selectTouristInfo();
  const dispatch = useAppDispatch();
  const { setTouristInfo } = touristActions;
  const infoRef = useRef<HTMLDivElement | null>(null);

  const closeInfo = () => {
    dispatch(setTouristInfo(null));
  };

  useEffect(() => {
    if (touristInfo) {
      try {
        const description = parse(touristInfo.description);
        setViewInfo({ ...touristInfo, description });
      } catch {
        setViewInfo(null);
      }
    } else {
      setViewInfo(null);
    }
  }, [touristInfo]);

  useEffect(() => {
    if (!touristInfo || !infoRef.current) return;
    infoRef.current.scrollIntoView();
  }, [viewInfo]);

  useEffect(() => {
    return () => closeInfo();
  }, []);

  if (!viewInfo) {
    return null;
  }

  return (
    <div className='container container__tourist-info' ref={infoRef}>
      <article className='tourist-info'>
        <div className='tourist-info__head'>
          <HeadIcon className='tourist-info__icon' />
          <h2 className='tourist-info__title'>{viewInfo.name}</h2>
          <button className='tourist-info__close' onClick={closeInfo}></button>
        </div>
        <div className='tourist-info__content'>{viewInfo.description}</div>
      </article>
    </div>
  );
};
