import {
    Labeled, required,
    SimpleForm, TextInput
} from 'react-admin';
import {PAGE_SETTINGS_FIELDS} from "../../../shared/constants/const";
export const PageSettingsForm = () => {
    return (
        <SimpleForm>
            <Labeled label={PAGE_SETTINGS_FIELDS.key} fullWidth isRequired>
                <TextInput source='key' validate={[required()]} label='' />
            </Labeled>
            <Labeled label={PAGE_SETTINGS_FIELDS.value} fullWidth isRequired>
                <TextInput source='value' validate={[required()]} label='' />
            </Labeled>
            <Labeled label={PAGE_SETTINGS_FIELDS.icon} fullWidth>
                <TextInput source='icon' label='' />
            </Labeled>
        </SimpleForm>
    )
};