import { SimpleForm, ReferenceInput, TextInput, required, Labeled } from 'react-admin';
import FileInputAdmin from '../FileInputAdmin';
import SelectCity from '../OsmMapForm/SelectCity';
import TinyMCE from '../TinyMCE';
import { ATTRACTION_FIELDS } from '../../../shared/constants/const';

const AttractionForm = () => {
  return (
    <SimpleForm>
      <Labeled label={ATTRACTION_FIELDS.title} fullWidth>
        <TextInput source='name' validate={[required()]} label='' />
      </Labeled>
      <ReferenceInput source='city' reference='city' perPage={Infinity} fullWidth>
        <SelectCity />
      </ReferenceInput>
      <Labeled label={ATTRACTION_FIELDS.description} fullWidth>
        <TinyMCE property='description' required />
      </Labeled>
      <FileInputAdmin source='preview' label={ATTRACTION_FIELDS.preview} multiple={false} />
      <Labeled label={ATTRACTION_FIELDS.time} fullWidth>
        <TextInput source='time' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={ATTRACTION_FIELDS.contacts} fullWidth>
        <TextInput source='contacts' validate={[required()]} label='' />
      </Labeled>
    </SimpleForm>
  );
};

export default AttractionForm;
