import * as React from 'react';
import parse from 'html-react-parser';
import { Show, SimpleShowLayout, TextField, ChipField, FunctionField } from 'react-admin';
import { NEWS_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const NewsShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='title' label={NEWS_FIELDS.title} />
        <TextField source='desc' label={NEWS_FIELDS.shortDesc} />
        <FunctionField
          label={NEWS_FIELDS.content}
          render={(record) => {
            return <div>{parse(record?.content || '')}</div>;
          }}
        />
        <FunctionField
          label={NEWS_FIELDS.preview}
          render={(record) => {
            return record.preview ? (
              <img
                className='admin-image admin-image__single'
                src={`${URL_FOR_IMG}/${record.preview.path}`}
                alt='preview'
              />
            ) : null;
          }}
        />
        <FunctionField
          label={NEWS_FIELDS.important}
          render={(record) => (
            <ChipField
              source='important'
              record={{
                important: record.important ? 'Да' : 'Нет',
              }}
            />
          )}
        />
        <FunctionField
          label={NEWS_FIELDS.isPublished}
          render={(record) => (
            <ChipField
              source='isPublished'
              record={{
                isPublished: record.isPublished ? 'Да' : 'Нет',
              }}
            />
          )}
        />
        <TextField source='date' label={NEWS_FIELDS.date} />
        <TextField source='author' label={NEWS_FIELDS.author} />
        <TextField source='views' label={NEWS_FIELDS.views} />
        <TextField source='metaDesc' label={NEWS_FIELDS.metaDesc} />
        <TextField source='metaKeywords' label={NEWS_FIELDS.metaKeywords} />
      </SimpleShowLayout>
    </Show>
  </div>
);

export default NewsShow;
