import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { setFilters } from 'manageStore/partners/partners.slice';
import { selectFiltersPartners, selectLoading, selectFilteredPartners } from 'manageStore/partners/partners.select';
import Loading from 'components/Loading/Loading';
import { PartnerCard } from 'entities/partner/PartnerCard/PartnerCard';
import { TCategoryPartner, TNamesTypePartnersFilter } from 'shared/types/partner.types';
import { PartnersFilters } from 'features';

export const PartnersMain = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const filters = useSelector(selectFiltersPartners);
  const partners = useAppSelector((state) => selectFilteredPartners(state, filters));

  const changeFiltersPartners = (type: TCategoryPartner, fieldFilters: TNamesTypePartnersFilter) => {
    if (!type) {
      dispatch(setFilters({ ...filters, types: [] }));
      return;
    }
    const fieldValue = [...filters[fieldFilters]];
    if (filters[fieldFilters].includes(type)) {
      const typeIndex = fieldValue.findIndex((value) => value === type);
      fieldValue.splice(typeIndex, 1);
    } else {
      fieldValue.push(type);
    }
    dispatch(setFilters({ ...filters, [fieldFilters]: fieldValue }));
  };

  return (
    <main className='partners'>
      <div className='container partners__container'>
        <h1 className='partners__title'>Организаторы путешествий</h1>
        <PartnersFilters filters={filters} changeFiltersPartners={changeFiltersPartners} />
        {loading ? (
          <Loading />
        ) : partners?.length ? (
          <div className='partners__list-wrapper'>
            <div className='partners-list'>
              {partners.map((partner) => (
                <PartnerCard key={partner.id} partner={partner} />
              ))}
            </div>
          </div>
        ) : (
          <div className='no-results-text'>По выбранным параметрам организаторы пока отсутствуют.</div>
        )}
      </div>
    </main>
  );
};
