import axios from 'axios';
import { API_BASE_URL } from 'shared/constants/const';

export const API_URL = API_BASE_URL + '/api';

export const api = () => {
  const axiosConfig = {
    baseURL: API_URL,
    withCredentials: true,
  };
  return axios.create(axiosConfig);
};

export default api;
