import { URL_FOR_IMG } from 'shared/constants/const';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { TSingleFile } from 'shared/types/common.types';
import { TextSplitParagraph } from 'widgets/shared/TextSplitParagraph/TextSplitParagraph';

type TTravelGuideItemDigitsProps = {
  description?: string;
  title?: string;
  photo?: TSingleFile;
};

export const TravelGuideItemDigits = ({ description, title, photo }: TTravelGuideItemDigitsProps) => {
  return (
    <section className='travel-guide-item-digits'>
      <div className='container travel-guide-item__container'>
        <TitleSectionMain title='Цифры и факты' className='travel-guide-item-digits__title' />
        <div className='travel-guide-item-digits__content'>
          <div className='travel-guide-item-digits__image'>
            {photo && <img src={`${URL_FOR_IMG}/${photo?.path}`} alt='' />}
          </div>
          <div className='travel-guide-item-digits__description'>
            {!!description && <TextSplitParagraph text={description} />}
          </div>
        </div>
      </div>
    </section>
  );
};
