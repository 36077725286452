import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Notification from 'shared/lib/notification';
import AuthService from 'services/auth.service';
import { setAuth, setUserData } from 'manageStore/user/userSlice';
import { BaseInput } from 'shared/ui/BaseInput/BaseInput';

type TTourApplicationFormProps = {
  handleSuccess: () => void;
};

export const AuthUser = ({ handleSuccess }: TTourApplicationFormProps) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      password: Yup.string().required('Заполните поле'),
    }),
    onSubmit: async (values) => {
      const { data } = await Notification.promise(AuthService.login(values));
      localStorage.setItem('token', data.accessToken);
      dispatch(setUserData(data?.user));
      dispatch(setAuth(true));
      handleSuccess();
    },
    enableReinitialize: true,
  });

  return (
    <form className='auth-user-form' onSubmit={formik.handleSubmit}>
      <BaseInput
        showError={!!(formik.touched.email && formik.errors.email)}
        errorText={formik.errors.email}
        placeholder='E-mail'
        id='email'
        type='email'
        {...formik.getFieldProps('email')}
      />
      <BaseInput
        showError={!!(formik.touched.password && formik.errors.password)}
        errorText={formik.errors.password}
        placeholder='Пароль'
        id='password'
        type='password'
        {...formik.getFieldProps('password')}
      />
      <Link className='auth-user-form__restore-pass' to='/restore-pass'>
        Забыли пароль?
      </Link>
      <button className='auth-user-form__submit' type='submit'>
        Войти
      </button>
    </form>
  );
};
