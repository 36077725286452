import {
  TCategoryTravelGuide,
  TFiltersTravelsGuide,
  TNamesTypeTravelsGuideFilter,
} from 'shared/types/travelGuide.types';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { TravelsGuideTypesSelect } from './TravelsGuideTypesSelect';
import { TRANSFORM_TRAVEL_GUIDE } from 'shared/constants/travelGuide.constants';
import { TravelGuideButtonsType } from './TravelGuideButtonsType';

type TTravelsGuideFiltersByType = {
  filters: TFiltersTravelsGuide;
  clickFilter: (filter: TCategoryTravelGuide | null, fieldFilters: TNamesTypeTravelsGuideFilter) => void;
};

type TItemFilters = {
  id: string;
  label: string;
  value: TCategoryTravelGuide | '';
  fieldFilters: string;
};

const itemsTypeTravelsGuide: TItemFilters[] = [
  {
    id: 'all',
    label: 'Все',
    value: '',
    fieldFilters: 'type',
  },
  {
    id: TRANSFORM_TRAVEL_GUIDE.tags.city.value,
    label: 'Городские локации',
    value: TRANSFORM_TRAVEL_GUIDE.tags.city.value as TCategoryTravelGuide,
    fieldFilters: 'type',
  },
  {
    id: TRANSFORM_TRAVEL_GUIDE.tags.flora.value,
    label: 'Природные локации',
    value: TRANSFORM_TRAVEL_GUIDE.tags.flora.value as TCategoryTravelGuide,
    fieldFilters: 'type',
  },
];

export const TravelsGuideFiltersByType = ({ filters, clickFilter }: TTravelsGuideFiltersByType) => {
  const [isMobile] = useMediaQuery(['(max-width: 1123px)']);

  return isMobile ? (
    <TravelsGuideTypesSelect filters={filters} clickFilter={clickFilter} itemsFilters={itemsTypeTravelsGuide} />
  ) : (
    <TravelGuideButtonsType filters={filters} clickFilter={clickFilter} itemsFilters={itemsTypeTravelsGuide} />
  );
};
