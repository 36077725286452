import React, { useState } from 'react';
import ApplicationPreview from '../../../features/application-preview/ui';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import s from './index.module.scss';
import { TRequestFetch, TStatusRequest } from 'shared/types/request.types';
import { useAppSelector } from 'shared/hooks/useAppSelector';


const cvaWrapper = cva([s.wrapper]);
const cvaColumn = cva([s.column]);
const cvaHead = cva([s.head], {
  variants: {
    requestType: {
      NEW: [s.available],
      PROCESSING: [s.now],
      DONE: [s.end],
      DENIED: [s.unaccepted],
      DELETED: [s.unaccepted],
      CANCELED: [s.unaccepted],
    },
  },
});

export type TUiStatusRequest = TStatusRequest | 'CANCELED';
interface IRequestTypes {
  name: string;
  status: TUiStatusRequest;
  requests: TRequestFetch[];
}

type ApplicationListProps = {
  requests: IRequestTypes[];
};

export default function ApplicationList(props: ApplicationListProps) {
  const { requests } = props;
  return (
    <div className={clsx(cvaWrapper(), 'scrollbar')}>
      {requests.map((type, i) => (
        <Column key={type.name} requestType={type}/>
      ))}
    </div>
  );
}

type ColumnProps = {
  requestType: IRequestTypes
}

const Column = (props: ColumnProps) => {
  const { requestType } = props
  const userData = useAppSelector((state) => state.user.userData);
  const [lengthReq, setLengthReq] = useState(requestType.requests.length)
  return (
    <div className={cvaColumn()}>
      <div
        className={cvaHead({
          requestType: requestType.status,
        })}
      >
        <span>{requestType.name}</span>
        <span>{lengthReq}</span>
      </div>
      {requestType.requests.map((application) => (
        <ApplicationPreview onDelete={() => setLengthReq(lengthReq - 1)} userData={userData} application={application} key={application.id} />
      ))}
    </div>
  )
}