import { TRANSFORM_TRAVEL_GUIDE, TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import FileInputAdmin from '../FileInputAdmin';
import { Labeled, SelectArrayInput, SelectInput, TextInput, required } from 'react-admin';
import Box from '@mui/material/Box';
import { GuideFacts } from './GuideFacts';
import { GuideAdvantageLocation } from './GuideAdvantageLocation';
import { GuidePlaceMap } from './GuidePlaceMap';
import { GuideSimilarPlaces } from './GuideSimilarPlaces';
import { GuideHistory } from './GuideHistory';
import { GuideDigits } from './GuideDigits';
import { GuideTours } from './GuideTours';
import { GuideFaces } from './GuideFaces';
import { GuideAttributes } from './GuideAttributes';

export const choicesTerritory = [
  {
    label: TRANSFORM_TRAVEL_GUIDE.territory.norilsk.label,
    value: TRANSFORM_TRAVEL_GUIDE.territory.norilsk.value,
    valueEnum:TRANSFORM_TRAVEL_GUIDE.territory.norilsk.valEnum
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.territory.dudinka.label,
    value: TRANSFORM_TRAVEL_GUIDE.territory.dudinka.value,
    valueEnum:TRANSFORM_TRAVEL_GUIDE.territory.dudinka.valEnum
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.territory.dikson.label,
    value: TRANSFORM_TRAVEL_GUIDE.territory.dikson.value,
    valueEnum:TRANSFORM_TRAVEL_GUIDE.territory.dikson.valEnum
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.territory.hatanga.label,
    value: TRANSFORM_TRAVEL_GUIDE.territory.hatanga.value,
    valueEnum:TRANSFORM_TRAVEL_GUIDE.territory.hatanga.valEnum
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.territory.villages.label,
    value: TRANSFORM_TRAVEL_GUIDE.territory.villages.value,
    valueEnum:TRANSFORM_TRAVEL_GUIDE.territory.villages.valEnum
  },
];






 const choicesSort = [
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.attractions.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.attractions.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.gastronomy.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.gastronomy.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.industry.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.industry.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.ethnicity.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.ethnicity.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.history.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.history.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.entertainment.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.entertainment.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.mountains.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.mountains.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.water.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.water.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.theme.touristArea.label,
    value: TRANSFORM_TRAVEL_GUIDE.theme.touristArea.value,
  },
];

export const locationList =  choicesSort


const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

const defaultAttributes = [
  { name: '', value: '' },
  { name: '', value: '' },
  { name: '', value: '' },
];

export const GuideCityForm = () => {
  return (
    <>
      <FileInputAdmin source='photo' label={TRAVEL_GUIDE_FIELDS.preview} multiple={false} validate={[required()]} />
      <Labeled label={TRAVEL_GUIDE_FIELDS.title} fullWidth isRequired>
        <TextInput source='name' validate={[required()]} label='' format={(v) => (v ? v : '')} />
      </Labeled>
      <Labeled label={TRAVEL_GUIDE_FIELDS.description} fullWidth isRequired>
        <TextInput
          source='description'
          validate={[required()]}
          label=''
          multiline={true}
          minRows='3'
          maxRows='3'
          format={(v) => (v ? v : '')}
        />
      </Labeled>
      <Labeled label={TRAVEL_GUIDE_FIELDS.territory} fullWidth isRequired>
        <SelectInput
          source='territoryTag'
          label='Select...'
          optionText='label'
          optionValue='value'
          choices={choicesTerritory}
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : '')}
        />
      </Labeled>
      <Labeled label={TRAVEL_GUIDE_FIELDS.sort} fullWidth isRequired>
        <SelectArrayInput
          source='locationTags'
          label='Select...'
          optionText='label'
          optionValue='value'
          choices={choicesSort}
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : [])}
          parse={(v) => (v ? v.slice(0, 3) : [])}
        />
      </Labeled>
      <GuideAttributes defaultValues={defaultAttributes} type={TRANSFORM_TRAVEL_GUIDE.tags.city.value} />
      <Labeled label={TRAVEL_GUIDE_FIELDS.route} fullWidth isRequired>
        <Box sx={{ ...styleInBlock, paddingTop: '1em' }}>
          <TextInput source='address' validate={[required()]} label='Адрес' fullWidth format={(v) => (v ? v : '')} />
          <TextInput
            source='howToReach'
            validate={[required()]}
            label='Дополнительная иформация'
            fullWidth
            format={(v) => (v ? v : '')}
          />
        </Box>
      </Labeled>
      <GuideHistory />
      <GuideFacts />
      <GuideAdvantageLocation />
      <GuidePlaceMap />
      <GuideSimilarPlaces />
      <GuideDigits />
      <GuideTours />
      <GuideFaces />
      <Labeled label={TRAVEL_GUIDE_FIELDS.video} fullWidth>
        <TextInput source='video' label='' format={(v) => (v ? v : '')} />
      </Labeled>
    </>
  );
};
