import { PropsWithChildren, useEffect } from 'react';
import Map from 'ol/Map';

type TOsmMap = {
  map: Map | null;
};

export const OsmMap = ({ children, map }: PropsWithChildren<TOsmMap>) => {
  useEffect(() => {
    if (!map) return;
    setTimeout(() => {
      map.updateSize();
    }, 1000);
  }, [map]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id={'osm-map'} style={{ width: '100%', height: '100%' }}></div>
      {children}
    </div>
  );
};
