import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpen, setIsOpen } from 'manageStore/common/common.slice';
import Portal from 'components/Portal/Portal';
import LoginForm from 'features/lib/AuthUser/LoginForm';

export const AuthPortal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);

  const handelPortal = useCallback(() => {
    dispatch(setIsOpen(false));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setIsOpen(false));
    };
  }, []);

  return (
    <Portal
      isOpen={isOpen}
      onClose={handelPortal}
      styles={{
        padding: '20px',
        maxWidth: '500px',
        border: '1px solid #dedede',
      }}
      title='Авторизация'
    >
      <LoginForm isAdmin={false} />
    </Portal>
  );
};
