import React from 'react'
import { Create } from 'react-admin'
import BeautyForm from './BeautyForm'

const BeautyCreate = () => (
  <Create>
    <BeautyForm />
  </Create>
)

export default BeautyCreate
