import arrowReviews from 'assets/images/account/arrow-reviews.svg';
import openBox from 'assets/images/account/open-box.svg';
import checkedList from 'assets/images/account/checked-list.svg';
import circleArrow from 'assets/images/account/circle-arrow.svg';
import denied from 'assets/images/account/denied.svg';

import arrowReviewsWhite from 'assets/images/account/arrow-reviews-white.svg';
import checkedListWhite from 'assets/images/account/checked-list-white.svg';
import circleArrowWhite from 'assets/images/account/circle-arrow-white.svg';
import deniedWhite from 'assets/images/account/deniedWhite.svg';
import toursWhite from 'assets/images/account/toursWhite.svg';

export const touristNavs = [
  [
    { link: '/account/tourist/new-requests', text: 'Новые заявки', icon: circleArrow },
    { link: '/account/tourist/processing-requests', text: 'Текущие заявки', icon: circleArrow },
    { link: '/account/tourist/done-requests', text: 'Завершенные заявки', icon: checkedList },
    { link: '/account/tourist/denied-requests', text: 'Непринятые заявки', icon: denied },
  ],
  [
    { link: '/account/tourist/my-reviews', text: 'Мои отзывы на туры', icon: arrowReviews },
    { link: '/tours', text: 'Подобрать тур', icon: openBox },
  ],
];

export const mobileTouristNavs = [
  { link: '/account/tourist/new-requests', text: 'Новое', icon: circleArrowWhite },
  { link: '/account/tourist/processing-requests', text: 'В работе', icon: circleArrowWhite },
  { link: '/account/tourist/done-requests', text: 'Закрыто', icon: checkedListWhite },
  { link: '/account/tourist/denied-requests', text: 'Отказано', icon: deniedWhite },
  { link: '/account/tourist/my-reviews', text: 'Отзывы', icon: arrowReviewsWhite },
  { link: '/tours', text: 'Подобрать', icon: toursWhite },
];
