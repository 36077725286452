import Map from 'ol/Map';
import { useEffect, useMemo } from 'react';
import { defaults } from 'ol/interaction/defaults';




export const useMapActiveState = (map: Map | null, isMapActive: boolean = false) => {
  const defaultsInteractions = useMemo(()=>defaults().getArray(), []);
  
  useEffect(
    ()=>{
      if (!map) return;
      const interactions = map.getInteractions();
      isMapActive ? interactions.extend(defaultsInteractions) : interactions.clear();
    },
    [map, isMapActive]
  );
};