import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { ReactComponent as SelectedIcon } from 'assets/images/common/select/selected.svg';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { useOutsideClick } from 'shared/hooks/useOutsideClick';

type TItem<TValue, TNames> = {
  label: string;
  value: TValue;
  fieldFilters?: TNames;
};

type THomeSelectProps<TValue, TNames> = {
  title: string;
  items: TItem<TValue, TNames>[];
  handlerSelect: (value: TValue, fieldFilters: TNames) => void;
  isMulti?: boolean;
  placeholder?: string;
  lightBackground?: boolean;
  filters?: TValue[];
  isOpenSelect?: boolean;
  bottom?: JSX.Element;
  toggleSelect?: () => void;
};

export const BaseSelect = <TValue, TNames>({
  title,
  items,
  handlerSelect,
  placeholder,
  isMulti,
  lightBackground,
  filters,
  isOpenSelect,
  bottom,
  toggleSelect,
}: THomeSelectProps<TValue, TNames>) => {
  const dropdownListRef = useRef<HTMLLIElement>(null);
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState<string[]>([]);

  const isOnScreen = useOnScreen(dropdownListRef);

  const setOpenHandler = () => {
    toggleSelect ? toggleSelect() : setOpen(!open);
  };

  const selectRef = useOutsideClick(setOpenHandler, open);

  const checkActiveSelect = ({ value, label }: TItem<TValue, TNames>) => {
    const isAddedLabel = selection.includes(label);
    return value ? isAddedLabel : !selection.length || isAddedLabel;
  };

  const changeSingleSelect = (label: string) => {
    setSelection([label]);
    setOpenHandler();
  };

  const changeMultiSelect = ({ value, label }: Omit<TItem<TValue, TNames>, 'fieldFilters'>) => {
    if (!value) {
      setSelection([label]);
    } else {
      setSelection((prev) => {
        const addedIndex = selection.findIndex((item) => item === label);
        addedIndex === -1 ? prev.push(label) : prev.splice(addedIndex, 1);
        return prev;
      });
    }
  };

  const changeSelectHandler = ({ label, value, fieldFilters }: TItem<TValue, TNames>) => {
    isMulti ? changeMultiSelect({ label, value }) : changeSingleSelect(label);
    handlerSelect(value, fieldFilters || ('types' as TNames));
  };

  useEffect(() => {
    setOpen(!!isOpenSelect);
  }, [isOpenSelect]);

  useEffect(() => {
    if (filters) {
      const selected = items.reduce((acc, item) => {
        return filters.includes(item.value) ? [...acc, item.label] : acc;
      }, [] as string[]);
      setSelection(selected);
    }
  }, [filters]);

  return (
    <div
      className={classNames('base-select', {
        open,
        selected: !!selection.length,
        'light-background': lightBackground,
        'base-select_multi': isMulti,
      })}
      ref={selectRef}
    >
      <div className='base-select__inner' onClick={setOpenHandler}>
        <div className='base-select__title'>{placeholder ? placeholder : title}</div>
        <button className='base-select__value'>
          {selection.length ? selection[0] : null}
          {selection.length > 1 && <span>{` + ${selection.length - 1}`}</span>}
        </button>
      </div>
      <div className='base-select__dropdown'>
        <div className={classNames('base-select__dropdown-header', { fixed: !isOnScreen })}>
          <span>{title}</span>
          <CloseIcon className='close-icon' onClick={setOpenHandler} />
        </div>
        <ul className='base-select__dropdown__list'>
          <li ref={dropdownListRef}></li>
          {items.map((option) => {
            return (
              <li
                className={classNames('base-select__dropdown-item')}
                key={option.label}
                onClick={() => changeSelectHandler(option)}
              >
                {option.label}
                <div className={classNames('selected-icon', { visible: checkActiveSelect(option) })}>
                  <SelectedIcon />
                </div>
              </li>
            );
          })}
        </ul>
        {bottom}
      </div>
    </div>
  );
};
