import { useEffect, useState } from 'react';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import { Cluster } from 'ol/source';
import { TClusterStyle, generateClusters } from '../helpers/generateClusters';
import { TClusterData } from 'shared/types/location.types';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import {refreshMap} from "../../../../helpers/map.helper";


export const useAddPoints = (
  map: Map | null,
  clusterData: TClusterData[] | null,
  clusterStyle: TClusterStyle,
) => {
  const [clusters, setClusters] = useState<VectorLayer<Cluster> | null>(null);
  const [features, setFeatures] = useState<Feature<Geometry>[] | undefined>();

  const [clustersNature, setClustersN] = useState<VectorLayer<Cluster> | null>(null);
  const [featuresNature, setFeaturesN] = useState<Feature<Geometry>[] | undefined>();


  useEffect(() => {
    console.log('asdadasd')
    if (!map || !clusterData) return;
    // const cityClusterData = clusterData.filter(item=>item?.infoLoc?.city);
    // const natureClusterData = clusterData.filter(item=>!item?.infoLoc?.city);

    const [newClusters, newFeatures] = generateClusters(clusterData, clusterStyle);
    // const [newClustersNature, newFeaturesNature] = generateClusters(natureClusterData, clusterStyle);
    if (clusters) {
      map.removeLayer(clusters);
    }
    setClusters(newClusters);
    // setClustersN(newClustersNature);
    setFeatures(newFeatures)
    // setFeaturesN(newFeaturesNature)

    map.getLayers().insertAt(1, newClusters);
    // map.getLayers().insertAt(2, newClustersNature);
    refreshMap(map);
  }, [clusterData, map, clusterStyle]);

  return [clusters, features] as const;
};
