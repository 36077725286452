import api from 'http/index';
import { store } from 'store';
import { logout, refreshTokens } from 'manageStore/user/userSlice';
import { TReviewSendParams, TReviewFetchResponse, TReviewEditParams } from 'shared/types/review.types';

export class ReviewService {
  static async newReview({ tourId, ...sendData }: TReviewSendParams) {
    return api(store.dispatch, logout, refreshTokens).post<TReviewFetchResponse>(`/tours/${tourId}/reviews`, sendData);
  }
  static async editReview({ reviewId, ...sendData }: TReviewEditParams) {
    return api(store.dispatch, logout, refreshTokens).put<TReviewFetchResponse>(`/tours/reviews/${reviewId}`, sendData);
  }
  static async getReviewsList() {
    return api(store.dispatch, logout, refreshTokens).get<TReviewFetchResponse[]>(`/tours/reviews/my`);
  }
}
