import { AsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from 'shared/constants/const';
import Notification from 'shared/lib/notification';
import { TAppDispatch } from 'store';

export const API_URL = API_BASE_URL + '/api';

export const api = (
  dispatch: TAppDispatch | null = null,
  logout: AsyncThunk<void, void, {}> | null = null,
  refresh: AsyncThunk<void, void, {}> | null = null
) => {
  const axiosConfig = {
    baseURL: API_URL,
    withCredentials: true,
  };

  const setHeader = (config: AxiosRequestConfig) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  };

  const refreshAndRetry = async (error: AxiosError<AxiosRequestConfig>) => {
    if (!dispatch || !refresh || !logout) return;
    const originalRequest: AxiosRequestConfig & { _isRetry?: boolean } = error?.config;
    if (error && error.response && error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest._isRetry = true;
      try {
        await dispatch(refresh());
        return api.request(originalRequest);
      } catch (e) {
        dispatch(logout());
        Notification.error('Вы не авторизованы!');
      }
    }
    throw error;
  };

  const api = axios.create(axiosConfig);

  api.interceptors.request.use(setHeader);
  api.interceptors.response.use((config) => config, refreshAndRetry);
  return api;
};

export default api;
