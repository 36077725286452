import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { setLoading, setPartners } from './partners.slice';
import PartnerService from 'services/partner.service';

export const fetchPartners = createAsyncThunk('fetchPartners', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await PartnerService.getPartners();
    dispatch(setPartners(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});
