import React, { useEffect, useState } from 'react';
import Layout from '../../widgets/layout/ui';
import ProfileComponent from '../../widgets/profile/ui';
import PageHead from '../../entities/page-head';
import ContainerContent from '../../entities/container';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { useAppSelector } from '../../../shared/hooks/useAppSelector';
import { useHistory } from 'react-router-dom';
import s from './index.module.scss'
import { TRANSFORM_TEXT } from '../../../shared/constants/const';
import { imagePrefix } from 'shared/utils/imagePrefix';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

export default function TouristProfilePageNew() {
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();

  useEffect(() => {
    if (!isAuth) {
      history.push('/login');
    } else {
      const { admin, superAdmin, operator, tourist } = TRANSFORM_TEXT.role;
      if (userData?.role === admin || userData?.role === superAdmin) {
        history.push('/account/admin');
      } else {
        if (userData?.role === operator) history.push('/account/operator/stats');
      }
    }
  }, []);


  return (
    <Layout classNameMain={'bg-c-blue-light'}>
      <PageHead isFixed={false}>
        <div className={s.wrapperHead}>
          Профиль
        </div>
      </PageHead>

      <ContainerContent>
        {userData && (
          <ProfileComponent
            name={userData.login}
            imageData={userData.photo}
            image={imagePrefix(userData.photo?.path)}
            email={userData.email}
            gender={userData.gender ?? ''}
            phone={userData.phone ?? ''}
          />
        )}
      </ContainerContent>
    </Layout>
  );
}
