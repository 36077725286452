import * as React from 'react';
import { TextField, SimpleShowLayout, EmailField } from 'react-admin';
import { OPERATOR_FIELDS } from '../../../shared/constants/const';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
};

const TouroperatorShow = () => (
  <SimpleShowLayout>
    <p style={{ textAlign: 'center' }}>Данные организации</p>
    <SimpleShowLayout sx={styleInBlock}>
      <TextField source='type' label={OPERATOR_FIELDS.type} />
      <TextField source='registryNumber' label={OPERATOR_FIELDS.registryNumber} />
      <TextField source='fullName' label={OPERATOR_FIELDS.fullName} />
      <TextField source='shortName' label={OPERATOR_FIELDS.shortName} />
      <TextField source='address' label={OPERATOR_FIELDS.address} />
      <TextField source='ogrn' label={OPERATOR_FIELDS.ogrn} />
      <TextField source='inn' label={OPERATOR_FIELDS.inn} />
      <TextField source='phone' label={OPERATOR_FIELDS.phone} />
      <EmailField source='email' label={OPERATOR_FIELDS.email} />
    </SimpleShowLayout>
    <p style={{ textAlign: 'center' }}>Данные контактного лица</p>
    <SimpleShowLayout sx={styleInBlock}>
      <TextField source='contactLastName' label={OPERATOR_FIELDS.contactLastName} />
      <TextField source='contactFirstName' label={OPERATOR_FIELDS.contactFirstName} />
      <TextField source='contactPatronymic' label={OPERATOR_FIELDS.contactPatronymic} />
      <TextField source='contactPhone' label={OPERATOR_FIELDS.contactPhone} />
      <EmailField source='contactEmail' label={OPERATOR_FIELDS.contactEmail} />
    </SimpleShowLayout>
  </SimpleShowLayout>
);

export default TouroperatorShow;
