import { cva } from "class-variance-authority";
import React, { useState } from "react";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import s from './index.module.scss'
type Props = {
  rating: number;
  isDynamic?: true;
  onChange?: (index: number) => void;
};

const cvaRating = cva(["flex"]);

const cvaStar = cva(["shrink-0"], {
  variants: {
    active: {
      true: [s.star],
    },
    isChange: {
      true: [s.change],
    },
  },
});

export default function Rating({ rating, onChange }: Props) {
  const [enterIndex, setEnterIndex] = useState(rating);

  const onEnter = (i: number) => {
    setEnterIndex(i);
  };
  const onLeave = () => {
    setEnterIndex(rating);
  };
  const onClick = (i: number) => {
    onChange && onChange(i);
  };

  return (
    <div className={cvaRating()}>
      {new Array(5).fill(1).map((_, i) => (
        <StarIcon
          onMouseEnter={() => onEnter(i + 1)}
          onMouseLeave={onLeave}
          key={i}
          onClick={() => onClick(i + 1)}
          className={cvaStar({
            active: onChange ? i < enterIndex : i < rating,
            isChange: onChange !== undefined
          })}
        />
      ))}
    </div>
  );
}
