import api from 'http/index';
import commonApi from 'http/commonApi';
import { TUserLoginRequest, TUserLoginResponse, TUserRegistrationRequest } from 'shared/types/types';

export default class AuthService {
  static async login(sendParams: TUserLoginRequest) {
    return commonApi().post<TUserLoginResponse>('/user/login', sendParams);
  }

  static async logout() {
    return api().post('/user/logout');
  }

  static async registration(sendData: TUserRegistrationRequest) {
    return commonApi().post<TUserLoginResponse>('/user/registration', sendData);
  }

  static async registrationOperator<T>(params: T) {
    return commonApi().post('/operator', params);
  }

  static async restorePass(email: string) {
    return commonApi().post('/user/restore/password', { email });
  }

  static async refreshToken() {
    return commonApi().post('/user/refresh');
  }
}
