import { api as apiNoAuth } from 'http/commonApi';
import { TPartner } from 'shared/types/partner.types';

export default class PartnerService {
  static async getPartners() {
    return await apiNoAuth().get<TPartner[]>('/partners');
  }
  static async getPartnersById(id: number) {
    return await apiNoAuth().get<TPartner[]>(`/partners/${id}`);
  }
}
