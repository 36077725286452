import { OverviewMap } from 'ol/control.js';

export const getOverviewMapControl = (layers: any[]) => {
  return new OverviewMap({
    className: 'ol-overviewmap ol-custom-overviewmap',
    layers,
    collapseLabel: '',
    label: '',
    collapsed: true,
  });
};
