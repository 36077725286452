import { useDispatch, useSelector } from 'react-redux';
import { selectFiltersTours } from 'manageStore/tours/tours.select';
import Loading from 'components/Loading/Loading';
import { NewsFilters } from '../../../../features/lib/NewsFilter/NewsFilters';
import { NewsCard } from '../../../../entities/news/NewsCard/NewsCard';
import {
  getPublishedNews, selectfilterBlogs, selectFilterBlogs_v2,
  selectFilterMeta,
  selectIsLoadingBlog,
  selectNewsList, setBlogsList,
  setFilteredNews,
  setFilterMeta, setFilterTag,
} from '../../../../manageStore/news/newsSlice';
import {useEffect} from 'react';
import { useMediaQuery } from '../../../../shared/hooks/useMatchMedia';
import { NewsMobileCard } from '../../../../entities/news/NewsCard/NewsMobileCard';
import { useAppSelector } from '../../../../shared/hooks/useAppSelector';
import { TNew} from '../../../../shared/types/types';
import BlogService from "../../../../services/blog.service";

//тут жеский костыль
//тут две сущности а должна быть одна надо переписать все


export const NewsMain = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFiltersTours);

  const news = useSelector(selectNewsList);
  const isLoading = useSelector(selectIsLoadingBlog);
  const [isMobile] = useMediaQuery();
  const currentTag = useAppSelector(selectFilterMeta);
  const blogs = useSelector(selectFilterBlogs_v2);

  const changeFiltersNews = (metaKeywords: string) => {
    dispatch(setFilterTag([metaKeywords]));
    dispatch(setFilterMeta(metaKeywords));
  };

  useEffect(() => {
    let filteredNews: TNew[] = [];
    filteredNews = news.filter((newItem) => newItem?.metaKeywords?.includes(currentTag));

    if (filteredNews.length) {
      dispatch(setFilteredNews(filteredNews));
    }

    if(currentTag === ''){
      dispatch(getPublishedNews());
    }

  }, [currentTag]);

  useEffect(() => {
    if (!news) {
      dispatch(getPublishedNews());
    }
  }, [dispatch, news]);

  const getBlogs = async ()=>{
    try {
      const response = await BlogService.getBlogs();
      if (response.data.length) {
        dispatch(setBlogsList(response.data));
      }
    } catch (e){
      console.error(e)
    }
  }

  useEffect(()=>{
    const fd = async ()=>{
      await getBlogs()
    }
    fd()
  }, [])

  return (
    <main className='news'>
      <div className='container news__container'>
        <h1 className='news__title'>Изучаем Таймыр</h1>
        <NewsFilters filters={filters} changeFiltersTours={changeFiltersNews} withParams withFixed isMulti />
        {isLoading ? (
          <Loading />
        ) : blogs?.length ? (
          <div className='news__list-wrapper'>
            <div className='news-list'>
              {blogs?blogs.map((newItem) =>
                  isMobile ? (
                      <NewsMobileCard key={newItem.id} newsItem={newItem} isBlog={true} />
                  ) : (
                      <NewsCard key={newItem.id} newsItem={newItem} isBlog={true}/>
                  )
              ):<></>}
            </div>
          </div>
        ) : (
          <div className='no-results-text'>По выбранным параметрам туры пока отсутствуют.</div>
        )}
      </div>
    </main>
  );
};
