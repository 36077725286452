import React from 'react';
import s from './index.module.scss';
import Button from '../../shared/ui/button';
import clsx from 'clsx';
import { ReactComponent as NoReqIcon } from 'assets/icons/no-req.svg';
const NoRequests = () => {
  return (
    <div className={s.wrapper}>
      <div>
        <NoReqIcon />
      </div>
      <div className={s.right}>
        <h3 className={clsx(s.requests, 'h1')}>
          У вас нет <br />
          активных заказов
        </h3>
        <p className={s.text}>
          Подберите подходящий для вас готовый тур, или составьте собственный. Это совсем не сложно. Туроператоры с
          радостью помогут вам отправиться в незабываемое путешествие по Таймыру!
        </p>
        <div className={s.buttons}>
          <Button href={'/tours'}>Готовые туры</Button>
          <Button href={'/designer'} intent={'secondary'}>
            Конструктор
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoRequests;
