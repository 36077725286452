import React from 'react';
import { ReactComponent as ClipIcon } from '../assets/file.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import { cva } from 'class-variance-authority';
import { convertDate } from '../../../shared/utils/convert-date';
import s from './index.module.scss';
import { TDocFetchWithUser } from '../../../../shared/types/common.types';
import { formatSize } from 'newProject/entities/formatSize/formatSize';
import { imagePrefix, urlPrefix } from 'shared/utils/imagePrefix';
import clsx from 'clsx';
type Props = {
  file: TDocFetchWithUser;
  onDelete?: () => void;
  withInfo?: boolean;
  className?: string;
  isPopup?: boolean;
};
const cvaWrapper = cva([s.wrapper]);

const cvaFile = cva([s.file]);
const cvaFileInfo = cva([s.fileInfo]);

export default function FileButton({ file, className, withInfo, isPopup, onDelete }: Props) {
  function processFileName(originalName: string) {
    // Regular expression to remove id and _
    const regex = /^(?:\d+_)?(.+)$/;

    // Apply the regular expression to the original file name
    const result = originalName.match(regex);

    // If no result is found, return the original name
    if (!result) {
      return originalName;
    }

    // Return the part of the name without id and _
    return result[1];
  }

  function getFileExtensionFromMIME(file: TDocFetchWithUser) {
    return '.' + file.filename.split('.').at(-1);
  }

  return (
    <div className={clsx(isPopup && s.popupFile, className)}>
      <div className={clsx(cvaWrapper(), withInfo && s.withInfo)}>
        <a href={urlPrefix(file.url)} target='_blank' rel='noreferrer' className={clsx(cvaFile())} download>
          {!isPopup && <ClipIcon />}
          <span>{file.filename}</span>
        </a>
        {!withInfo && <div className={s.date}>{convertDate(file.createdDate, { onlyTime: true })}</div>}
        {onDelete && <DeleteIcon className={s.deleteIcon} onClick={onDelete} />}
      </div>
      {withInfo && (
        <div className={cvaFileInfo()}>
          <div>
            {formatSize(file.size)}, {getFileExtensionFromMIME(file)}
          </div>
          <div>{convertDate(file.createdDate, { withTime: true })}</div>
        </div>
      )}
    </div>
  );
}
