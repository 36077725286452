import { SimpleForm, TextInput, required, Labeled } from 'react-admin';
import { NPA_FIELDS } from '../../../shared/constants/const';
import FileInputAdmin from '../FileInputAdmin';

const NpaForm = () => (
  <SimpleForm>
    <Labeled label={NPA_FIELDS.title} fullWidth isRequired>
      <TextInput source='caption' validate={[required()]} label='' />
    </Labeled>
    <FileInputAdmin
      source='npa_doc'
      label={NPA_FIELDS.file}
      multiple={false}
      validate={[required()]}
      accept='image/png, image/jpg, image/jpeg, application/pdf'
      isDoc
    />
  </SimpleForm>
);

export default NpaForm;
