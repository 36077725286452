import { cva } from "class-variance-authority"
import s from './index.module.scss'


export const cvaInput = cva([s.input], {
  variants: {
    isPlaceholder: {
      true: [s.isPlaceholder]
    },
    hasError: {
      true: [s.hasError],
      false: [s.hasntError]
    },
    disabled: {
      true: [s.disabled]
    }
  },
})


export const cvaLabel = cva([s.label], {
  variants: {
    required: {
      true: [s.required]
    }
  }
})
