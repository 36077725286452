import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode, useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import clsx from 'clsx';
import s from './index.module.scss'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import 'app/styles/null.scss'

type Props = {
  children: ReactNode;
  onClose: () => void;
  withClose?: boolean;
  classNameInner?: string;
} & VariantProps<typeof cvaClose>;

const cvaOverlay = cva([s.overlay]);
const cvaPopup = cva([s.popup]);

const cvaPopupInner = cva([s.popupInner]);

const cvaClose = cva([s.close], {
  variants: {
    minClose: {
      true: [s.min]
    }
  }
});

export default function Popup({ children, classNameInner, onClose, minClose, withClose }: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //@ts-ignore
    disableBodyScroll(scrollRef.current, {
      reserveScrollBarGap: true,
    });
    return () => clearAllBodyScrollLocks();
  }, []);
  return (
    <div className={cvaPopup()}>
      <div onClick={onClose} className={cvaOverlay()}></div>
      <div ref={scrollRef} className={clsx(cvaPopupInner(), classNameInner)}>
        {withClose && <CloseIcon onClick={onClose} className={cvaClose({minClose})} />}
        {children}
      </div>
    </div>
  );
}
