import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading, selectLocations } from 'manageStore/designer/designer.select';
import { fetchLocations } from 'manageStore/designer/designer.thunk';
import {resetStore, setFilteredLocations} from 'manageStore/designer/designer.slice';
import Loading from 'components/Loading/Loading';
import { TourDesignerMap } from '../TourDesignerMap/TourDesignerMap';
import { TourDesignerLearn } from '../TourDesignerLearn/TourDesignerLearn';
import {useHistory} from "react-router-dom";

export const TourDesignerMain = () => {
  const locations = useSelector(selectLocations);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  const history = useHistory()

  useEffect(() => {
    console.log('history')
    const unListen = history.listen(() => {

      dispatch(setFilteredLocations({
        filter: {
          season: [],
          types: [],
          territory: [],
          transport: [],
        },
        locations: locations
      }))
    });

    return () => {
      unListen();
    };
  }, [history]);


  useEffect(() => {
    if (locations && !locations.length) {
      dispatch(fetchLocations());
    }
  }, [locations, dispatch]);

  useEffect(() => {
    dispatch(resetStore());
    dispatch(fetchLocations());
  }, []);

  return (
    <main className='designer'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TourDesignerMap />
          <TourDesignerLearn />
        </>
      )}
    </main>
  );
};
