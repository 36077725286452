import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import Message from '../../../../features/message/ui';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as ClipIcon } from 'assets/icons/clip.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as SendGrayIcon } from 'assets/icons/send-gray.svg';
import { ReactComponent as CloseChatIcon } from 'assets/icons/close-chat.svg';
import Tag from '../../../../entities/tag';
import s from './index.module.scss';
import { convertDate } from '../../../../shared/utils/convert-date';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { TChatMessage, TRequestFetch } from '../../../../../shared/types/request.types';
import { useAppSelector } from '../../../../../shared/hooks/useAppSelector';
import Notification from '../../../../../shared/lib/notification';
import { useParams } from 'react-router-dom';
import { selectCurrentRequest, selectIsLoadingRequest } from '../../../../../manageStore/request/request.select';
import { requestActions } from '../../../../../manageStore/request/request.slice';
import { useAppDispatch } from '../../../../../shared/hooks/useAppDispatch';
import Stomp, { Client } from 'stompjs';
import SockJS from 'sockjs-client';
import { SOCKET_URL } from '../../../../../shared/constants/const';
import RequestService from '../../../../../services/request.service';
import LoadFile from 'newProject/entities/load-file';
import { getRequestByIdThunk } from '../../../../../manageStore/request/request.thunk';
import { useAsync } from 'react-use';
import { ReviewService } from 'services/review.service';
import Button from 'newProject/shared/ui/button';

type Props = {
  messages: TChatMessage[];
  isClose: boolean;
  request: TRequestFetch;
};

const cvaChat = cva([s.chat]);
const cvaGrowContainer = cva([s.growContainer]);
const cvaScrollContainer = cva([s.scrollContainer]);
const cvaMessagesList = cva([s.messagesList]);
const cvaWriteMessage = cva([s.writeMessage], {
  variants: {
    withFiles: {
      true: [s.withFiles],
    },
  },
});
const cvaTextarea = cva([s.textarea]);

export default function ApplicationChat({ messages, isClose, request: requestData }: Props) {
  const { id } = useParams<{ id: string }>();

  const userData = useAppSelector((state) => state.user.userData);
  const request = selectCurrentRequest();
  const messagesList = useAppSelector((state) => state.request.messagesList);

  const { setNewMessage } = requestActions;

  const dispatch = useAppDispatch();

  const textArea = useRef<HTMLTextAreaElement>(null);

  const [typed, setTyped] = useState('');
  const [socket, setSocket] = useState<Client | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [sendFiles, setSendFiles] = useState<File[]>([]);

  const handleTyping = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTyped(e.target.value);
  };

  const onMessageReceived = (msg: Stomp.Message) => {
    dispatch(setNewMessage(JSON.parse(msg.body)));
  };

  useEffect(() => {
    var sockjs = new SockJS(SOCKET_URL);
    let stompClient = Stomp.over(sockjs);
    stompClient.debug = () => {};
    stompClient.connect({}, function () {
      stompClient.subscribe('/request/' + id + '/queue/messages', onMessageReceived);
    });
    setSocket(stompClient);
    return () => sockjs.close();
  }, [setSocket, id, userData]);

  useEffect(() => {
    (async () => {
      if (sendFiles.length && messagesList) {
        try {
          await Promise.all(
            sendFiles.map((file) =>
              RequestService.sendFilesWithMessage(
                file,
                requestData.id,
                Number(messagesList[messagesList.length - 1].id)
              )
            )
          );
          setSendFiles([]);
          dispatch(getRequestByIdThunk(Number(id)));
        } catch {
          Notification.error('Произошла ошибка при загрузке файла');
        }
      }
    })();
  }, [messagesList]);

  useEffect(() => {
    if (!userData) return;
    const userType = userData?.type.toLowerCase() as keyof TRequestFetch['firstView'];
    if (request && (!request.firstView || !request.firstView[userType])) {
      RequestService.setRead({ id: Number(id), type: userType })
        .then()
        .catch((error) => Notification.error(error?.response?.data?.message || 'Произошла ошибка!'));
    }
  }, [request, id, userData]);

  const sendMessage = useCallback(
    async (e) => {
      setFiles([]);
      e.preventDefault();
      if (typed.trim()) {
        const message = {
          text: typed,
          userId: userData?.id,
          requestId: id,
        };
        socket?.send('/app/chat', {}, JSON.stringify(message));
        setTyped('');
      } else if (sendFiles.length > 0) {
        const message = {
          text: '',
          userId: userData?.id,
          requestId: id,
        };
        socket?.send('/app/chat', {}, JSON.stringify(message));
      } else {
        Notification.warning('Введите текст или прикрепите документ!');
      }
    },
    [socket, typed, files, sendFiles]
  );

  useEffect(() => {
    let textAreaVariable: HTMLTextAreaElement | null = null;
    const textAreaListener = (e: KeyboardEvent) => {
      if (e.code === 'Enter' && e.getModifierState('Shift')) {
        sendMessage(e);
      }
    };
    if (textArea.current) {
      textAreaVariable = textArea.current;
      textAreaVariable.addEventListener('keypress', textAreaListener);
    }
    return () => {
      if (textAreaVariable) {
        textAreaVariable.removeEventListener('keypress', textAreaListener);
      }
    };
  }, [sendMessage]);

  function calculateShouldShowDate(messages: TChatMessage[]) {
    if (messages.length === 0) return [];

    const result = [{ ...messages[0], shouldShowDate: true }];
    for (let i = 1; i < messages.length; i++) {
      const prevDate = new Date(result[i - 1].time);
      const currentDate = new Date(messages[i].time);

      if (
        prevDate.getDate() !== currentDate.getDate() ||
        prevDate.getMonth() !== currentDate.getMonth() ||
        prevDate.getFullYear() !== currentDate.getFullYear()
      ) {
        result.push({ ...messages[i], shouldShowDate: true });
      } else {
        result.push({ ...messages[i], shouldShowDate: false });
      }
    }
    return result;
  }

  // Ваш рендер компонента
  const adjustedMessages = calculateShouldShowDate(messages);

  const setColorMessage = (text: string) => {
    if (!text) {
      return undefined;
    }
    if (text.includes('Заявка создана')) {
      return 'green';
    } else if (text.includes('Заявка отменена')) {
      return 'red';
    }
    return undefined;
  };

  const { value: hasReview } = useAsync(async () => {
    try {
      const reviews = await ReviewService.getReviewsList();
      return reviews.data.every((item) => item.tour.id === requestData.tour.id);
    } catch {
      return false;
    }
  }, []);
  return (
    <div className={cvaChat()}>
      <div className={cvaGrowContainer()}>
        <div className={clsx(cvaScrollContainer(), 'noscrollbar')}>
          <div className={cvaMessagesList()}>
            {adjustedMessages.map((e) => (
              <>
                {e.shouldShowDate && (e.text || e.documents.length > 0) && (
                  <Tag className={s.date} variant={'gray-light'} font={'main'} size={'s'}>
                    {convertDate(e.time, { isLong: true })}
                  </Tag>
                )}
                <Message
                  serverTextVariant={userData?.role !== 'ROLE_TOURIST' ? setColorMessage(e.text) : undefined}
                  text={e.text}
                  files={e.documents}
                  isUserMessage={e.from.id === userData?.id}
                  date={e.time}
                />
              </>
            ))}
            {!hasReview && requestData.status === 'DONE' && (
              <Message
                isFull
                text={
                  <>
                    <b>Запрос отзыва на тур</b>
                    <br />
                    <br />
                    <p>Поздравляем! Ваш тур завершен</p>
                    <br />
                    <p>
                      Надеемся, все было прекрасно, и вы остались довольны. Приходите к нам снова с семьей и друзьями.
                      Также, нам очень важно ваше мнение о туре. Пожалуйста, поставьте оценку и напишите как проходило
                      ваше приключение.
                    </p>
                    <br />
                    <Button href={`/account/tourist/tour/${requestData.tour.id}/create-review`} intent={'outlined'}>
                      Оставить отзыв
                    </Button>
                  </>
                }
                isUserMessage={false}
                date={adjustedMessages.at(-1)?.time}
              />
            )}
          </div>
        </div>
      </div>

      <div className={cvaWriteMessage({ withFiles: files && files.length > 0 })}>
        {isClose ? (
          <div className={s.closeChat}>
            <CloseChatIcon />
            <span>Заявка {requestData.status === 'DELETED' ? 'отменена' : 'закрыта'}</span>
          </div>
        ) : (
          <>
            {files && files.length > 0 && (
              <div className={s.wrapperFiles}>
                <div className={s.files}>
                  {files.map((file, i) => (
                    <LoadFile
                      onDelete={() => setFiles(files.filter((f) => f.name !== file.name))}
                      name={file.name}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className={s.writeMessageInner}>
              <label className='cursor-pointer'>
                <input
                  multiple
                  onChange={(e) => {
                    if (!e.target.files?.[0]) return;
                    setFiles([...files, e.target.files[0]]);
                    setSendFiles([...sendFiles, e.target.files[0]]);
                  }}
                  className='hidden'
                  type='file'
                />
                <ClipIcon />
              </label>
              <TextareaAutosize
                placeholder='Написать сообщение'
                value={typed}
                onChange={handleTyping}
                className={clsx(cvaTextarea(), 'scrollbar')}
                maxRows={4.65}
              />
              {typed.trim() || files.length > 0 ? (
                <SendIcon onClick={sendMessage} className={clsx('cursor-pointer')} />
              ) : (
                <SendGrayIcon className={clsx('cursor-pointer')} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
