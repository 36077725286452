import {Labeled, required, SimpleForm, TextInput} from 'react-admin';
import {CLASTER_FIELD} from '../../../shared/constants/const';


export const ClustorForm = () => {
    return (
        <SimpleForm>
            <Labeled label={CLASTER_FIELD.name} fullWidth isRequired>
                <TextInput source='name' validate={[required()]} label=''/>
            </Labeled>
        </SimpleForm>
    );
};
