import { cva } from 'class-variance-authority';
import React, { ChangeEvent, useRef, useState } from 'react';
import Avatar from '../../../entities/avatar/ui';
import Button from '../../../shared/ui/button';
import { object, string } from 'yup';
import { phoneValidation, phoneValidationEmpty } from '../../validation';
import { Form, Formik } from 'formik';
import s from './index.module.scss';
import Dropdown from '../../../entities/dropdown/ui';
import { useMedia } from 'react-use';
import CustomField from '../../../entities/custom_field';
import UserService from '../../../../services/user.service';
import { logout, selectUserData, setUserData } from '../../../../manageStore/user/userSlice';
import Notification from '../../../../shared/lib/notification';
import { handleError } from '../../../../http/handleError';
import { useDispatch, useSelector } from 'react-redux';
import { TSingleFile } from 'shared/types/common.types';
import { useHistory } from 'react-router-dom';

type Props = {
  name: string;
  email: string;
  phone: string;
  gender?: string;
  imageData: TSingleFile | null;
  image?: string | null;
};

type profileFormValues = {
  login: string;
  email: string;
  phone: string;
  gender?: string;
  photo?: string | null;
};

function formatPhoneNumber(phoneNumberString: string) {
  // Удалите все символы, кроме цифр
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  // Убедитесь, что номер начинается с "7"
  if (cleaned.length >= 10 && (cleaned[0] === '8' || cleaned[0] === '7')) {
    cleaned = '7' + cleaned.substring(1);
  }

  // Обработайте случай, если номер начинается с "+8"
  if (phoneNumberString.startsWith('+8')) {
    cleaned = '7' + cleaned.substring(1);
  }

  // Добавьте пробелы между цифрами
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
  }

  return '';
}

const cvaWrapper = cva([s.wrapper]);
const cvaProfile = cva([s.profile]);
const cvaImage = cva([s.image]);
const cvaCols = cva([s.cols]);

export default function ProfileData({ name, email, phone, gender, image, imageData }: Props) {
  const formInitialValues = {
    login: name,
    email: email,
    photo: image,
    phone: formatPhoneNumber(phone),
    gender: gender,
  };
  const fileInputRef = useRef(null);
  const [avatar, setAvatar] = useState(image);
  const user = useSelector(selectUserData);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const clickLogout = async () => {
    await dispatch(logout());
    history.push('/');
  };

  const handleAvatarUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const urlFile = URL.createObjectURL(files[0]);
      URL.revokeObjectURL(avatar || '');
      setAvatar(urlFile);
    }
  };

  const userSchema = object({
    login: string()
      .required('Обязательное поле')
      .matches(/^[A-Za-zА-Яа-я]+( [A-Za-zА-Яа-я]+)?$/, 'Некорректное имя пользователя')
      .max(35, 'Имя слишком длинное'),
    email: string().email('Неккоректный email').required('Обязательное поле'),
    gender: string(),
    phone: string()
      .test('phoneValidateEmpty', 'Обязательное поле', phoneValidationEmpty)
      .test('phoneValidateRegex', 'Некорректный телефон', phoneValidation),
  });

  const [editing, setEditing] = useState(false);

  const handleEditing = () => setEditing(!editing);

  const saveProfile = async (v: profileFormValues, changingEmail: boolean) => {
    //@ts-ignore
    const avatarFile = fileInputRef?.current?.files[0];
    if (!user) return;
    setSaving(true);
    const savedData = { ...v, id: user.id, photo: undefined };
    try {
      if (avatarFile) {
        const newAvatar = avatarFile;
        const file = await UserService.savePhoto(newAvatar);
        savedData.photo = file.data[0] || null;
        if (newAvatar) {
          URL.revokeObjectURL(avatar || '');
        }
      }
      //@ts-ignore
      const { data } = await UserService.updateProfileData(savedData);
      localStorage.setItem('token', data.accessToken);
      if (changingEmail) {
        clickLogout();
      } else {
        dispatch(setUserData(data.user));
        handleEditing();
      }
      Notification.success('Успешно');
    } catch (e) {
      Notification.error(handleError(e));
    } finally {
      setSaving(false);
    }
  };

  const checkChangeEmail = (email: string, values: profileFormValues) => {
    if (email !== user?.email) {
      saveProfile(values, true);
    } else {
      saveProfile(values, false);
    }
  };

  const onSubmit = (v: profileFormValues) => {
    checkChangeEmail(v.email, v);
  };

  const isMobile = useMedia('(max-width: 1023px)');

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={userSchema}
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values, setFieldValue, handleChange, errors, touched }) => (
          <>
            <Form className={cvaWrapper()}>
              <div className={cvaProfile()}>
                <div className={cvaImage()}>
                  <Avatar
                    intent={'light'}
                    src={avatar ? avatar : image}
                    alt={name}
                    name={name}
                    surname={name.split(' ')[1]}
                    size={90}
                  />
                  <label style={{ cursor: 'pointer' }}>
                    <input
                      ref={fileInputRef}
                      accept='image/png, image/jpeg'
                      onChange={handleAvatarUpload}
                      type='file'
                      className='hidden'
                    />
                    <Button
                      className={s.change}
                      text={isMobile ? 'base' : 'uppercase'}
                      size={isMobile ? 's' : 'xs'}
                      intent={'outlined'}
                    >
                      Изменить
                    </Button>
                  </label>
                </div>
                <div className='grow'>
                  <CustomField
                    error={errors.login}
                    hasError={errors.login && touched.login}
                    className={s.margin}
                    placeholder='Введите имя'
                    value={values.login}
                    label={'Название или имя'}
                    required
                    name={'login'}
                    onChange={(e) => handleChange(e)}
                    asFormikField
                  />
                  <div className={cvaCols()}>
                    <CustomField
                      disabled
                      error={errors.email}
                      value={values.email}
                      hasError={errors.email && touched.email}
                      placeholder='Введите email'
                      label={'Адрес электронной почты'}
                      required
                      name={'email'}
                      onChange={(e) => handleChange(e)}
                      asFormikField
                    />
                    <CustomField
                      error={errors.phone}
                      hasError={errors.phone && touched.phone}
                      placeholder='Введите телефон'
                      label={'Номер телефона'}
                      required
                      value={values.phone}
                      name={'phone'}
                      onChange={(e) => setFieldValue('phone', e.target.value)}
                      mask='+7 999 999 99 99'
                    />
                    <Dropdown
                      label={'Пол'}
                      placeholder='Не указан'
                      value={values.gender ?? ''}
                      variants={['Мужской', 'Женский']}
                      onChange={(e) => setFieldValue('gender', e)}
                    />
                  </div>
                </div>
              </div>
              <Button className={s.btn} type='submit' intent={'dark'}>
                Сохранить изменения
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
