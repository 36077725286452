import React from 'react'
import { Edit } from 'react-admin'
import GalleryForm from './GalleryForm'

const GalleryEdit = () => (
  <Edit>
    <GalleryForm />
  </Edit>
)

export default GalleryEdit
