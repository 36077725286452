import Slider, { Settings } from 'react-slick';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TTravelGuideCityFacts } from 'shared/types/travelGuide.types';

type TTravelGuideItemFactsProps = {
  facts: TTravelGuideCityFacts[];
};

const settingsSlider: Settings = {
  className: 'travel-guide-item-facts__slider',
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const TravelGuideItemFacts = ({ facts }: TTravelGuideItemFactsProps) => {
  return (
    <section className='travel-guide-item-facts'>
      <div className='container travel-guide-item__container'>
        <h3 className='travel-guide-item-facts__title'>Факты</h3>
        <Slider {...settingsSlider}>
          {facts.map(({ id, photo, description }) => (
            <div className='travel-guide-item-facts__group' key={id}>
              <div className='travel-guide-item-facts__image'>
                <img
                  src={`${URL_FOR_IMG}/${photo?.path}`} alt='fact' />
              </div>
              <div className='travel-guide-item-facts__description'>{description}</div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
