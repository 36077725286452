import React from 'react'
import { Edit } from 'react-admin'
import AttractionForm from './AttractionForm'

const AttractionEdit = () => (
  <Edit>
    <AttractionForm />
  </Edit>
)

export default AttractionEdit
