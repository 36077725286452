import { api as apiNoAuth } from 'http/commonApi';
import { api as authApi } from 'http/index';
import { TLocation } from 'shared/types/location.types';
import { TTour } from 'shared/types/tours.types';

export default class TapeParticipantsService {
  static async getTapeParticipants() {
    return await apiNoAuth().get<TLocation[]>('/tape-participants');
  }
}
