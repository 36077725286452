import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Notification from 'shared/lib/notification';
import UserService from 'services/user.service';
import { logout, setUserData, selectUserData } from 'manageStore/user/userSlice';
import PopupInfo from 'components/PopupInfo/PopupInfo';
import ChangePassCard from 'components/Account/ChangePassCard';
import { URL_FOR_IMG } from '../../shared/constants/const';
import { handleError } from 'http/handleError';

const ProfileCard = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const avatarRef = useRef<HTMLInputElement | null>(null);

  const [editing, setEditing] = useState(false);
  const [changingPass, setChangingPass] = useState(false);
  const [saving, setSaving] = useState(false);
  const urlAvatar = user?.photo?.path ? `${URL_FOR_IMG}/${user.photo.path}` : null;
  const [avatar, setAvatar] = useState(urlAvatar);
  const [changingEmail, setChangingEmail] = useState(false);

  const handleEditingPass = () => {
    setChangingPass(!changingPass);
  };

  const handleEditing = () => setEditing(!editing);

  const clickLogout = () => dispatch(logout());

  const cancelEditing = () => {
    const urlAvatar = user?.photo?.path ? `${URL_FOR_IMG}/${user.photo.path}` : null;
    setEditing(false);
    setAvatar(urlAvatar);
    formik.resetForm();
  };

  const saveProfile = async () => {
    const { values } = formik;
    const avatarFile = avatarRef?.current?.files;
    if (!user) return;
    setSaving(true);
    const savedData = { ...values, id: user.id };
    try {
      const newAvatar = avatarFile ? avatarFile[0] :null;
      if (newAvatar) {
        const file = await UserService.savePhoto(newAvatar);
        savedData.photo = file.data[0] || null;
      }
      const { data } = await UserService.updateProfileData(savedData);
      localStorage.setItem('token', data.accessToken);
      if (newAvatar) {
        URL.revokeObjectURL(avatar || '');
      }
      if (changingEmail) {
        clickLogout();
      } else {
        dispatch(setUserData(data.user));
        setChangingEmail(false);
        handleEditing();
      }
      Notification.success('Успешно');
    } catch (e) {
      Notification.error(handleError(e));
    } finally {
      setSaving(false);
    }
  };

  const checkChangeEmail = (email: string) => {
    email !== user?.email ? setChangingEmail(true) : saveProfile();
  };

  const formik = useFormik({
    initialValues: {
      login: user?.login || '',
      email: user?.email || '',
      phone: user?.phone || '',
      info: user?.info || '',
      gender: user?.gender || 'Не выбрано',
      photo: user?.photo || null,
    },
    validationSchema: Yup.object({
      login: Yup.string().min(3, 'Длина должна быть не менее 3 символов').required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      phone: Yup.string()
        .matches(/('+7'|7|8)([0-9]{10})$/, 'Некорректный номер телефона')
        .required('Заполните поле'),
      info: Yup.string(),
      gender: Yup.string(),
    }),
    onSubmit: (values) => {
      checkChangeEmail(values.email);
    },
    enableReinitialize: true,
  });

  const handleAvatarUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const urlFile = URL.createObjectURL(files[0]);
      URL.revokeObjectURL(avatar || '');
      setAvatar(urlFile);
    }
  };

  useEffect(() => {
    const urlAvatar = user?.photo?.path ? `${URL_FOR_IMG}/${user.photo.path}` : null;
    setAvatar(urlAvatar);
  }, [user]);

  return (
    <>
      {!editing && !changingPass && (
        <PopupInfo title={'Информация о пользователе'}>
          <div className='profile-user'>
            <div className='profile-user__img'>{avatar && <img src={avatar} alt='avatar' />}</div>
            <div className='profile-user__info'>
              <p className='profile-user__name'>{user?.login}</p>
              <button className='profile-user__psw' onClick={handleEditingPass}>
                Изменить пароль
              </button>
            </div>
          </div>
          <div className='profile-contacts'>
            <div className='profile-box'>
              <h4 className='profile-title'>Адрес эл. почты:</h4>
              <p className='profile-text'>{user?.email ? user.email : 'Не указано'}</p>
            </div>
            <div className='profile-box'>
              <h4 className='profile-title'>Номер телефона:</h4>
              <p className='profile-text'>{user?.phone ? user.phone : 'Не указано'}</p>
            </div>
            <div className='profile-box'>
              <h4 className='profile-title'>Пол:</h4>
              <p className='profile-text'>{user?.gender ? user.gender : 'Не указано'}</p>
            </div>
          </div>
          <h4 className='profile-title'>Коротко о вас:</h4>
          <p className='profile-text'>{user?.info ? user.info : 'Не указано'}</p>
          <div className='profile-bottom'>
            <button className='profile-btn btn red' onClick={handleEditing}>
              Изменить
            </button>
            <button className='profile-btn btn blue' onClick={clickLogout}>
              Выйти
            </button>
          </div>
        </PopupInfo>
      )}
      {editing && !changingPass && !changingEmail && (
        <PopupInfo title={'Информация о пользователе (редактирование)'}>
          <form onSubmit={formik.handleSubmit}>
            <div className='profile-user'>
              <div className='profile-user__img'>{avatar && <img src={avatar} alt='avatar' />}</div>
              <div className='profile-user__info'>
                <input className='profile-input' id='login' type='text' {...formik.getFieldProps('login')} />
                {formik.touched.login && formik.errors.login ? (
                  <div className='profile-error'>{formik.errors.login}</div>
                ) : null}
                <input
                  className='profile-user__avatar-input'
                  id='photo'
                  name='photo'
                  type='file'
                  accept='image/png, image/jpg, image/jpeg'
                  onChange={handleAvatarUpload}
                  ref={avatarRef}
                />
                <label className='profile-user__psw' htmlFor='photo'>
                  Загрузить фотографию
                </label>
              </div>
            </div>
            <div className='profile-contacts'>
              <div className='profile-box'>
                <h4 className='profile-title'>Адрес эл. почты:</h4>
                <input className='profile-input' id='email' type='email' {...formik.getFieldProps('email')} />
                {formik.touched.email && formik.errors.email ? (
                  <div className='profile-error'>{formik.errors.email}</div>
                ) : null}
              </div>
              <div className='profile-box'>
                <h4 className='profile-title'>Номер телефона:</h4>
                <input className='profile-input' id='phone' type='text' {...formik.getFieldProps('phone')} />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className='profile-error'>{formik.errors.phone}</div>
                ) : null}
              </div>
              <div className='profile-box'>
                <h4 className='profile-title'>Пол:</h4>
                <select
                  id='gender'
                  {...formik.getFieldProps('gender')}
                  style={{ border: 'none', color: '#33404f', fontSize: '14px' }}
                >
                  <option value='Не выбрано'>Не выбрано</option>
                  <option value='Мужской'>Мужской</option>
                  <option value='Женский'>Женский</option>
                </select>
              </div>
            </div>
            <h4 className='profile-title'>Коротко о вас:</h4>
            <textarea className='profile-textarea' id='info' {...formik.getFieldProps('info')} />
            {formik.touched.info && formik.errors.info ? (
              <div className='profile-error'>{formik.errors.info}</div>
            ) : null}
            <div className='profile-bottom'>
              <button className='profile-btn btn red' type='submit' disabled={saving}>
                Сохранить
              </button>
              <button className='profile-btn btn blue' onClick={cancelEditing} disabled={saving}>
                Отмена
              </button>
            </div>
          </form>
        </PopupInfo>
      )}
      {changingPass && (
        <PopupInfo title={'Изменение вашего пароля'}>
          <ChangePassCard cancelEditing={handleEditingPass} />
        </PopupInfo>
      )}
      {changingEmail && (
        <PopupInfo title={'Изменение электронной почты'}>
          <div className='profile-user__info'>
            <p className='profile-user__name'>При сохранении потребуется подтвержедние новой электронной почты.</p>
          </div>
          <div className='profile-bottom'>
            <button className='profile-btn btn red' type='button' onClick={saveProfile}>
              Сохранить
            </button>
            <button className='profile-btn btn blue' onClick={() => setChangingEmail(false)}>
              Отмена
            </button>
          </div>
        </PopupInfo>
      )}
    </>
  );
};

export default ProfileCard;
