import { useState, useEffect } from 'react';
import { IApplication, IFile } from '../../types';
import { cva } from 'class-variance-authority';
import InfoApplication from '../../../../features/application-preview/info-application';
import Tag from '../../../../entities/tag';
import s from './index.module.scss';
import clsx from 'clsx';
import FileButton from '../../../../entities/file/ui';
import Button from '../../../../shared/ui/button';
import { useAppSelector } from '../../../../../shared/hooks/useAppSelector';
import { selectCurrentRequest, selectMessageList } from '../../../../../manageStore/request/request.select';
import { TDocFetchWithUser } from '../../../../../shared/types/common.types';
import { TRequestFetch } from '../../../../../shared/types/request.types';
import { RequestsTypes } from 'newProject/pages/my-applications';
import DeleteReqPopup from '../../delete-popup/index';
import Portal from 'newProject/features/portal/ui';
import RequestService from 'services/request.service';
import Notification from 'shared/lib/notification';
import { getRequestByIdThunk } from '../../../../../manageStore/request/request.thunk';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

type Props = {
  files?: TDocFetchWithUser[];
  application: TRequestFetch;
};

const cvaBlock = cva([s.block]);
const cvaTitle = cva([s.title]);
const cvaHead = cva([s.head]);
export const cvaName = cva([s.name]);

export const cvaFiles = cva([s.files]);
export const cvaSubtitle = cva(s.subtitle);

export default function ApplicationsActions({ files, application }: Props) {
  const userData = useAppSelector((state) => state.user.userData);
  const [openDelete, setOpenDelete] = useState(false);
  const [filesState, setFilesState] = useState(files ?? []);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setFilesState(files ?? []);
  }, [files]);

  const onDelete = async (fileId: number) => {
    try {
      const isDeleted = await RequestService.deleteFile(application.id, fileId);
      if (isDeleted.status < 300) {
        setFilesState(filesState.filter((file) => file.id !== fileId));
        dispatch(getRequestByIdThunk(application.id));
      }
    } catch (e) {
      Notification.error('Произошла ошибка');
    }
  };

  return (
    <div className={s.container}>
      <div className={cvaBlock()}>
        <div className={cvaHead()}>
          <div className={cvaTitle()}>СТАТУС</div>
          <Tag variant={'blue-dark'}>
            {RequestsTypes.find((e) => e.status === application.status)?.value ?? 'Отменена'}
          </Tag>
          <Tag variant={application.isPaid ? 'green' : 'orange'}>{application.isPaid ? 'Оплачено' : 'Не оплачено'}</Tag>
        </div>
        <div className={cvaName()}>{application.tour.name}</div>
        <InfoApplication
          groupSize={application.groupSize}
          price={application.tour.price}
          duration={application.tour.duration}
          dateStart={application.dateStart}
        />
      </div>
      <div className={clsx(cvaBlock(), s.secondCont)}>
        <div className={cvaTitle()}>Вложения</div>
        {filesState.length ? (
          <>
            <div className={s.scrollBar}>
              <div className={cvaSubtitle()}>Пользователь</div>
              <div className={cvaFiles()}>
                {filesState
                  .filter(Boolean)
                  .filter((e) => e.user.id === userData?.id)
                  .map((e) => (
                    <FileButton onDelete={() => onDelete(e.id)} key={e.id} file={e} withInfo />
                  ))}
              </div>
            </div>
            <div>
              <div className={cvaSubtitle()}>Оператор</div>
              <div className={cvaFiles()}>
                {filesState
                  .filter(Boolean)
                  .filter((e) => e.user.id !== userData?.id)
                  .map((e) => (
                    <FileButton
                      onDelete={e.user.role !== 'ROLE_TOURIST' ? () => onDelete(e.id) : undefined}
                      key={e.id}
                      file={e}
                      withInfo
                    />
                  ))}
              </div>
            </div>
          </>
        ) : (
          <div className={s.filesNo}>Вы еще не обменивались файлами</div>
        )}
      </div>
      {application.status !== 'DELETED' && application.status !== 'DENIED' && (
        <div className={clsx(cvaBlock(), s.secondCont)}>
          <div className={s.actions}>Действия с заявкой</div>
          <Button onClick={() => setOpenDelete(true)} intent={'outlined'} size={'sm'}>
            Отменить запрос
          </Button>
        </div>
      )}
      {openDelete && application.id && (
        <Portal>
          <DeleteReqPopup id={application.id} onClose={() => setOpenDelete(false)} />
        </Portal>
      )}
    </div>
  );
}
