import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import { ArrayInput, Labeled, SimpleFormIterator, TextInput, required, useRecordContext } from 'react-admin';
import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { TCategoryTravelGuide } from 'shared/types/travelGuide.types';

type TGuideAttributesProps = {
  defaultValues: Record<string, string>[];
  isDisableTitle?: boolean;
  type: TCategoryTravelGuide;
};

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const GuideAttributes = ({ defaultValues, isDisableTitle, type }: TGuideAttributesProps) => {
  const { resetField } = useFormContext();
  const record = useRecordContext();

  useEffect(() => {
    if (record && record.type !== type) {
      record.attributes = defaultValues;
      record.type = type;
    } else {
      resetField('attributes');
    }
  }, [defaultValues, type, record, resetField]);

  return (
    <Labeled label={TRAVEL_GUIDE_FIELDS.characteristics} fullWidth isRequired>
      <Box sx={styleInBlock}>
        <ArrayInput source='attributes' label='' fullWidth defaultValue={defaultValues}>
          <SimpleFormIterator disableReordering disableAdd={true} disableRemove={true}>
            <TextInput
              source='name'
              validate={[required()]}
              label='Заголовок'
              disabled={isDisableTitle}
              fullWidth
              format={(v) => (v ? v : '')}
            />
            <TextInput source='value' validate={[required()]} label='Контент' fullWidth format={(v) => (v ? v : '')} />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </Labeled>
  );
};
