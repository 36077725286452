import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import notification from 'assets/images/account/notification.svg';
// import settings from 'assets/images/account/settings.svg'
import denied from 'assets/images/account/denied.svg';
import checked from 'assets/images/account/checked.svg';
import waiting from 'assets/images/account/waiting.svg';
import { URL_FOR_IMG } from '../../shared/constants/const';
import { TRequestFetch } from 'shared/types/request.types';

type TMainWorkflowProps = {
  requests: TRequestFetch[];
};

type TWorkflowItemProps = {
  item: TRequestFetch;
};

const MainWorkflow = ({ requests }: TMainWorkflowProps) => {
  const [newItems, setNewItems] = useState<TRequestFetch[]>([]);
  const [processingItems, setProcessingItems] = useState<TRequestFetch[]>([]);
  const [doneItems, setDoneItems] = useState<TRequestFetch[]>([]);
  const [deniedItems, setDeniedItems] = useState<TRequestFetch[]>([]);

  useEffect(() => {
    if (requests && requests.length) {
      const newR: TRequestFetch[] = [];
      const processingR: TRequestFetch[] = [];
      const doneR: TRequestFetch[] = [];
      const deniedR: TRequestFetch[] = [];
      requests.forEach((item) => {
        if (item.status === 'NEW') newR.push(item);
        if (item.status === 'PROCESSING') processingR.push(item);
        if (item.status === 'DONE') doneR.push(item);
        if (item.status === 'DENIED') deniedR.push(item);
      });
      setNewItems(newR);
      setProcessingItems(processingR);
      setDoneItems(doneR);
      setDeniedItems(deniedR);
    }
  }, [requests]);

  const WorkflowItem = ({ item }: TWorkflowItemProps) => {
    let icon = null;
    if (item.status === 'NEW') icon = waiting;
    if (item.status === 'PROCESSING') icon = notification;
    // if (item.status === 'SETTINGS') icon = settings
    if (item.status === 'DONE') icon = checked;
    if (item.status === 'DENIED') icon = denied;

    const lastMessageDate = new Date(item.lastMessage);
    const lastMessage = lastMessageDate ? lastMessageDate.toLocaleString() : '';

    return (
      <li className='Amain__workflow-item'>
        <Link
          className='Amain__workflow-link'
          to={`/account/operator/${item.status.toLowerCase()}-requests/${item.id}`}
        >
          <div className='Amain__workflow-user'>
            <div className='Amain__workflow-user__img'>
              {item?.tourist?.photo?.path ? (
                <img src={`${URL_FOR_IMG}/${item.tourist.photo.path}`} alt='Фото' />
              ) : (
                item.tourist.login[0]
              )}
            </div>
            <div className='Amain__workflow-user__data'>
              <p className='Amain__workflow-username'>{item?.tourist?.login}</p>
              <p className='Amain__workflow-usermail'>{item?.tourist?.email}</p>
            </div>
          </div>
          <p className='Amain__workflow-item__title'>{item?.tour?.name}</p>
          <div className='Amain__workflow-item__price'>
            <div>
              <span>{item.tour.price ? item.tour.price : ''}</span> руб.
            </div>
            <div>
              Начало: <span>{item?.tour?.dateStart ? new Date(item.tour.dateStart).toLocaleDateString() : '-'}</span>
            </div>
          </div>
          <div className='Amain__workflow-item__bottom'>
            <p className='Amain__workflow-item__price'>
              Стоимость: <span>{item.tour.price ? item.tour.price : ''}</span>
            </p>
            <p className='Amain__workflow-item__date'>{lastMessage}</p>
            {icon ? <img className='Amain__workflow-item__icon' src={icon} alt={item.status} /> : null}
          </div>
        </Link>
      </li>
    );
  };

  return (
    <div className='Amain__workflow'>
      <div className='Amain__workflow-column'>
        <div className='Amain__workflow-column__title blue'>
          Доступные заявки ({newItems.length ? newItems.length : 0})
        </div>
        <ul className='Amain__workflow-box'>
          {newItems.length ? newItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
        </ul>
      </div>
      <div className='Amain__workflow-column'>
        <div className='Amain__workflow-column__title yellow'>
          Текущие заявки ({processingItems.length ? processingItems.length : 0})
        </div>
        <ul className='Amain__workflow-box'>
          {processingItems.length ? processingItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
        </ul>
      </div>
      <div className='Amain__workflow-column'>
        <div className='Amain__workflow-column__title green'>
          Завершенные заявки ({doneItems.length ? doneItems.length : 0})
        </div>
        <ul className='Amain__workflow-box'>
          {doneItems.length ? doneItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
        </ul>
      </div>
      <div className='Amain__workflow-column'>
        <div className='Amain__workflow-column__title red'>
          Непринятые заявки ({deniedItems.length ? deniedItems.length : 0})
        </div>
        <ul className='Amain__workflow-box'>
          {deniedItems.length ? deniedItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
        </ul>
      </div>
    </div>
  );
};

export default MainWorkflow;
