import { useCallback, useEffect } from 'react';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import VectorLayer from 'ol/layer/Vector';
import { Cluster } from 'ol/source';

export const usePointer = (
  map: Map | null,
  clusters: VectorLayer<Cluster> | null,
  handleClickCluster: (event: MapBrowserEvent<any>, clusters: VectorLayer<Cluster>, map: Map) => void
) => {
  const setPointer = useCallback((event: MapBrowserEvent<any>, clusters: VectorLayer<Cluster>, map: Map) => {
    if (!map) return;
    clusters.getFeatures(event.pixel).then((features) => {
      map.getTargetElement().style.cursor = features[0] ? 'pointer' : '';
    });
  }, []);


  useEffect(() => {
    if (!map || !clusters) return;

    const handleSingleClick = (event: MapBrowserEvent<any>) => handleClickCluster(event, clusters, map);
    const handlePointerMove = (event: MapBrowserEvent<any>) => setPointer(event, clusters, map);
    if (window.innerWidth > 1123) {
      map.on('pointermove', handlePointerMove);
    }
    map.on('click', handleSingleClick);

    return () => {
      map.un('pointermove', handlePointerMove);
      map.un('click', handleSingleClick);
    };
  }, [map, clusters, handleClickCluster, setPointer]);
};
