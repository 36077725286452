import api from 'http/commonApi';
import { TNew, TPlace, TClusterItem } from 'shared/types/types';
import { TCity } from 'shared/types/location.types';
import { TDocFetch } from 'shared/types/common.types';

export default class CommonService {
  static async getPublishedNews() {
    return api().get<TNew[]>('/news/published');
  }
  static async getPageSettings() {
    return api().get<TClusterItem[]>('/page-settings');
  }
  static async getPlaces<TType extends keyof TPlace>(type: TType) {
    return api().get(`/${type}`);
  }
  static async getCities() {
    return api().get<TCity[]>('/city');
  }
  static async postViewsNews<T>(id: number, params: T) {
    return api().post(`/news/${id}`, params);
  }
  static async feedback<T>(params: T) {
    return api().post('/feedback', params);
  }
  static async getNpaDocs() {
    return api().get<TDocFetch[]>(`/inddoc`);
  }
  static async becomeMember<T>(payload: T) {
    return api().post('/trk-member', payload)
  }
}
