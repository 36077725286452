import classNames from 'classnames';
import { TourCard } from 'entities/tour/TourCard/TourCard';
import { TTour } from 'shared/types/tours.types';

type TTravelGuideItemToursProps = {
  tours: TTour[];
  isCity: boolean;
};

export const TravelGuideItemTours = ({ tours, isCity }: TTravelGuideItemToursProps) => {
  return (
    <section className={classNames('travel-guide-item-tours', { city: isCity })}>
      <div className='container travel-guide-item__container'>
        <div className='travel-guide-item-tours__title'>туры и экскурсии</div>
        <div className='travel-guide-item-tours__list'>
          <div className='tours-list'>
            {tours.map((tour) => (
              <TourCard key={tour.id} tour={tour} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
