import { Datagrid, List, TextField, EditButton, ChipField, Pagination } from 'react-admin';
import { MENU_ADMIN_LIST } from '../../../shared/constants/const';
import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';

export const GuideList = () => (
  <List title={MENU_ADMIN_LIST.locations} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source='name' sortable={false} label={TRAVEL_GUIDE_FIELDS.title} />
      <ChipField source='type' sortable={false} label={TRAVEL_GUIDE_FIELDS.locationType} />
      {/* <ShowButton /> */}
      <EditButton />
    </Datagrid>
  </List>
);
