import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRoutes } from './routes';
import CookieNotification from 'components/CookieNotification/CookieNotification';
import { setCookie, getCookie } from './helpers/manageCookie';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { ImproveProductPopupFrame } from './components/ImproveProductPopup/ImproveProductPopupFrame';

function App() {
  const [isAcceptCookie, setAcceptCookie] = useState(false);
  const routes = useRoutes();

  const handleClickAccept = () => {
    setCookie('cookies_policy', 'true', 365);
    setAcceptCookie(true);
  };

  useEffect(() => {
    const isCookie = !!getCookie('cookies_policy');
    setAcceptCookie(isCookie);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop>
          <div className='App'>{routes}</div>
        </ScrollToTop>
      </Router>
      {!isAcceptCookie && <CookieNotification handleClickAccept={handleClickAccept} />}
      <ImproveProductPopupFrame/>
    </>
  );
}

export default App;
