import React from 'react';
import { Controller } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import ErrorMessage from './ErrorMessage/ErrorMessage';

const TinyMCE = (props) => {
  const { property, required } = props;

  const menubar = 'file edit view insert format tools table help';

  const plugins = 'link image code table fullscreen hr lists';

  const toolbar =
    'fontselect fontsizeselect formatselect | ' +
    'bold italic underline strikethrough subscript superscript | ' +
    'blockquote removeformat | forecolor backcolor | ' +
    'alignleft aligncenter alignright alignjustify | ' +
    'indent outdent | numlist bullist | ' +
    'link unlink | hr table image | fullscreen code | undo redo';

  return (
    <Controller
      name={property}
      rules={{ required }}
      render={({ field, formState }) => {
        const { value, onChange } = field;
        const { errors } = formState;
        return (
          <>
            <div style={{ marginBottom: '20px' }}>
              <Editor
                apiKey='nzzjmbe4sdch1gel06ol3cih8m9pildwtyakeg1xj871n5zi'
                value={value || ''}
                onEditorChange={onChange}
                init={{
                  height: 500,
                  menubar,
                  plugins,
                  toolbar,
                  image_advtab: true,
                  toolbar_mode: 'sliding',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
              {errors[property] && <ErrorMessage message='Required' />}
            </div>
          </>
        );
      }}
    />
  );
};

export default TinyMCE;
