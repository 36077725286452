import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { setLoading, setQuestions } from './questions.slice';
import QuestionService from 'services/question.service';

export const fetchQuestions = createAsyncThunk('fetchQuestions', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await QuestionService.getQuestions();
    dispatch(setQuestions(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});
