import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import CommonService from 'services/common.service';
import { TRootState } from 'store';
import { handleError } from 'http/handleError';
import { TAttraction, TFood, TLodging, TPlace } from 'shared/types/types';
import { TCity } from 'shared/types/location.types';
import { PLACE_TYPES } from 'shared/constants/const';

type TInitialState = {
  food: TFood[];
  lodgings: TLodging[];
  attractions: TAttraction[];
  cities: TCity[];
  activeCity: TCity | null;
};

const initialState: TInitialState = {
  food: [],
  lodgings: [],
  attractions: [],
  cities: [],
  activeCity: null,
};

export const slice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setFood: (state, { payload }: PayloadAction<TFood[]>) => {
      state.food = payload;
    },
    setLodgings: (state, { payload }: PayloadAction<TLodging[]>) => {
      state.lodgings = payload;
    },
    setAttractions: (state, { payload }: PayloadAction<TAttraction[]>) => {
      state.attractions = payload;
    },
    setCities: (state, { payload }: PayloadAction<TCity[]>) => {
      state.cities = payload;
    },
    setActiveCity: (state, { payload }: PayloadAction<TCity>) => {
      state.activeCity = payload;
    },
  },
});

export const { setFood, setLodgings, setAttractions, setCities, setActiveCity } = slice.actions;

export const getCities = createAsyncThunk('getCities', async (_, { dispatch }) => {
  try {
    const { data } = await CommonService.getCities();
    dispatch(setCities(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  }
});

export const getPlaces = createAsyncThunk('getPlaces', async (type: keyof TPlace, { dispatch }) => {
  try {
    const { data } = await CommonService.getPlaces(type);
    if (type === PLACE_TYPES.food) {
      dispatch(setFood(data));
    }
    if (type === PLACE_TYPES.lodging) {
      dispatch(setLodgings(data));
    }
    if (type === PLACE_TYPES.attraction) {
      dispatch(setAttractions(data));
    }
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  }
});

export const selectFood = (state: TRootState) => state.places.food;
export const selectLodgings = (state: TRootState) => state.places.lodgings;
export const selectAttractions = (state: TRootState) => state.places.attractions;
export const selectCities = (state: TRootState) => state.places.cities;
export const selectCity = (state: TRootState) => state.places.activeCity;

export default slice.reducer;
