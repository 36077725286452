type TPopupInfoTitle = {
  cName: string;
  children: string | null;
};

const PopupInfoTitle = ({ cName, children }: TPopupInfoTitle) => {
  return <h4 className={`popupInfo-title ${cName ? cName : null}`}>{children ? children : null}</h4>;
};

export default PopupInfoTitle;
