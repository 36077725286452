import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store';
import { TQuestion } from 'shared/types/question.types';

export const selectLoading = (state: TRootState) => state.questions.isLoading;
export const selectQuestions = (state: TRootState) => state.questions.questions;

export const selectQuestionItem = createSelector(
  selectQuestions,
  (state: TRootState, id: string) => id,
  (questions, id) => {
    const idNumber = Number(id);
    return idNumber && questions ? questions.find((questions: TQuestion) => questions.id === idNumber) : null;
  }
);
