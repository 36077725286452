import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { selectSortedTours } from 'manageStore/tours/tours.select';
import { setFilters } from 'manageStore/tours/tours.slice';
import { TFiltersTours, TNamesTypeTourFilter } from 'shared/types/tours.types';
import { BaseSelect } from 'shared/ui/BaseSelect/BaseSelect';
import { FiltersManageButtons } from 'features/shared';

type TItemFilters = {
  id: string;
  label: string;
  value: string;
  icon: JSX.Element;
  fieldFilters: TNamesTypeTourFilter;
};

type TToursTypesSelectProps = {
  filters: TFiltersTours;
  clickFilter: (filter: string, fieldFilters: TNamesTypeTourFilter) => void;
  setFiltersToUrl?: (newFilters: TFiltersTours) => void;
  itemsFilters: TItemFilters[];
  isMulti?: boolean;
};

export const ToursTypesSelect = ({
  filters,
  clickFilter,
  setFiltersToUrl,
  itemsFilters,
  isMulti
}: TToursTypesSelectProps) => {
  const dispatch = useDispatch()

  const [localFilters, setLocalFilters] = useState(filters);
  useEffect(() => setLocalFilters(filters), [filters]);
  const [isOpenSelect, setOpenSelect] = useState(false);

  const tours = useAppSelector((state) => selectSortedTours(state, localFilters));

  const items = isMulti ? itemsFilters.slice(1) : itemsFilters;
  const isActiveClearButton = !!localFilters.types.length || !!localFilters.locations.length;

  const changeTypesTours = (filter: string, fieldFilters: TNamesTypeTourFilter) => {
    if (isMulti) {
      setLocalFilters((prev) => {
        const fieldValue = [...prev[fieldFilters]];
        const typeIndex = fieldValue.findIndex((value) => value === filter);
        typeIndex === -1 ? fieldValue.push(filter) : fieldValue.splice(typeIndex, 1);
        return { ...prev, [fieldFilters]: fieldValue };
      });
    }
    else {
      clickFilter(filter, fieldFilters);
    }
  };

  const clearTypesFilters = () => {
    setLocalFilters((prev) => ({ ...prev, types: [], locations: [] }));
  };

  const toggleSelect = () => {
    if (isOpenSelect) {
      setLocalFilters(filters);
    }
    setOpenSelect((prev) => !prev);
  };

  const showTours = () => {
    toggleSelect();
    if (setFiltersToUrl) setFiltersToUrl(localFilters);
    else dispatch(setFilters(localFilters));
  };


  return (
    <div>
      <BaseSelect
        title='Вид отдыха'
        items={items}
        handlerSelect={changeTypesTours}
        isMulti={isMulti}
        lightBackground
        filters={[...localFilters.types, ...localFilters.locations]}
        isOpenSelect={isOpenSelect}
        bottom={
          <FiltersManageButtons
            clearFilters={clearTypesFilters}
            applyFilters={showTours}
            countVariants={tours.length}
            isActiveClearButton={isActiveClearButton}
          />
        }
        toggleSelect={toggleSelect}
      />
    </div>
  );
};
