import {
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  ChipField,
  ArrayField,
  SimpleList,
  FunctionField,
  ReferenceField,
  BooleanField,
  NumberField,
  WithRecord,
  TopToolbar,
  useDataProvider,
  useShowContext,
  useRefresh,
  useRedirect,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { Button, Chip } from '@mui/material';
import { TOURS_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
};

const TourMultiField = () => {
  const record = useRecordContext();
  const convertData = record.tourTypeMulti ? record.tourTypeMulti.split(';') : [];
  const res = convertData.map((el) => <Chip key={el} label={el} sx={{ marginRight: '10px' }} />);
  return res;
};

const PostShowActions = () => {
  const dataProvider = useDataProvider();
  const { resource } = useShowContext();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const approveAction = (record) => {
    dataProvider
      .confirmTour(record)
      .then(() => {
        redirect('list', resource);
        refresh();
      })
      .catch((error) => {
        const message = error?.message || 'error';
        notify(message, { type: 'warning' });
      });
  };
  const rejectAction = (record) => {
    dataProvider
      .rejectTour({ id: record.id })
      .then(() => {
        redirect('list', resource);
        refresh();
      })
      .catch((error) => {
        const message = error?.message || 'error';
        notify(message, { type: 'warning' });
      });
  };

  return (
    <TopToolbar>
      <WithRecord
        render={(record) => {
          if (record.status !== 'NEW') return null;
          return (
            <>
              <Button variant='outlined' onClick={() => approveAction(record)}>
                Согласовать
              </Button>
              <Button variant='outlined' onClick={() => rejectAction(record)}>
                Отклонить
              </Button>
            </>
          );
        }}
      />
    </TopToolbar>
  );
};

const ToursShow = () => (
  <Show actions={<PostShowActions />}>
    <SimpleShowLayout>
      <FunctionField
        label={TOURS_FIELDS.preview}
        render={(record) => {
          return record.preview ? (
            <img
              className='admin-image admin-image__single'
              src={`${URL_FOR_IMG}/${record.preview.path}`}
              alt='preview'
            />
          ) : null;
        }}
      />
      <FunctionField
        label={TOURS_FIELDS.photos}
        render={(record) => {
          return record?.photos ? (
            record?.photos.map((photo, index) => (
              <img
                key={`photos_${index}`}
                className='admin-image admin-image__multi'
                src={`${URL_FOR_IMG}/${photo.path}`}
                alt='photos'
              />
            ))
          ) : (
            <>нет фото</>
          );
        }}
      />
      <TextField source='title' label={TOURS_FIELDS.title} />
      <TextField source='shortDesc' label={TOURS_FIELDS.shortDesc} />
      <TextField source='desc' label={TOURS_FIELDS.desc} />
      <NumberField source='price' label={TOURS_FIELDS.price} />
      <TextField source='operatorEmail' label={TOURS_FIELDS.operator} />
      <NumberField source='duration' label={TOURS_FIELDS.duration} />
      <BooleanField source='winter' label={TOURS_FIELDS.winter} fullWidth />
      <BooleanField source='spring' label={TOURS_FIELDS.spring} fullWidth />
      <BooleanField source='summer' label={TOURS_FIELDS.summer} fullWidth />
      <BooleanField source='autumn' label={TOURS_FIELDS.autumn} fullWidth />
      <TextField source='promo' label={TOURS_FIELDS.promo} />
      <TextField source='benefits' label={TOURS_FIELDS.benefits} />
      <ArrayField source='habitation' label={TOURS_FIELDS.habitation}>
        <SimpleList
          primaryText={
            <>
              <Labeled label='Заголовок'>
                <ChipField source='title' />
              </Labeled>
              <br />
              <Labeled label='Описание'>
                <TextField source='desc' />
              </Labeled>
            </>
          }
          linkType={false}
          rowStyle={() => styleInBlock}
        />
      </ArrayField>
      <FunctionField
        label={TOURS_FIELDS.habitationPhoto}
        render={(record) => {
          return record?.habitationPhotos?.url?.map((url, index) => (
            <img
              key={`habbitation_photo_${index}`}
              className='admin-image admin-image__multi'
              src={url}
              alt='habbitation'
            />
          ));
        }}
      />
      <TextField source='included' label={TOURS_FIELDS.included} />
      <TextField source='notIncluded' label={TOURS_FIELDS.notIncluded} />
      <ArrayField source='locations' label={TOURS_FIELDS.locations}>
        <SimpleList
          primaryText={
            <ReferenceField source='id' reference='locations'>
              <TextField source='name' label={false} />
            </ReferenceField>
          }
          linkType={false}
          rowStyle={() => ({ ...styleInBlock, color: '#4268f6' })}
        />
      </ArrayField>
      <ArrayField source='days' label={TOURS_FIELDS.days}>
        <SimpleList
          primaryText={
            <div>
              <Labeled label='Заголовок' fullWidth>
                <TextField source='title' />
              </Labeled>
              <Labeled label='Описание' fullWidth>
                <TextField source='desc' />
              </Labeled>
              <Labeled label='Распорядок дня' fullWidth>
                <TextField source='timeline' />
              </Labeled>
              <Labeled label='Фотографии'>
                <FunctionField
                  render={(record) => {
                    return record?.days?.dayPhotos?.map((url, index) => (
                      <img
                        key={`tour_photo_${index}`}
                        className='admin-image admin-image__multi'
                        src={url}
                        alt='tour_photo'
                      />
                    ));
                  }}
                />
              </Labeled>
            </div>
          }
          linkType={false}
          rowStyle={() => styleInBlock}
        />
      </ArrayField>
      <TourMultiField source='tourTypeMulti' sortable={false} label={TOURS_FIELDS.tourType} />
      <Labeled label={TOURS_FIELDS.productType}>
        <ChipField source='productType' />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);

export default ToursShow;
