import React from 'react'
import { Create } from 'react-admin'
import UserForm from './UserForm'

const UserCreate = () => (
  <Create>
    <UserForm isCreate={true} />
  </Create>
)

export default UserCreate
