import classNames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { URL_FOR_IMG } from 'shared/constants/const';
import { OpacityContentCard } from 'shared/lib/OpacityContentCard/OpacityContentCard';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { TNew } from 'shared/types/types';

type TTravelGuideItemFaunaProps = {
  places: TNew[];
  isFauna?: boolean;
};

export const TravelGuideItemBlog = ({ places, isFauna }: TTravelGuideItemFaunaProps) => {
  const history = useHistory();

  const [isShowMore, setShowMore] = useState(false);

  const showMoreText = `еще ${places.length - 3}`;
  const isHiddenShowMore = isShowMore || places.length < 4;

  const showNews = (id: number) => {
    history.push(`/news/${id}`);
  };

  const showMoreHandler = () => {
    setShowMore(true);
  };

  return (
    <section className='travel-guide-item-blog'>
      <div className='container travel-guide-item__container'>
        <TitleSectionMain
          title={isFauna ? 'Животный мир' : 'мир растений'}
          isSmall
          className='travel-guide-item-blog__title'
        />
        <div className={`travel-guide-item-blog__list ${isFauna ? 'fauna' : 'flora'}`}>
          {places.map(({ id, title, preview }, index) => (
            <div
              className={classNames('travel-guide-item-blog__card-wrapper', { hidden: !isShowMore && index > 2 })}
              key={id}
            >
              <OpacityContentCard
                className='travel-guide-item-blog__card'
                srcImage={`${URL_FOR_IMG}/${preview?.path}`}
                altImage={title}
              >
                <>
                  <TitleSectionMain title={title} className='travel-guide-item-blog__card__title' />
                  <button className='travel-guide-item-blog__card__btn' type='button' onClick={() => showNews(id)}>
                    Хочу посмотреть
                  </button>
                </>
              </OpacityContentCard>
            </div>
          ))}
        </div>
        <button
          className={classNames('travel-guide-item-blog__show-more', { hidden: isHiddenShowMore })}
          type='button'
          onClick={showMoreHandler}
        >
          {showMoreText}
        </button>
      </div>
    </section>
  );
};
