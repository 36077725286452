import {CloseFilter} from "./CloseFilter";
import {TFilterList} from "./SimpleFilter";
import {NextFilterIcon} from "./NextFilterIcon";
import {SimpleFIlterCheckbox} from "./SimpleFIlterCheckbox";

type TpropListFilter = {
    filters: TFilterList[];
    closeEvent: Function;
    preAppleEvent: Function;
    appleEvent: Function;
    countVariant: number;
}

export const SimpleFilterList = ({filters, closeEvent, preAppleEvent, appleEvent, countVariant}: TpropListFilter) => {

    const setCheck = (val: boolean, uKey: string) => {
        const nowFilter = filters.map(el => {
                if (el.value === uKey) {
                    el.select = val
                }
                return el
            }
        );
        preAppleEvent(nowFilter)
    }

    const clearEvent = () => {
        const nowFilter = filters.map(el => {
                el.select = false
                return el
            }
        );
        preAppleEvent(nowFilter)
    }

    return (
        <div className='simpleListPanel'>
            <div className="simpleListPanel__title">выберите теги <div onClick={() => closeEvent()}><CloseFilter/></div>
            </div>
            <ul className='list'>
                {filters.map((filter) => (
                    <li key={filter.value}><span>#{filter.label}</span>
                        <SimpleFIlterCheckbox
                            isCheck={filter.select}
                            setCheck={(val: boolean) => setCheck(val, filter.value)}/>
                    </li>
                ))}
            </ul>
            <div className='controls'>
                <button className='controls__btn blueBtn'
                        onClick={() => appleEvent()}>Показать {countVariant} вариантов <NextFilterIcon/></button>
                <button className='controls__btn whiteBtn' onClick={() => clearEvent()}>Очистить всё</button>
            </div>

        </div>
    );
};
