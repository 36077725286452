export const getFormattedDate = (date) =>  {
    const newsDate = new Date(date);
    return newsDate.toLocaleDateString('ru', {
      year: 'numeric',
      day: '2-digit',
      month: 'long',
    });
}

export const formatToLocalTime = (utcTimeString) => {
  const utcDate = new Date(utcTimeString);
  const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
  return localDate.toLocaleString();
};