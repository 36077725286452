import { useState } from 'react';

type TPartnerItemVideoProps = {
  link: string;
};

export const PartnerItemVideo = ({ link }: TPartnerItemVideoProps) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => setClicked(true);

  return (
    <section className='partner-item__video'>
      <div className='container partner-item__container'>
        <div className='yvideo yvideo--enabled' onClick={handleClick}>
          {!clicked ? (
            <>
              <picture>
                <source srcSet={`https://i.ytimg.com/vi_webp/${link}/sddefault.webp`} type='image/webp' />
                <img
                  className='yvideo__media'
                  src={`https://i.ytimg.com/vi/${link}/sddefault.jpg`}
                  alt='Превью видео'
                />
              </picture>
              <button className='yvideo__button' aria-label='Запустить видео' onClick={handleClick}>
                <svg width='68' height='48' viewBox='0 0 68 48'>
                  <path
                    className='yvideo__button-shape'
                    d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z'
                  ></path>
                  <path className='yvideo__button-icon' d='M 45,24 27,14 27,34'></path>
                </svg>
              </button>
            </>
          ) : (
            <>
              <iframe
                title='Видео'
                src={`https://www.youtube-nocookie.com/embed/${link}?rel=0&showinfo=0&autoplay=1`}
                frameBorder='0'
                allowFullScreen
              ></iframe>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
