import React from 'react';
import { Show, WithRecord, TopToolbar, useDataProvider, useShowContext, useRefresh, useRedirect, useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import GuideShow from './GuideShow';
import TouroperatorShow from './TouroperatorShow';

const PostShowActions = () => {
  const dataProvider = useDataProvider();
  const { resource } = useShowContext();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const customAction = (record) => {
    dataProvider
      .confirmOperator({ id: record.id })
      .then(() => {
        redirect('list', resource);
        refresh();
      })
      .catch((error) => {
        const message = error?.message || 'error';
        notify(message, { type: 'warning' });
      });
  };
  return (
    <TopToolbar>
      <WithRecord
        render={(record) => (
          <Button variant="outlined" onClick={() => customAction(record)}>
            Согласовать
          </Button>
        )}
      />
    </TopToolbar>
  );
};

const OperatorShow = () => (
  <div style={{ paddingBottom: '50px' }}>
    <Show actions={<PostShowActions />}>
      <WithRecord label="author" render={(record) => (record.type === 'Экскурсовод / гид-проводник' ? <GuideShow /> : <TouroperatorShow />)} />
    </Show>
  </div>
);

export default OperatorShow;
