import * as React from 'react';
import { Datagrid, List, EditButton, ShowButton, FunctionField, Pagination } from 'react-admin';
import { MENU_ADMIN_LIST, GALLERY_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const GalleryList = () => (
  <List title={MENU_ADMIN_LIST.photoTours} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <FunctionField
        label={GALLERY_FIELDS.uploadedFiles}
        render={(record) => {
          return record?.uploadedFiles?.url?.map((url, index) => {
            let urlComplex = '';
            try {
              let path = record?.uploadedFiles.path[index];
              urlComplex = URL_FOR_IMG + '/' + path;
            } catch (error) { }
            return (
              <img
                key={`gallery_${index}`}
                src={urlComplex}
                alt='gallery'
                style={{
                  maxHeight: '100px',
                  maxWidth: '100px',
                }}
              />
            );
          });
        }}
      />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default GalleryList;
