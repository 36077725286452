import * as React from 'react';
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
  ImageField,
  Pagination,
  ReferenceField,
} from 'react-admin';
import { MENU_ADMIN_LIST, LODGING_FIELDS } from '../../../shared/constants/const';

const LodgingList = () => (
  <List
    title={MENU_ADMIN_LIST.locations}
    exporter={false}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source="name" sortable={false} label={LODGING_FIELDS.title} />
      <ReferenceField source="city" reference="city" label={LODGING_FIELDS.city}>
        <TextField source="name" label={false} />
      </ReferenceField>
      <TextField source="time" sortable={false} label={LODGING_FIELDS.time} />
      <ImageField
        source="preview"
        sortable={false}
        label={LODGING_FIELDS.preview}
      />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default LodgingList;
