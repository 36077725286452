import { selectQuestions } from 'manageStore/questions/questions.select';
import { fetchQuestions } from 'manageStore/questions/questions.thunk';
import { useEffect } from 'react';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { BaseAccordion } from 'shared/ui/BaseAccordion/BaseAccordion';

export const HomeFaq = () => {
  const dispatch = useAppDispatch();
  const questions = useAppSelector(selectQuestions);

  useEffect(() => {
    if (!questions) {
      dispatch(fetchQuestions());
    }
  }, [questions]);

  return (
    <section className='home-faq'>
      <div className='container home-faq__container'>
        <TitleSectionMain className='home-faq__title' title='вопросы и ответы' isSmall />
        <div className='faq-list'>
          {questions?.map((el) => (
            <BaseAccordion key={el.id} question={el.title}>
              <article className='home-faq__answer'>
                {el.body.split('\n').filter((item) => !!item).map((item, index) => (
                  <p key={index}>
                    {item}
                  </p>
                ))}
              </article>
            </BaseAccordion>
          ))}
        </div>
      </div>
    </section>
  );
};
