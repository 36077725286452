import React from 'react'
import { Create } from 'react-admin'
import LodgindForm from './LodgindForm'

const LodgingCreate = () => (
  <Create>
    <LodgindForm />
  </Create>
)

export default LodgingCreate
