import classNames from 'classnames';
import { PropsWithChildren, MouseEvent } from 'react';
import ReactDom from 'react-dom';

type TPortalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  classContainer?: string;
  classOverlay?: string;
  isCenter?: boolean;
  isBottom?: boolean;
  isBottomRight?: boolean;
  isBgcOpacity20?: boolean;
  isBgcTransparent?: boolean;
  isNoPointerEvents?: boolean;
};

export const Portal = ({
  children,
  isOpen,
  onClose,
  classContainer,
  classOverlay,
  isCenter,
  isBottom,
  isBottomRight,
  isBgcOpacity20,
  isBgcTransparent,
  isNoPointerEvents,
}: PropsWithChildren<TPortalProps>) => {
  const portalElement = document.querySelector('#portal');
  if (!portalElement) {
    return null;
  }

  const handleClick = ({ target, currentTarget }: MouseEvent<HTMLDivElement>) => {
    if (onClose && target === currentTarget) {
      onClose();
    }
  };

  return ReactDom.createPortal(
    isOpen ? (
      <>
        <div
          className={classNames(
            'portal__overlay',
            classOverlay,
            { 'opacity-20': isBgcOpacity20 },
            { 'transparent': isBgcTransparent },
            { 'no-pointer-events': isNoPointerEvents },
          )}
          onClick={onClose}
        />
        <section
          className={classNames(
            'portal__container',
            classContainer,
            { 'align-center': isCenter },
            { 'align-bottom': isBottom },
            { 'align-bottom-right': isBottomRight },
          )}
          onClick={handleClick}
        >
          {children}
        </section>
      </>
    ) : null,
    portalElement
  );
};
