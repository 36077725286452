import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useFirstView } from 'shared/hooks/useFirstView';
import { mobileOperatorNavs } from './Operator/navigation';
import { mobileTouristNavs, touristNavs } from './Tourist/navigation';
import Aside from 'components/Account/Aside';
import MainChat from 'components/Account/Chat/MainChat';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import Loading from 'components/Loading/Loading';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { getRequestByIdThunk } from 'manageStore/request/request.thunk';
import { selectIsLoadingRequest } from 'manageStore/request/request.select';
import AlternativeHeader from 'components/Header/AlternativeHeader';

type TChatPage = {
  type: string;
  navs: typeof touristNavs;
};

const ChatPage = ({ type, navs }: TChatPage) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const isLoading = selectIsLoadingRequest();

  const firstView = useFirstView(userData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuth) history.push('/login');
    if (userData?.type !== type) history.push('/account');
  }, [isAuth, userData, history, type]);

  useEffect(() => {
    dispatch(getRequestByIdThunk(Number(id)));
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Чат - Туркластер Арктический</title>
      </Helmet>
      <section className='account'>
        <AlternativeHeader />
        <Aside
          navs={navs}
          mobileNavs={userData?.type === 'OPERATOR' ? mobileOperatorNavs : mobileTouristNavs}
          unreadStatus={firstView}
        />
        <section className='Amain'>
          {isLoading ? <Loading /> : <MainChat />}
          <MobileBottom />
        </section>
      </section>
    </>
  );
};

export default ChatPage;
