import classNames from 'classnames';

type TTitleSectionProps = {
  title: string | JSX.Element;
  className?: string;
  isSmall?: boolean;
  subtitle?: string;
};

export const TitleSectionMain = ({ title, isSmall, className, subtitle }: TTitleSectionProps) => {
  return (
    <div className={classNames('title-section', className)}>
      <h2 className={classNames('title-section__title', { small: isSmall })}>{title}</h2>
      {!!subtitle && <p className='title-section__subtitle'>{subtitle}</p>}
    </div>
  );
};
