import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

type TNavigationList = {
  isMobile?: boolean;
};

const NavigationList = ({ isMobile }: TNavigationList) => {
  const { t } = useTranslation();

  return (
    <nav className='header__nav'>
      <ul className='header__menu'>
        {isMobile && (
          <li className='header__menu-item'>
            <NavLink exact to='/' className='header__menu-link'>
              Главная
            </NavLink>
          </li>
        )}
        <li className='header__menu-item'>
          <NavLink to='/tours' className='header__menu-link'>
            Туры и экскурсии
          </NavLink>
        </li>
        <li className='header__menu-item'>
          <a href="/designer"  className='header__menu-link'>Конструктор путешествий</a>
        </li>
        {isMobile && (
          <li className='header__menu-item'>
            <NavLink to='/partners' className='header__menu-link'>
              Организаторы путешествий
            </NavLink>
          </li>
        )}
        {/* <li className='header__menu-item'>
          <NavLink to='/tourist' className='header__menu-link'>
            {t('headerMenu.forTourist')}
          </NavLink>
        </li> */}
        {/* <li className='header__menu-item'>
          <NavLink to='/3dmap' className='header__menu-link'>
            {t('headerMenu.map')}
          </NavLink>
        </li> */}
        <li className='header__menu-item'>
          <NavLink to='/travel-guides' className='header__menu-link'>
            {t('headerMenu.travelGuides')}
          </NavLink>
        </li>
        <li className='header__menu-item'>
          <NavLink to='/blog' className='header__menu-link'>
            {t('headerMenu.news')}
          </NavLink>
        </li>
        <li className='header__menu-item'>
          <NavLink to='/trk-info' className='header__menu-link'>
            О нас
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationList;
