import { useCallback, useState } from 'react';

export const usePortal = (initPortal: boolean): [boolean, () => void] => {
  const [isOpen, setOpen] = useState(initPortal);

  const togglePortal = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return [isOpen, togglePortal];
};
