import redStar from 'assets/images/account/rating-star-red.svg';
import grayStar from 'assets/images/account/rating-star-gray.svg';

type TReviewRatingProps = {
  rating: number;
  label?: string;
  setRating?: (rating: number) => void;
};

export const ReviewRating = ({ rating, label, setRating }: TReviewRatingProps) => {
  const stars = [];
  const gray = 5 - +rating;

  for (let i = 0; i < rating; i++) {
    stars.push(redStar);
  }
  for (let i = 0; i < gray; i++) {
    stars.push(grayStar);
  }

  const handleClick = (newRaiting: number) => {
    if (!setRating) return;
    setRating(newRaiting);
  };

  return (
    <p className='Amain-review__rating'>
      {label && `${label}: `}
      {stars.map((item, index) => (
        <img
          src={item}
          alt='Звезда'
          key={index}
          onClick={() => handleClick(index + 1)}
          style={{ cursor: setRating ? 'pointer' : 'auto' }}
        />
      ))}
    </p>
  );
};
