import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Slider, { Settings } from 'react-slick';
import { useSwipe } from 'shared/hooks/useSwipe';
import { useClientRect } from 'shared/hooks/useClientRect';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { TFiltersTours, TNamesTypeTourFilter } from 'shared/types/tours.types';

const settings: Settings = {
  dots: false,
  infinite: false,
  slidesToScroll: 6,
  variableWidth: true,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};

type TItemFilters = {
  id: string;
  label: string;
  value: string;
  icon: JSX.Element;
  fieldFilters: TNamesTypeTourFilter;
};

type TToursTypesSliderProps = {
  filters: TFiltersTours;
  clickFilter: (filter: string, fieldFilters: TNamesTypeTourFilter) => void;
  itemsFilters: TItemFilters[];
};

export const ToursTypesSlider = ({ filters, clickFilter, itemsFilters }: TToursTypesSliderProps) => {
  const [slidesToShow, setSlidesToShow] = useState(7);
  const [widthLastElements, setWidthLastElements] = useState(0);

  const slidesRef = useRef<Array<HTMLDivElement | null>>([]);
  const slickTrackRef = useRef<{ node: HTMLDivElement | null }>({ node: null });

  const [startSwipe, endSwipe, isSwipe] = useSwipe();
  const [rectSection, refSection] = useClientRect();

  const checkActiveFilter = (filter: string) => {
    return filter
      ? filters.types.includes(filter) || filters.locations.includes(filter)
      : !filters.types.length && !filters.locations.length;
  };

  const changeSlider = (prev: number, next: number) => {
    const slickTrackNode = slickTrackRef.current.node;
    const isEndSlider = next + slidesToShow === itemsFilters.length;
    if (!slickTrackNode || !rectSection) return;
    if (isEndSlider) {
      setTimeout(() => {
        const newDeltaTransform = rectSection.width - widthLastElements;
        slickTrackNode.style.left = `${newDeltaTransform}px`;
      }, 0);
    } else {
      slickTrackNode.style.left = '0';
    }
  };

  const getSlickTrackNode = () => {
    slickTrackRef.current.node = document.querySelector('.tours-filters__types-slider .slick-track');
  };

  useEffect(() => {
    const slides = slidesRef.current;
    if (rectSection && slides.length === itemsFilters.length) {
      let numbersSlides = 0;
      let accWidth = 0;
      for (let i = slides.length - 1; i > 0; i--) {
        const slide = slides[i];
        if (!slide) {
          break;
        }
        const rect = slide.getBoundingClientRect();
        const newAccWidth = accWidth + Math.ceil(rect.width * 100) / 100;
        if (newAccWidth > rectSection.width) {
          break;
        }
        ++numbersSlides;
        accWidth = newAccWidth;
      }
      setSlidesToShow(numbersSlides || 7);
      setWidthLastElements(accWidth);
    }
  }, [rectSection]);

  return (
    <div ref={refSection}>
      <Slider
        {...settings}
        className={classNames('tours-filters__types-slider')}
        slidesToShow={slidesToShow}
        swipeEvent={startSwipe}
        beforeChange={changeSlider}
        onInit={getSlickTrackNode}
      >
        {itemsFilters.map((filter, index) => (
          <div
            key={filter.id}
            className='tours-filters__wrapper'
            onMouseDown={endSwipe}
            onClick={() => !isSwipe && clickFilter(filter.value, filter.fieldFilters)}
            ref={(el) => (slidesRef.current[index] = el)}
          >
            <div
              className={classNames('tours-filters__type', {
                'tours-filters__type_active': checkActiveFilter(filter.value),
              })}
            >
              {filter.icon}
              <div className='tours-filters__type__name'>{filter.label}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
