import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import Loading from 'components/Loading/Loading';
import { fetchPartners } from 'manageStore/partners/partners.thunk';
import { selectPartnerItem, selectPartners, selectLoading } from 'manageStore/partners/partners.select';
import { PartnerItemTopSection } from 'widgets/lib/partnerItemMain/PartnerItemTopSection/PartnerItemTopSection';
import { PartnerItemVideo } from 'widgets/lib/partnerItemMain/PartnerItemVideo/PartnerItemVideo';
import { fetchTours } from 'manageStore/tours/tours.thunk';
import { HomeToursItem } from 'entities/tour/HomeToursItem/HomeToursItem';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';

export const PartnerItemMain = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectLoading);
  const partners = useSelector(selectPartners);
  const partnerItem = useAppSelector((state) => selectPartnerItem(state, params.id));

  useEffect(() => {
    if (partners && !partners.length) {
      dispatch(fetchPartners());
    }
  }, [partners]);

  useEffect(() => {
    if (!isLoading && partners?.length && !partnerItem) {
      history.push('/partners');
    }
    partnerItem && console.log(partnerItem);
  }, [partnerItem, isLoading, partners]);

  useEffect(() => {
    dispatch(fetchTours());
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!partnerItem) {
    return null;
  }

  return (
    <main className='partner-item'>
      <PartnerItemTopSection partnerItem={partnerItem} />
      <section className='partner-item__description'>
        <div className='container partner-item__container'>
          <div className='partner-item__description-content'>
            <div className='partner-item__description-title'>описание</div>
            <div className='partner-item__description-text'>{partnerItem.description}</div>
          </div>
        </div>
      </section>
      {!!partnerItem.videoLink && <PartnerItemVideo link={partnerItem.videoLink} />}
      {!!partnerItem.partnerTour?.length ? (
        <section className='partner-item__tours'>
          <div className='container partner-item__container'>
            <TitleSectionMain title='туры и экскурсии' className='partner-item__tours__title' isSmall />
            <div className='home-tours__list'>
              {partnerItem.partnerTour.map((product) => (
                <HomeToursItem key={product.id} tour={product} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        <div className='partner-item__bottom'></div>
      )}
    </main>
  );
};
