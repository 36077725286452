import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TCategoryPartner, TFiltersPartners, TNamesTypePartnersFilter } from 'shared/types/partner.types';
import { setFilters } from 'manageStore/partners/partners.slice';
import { BaseSelect } from 'shared/ui/BaseSelect/BaseSelect';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { FiltersManageButtons } from 'features/shared';
import { selectFilteredPartners } from 'manageStore/partners/partners.select';

type TItemFilters = {
  id: string;
  label: string;
  value: TCategoryPartner;
  icon: JSX.Element;
  fieldFilters: TNamesTypePartnersFilter;
};

type TPartenrsTypesSelectProps = {
  filters: TFiltersPartners;
  itemsFilters: TItemFilters[];
};

export const PartenrsTypesSelect = ({ filters, itemsFilters }: TPartenrsTypesSelectProps) => {
  const dispatch = useDispatch();
  const [localFilters, setLocalFilters] = useState(filters);
  const [isOpenSelect, setOpenSelect] = useState(false);

  const partners = useAppSelector((state) => selectFilteredPartners(state, localFilters));

  const items = itemsFilters.slice(1);
  const isActiveClearButton = !!localFilters.types.length;

  const changeTypesPartners = (filter: TCategoryPartner, fieldFilters: TNamesTypePartnersFilter) => {
    setLocalFilters((prev) => {
      const fieldValue = [...prev[fieldFilters]];
      const typeIndex = fieldValue.findIndex((value) => value === filter);
      typeIndex === -1 ? fieldValue.push(filter) : fieldValue.splice(typeIndex, 1);
      return { ...prev, [fieldFilters]: fieldValue };
    });
  };

  const clearTypesFilters = () => {
    setLocalFilters((prev) => ({ ...prev, types: [], locations: [] }));
  };

  const toggleSelect = () => {
    if (isOpenSelect) {
      setLocalFilters(filters);
    }
    setOpenSelect((prev) => !prev);
  };

  const showPartners = () => {
    toggleSelect();
    dispatch(setFilters(localFilters));
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <BaseSelect
      title='Категории партнеров'
      items={items}
      handlerSelect={changeTypesPartners}
      isMulti
      lightBackground
      filters={[...localFilters.types]}
      isOpenSelect={isOpenSelect}
      bottom={
        <FiltersManageButtons
          clearFilters={clearTypesFilters}
          applyFilters={showPartners}
          countVariants={partners?.length || 0}
          isActiveClearButton={isActiveClearButton}
        />
      }
      toggleSelect={toggleSelect}
    />
  );
};
