import { TRANSFORM_TEXT } from 'shared/constants/const';
import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';
import {useSelector} from "react-redux";
import {selectSelectedTag} from "../../../manageStore/news/newsSlice";
import {TERRITORY_ENUM} from "../../../shared/constants/travelGuide.constants";

const itemsNewsTag = [
  {
    value: TRANSFORM_TEXT.news.tags.ALL,
    label: TRANSFORM_TEXT.news.tags.ALL,
    valueEnum: TERRITORY_ENUM.ALL
  },
  {
    value: TRANSFORM_TEXT.news.tags.NORILSK,
    label: TRANSFORM_TEXT.news.tags.NORILSK,
    valueEnum: TERRITORY_ENUM.NORILSK
  },
  {
    value: TRANSFORM_TEXT.news.tags.PLATO_PUTORANA,
    label: TRANSFORM_TEXT.news.tags.PLATO_PUTORANA,
    valueEnum: TERRITORY_ENUM.PLATO_PUTORANA
  },
  {
    value: TRANSFORM_TEXT.news.tags.ANAVAR_PLATEAUS,
    label: TRANSFORM_TEXT.news.tags.ANAVAR_PLATEAUS,
    valueEnum: TERRITORY_ENUM.PLATO_ANABAR
  },
  {
    value: TRANSFORM_TEXT.news.tags.DUBINKA,
    label: TRANSFORM_TEXT.news.tags.DUBINKA,
    valueEnum: TERRITORY_ENUM.DUDINKA
  },
  {
    value: TRANSFORM_TEXT.news.tags.DIKSON,
    label: TRANSFORM_TEXT.news.tags.DIKSON,
    valueEnum: TERRITORY_ENUM.DIKSON
  },
  {
    value: TRANSFORM_TEXT.news.tags.KHATANGA,
    label: TRANSFORM_TEXT.news.tags.KHATANGA,
    valueEnum: TERRITORY_ENUM.HATANGA
  },
  {
    value: TRANSFORM_TEXT.news.tags.REMOTE_VILAGES,
    label: TRANSFORM_TEXT.news.tags.REMOTE_VILAGES,
    valueEnum: TERRITORY_ENUM.OTDALENNYE_POSELKI
  },
  {
    value: TRANSFORM_TEXT.news.tags.ARCTIC_COAST,
    label: TRANSFORM_TEXT.news.tags.ARCTIC_COAST,
    valueEnum: TERRITORY_ENUM.ARKTICHESKOE_POBEREZHE
  },
];

export const territoryTagCommon = itemsNewsTag;

type TToursFiltersBySeason = {
  filters: string[];
  onClick: <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours["season"]) => void;
};

export const NewsFiltersByTag = ({ filters, onClick }: TToursFiltersBySeason) => {
  const selectedTag = useSelector(selectSelectedTag)
  const checkActiveTag = (value: string) => {
    return selectedTag.includes(value);
  };

  const handleClick = (value: string) => {
    const tag = [...filters];
    const indexTag = tag.findIndex((item) => item === value);
    indexTag === -1 ? tag.push(value as string) : tag.splice(indexTag, 1);
    onClick('season', tag);
  };

  return (
    <ParamsFilterGroup title='Локации'>
      {itemsNewsTag.map((tag) => (
        <ParamsFilterItem
          key={tag.label}
          active={checkActiveTag(tag.value)}
          onClick={() => handleClick(tag.value)}
          label={tag.label}
        />
      ))}
    </ParamsFilterGroup>
  );
};
