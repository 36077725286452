import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useFirstView } from 'shared/hooks/useFirstView';
import Notification from 'shared/lib/notification';
import RequestService from 'services/request.service';
import { operatorNavs, mobileOperatorNavs } from './navigation';
import Aside from 'components/Account/Aside';
import MainStats from 'components/Account/MainStats';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import Loading from 'components/Loading/Loading';
import PopupInfo from 'components/PopupInfo/PopupInfo';
import ChangePassCard from 'components/Account/ChangePassCard';
import Portal from 'components/Portal/Portal';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { TStatsData } from 'shared/types/types';
import AlternativeHeader from 'components/Header/AlternativeHeader';
import { NoRequests } from 'components/Account/NoRequests';

const StatsPage = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();
  const firstView = useFirstView(userData);
  const [loading, setLoading] = useState(false);
  const [statsData, setStatsData] = useState<TStatsData[] | null>(null);
  const [openPortal, setOpenPortal] = useState(!!userData?.isPasswordTemporary);

  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    } else if (userData.type !== 'OPERATOR') {
      history.push('/account');
    }
  }, [isAuth, userData, history]);

  useEffect(() => {
    if (!userData) return;
    setLoading(true);
    RequestService.fetchStats({ id: userData.id, email: userData.email })
      .then(({ data }) => {
        setStatsData(data);
      })
      .catch((error) => {
        Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userData]);

  return (
    <>
      <Helmet>
        <title>Статистика - Туркластер Арктический</title>
      </Helmet>
      <section className='account'>
        <AlternativeHeader />
        <Aside navs={operatorNavs} mobileNavs={mobileOperatorNavs} unreadStatus={firstView} />
        <section className='Amain'>
          <div className='Amain__stats-wrapper'>
            {loading ? (
              <Loading />
            ) : statsData && statsData.length ? (
              statsData.map((item, index) => (
                <MainStats info={item.info} type={item.type} series={item.series} options={item.options} key={index} />
              ))
            ) : (
              <NoRequests
                title='Данных пока что нет. Опубликуйте ваши продукты на сайте, чтобы увидеть статистику!'
                type='OPERATOR'
              />
            )}
          </div>
          <MobileBottom />
        </section>
      </section>
      <Portal
        isOpen={openPortal}
        styles={{
          padding: '15px 15px 20px',
          maxWidth: '600px',
          boxShadow: '8px 8px 4px rgba(0, 0, 0, 0.25)',
          border: '1px solid #dedede',
        }}
      >
        <PopupInfo title={'Измените временный пароль'}>
          <ChangePassCard setOpenPortal={setOpenPortal} />
        </PopupInfo>
      </Portal>
    </>
  );
};

export default StatsPage;
