import { ReactComponent as DocIcon } from 'assets/images/trk/trk-doc.svg';
import {TDocFetch} from "../../../../shared/types/common.types";
import {APP_BASE_URL} from "../../../../shared/constants/const";

type TProps = {
  doc: TDocFetch;
};

/*
* doc.url doesn't work, so we use doc.path
* */

export const ParticipantInfosCard = ({ doc }: TProps) => {
  return (
    <li>
      <a href={`${APP_BASE_URL}/norilsk-imgs/${doc.path}`} target='_blank' className='participants-infos__list__row' rel='noreferrer'>
          <span className="participants-infos__list__row__icon">
              <DocIcon className='participants-infos__list__row__icon' />
          </span>
        <span className='npa__file_name'>{doc.caption}</span>
      </a>
    </li>
  );
};
