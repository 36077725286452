import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';

const itemsPriceTour = [
  { value: [0, 5000], label: 'до 5 000 ₽' },
  { value: [5000, 50000], label: '5 000 — 50 000 ₽' },
  { value: [50000, 200000], label: '50 000 — 200 000 ₽' },
  { value: [200000, 500000], label: '200 000 — 500 000 ₽' },
  { value: [500000, Infinity], label: '500 000 ₽ +' },
];

type TToursFiltersByPriceProps = {
  filters: number[];
  onClick: <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours[TName]) => void;
};

export const ToursFiltersByPrice = ({ filters, onClick }: TToursFiltersByPriceProps) => {
  const checkActivePrice = (value: number[]) => {
    return filters[0] === value[0] && filters[1] === value[1];
  };

  return (
    <ParamsFilterGroup title='Стоимость'>
      {itemsPriceTour.map((price) => (
        <ParamsFilterItem
          key={price.label}
          active={checkActivePrice(price.value)}
          onClick={() => onClick('price', price.value)}
          label={price.label}
        />
      ))}
    </ParamsFilterGroup>
  );
};
