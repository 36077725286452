import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectCities, selectCity, setActiveCity } from 'manageStore/places/placesSlice';
import TitleSection from 'components/TitleSection/TitleSection';
import preview from 'assets/images/tourist/preview.svg';
import { ReactComponent as HomeIcon } from 'assets/images/tourist/home.svg';
import { ReactComponent as FoodIcon } from 'assets/images/tourist/food.svg';
import { ReactComponent as MuseumIcon } from 'assets/images/tourist/museum.svg';
import { ChangeEvent, MouseEvent } from 'react';

type TCitySelectProps = {
  title: string;
};

type TTypeLocationBtnProps = {
  btnsHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  activeBtn: string;
  mobile?: boolean;
};

type TTouristProps = {
  btnsHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  activeBtn: string;
};

const CitySelect = ({ title }: TCitySelectProps) => {
  const dispatch = useDispatch();
  const cities = useSelector(selectCities);
  const activeCity = useSelector(selectCity);

  const handleActiveCity = (e: ChangeEvent<HTMLSelectElement>) => {
    const city = cities.find((item) => item.id === Number(e.target.value));
    if (city) {
      dispatch(setActiveCity(city));
    }
  };

  return (
    <div className='where-tourism'>
      {title}
      <select className='tourist__city-select' value={activeCity?.id} onChange={handleActiveCity}>
        {cities.length ? (
          cities.map((city) => (
            <option value={city.id} key={city.id}>
              {city.name}
            </option>
          ))
        ) : (
          <option value='Норильск'>Норильск</option>
        )}
      </select>
    </div>
  );
};

const TypeLocationBtn = ({ btnsHandler, activeBtn, mobile }: TTypeLocationBtnProps) => {
  return (
    <div className='tourist__btns'>
      <button
        className={classNames('tourist__btn', {
          active: activeBtn === 'lodging',
        })}
        data-name='lodging'
        onClick={btnsHandler}
      >
        <div className='tourist__btn_icon-wrapper'>
          <HomeIcon className='tourist__btn_icon home' />
        </div>
        {mobile ? 'Ночевать' : 'Остановиться'}
      </button>
      <button
        className={classNames('tourist__btn', {
          active: activeBtn === 'food',
        })}
        data-name='food'
        onClick={btnsHandler}
      >
        <div className='tourist__btn_icon-wrapper'>
          <FoodIcon className='tourist__btn_icon food' />
        </div>
        Поесть
      </button>
      <button
        className={classNames('tourist__btn', {
          active: activeBtn === 'attraction',
        })}
        data-name='attraction'
        onClick={btnsHandler}
      >
        <div className='tourist__btn_icon-wrapper'>
          <MuseumIcon className='tourist__btn_icon museum' />
        </div>
        {mobile ? 'Cмотреть' : 'Посмотреть'}
      </button>
    </div>
  );
};

const Tourist = ({ btnsHandler, activeBtn }: TTouristProps) => {
  return (
    <section className='tourist'>
      <div className='container'>
        <div className='tourist__inner ornament ornament_big'>
          <TitleSection title='Туризм легко' subtitle='Интересное' revert blue />
          <CitySelect title='Где путешествуем Город:' />
          <div className='tourist__box'>
            <div className='tourist__content'>
              <div className='tourist__img-wrapper'>
                <div className='tourist__img'>
                  <img src={preview} alt='' />
                </div>
              </div>
              <p className='tourist__text text'>
                Таймыр – место, которое изменит ваш взгляд на Север. Суровые морозные дни будут согреты посещением
                множества достопримечательностей. Именно в морозные ясные дни большая вероятность увидеть невероятной
                красоты полярное сияние. Летом и осенью вы сможете насладиться красотой природы, устроить культурный
                отдых и не пожалеете, если попробуете Таймырские деликатесы. Северная кухня не оставит никого
                равнодушным.
              </p>
              <TypeLocationBtn btnsHandler={btnsHandler} activeBtn={activeBtn} />
            </div>
          </div>
        </div>
      </div>
      <div className='tourist-mobile-menu'>
        <CitySelect title='Где ищем:' />
        <TypeLocationBtn btnsHandler={btnsHandler} activeBtn={activeBtn} mobile />
      </div>
    </section>
  );
};

Tourist.propTypes = {
  activeBtn: PropTypes.string,
  btnsHandler: PropTypes.func,
};

export default Tourist;
