import Hammer from 'react-hammerjs';
import React, {useState} from 'react';
import Img1 from 'assets/images/trk/participants/2.jpeg';
import Img3 from 'assets/images/trk/participants/3.jpeg';
import Img2 from 'assets/images/trk/participants/1.jpeg';
import Img4 from 'assets/images/trk/participants/4.jpeg';
import Img5 from 'assets/images/trk/participants/5.jpeg';
import { useMediaQuery } from '../../../../shared/hooks/useMatchMedia';

export const NewsGalleryOnMobile = () => {
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);
  const [imgList, setImgList ] = useState([Img3, Img1, Img2, Img4, Img5]) ;


  const handleSwipeUp = () => {
    let newArray = [...imgList]
    newArray.push(newArray.shift())
    setImgList(newArray)
  };

  const handleSwipeDown = () => {
    let newArray = [...imgList]
    newArray.unshift(newArray.pop())
    setImgList(newArray)
  };

  return (
    <Hammer
      options={{ recognizers: { swipe: { enable: true } } }}
      direction='DIRECTION_ALL'
      onSwipeUp={handleSwipeUp}
      onSwipeDown={handleSwipeDown}
    >
      {isMobile ? (
        <div className='cluster-member-gallery__frist'>
          <div className='cluster-member-gallery__imag cluster-member-gallery__imag__1'>
            <img src={imgList[1]} alt='img1' />
          </div>
          <div className='cluster-member-gallery__imag cluster-member-gallery__imag__2'>
            <img src={imgList[2]} alt='img2' />
          </div>
          <div className='cluster-member-gallery__imag cluster-member-gallery__imag__hammer-active-img'>
            <img src={imgList[3]}  alt='img2' />
          </div>
        </div>
      ) : (
        <div className='cluster-member-gallery__frist'>
          <div className='cluster-member-gallery__imag cluster-member-gallery__imag__1'>
            <img src={Img3} alt='img1' />
          </div>
          <div className='cluster-member-gallery__imag cluster-member-gallery__imag__2'>
            <img src={Img1} alt='img1' />
          </div>
          <div className='cluster-member-gallery__imag cluster-member-gallery__imag__hammer-active-img'>
            <img src={Img2} alt='img2' />
          </div>
        </div>
      )}
    </Hammer>
  );
};
