import * as React from 'react';
import {
  Datagrid,
  List,
  TextField,
  Pagination,
} from 'react-admin';
import { MENU_ADMIN_LIST } from '../../../shared/constants/const';

const TrKMemberList = () => (
  <List
    title={MENU_ADMIN_LIST.members}
    exporter={false}
    hasCreate={false}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField
        source="email"
        sortable={false}
        label={'email'}
      />
      <TextField
          source="name"
          sortable={false}
          label={'name'}
      />

      <TextField
        source="message"
        sortable={false}
        label={'message'}
      />
    </Datagrid>
  </List>
);

export default TrKMemberList;
