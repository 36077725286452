import {TravelGuideCard} from "../../travelGuide/TravelGuideCard/TravelGuideCard";
import {TTravelGuide} from "../../../shared/types/travelGuide.types";
export type TGuideBlockProps = {
    travelGuides :TTravelGuide[]
}

export const GuideBlock = ({travelGuides}:TGuideBlockProps) => {
    return (
        <div className='guideBlock' id='guideBlog'>
            <h4>Путеводитель</h4>
            <div className='travel__list-wrapper  travellistblog '>
                <div className='travel-list'>
                    {travelGuides.map((item) => (
                        <TravelGuideCard key={item.id} travelGuide={item}  />
                    ))}
                </div>
            </div>
        </div>
    );
};
