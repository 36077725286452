import { Layout } from 'react-admin';
import AdminMenu from './AdminMenu';
import AdminHeader from './AdminHeader';
import { useEffect, useRef } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { useSidebarState } from 'react-admin';

const AdminLayout = (props: any) => {
  const isOpen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const setOpen = useSidebarState()[1];
  const isFirstRender = useRef(false);
  useEffect(() => {
    setOpen(false);
    if (isFirstRender.current && isOpen) {
      setOpen(isOpen);
    }
    isFirstRender.current = true;
  }, [isOpen, setOpen]);
  return (
    <Layout
      sx={{
        '& .RaLayout-appFrame': {
          margin: '45px 0',
        },
      }}
      {...props}
      appBar={AdminHeader}
      menu={AdminMenu}
    />
  );
};

export default AdminLayout;
