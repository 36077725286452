import React from 'react'
import { Create } from 'react-admin'
import AttractionForm from './AttractionForm'

const AttractionCreate = () => (
  <Create>
    <AttractionForm />
  </Create>
)

export default AttractionCreate
