import { TRANSFORM_TEXT } from 'shared/constants/const';
import { TRANSFORM_LOCATIONS } from 'shared/constants/locations.constants';
import {TFilterLocations, TLocation} from 'shared/types/location.types';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { BaseCheckbox } from 'shared/ui/BaseCheckbox/BaseCheckbox';
import { useEffect, useRef, useState } from 'react';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import classNames from 'classnames';
import { FiltersManageButtons } from 'features/shared';
import { LocationIcon } from '../../../widgets/lib/tourDesignerMain/LocationIcon/LocationIcon';
import {useDispatch, useSelector} from "react-redux";
import {setFilteredLocations} from "../../../manageStore/designer/designer.slice";
import {selectLocations} from "../../../manageStore/designer/designer.select";
type TField = keyof TFilterLocations;

const itemsTypeLocation = [
  {
    id: 'city',
    value: TRANSFORM_LOCATIONS.active.city,
    label: 'Городские объекты',
    icon: <LocationIcon icon='CITY' className='designer-map-legend__icon' />,
  },
  {
    id: 'industry',
    value: TRANSFORM_LOCATIONS.active.industry,
    label: 'Промышленные объекты',
    icon: <LocationIcon icon='DEVELOP' className='designer-map-legend__icon' />,
  },
  {
    id: 'jeeps',
    value: TRANSFORM_LOCATIONS.active.jeeps,
    label: 'Локации для джипов',
    icon: <LocationIcon icon='SPECIAL_TRANSFER' className='designer-map-legend__icon' />,
  },
  {
    id: 'expedition',
    value: TRANSFORM_LOCATIONS.active.expedition,
    label: TRANSFORM_LOCATIONS.active.expedition,
    icon: <LocationIcon icon='EXPEDITION' className='designer-map-legend__icon' />,
  },
  {
    id: 'northernLights',
    value: TRANSFORM_LOCATIONS.active.northernLights,
    label: 'Северное сияние',
    icon: <LocationIcon icon='NONE' className=' point_count_icon' />,
  },
  {
    id: 'birdwatching',
    value: TRANSFORM_LOCATIONS.active.birdwatching,
    label: TRANSFORM_LOCATIONS.active.birdwatching,
    icon: <LocationIcon icon='BIRDWATCHING' className='designer-map-legend__icon' />,
  },
  {
    id: 'quadBikes',
    value: TRANSFORM_LOCATIONS.active.quadBikes,
    label: 'Локации для квадроциклов',
    icon: <LocationIcon icon='QUAD_BIKE' className='designer-map-legend__icon' />,
  },
  {
    id: 'sapsurfing',
    value: TRANSFORM_LOCATIONS.active.sapsurfing,
    label: 'Водные локации',
    icon: <LocationIcon icon='WATER' className='designer-map-legend__icon' />,
  },
  {
    id: 'photo',
    value: TRANSFORM_LOCATIONS.active.photo,
    label: 'Фотозоны',
    icon: <LocationIcon icon='PHOTO' className='designer-map-legend__icon' />,
  },
  {
    id: 'mountaineering',
    value: TRANSFORM_LOCATIONS.active.mountaineering,
    label: TRANSFORM_LOCATIONS.active.mountaineering,
    icon: <LocationIcon icon='MOUNT' className='designer-map-legend__icon' />,
  },
];
const itemsTerritoryLocation = [
  {
    value: TRANSFORM_LOCATIONS.territory.PLATO_PUTORANA.value,
    label: TRANSFORM_LOCATIONS.territory.PLATO_PUTORANA.label,
  },
  {
    value: TRANSFORM_LOCATIONS.territory.HATANG_ZALIV.value,
    label: TRANSFORM_LOCATIONS.territory.HATANG_ZALIV.label,
  },
  {
    value: TRANSFORM_LOCATIONS.territory.ENISEY_ZALIV.value,
    label: TRANSFORM_LOCATIONS.territory.ENISEY_ZALIV.label,
  },
  {
    value: TRANSFORM_LOCATIONS.territory.NORILSK.value,
    label: TRANSFORM_LOCATIONS.territory.NORILSK.label,
  },
];
const itemsTransportLocation = [
  {
    value: TRANSFORM_LOCATIONS.transport.OFFROAD.value,
    label: TRANSFORM_LOCATIONS.transport.OFFROAD.label,
  },
  {
    value: TRANSFORM_LOCATIONS.transport.HELICOPTER.value,
    label: TRANSFORM_LOCATIONS.transport.HELICOPTER.label,
  },
  {
    value: TRANSFORM_LOCATIONS.transport.HIKING.value,
    label: TRANSFORM_LOCATIONS.transport.HIKING.label,
  },
  {
    value: TRANSFORM_LOCATIONS.transport.AUTO.value,
    label: TRANSFORM_LOCATIONS.transport.AUTO.label,
  },
  {
    value: TRANSFORM_LOCATIONS.transport.WATER.value,
    label: TRANSFORM_LOCATIONS.transport.WATER.label,
  },
];
const itemsSeasonTour = [
  {
    id: 'summer',
    value: TRANSFORM_TEXT.season.SUMMER,
  },
  {
    id: 'autumn',
    value: TRANSFORM_TEXT.season.AUTUMN,
  },
  {
    id: 'winter',
    value: TRANSFORM_TEXT.season.WINTER,
  },
  {
    id: 'spring',
    value: TRANSFORM_TEXT.season.SPRING,
  },
];

type TTourDesignerFiltersProps = {
  filters: TFilterLocations;
  onChange: (field: TField, value: string | TFilterLocations) => void;
  clearFilters: () => void;
  onClose: () => void;
  showClear: boolean;
};

export const TourDesignerFilters = ({
  filters,
  onClose,
  onChange,
  clearFilters,
  showClear,
}: TTourDesignerFiltersProps) => {
  const dispath = useDispatch()
  const listRef = useRef<HTMLDivElement>(null);
  const isOnscreen = useOnScreen(listRef);
  const locations = useSelector(selectLocations);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const [localFilters, setLocalFilters] = useState<TFilterLocations>({
    season: [],
    types: [],
    territory: [],
    transport: [],
  });

  const handleChange = (field: TField, value: string) => {
    if (isMobile) {
      const isChecked = localFilters[field].includes(value);
      setLocalFilters((prev) =>
        isChecked
          ? { ...prev, [field]: [...prev[field].filter((item) => item !== value)] }
          : { ...prev, [field]: [...prev[field], value] }
      );
    } else {
      onChange(field, value);
    }
  };

  const handleClearFilters = () => {
    setLocalFilters({
      season: [],
      types: [],
      territory: [],
      transport: [],
    });
  };

  const handleApplyFilters = () => {
    onChange('season', localFilters);
    onClose();
  };

  useEffect(() => {
      dispath(setFilteredLocations({
        filter: localFilters,
        locations: locations
      }))
  }, [localFilters]);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <div className='designer-filters'>
      <div className={classNames('designer-filters__header', { fixed: !isOnscreen && isMobile })}>
        <CloseIcon className='designer-filters__close' onClick={onClose} />
        <h3 className='designer-filters__title'>{isMobile ? 'Фильтр' : 'Фильтр объектов'}</h3>
      </div>
      {showClear && (
        <div className='designer-filters__clear'>
          <span onClick={clearFilters}>Очистить фильтр</span>
        </div>
      )}
      <div className='designer-filters__list'>
        <div style={{ position: 'absolute' }} ref={listRef}></div>
        <div className='designer-filters-group'>
          <p className='designer-filters-group__title'>Локации</p>
          <ul className='designer-filters-group__list'>
            {itemsTypeLocation.map((item) => (
              <li
                className='designer-filters-group__item'
                key={item.label}
                onClick={() => handleChange('types', item.value)}
              >
                {isMobile && <span className='designer-filters-group__icon icon-m-sm'>{item.icon}</span>}

                <BaseCheckbox
                  label={item.label}
                  isActive={localFilters.types.includes(item.value)}
                  isDark={!isMobile}
                  isReverse={isMobile}
                  isSpaceBetween={isMobile}
                  isMobile={isMobile}
                />
                {!isMobile && <span className='designer-filters-group__icon'>{item.icon}</span>}
              </li>
            ))}
          </ul>
        </div>
        <div className='designer-filters-group'>
          <p className='designer-filters-group__title'>Кластеры</p>
          <ul className='designer-filters-group__list'>
            {itemsTerritoryLocation.map((item) => (
              <li
                className='designer-filters-group__item'
                key={item.label}
                onClick={() => handleChange('territory', item.value)}
              >
                <BaseCheckbox
                  label={item.label}
                  isActive={localFilters.territory.includes(item.value)}
                  isDark={!isMobile}
                  isReverse={isMobile}
                  isSpaceBetween={isMobile}
                  isMobile={isMobile}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className='designer-filters-group'>
          <p className='designer-filters-group__title'>Сезон</p>
          <ul className='designer-filters-group__list row'>
            {itemsSeasonTour.map((item) => (
              <li
                className='designer-filters-group__item'
                key={item.value}
                onClick={() => handleChange('season', item.value)}
              >
                <BaseCheckbox
                  label={item.value}
                  isActive={localFilters.season.includes(item.value)}
                  isDark={!isMobile}
                  isMobile={isMobile}
                  isButton={isMobile}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className='designer-filters-group'>
          <p className='designer-filters-group__title'>Транспорт</p>
          <ul className={classNames('designer-filters-group__list', { row: isMobile })}>
            {itemsTransportLocation.map((item) => (
              <li
                className='designer-filters-group__item'
                key={item.label}
                onClick={() => handleChange('transport', item.value)}
              >
                <BaseCheckbox
                  label={item.label}
                  isActive={localFilters.transport.includes(item.value)}
                  isDark={!isMobile}
                  isMobile={isMobile}
                  isButton={isMobile}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isMobile && (
        <FiltersManageButtons
          clearFilters={handleClearFilters}
          applyFilters={handleApplyFilters}
          isActiveClearButton={Object.values(localFilters).some((filter) => filter.length)}
        />
      )}
    </div>
  );
};
