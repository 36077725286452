import { useState } from 'react';
import Filesharing from '../Filesharing';
import { ChatHeader } from './ChatHeader';
import { selectCurrentRequest } from 'manageStore/request/request.select';
import { MessagesList } from './MessagesList';
import { MessageForm } from './MessageForm';
import { ReviewForm } from '../Reviews/ReviewForm';
import { selectEdittedReview } from 'manageStore/reviews/reviews.select';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { reviewsActions } from 'manageStore/reviews/reviews.slice';
import { newReviewThunk } from 'manageStore/reviews/reviews.thunk';
import { TReviewFetchRequest } from 'shared/types/review.types';

const MainChat = () => {
  const dispatch = useAppDispatch();

  const { setEdittedReview } = reviewsActions;

  const request = selectCurrentRequest();
  const editedReview = selectEdittedReview();

  const [isOpenFileSharing, setOpenFileSharing] = useState(false);

  const toggleFilesharing = () => {
    setOpenFileSharing((prev) => !prev);
  };

  const cancelCreateReview = () => {
    dispatch(setEdittedReview(null));
  };

  const sendReview = async (newReview: TReviewFetchRequest) => {
    if (!request?.tour.id) return;
    await dispatch(newReviewThunk({ ...newReview, tourId: request.tour.id }));
  };

  // const Notify = ({ children }) => <p className='chat-body__notify'>{children}</p>

  return (
    <section className='AMain-chat-wrapper'>
      <div className='chat'>
        <ChatHeader toggleFilesharing={toggleFilesharing} />
        {editedReview ? (
          <ReviewForm handleSubmit={sendReview} closeForm={cancelCreateReview} isNewReview />
        ) : (
          <>
            <div className='chat-body'>
              <MessagesList />
              <div className='chat-filesharing__toggle' onClick={toggleFilesharing}></div>
            </div>
            <MessageForm />
            <Filesharing id={request?.id} toggleFilesharing={toggleFilesharing} isOpen={isOpenFileSharing} />
          </>
        )}
      </div>
    </section>
  );
};

export default MainChat;
