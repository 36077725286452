import Button from '../../../shared/ui/button';
import { object, ref, string } from 'yup';
import { Form, Formik } from 'formik';
import s from './index.module.scss';
import CustomField from '../../../entities/custom_field';
import Notification from '../../../../shared/lib/notification';
import UserService from '../../../../services/user.service';
import { selectUserData, setUserData } from '../../../../manageStore/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
type profileFormValues = {
  oldPass: string;
  newPass: string;
  newPass2: string;
};

export default function ChangePassword() {
  const formInitialValues = {
    oldPass: '',
    newPass: '',
    newPass2: '',
  };
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);

  const [saving, setSaving] = useState(false);

  const userSchema = object({
    oldPass: string().required('Обязательное поле'),
    newPass: string()
      .required('Обязательное поле')
      .min(5, 'Пароль должен содержать минимум 5 символов')
      .test('no-spaces', 'Пароль не должен содержать пробелы', (value) => {
        if (value) {
          return !/\s/.test(value);
        }
        return false; // возвращаем явно false, чтобы избежать типа undefined
      })
      .test('contains-digit', 'Пароль должен содержать хотя бы одну цифру', (value) => {
        if (value) {
          return /\d/.test(value);
        }
        return false;
      })
      .test('contains-uppercase', 'Пароль должен содержать хотя бы одну заглавную букву', (value) => {
        if (value) {
          return /[A-ZА-ЯЁ]/.test(value);
        }
        return false;
      }),
    newPass2: string()
      .oneOf([ref('newPass'), null], 'Пароли должны совпадать')
      .required('Обязательное поле'),
  });

  const onSubmit = async (v: profileFormValues) => {
    if (!user) return;
    setSaving(true);
    try {
      const { oldPass, newPass, newPass2 } = v;
      const { data } = await Notification.promise(UserService.updatePassword(user.id, oldPass, newPass, newPass2));
      localStorage.setItem('token', data.accessToken);
      dispatch(setUserData(data.user));
    } catch (e) {
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={userSchema}
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        {({ handleChange, errors, touched }) => (
          <>
            <Form>
              <CustomField
                className={s.input}
                placeholder='Старый пароль'
                label={'Текущий пароль'}
                required
                type='password'
                error={errors.oldPass}
                hasError={errors.oldPass && touched.oldPass}
                name={'oldPass'}
                onChange={(e) => handleChange(e)}
                asFormikField
              />
              <CustomField
                className={s.input}
                placeholder='Новый пароль'
                label={'Новый пароль'}
                required
                type='password'
                error={errors.newPass}
                hasError={errors.newPass && touched.newPass}
                name={'newPass'}
                onChange={(e) => handleChange(e)}
                asFormikField
              />
              <CustomField
                className={s.input}
                placeholder='Подтвердите пароль'
                label={'Подтвердите пароль'}
                required
                error={errors.newPass2}
                type='password'
                hasError={errors.newPass2 && touched.newPass2}
                name={'newPass2'}
                onChange={(e) => handleChange(e)}
                asFormikField
              />
              <Button type='submit' intent={'dark'}>
                Сменить
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
