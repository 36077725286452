import * as React from 'react';
import parse from 'html-react-parser';
import { Show, SimpleShowLayout, TextField, FunctionField, ReferenceField, Labeled } from 'react-admin';
import CoordsShow from '../CoordsShow';
import { LODGING_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const LodgingShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={LODGING_FIELDS.title} />
        <CoordsShow />
        <FunctionField
          label={LODGING_FIELDS.description}
          render={(record) => {
            return <div>{parse(record?.description || '')}</div>;
          }}
        />
        <TextField source='time' label={LODGING_FIELDS.time} />
        <TextField source='phone' label={LODGING_FIELDS.phone} />
        <TextField source='site' label={LODGING_FIELDS.site} />
        <FunctionField
          label={LODGING_FIELDS.preview}
          render={(record) => {
            return record.preview ? (
              <img
                className='admin-image admin-image__single'
                src={`${URL_FOR_IMG}/${record.preview.path}`}
                alt='preview'
              />
            ) : null;
          }}
        />
        <Labeled label={LODGING_FIELDS.city}>
          <ReferenceField source='city' reference='city'>
            <TextField source='name' />
          </ReferenceField>
        </Labeled>
      </SimpleShowLayout>
    </Show>
  </div>
);

export default LodgingShow;
