import classNames from 'classnames';
import {
  TCategoryTravelGuide,
  TFiltersTravelsGuide,
  TNamesTypeTravelsGuideFilter,
} from 'shared/types/travelGuide.types';

type TItemFilters = {
  id: string;
  label: string;
  value: TCategoryTravelGuide | '';
};

type TTravelGuideButtonsTypeProps = {
  filters: TFiltersTravelsGuide;
  clickFilter: (filter: TCategoryTravelGuide, fieldFilters: TNamesTypeTravelsGuideFilter) => void;
  itemsFilters: TItemFilters[];
};

export const TravelGuideButtonsType = ({ filters, clickFilter, itemsFilters }: TTravelGuideButtonsTypeProps) => {
  const checkActiveFilter = (filter: string) => {
    return filter ? filter === filters.type : filters.type === null;
  };

  return (
    <div className='travels-guide-filters__types-buttons'>
      {itemsFilters.map((filter) => (
        <button
          className={classNames('travels-guide-filters__type', {
            'travels-guide-filters__type_active': checkActiveFilter(filter.value),
          })}
          onClick={() => clickFilter(filter.value as TCategoryTravelGuide, 'type')}
          type='button'
          key={filter.id}
        >
          <div className='travels-guide-filters__type__name'>{filter.label}</div>
        </button>
      ))}
    </div>
  );
};
