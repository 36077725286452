import { BaseAccordion } from 'shared/ui/BaseAccordion/BaseAccordion';

type TFaqTourProps = {
  included: string | null;
  notIncluded: string | null;
  additionalServices: string | null;
};

export const TourItemFaq = ({ included, notIncluded, additionalServices }: TFaqTourProps) => {
  return (
    <div className='faq-list'>
      {!!(included || notIncluded) && (
        <BaseAccordion question='Включено и не включено в программу'>
          <>
            {!!included && included.split('\n').map((servise, index) => <p key={index}>{servise}</p>)}
            {!!notIncluded &&
              notIncluded.split('\n').map((servise, index) => (
                <p key={index} style={{ textDecoration: 'line-through' }}>
                  {servise}
                </p>
              ))}
          </>
        </BaseAccordion>
      )}
      {!!additionalServices && (
        <BaseAccordion question='Дополнительные услуги'>
          <>
            {additionalServices.split('\n').map((servise, index) => (
              <p key={index}>{servise}</p>
            ))}
          </>
        </BaseAccordion>
      )}
    </div>
  );
};
