import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  ShowButton,
  EditButton,
  ChipField,
  TopToolbar,
  CreateButton,
  useListContext,
} from 'react-admin';
import { ButtonGroup, Button } from '@mui/material';
import { MENU_ADMIN_LIST, TRANSFORM_TEXT, USERS_FIELDS } from '../../../shared/constants/const';

const BTN_TEXT = {
  admin: 'Администраторы',
  operator: 'Операторы',
  tourist: 'Туристы',
};

const ListActions = () => {
  const { setFilters, filterValues } = useListContext();
  const [activeBtn, setActiveBtn] = useState(filterValues?.role ? filterValues.role : 'admin');
  const handleClickToolbar = (role) => {
    if (activeBtn === role) return;
    setActiveBtn(role);
    setFilters({ role });
  };

  const BtnToolbar = ({ role }) => {
    return (
      <Button variant={activeBtn === role ? 'contained' : 'outlined'} onClick={() => handleClickToolbar(role)}>
        {BTN_TEXT[role]}
      </Button>
    );
  };

  return (
    <TopToolbar sx={{ width: '100%' }}>
      <ButtonGroup size='small' aria-label='small button group' sx={{ marginRight: 'auto' }}>
        {Object.keys(BTN_TEXT).map((role) => (
          <BtnToolbar role={role} key={role} />
        ))}
      </ButtonGroup>
      <CreateButton />
    </TopToolbar>
  );
};

const UsersList = () => {
  const userData = useSelector((state) => state.user.userData);
  return (
    <List
      title={MENU_ADMIN_LIST.administration}
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[]} />}
      actions={<ListActions />}
    >
      <Datagrid
        bulkActionButtons={userData.role === TRANSFORM_TEXT.role.superAdmin}
        sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}
      >
        <TextField source='login' label={USERS_FIELDS.login} />
        <ChipField source='role' label={USERS_FIELDS.role} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UsersList;
