import { useState } from 'react';
import classNames from 'classnames';
import ScrollTrigger from 'react-scroll-trigger';

type TTitleSectionProps = {
  title: string;
  subtitle: string;
  additionalText?: string;
  revert?: boolean;
  blue?: boolean;
};

const TitleSection = ({ title, subtitle, additionalText, revert, blue }: TTitleSectionProps) => {
  const [visible, setVisible] = useState(false);
  const handleVisibility = () => {
    setVisible(!visible);
    return {};
  };

  return (
    <ScrollTrigger onEnter={handleVisibility} onExit={handleVisibility}>
      <div className={classNames('section__top top', { revert })}>
        <div className={classNames('subtitle', { 'subTitle-anim': visible })}></div>
        <div className='section__top-subtitle'>
          <h3 className={classNames('section__top-subtitle__head', { revert })}>{subtitle}</h3>
          {additionalText ? <span className='section__top-subtitle__text'>{additionalText}</span> : null}
        </div>
        <div className={classNames('top-content', { 'title-anim': visible, revert })}>
          <div className='top-line'></div>
          <h2 className={classNames('section__top-title title', { blue, revert })}>{title}</h2>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default TitleSection;
