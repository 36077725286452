import parse from 'html-react-parser';
import { Show, SimpleShowLayout, TextField, FunctionField, ReferenceField } from 'react-admin';
import { BEAUTY_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const BeautyShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={BEAUTY_FIELDS.name} />
        <TextField source='title' label={BEAUTY_FIELDS.title} />
        <TextField source='subtitle' label={BEAUTY_FIELDS.subtitle} />
        <TextField source='desc' label={BEAUTY_FIELDS.shortDesc} />
        <FunctionField
          label={BEAUTY_FIELDS.content}
          render={(record) => {
            return <div>{parse(record?.content || '')}</div>;
          }}
        />
        <TextField source='video' label={BEAUTY_FIELDS.video} />
        <ReferenceField source='location.id' reference='locations' label={BEAUTY_FIELDS.location}>
          <TextField source='name' label={false} />
        </ReferenceField>
        <FunctionField
          label={BEAUTY_FIELDS.preview}
          render={(record) => {
            return record.preview ? (
              <img
                className='admin-image admin-image__single'
                src={`${URL_FOR_IMG}/${record.preview.path}`}
                alt='preview'
              />
            ) : null;
          }}
        />
        <FunctionField
          label={BEAUTY_FIELDS.gallery}
          render={(record) => {
            return record.uploadedFiles?.length
              ? record.uploadedFiles.map((galery) => (
                <img
                  key={galery.id}
                  className='admin-image admin-image__multi'
                  src={`${URL_FOR_IMG}/${galery.path}`}
                  alt='galery'
                />
              ))
              : null;
          }}
        />
      </SimpleShowLayout>
    </Show>
  </div>
);

export default BeautyShow;
