import { cva } from 'class-variance-authority';
import React, { useEffect, useState } from 'react';
import { convertDate } from '../../../shared/utils/convert-date';
import { ReactComponent as PayIcon } from '../assets/pay.svg';
import IconText from '../../../entities/icon-text';
import { Link } from 'react-router-dom';
import s from './index.module.scss';
import InfoApplication from '../info-application';
import { TRequestFetch, TStatusRequest } from '../../../../shared/types/request.types';
import SettingsPopper from 'newProject/features/settings-popper';
import Portal from 'newProject/features/portal/ui';
import DeleteReqPopup from '../../../widgets/application/delete-popup/index';
import { TUser } from 'shared/types/types';

type Props = {
  application: TRequestFetch;
  isDetail?: boolean;
  onDelete: () => void
  userData: TUser | null;
};

const cvaApplication = cva([s.application]);
const cvaHead = cva([s.head]);
const cvaFooter = cva([s.footer]);
const cvaLastUpdate = cva([s.lastUpdate]);

export default function ApplicationPreview({ application, userData, onDelete }: Props) {
  const [isDeleted, setIsDeleted] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    if(isDeleted) {
      onDelete()
    }
  }, [isDeleted])

  const lib: Record<TStatusRequest, boolean> = {
    "PROCESSING": true,
    "DONE": true,
    "DELETED": false,
    "DENIED": false,
    "NEW": false,
  }
  
  return (
    <>
      {isDeleted ? null : (
        <div className={cvaApplication()}>
          <div className={cvaHead()}>
            {application.id ? <Link to={`/account/tourist/requests/${application.id}`}>
              {application.tour.name ?? 'Запрос на свой маршрут'}
            </Link> : <div>
              {application.tour.name ?? 'Запрос на свой маршрут'}
            </div>}
            <SettingsPopper>
              {application.tour.id && <Link to={`/tours/${application.tour.id}`}>Открыть тур</Link>}
              {application.status !== 'DELETED' && application.status !== 'DENIED' && (
                <button onClick={() => setOpenDelete(true)}>Отменить запрос</button>
              )}
              {application.tour.id && lib[application.status] && <Link to={`/account/tourist/tour/${application.tour.id}/create-review`}>Оставить отзыв</Link>}
            </SettingsPopper>
          </div>
          <InfoApplication
            price={application.tour.price}
            duration={application.tour.duration}
            groupSize={application.tour.groupSize}
            dateStart={application.dateStart}
          />
          <div className={cvaFooter()}>
            <IconText icon={<PayIcon />} color={application.isPaid ? 'green' : 'red'}>
              <span>{!application.isPaid && 'не '}оплачена</span>
            </IconText>
            <div className={cvaLastUpdate()}>{convertDate(application.created_date_request, { withTime: true })}</div>
          </div>
        </div>
      )}
      {openDelete && (
        <Portal>
          <DeleteReqPopup
            onDelete={() => {
              setIsDeleted(true)
            }}
            id={application.id}
            onClose={() => setOpenDelete(false)}
          />
        </Portal>
      )}
    </>
  );
}
