import { useCallback, useState } from 'react';




type TGenerator<T> = ()=>T;
type TValueOrGenerator<T> = T | TGenerator<T>;

export const useBoolState = (initialValue: TValueOrGenerator<boolean>) => {
  const [value,setValue] = useState(initialValue);
  const setTrue = useCallback(()=>setValue(true), []);
  const setFalse = useCallback(()=>setValue(false), []);
  const toggleValue = useCallback(()=>setValue(!value), [value]);
  return [value, setTrue, setFalse, toggleValue, setValue] as const;
}