import classNames from 'classnames';
import { TagEntity } from 'shared/lib/TagEntity/TagEntity';
import { useHistory } from 'react-router-dom';
import { TRANSFORM_NEWS } from '../../../shared/constants/news.constants';
import { TCategoryNews } from '../../../shared/types/news.types';

type TNewsItemIntroProps = {
  id: number;
  title: string;
  tag?: TCategoryNews;
  backImage: string;
  className: TCategoryNews;
  isBlog?: boolean;
};

export const NewsItemIntro = ({ id, title, tag, backImage, className, isBlog = false }: TNewsItemIntroProps) => {
  const history = useHistory();

  const category = tag === 'важно' ? TRANSFORM_NEWS.tags.important : undefined;

  const seeMore = () => {
    if(isBlog){
      history.push(`/blog/${id}`);
    } else{
      history.push(`/news/${id}`);
    }

  };

  return (
    <section className={`news-item__intro ${className}`}>
      <div className='container news-item__container'>
        {category && <TagEntity className='news-item__type location-tag' tag={category.label} isRed />}
        <div
          className={classNames('news-item__image', {
            important: tag === 'важно',
          })}
          style={{ backgroundImage: `url(${backImage})` }}
        />
        <h1 className='news-item__title'>{title}</h1>

        <div className='news-item__intro__footer'>
          <button type='button' className='news-item__image__btn' onClick={seeMore}>
            Узнать больше
          </button>
        </div>

        <div className='news-item__intro__slider'></div>
      </div>
    </section>
  );
};
