export const TRANSFORM_NEWS = {
  tags: {
    important: {
      label: 'важно',
      value: 'важно',
    },
    home: {
      label: 'дома',
      value: 'дома',
    },
    food: {
      label: 'еда',
      value: 'еда',
    },
    people: {
      label: 'люди',
      value: 'люди',
    },
    localResidents: {
      label: 'местные жители',
      value: 'местные жители',
    },
    trees: {
      label: 'деревья',
      value: 'деревья',
    },
    lakes: {
      label: 'озера',
      value: 'озера',
    },
    snow: {
      label: 'снег',
      value: 'снег',
    },
  },
  theme: {
    industry: {
      label: 'Промышленность',
      value: 'Промышленность',
    },
    gastronomy: {
      label: 'Гастрономия',
      value: 'Гастрономия',
    },
    attractions: {
      label: 'Достопримечательность',
      value: 'Достопримечательность',
    },
    history: {
      label: 'История',
      value: 'История',
    },
    ethnicity: {
      label: 'Этника',
      value: 'Этника',
    },
    entertainment: {
      label: 'Развлечение',
      value: 'Развлечение',
    },
    mountains: {
      label: 'Горы',
      value: 'Горы',
    },
    water: {
      label: 'Водная стихия',
      value: 'Водная стихия',
    },
    touristArea: {
      label: 'Туристическая зона',
      value: 'Туристическая зона',
    },
  },
} as const;
