import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

interface IConvertDateParams {
  withTime?: boolean,
  onlyTime?: boolean,
  withSeconds?: boolean,
  withComma?: boolean,
  isLong?: boolean,
}

export const convertDate = (date: string | Date, params?: IConvertDateParams): string => {
  const isParam = (returnValue: string, isTrue?: boolean,) => isTrue ? returnValue : ''
  if(!date) {
    return ''
  }
  if(params?.isLong) {
    return format(new Date(date), "dd MMMM yyyy г.", {locale: ru})
  }
  if(params?.onlyTime) {
    return format(new Date(date), 'HH:mm', {locale: ru})
  }
  const stringDate = "dd.MM.yyyy" + 
    isParam(',', params?.withComma) +
    isParam(' HH:mm', params?.withTime) + 
    isParam(':ss', params?.withSeconds)
  return format(new Date(date), stringDate, {locale: ru})
}