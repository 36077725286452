import { useDispatch, useSelector } from 'react-redux';
import {
  selectPartnersListToLeft,
  selectPartnersListToRight,
  selectTapeParticipants,
} from '../../../../manageStore/common/common.slice';
import { useEffect, useState } from 'react';
import { fetchTapeParticipants } from '../../../../manageStore/common/common.thunk';

/*
const partnersListToLeft = [
  'АНО "Туристический радиоканал Таймыра"',
  'МБУ Кинокомплекс "Родина"',
  'ООО "Нимбус"',
  'ИП Ковалева Т.В.',
  'ООО "Лама тур"',
  'ИП Яковлев А.В.',
  'ИП Сербин С.В.',
  'ИП Штарк Д.В.',
  'ИП Долганов Д.В.',
  'ИП Баутина И.В.',
  'ООО "Полар Вэй"',
  'ООО "Апекс"',
  'ИП Редковская Л.И.',
  'Красноярская общественная организация "Клуб исследователей Таймыра"',
];
const partnersListToRight = [
  'ИП Карелов В.В.',
  'ИП Сивкова О.В.',
  'Булавинец Д.В.',
  'ИП Давудов Ф.Ш.',
  'ИП Зейкан П.П.',
  'НМОО СФФМ "Кроссфит"',
  'ИП Болтачев А.Н.',
  'ООО "Норильск Вояж"',
  'ИП Каушан Л.В.',
  'МАУ "АЦК"',
  'ИП Ядне М.А.',
  'ИП Никитич М.Н.',
  'ИП Шахрай Т.В.',
  'ООО "ПутораныТур"',
  'ООО "Панарктики Стар"',
];
*/

interface IPartnerEntity {
  partner: string;
}

const PartnerEntity = ({ partner }: IPartnerEntity) => {
  return (
    <div className='banner__row'>
      <p>{partner}</p>
      <div className='banner__start'></div>
    </div>
  );
};

export const NewsBanner = () => {
  const dispatch = useDispatch();
  const tape_participants = useSelector(selectTapeParticipants);
  const partnersListToLeft = useSelector(selectPartnersListToLeft);
  const partnersListToRight = useSelector(selectPartnersListToRight);

  useEffect(() => {
    if (!tape_participants.length) {
      dispatch(fetchTapeParticipants());
    }
  }, [partnersListToLeft, partnersListToRight]);

  return (
    <>
      {tape_participants?.length > 0 && (
        <div className='banner__first '>
          <div className='banner-common-to-left'>
            {partnersListToLeft.map((partner) => (
              <PartnerEntity partner={partner} key={partner} />
            ))}
          </div>
        </div>
      )}

      {tape_participants?.length > 0 && (
        <div className='banner__second '>
          <div className='banner-common-to-right'>
            {partnersListToRight.map((partner) => (
              <PartnerEntity partner={partner} key={partner} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
