import classNames from 'classnames';
import { TTypeLocation } from 'shared/types/location.types';
import { ReactComponent as NatureIcon } from 'assets/images/locations/nature.svg';
import { ReactComponent as SurfIcon } from 'assets/images/locations/surf.svg';
import { ReactComponent as MountainIcon } from 'assets/images/locations/mountain.svg';
import { ReactComponent as JeepIcon } from 'assets/images/locations/jeep.svg';
import { ReactComponent as IndustryIcon } from 'assets/images/locations/fabric.svg';
import { ReactComponent as HelicopterIcon } from 'assets/images/locations/helicopter.svg';
import { ReactComponent as CityIcon } from 'assets/images/locations/city.svg';
import { ReactComponent as BagIcon } from 'assets/images/locations/bag.svg';
import { ReactComponent as PhotoIcon } from 'assets/images/locations/photo.svg';
import { ReactComponent as QuadBikeIcon } from 'assets/images/locations/quad_bike.svg';
import { ReactComponent as BirdIcon } from 'assets/images/locations/bird.svg';
import { ReactComponent as PointIcon } from 'assets/images/locations/point.svg';

type TLocationIconProps = {
  icon: TTypeLocation | 'CITY' | 'EXPEDITION' | 'PHOTO' | 'QUAD_BIKE' | 'BIRDWATCHING' | 'NONE' | null;
  className?: string;
};

export const LocationIcon = ({ icon, className }: TLocationIconProps) => {
  switch (icon) {
    case 'CITY':
      return (
        <div className={classNames('location-icon', className)}>
          <CityIcon />
        </div>
      );
    case 'NATURE':
      return (
        <div className={classNames('location-icon', className)}>
          <NatureIcon />
        </div>
      );
    case 'WATER':
      return (
        <div className={classNames('location-icon', className)}>
          <SurfIcon />
        </div>
      );
    case 'MOUNT':
      return (
        <div className={classNames('location-icon', className)}>
          <MountainIcon />
        </div>
      );
    case 'SPECIAL_TRANSFER':
      return (
        <div className={classNames('location-icon', className)}>
          <JeepIcon />
        </div>
      );
    case 'DEVELOP':
      return (
        <div className={classNames('location-icon', className)}>
          <IndustryIcon />
        </div>
      );
    case 'HELICOPTER':
      return (
        <div className={classNames('location-icon', className)}>
          <HelicopterIcon />
        </div>
      );
    case 'EXPEDITION':
      return (
        <div className={classNames('location-icon', className)}>
          <BagIcon />
        </div>
      );
    case 'PHOTO':
      return (
        <div className={classNames('location-icon', className)}>
          <PhotoIcon />
        </div>
      );
    case 'QUAD_BIKE':
      return (
        <div className={classNames('location-icon', className)}>
          <QuadBikeIcon />
        </div>
      );
    case 'BIRDWATCHING':
      return (
        <div className={classNames('location-icon', className)}>
          <BirdIcon />
        </div>
      );
    case 'NONE':
      return <PointIcon className={classNames(className)}>6</PointIcon>;

    default:
      return <div className={classNames('location-icon', className)}></div>;
  }
};
