import classNames from 'classnames';
import logo from 'assets/images/header/logo_top.svg';
import loaderDeer from 'assets/images/loading/loading_deer.gif';

type TLoadingProps = {
  isMain?: boolean;
};

const Loading = ({ isMain }: TLoadingProps) => {
  return (
    <div className={classNames('loading', { loading_main: isMain })}>
      {isMain && (
        <div className='loading__logo'>
          <img src={logo} alt='logo' />
        </div>
      )}
      <div className='container loading__container'>
        <div className='loading__deer'>
          <img src={loaderDeer} width='100%' alt='deer' />
        </div>
      </div>
    </div>
  );
};

export default Loading;
