import { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useFirstView } from 'shared/hooks/useFirstView';
import { operatorNavs, mobileOperatorNavs } from './navigation';
import Aside from 'components/Account/Aside';
import MainTours from 'components/Account/ManageTour/MainTours';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import TourShow from 'components/Account/ManageTour/TourShow';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import AlternativeHeader from 'components/Header/AlternativeHeader';

const ToursPage = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();
  const firstView = useFirstView(userData);
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    } else if (userData.type !== 'OPERATOR') {
      history.push('/account');
    }
  }, [isAuth, userData, history]);

  if (!isAuth || !userData) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Управление заказами - Туркластер Арктический</title>
      </Helmet>
      <section className='account'>
        <AlternativeHeader />
        <Aside navs={operatorNavs} mobileNavs={mobileOperatorNavs} unreadStatus={firstView} />
        <section className='Amain'>
          <Switch>
            <Route path={path} exact>
              <MainTours />
            </Route>
            <Route path={`${path}/:id`}>
              <TourShow />
            </Route>
          </Switch>
          <MobileBottom />
        </section>
      </section>
    </>
  );
};

export default ToursPage;
