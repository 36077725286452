import { TRANSFORM_TEXT } from 'shared/constants/const';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';

import {NewsTypesSlider } from "./NewsTypesSlider";
import {TFiltersTours} from "../../../shared/types/tours.types";

import {useState} from "react";
import {SimpleFilter, TFilterList} from "../../../shared/ui/SimpleFilter/SimpleFilter";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFilterBlogs_v2,
    setFilterTag
} from "../../../manageStore/news/newsSlice";

type TNewsFiltersByType = {
    filters: TFiltersTours;
    clickFilter: (filter: string ) => void;
    isMulti?: boolean;
};

type TItemFilters = {
    id: string;
    label: string;
    value: string;
};

export const allItem: TItemFilters =  {
        id: 'all',
        label: 'Все',
        value: '',
    }

export const itemsTypeNews: TItemFilters[] = [
    allItem,
    {
        id: 'food',
        label: TRANSFORM_TEXT.news.FOOD,
        value: TRANSFORM_TEXT.news.FOOD,
    },
    {
        id: 'industry',
        label: TRANSFORM_TEXT.news.INDUSTRY,
        value: TRANSFORM_TEXT.news.INDUSTRY,
    },
    {
        id: 'story',
        label: TRANSFORM_TEXT.news.STORY,
        value: TRANSFORM_TEXT.news.STORY,
    },
    {
        id: 'extreme',
        label: TRANSFORM_TEXT.news.EXTREME,
        value: TRANSFORM_TEXT.news.EXTREME,
    },
    {
        id: 'ethnic',
        label: TRANSFORM_TEXT.news.ETHNIC,
        value: TRANSFORM_TEXT.news.ETHNIC,
    },
    {
        id: 'nature',
        label: TRANSFORM_TEXT.news.NATURE,
        value: TRANSFORM_TEXT.news.NATURE,
    },
    {
        id: 'journey',
        label: TRANSFORM_TEXT.news.JOURNEY,
        value: TRANSFORM_TEXT.news.JOURNEY,
    }
];

const listForSimpleFilter: TFilterList[] = [...itemsTypeNews].map(el=>{
    return {
        label: el.label,
        value: el.value,
        select: false
    }
})


export const NewsFiltersByType = ({ filters, clickFilter, isMulti }: TNewsFiltersByType) => {
    const dispatch = useDispatch();
    const blogs = useSelector(selectFilterBlogs_v2);
    const [isMobile] = useMediaQuery(['(max-width: 1123px)']);
    const [listFilter,  setListFilter] = useState(listForSimpleFilter)

    const preSetFilter = (list: TFilterList[]) => {
        setListFilter(list);
        const newFilterTag = list.filter(el=>el.select).map(el=>el.value)
        dispatch(setFilterTag(newFilterTag));
    }

    return isMobile ? (
        <SimpleFilter filters={listFilter} setFilter={preSetFilter}  countVariant={blogs.length}  />
    ) : (
        <NewsTypesSlider filters={filters} clickFilter={clickFilter} itemsFilters={itemsTypeNews} />
    );
};
