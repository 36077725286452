export function formatSize(param: number | string): string {
  const sizeInBytes = Number(param)
  const sizeInKB = sizeInBytes / 1024;
  const sizeInMB = sizeInKB / 1024;

  if (sizeInMB >= 1) {
    return `${sizeInMB.toFixed(1)} Мб`;
  } else {
    return `${sizeInKB.toFixed(1)} Кб`;
  }
}