import { URL_FOR_IMG } from "shared/constants/const"

export const imagePrefix = (image?: string | null) => {
  if(!image) {
    return ''
  }
  if(image.startsWith('http')) {
    return image
  }
  return `${URL_FOR_IMG}/${image}`
}

export const urlPrefix = (url?: string | null) => {
  if(!url) {
    return ''
  }
  if(url.startsWith('http')) {
    return url
  }
  return `https://${url}`
}