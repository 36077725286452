import { TStepItem } from '../../../../../shared/types/aboutUs.types';
import { StepCardMobile } from './StepCardMobile';
import { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

type TStepsProps = {
  stepsItems: TStepItem[];
};

const settingsSlider: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const StepsSectionMobile = ({ stepsItems }: TStepsProps) => {
  const [currentSlider, setCurrentSlider] = useState(0);
  const slickTrackRef = useRef<{
    node: HTMLDivElement | null;
  }>({ node: null });

  const handleChangeSlide = (prev: number, next: number) => {
    setCurrentSlider(next);
  };

  const handleInitSlider = () => {
    slickTrackRef.current.node = document.querySelector('.news-item__slider .slick-track');
    handleChangeSlide(0, 0);
  };

  return (
    <>
      <Slider {...settingsSlider} className='news-item__slider' onInit={handleInitSlider}>
        {stepsItems.map((privilegeItem) => (
          <StepCardMobile key={privilegeItem.id} stepItem={privilegeItem} />
        ))}
      </Slider>
    </>
  );
};
