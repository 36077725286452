export const NextFilterIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <g clipPath="url(#clip0_2636_3340)">
                <path d="M1.50098 7.96565L14.9271 7.96565M14.9271 7.96565L9.57133 2.60986M14.9271 7.96565L9.57133 13.3214" stroke="white" strokeWidth="1.8"/>
            </g>
            <defs>
                <clipPath id="clip0_2636_3340">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
