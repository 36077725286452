import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TReviewFetchRequest, TReviewFetchResponse } from 'shared/types/review.types';

type TEdittedReview = TReviewFetchRequest & { id?: number };

type TInitialState = {
  isLoading: boolean;
  isUploading: boolean;
  reviewsList: TReviewFetchResponse[];
  edittedReview: TEdittedReview | null;
};

const initialState: TInitialState = {
  isLoading: false,
  isUploading: false,
  reviewsList: [],
  edittedReview: null,
};

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsUploading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUploading = payload;
    },
    setReviewsList: (state, { payload }: PayloadAction<TReviewFetchResponse[]>) => {
      state.reviewsList = payload;
    },
    setNewReview: (state, { payload }: PayloadAction<TReviewFetchResponse>) => {
      state.reviewsList.push(payload);
    },
    setChangedReview: (state, { payload }: PayloadAction<TReviewFetchResponse>) => {
      const reviewIndex = state.reviewsList.findIndex((review) => review.id === payload.id);
      state.reviewsList[reviewIndex] = payload;
    },
    setEdittedReview: (state, { payload }: PayloadAction<TEdittedReview | null>) => {
      state.edittedReview = payload;
    },
  },
});

export const reviewsActions = reviewsSlice.actions;
export const reviewsReducer = reviewsSlice.reducer;
