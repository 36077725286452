import React from "react";
import { cvaInput, cvaLabel } from "../../input_styles";
import { useState, useRef } from "react";
import { cva } from "class-variance-authority";
import { useClickAway } from "react-use";
import s from './index.module.scss'
import clsx from "clsx";
type Props = {
  variants: string[] | {name: string, value: string}[];
  value: string;
  placeholder?: string;
  className?: string;
  label?: string;
  onChange?: (el: string | {name: string, value: string}[]) => void;
  required?: boolean;
};

const cvaDropdownList = cva([s.dropdownList]);
const cvaDropdownListInner = cva([s.dropdownListInner]);

export default function Dropdown({
  variants,
  value,
  className,
  placeholder,
  label,
  onChange,
  required,
}: Props) {
  const [isOpenList, setIsOpenList] = useState(false);
  const refButton = useRef<HTMLButtonElement>(null);
  const refList = useRef<HTMLDivElement>(null);

  useClickAway(refList, (e) => {
    if (
      refButton &&
      refButton.current &&
      //@ts-ignore
      !refButton.current.contains(e.target)
    ) {
      setIsOpenList(false);
    }
  });

  return (
    <div className={clsx("relative", className)}>
      <div className={cvaLabel({ required })}>{label}</div>
      <button
        ref={refButton}
        onClick={(e) => {
          e.preventDefault();
          setIsOpenList(!isOpenList);
        }}
        className={clsx(
          cvaInput({ isPlaceholder: Boolean(!value) }),
          "justify-between-flex"
        )}
      >
        <span>{value ? value : placeholder}</span>
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.25 9.625L11.0021 12.375L13.75 9.625"
            stroke="#94A5B4"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </button>
      {isOpenList && variants && variants.length > 0 && (
        <div className={cvaDropdownList()}>
          <div ref={refList} className={cvaDropdownListInner()}>
            {variants.map((el, i) => (
              <button
                className={s.button}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpenList(false);
                  //@ts-ignore
                  onChange(el);
                }}
                key={i}
              >
                {typeof el === "string" ? el : el.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
//