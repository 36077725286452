import { useEffect, useRef } from 'react';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString } from 'ol/geom';
import { TClusterData } from 'shared/types/location.types';
import { generatePath } from '../helpers/generatePath';

export const useAddRoutes = (map: Map | null, routes: TClusterData[] | null) => {
  const pathRef = useRef<VectorLayer<VectorSource<LineString>> | null>(null);

  useEffect(() => {
    if (!map || !routes?.length) return;
    if (pathRef.current) {
      map.removeLayer(pathRef.current);
    }
    if (!routes?.length) return;
    const newPath = generatePath(routes);
    pathRef.current = newPath;
    map.addLayer(newPath);
  }, [routes, map]);

  return [pathRef.current];
};
