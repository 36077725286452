import { memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { TOrderLocation, TTerritoryLocation, TTypeLocation } from 'shared/types/location.types';
import { removeItemFromOrder, replaceItemOrder, setCity, setLocation } from 'manageStore/designer/designer.slice';
import { ReactComponent as CloseIcon } from 'assets/images/close-btn.svg';
import { ReactComponent as DragIcon } from 'assets/images/common/drag-icon.svg';
import { LocationIcon } from '../LocationIcon/LocationIcon';

type TTourDesignerOrderItemProps = {
  location: TOrderLocation;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  territory: TTerritoryLocation;
  index: number;
};

const TourDesignerOrderItem = ({ location, dragHandleProps, territory, index }: TTourDesignerOrderItemProps) => {
  const dispatch = useDispatch();

  const items = 'items' in location ? location.items : null;

  const icon: TTypeLocation | 'CITY' | null = 'items' in location ? 'CITY' : location.locationEnum;

  const removeCard = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, location: TOrderLocation, cityIndex?: number) => {
    e.stopPropagation();
    dispatch(removeItemFromOrder({ loc: location, cityIndex: cityIndex }));
  };

  const handleDragEnd = ({ destination, source }: DropResult, index: number) => {
    const isDrag = destination && destination.index !== source.index;
    if (!isDrag) return;
    dispatch(replaceItemOrder({ oldIndex: source.index, newIndex: destination.index, cityIndex: index, territory }));
  };

  const handleSelectLocation = (e: React.MouseEvent, location: TOrderLocation) => {
    e.stopPropagation();
    if ('items' in location) {
      dispatch(setCity(location));
    } else {
      dispatch(setLocation(location));
    }
  };

  return (
    <div className='designer-order-item'>
      <div className='designer-order-item__main' onClick={(e) => handleSelectLocation(e, location)}>
        <div className='designer-order-item__index'>{location.sequence}</div>
        <LocationIcon icon={icon} />
        <div className='designer-order-item__name'>
          <span>{location.name}</span>
        </div>
        <div className='designer-order-item__delete'>
          <div className='item-delete'>
            <CloseIcon className='item-delete__icon' onClick={(e) => removeCard(e, location)} />
          </div>
        </div>
        <div className='designer-order-list__drag'>
          <div className='list-drag' {...dragHandleProps}>
            <DragIcon className='list-drag__icon' />
          </div>
        </div>
      </div>
      {!!items?.length && (
        <DragDropContext onDragEnd={(dropResult) => handleDragEnd(dropResult, index)}>
          <Droppable droppableId='orderCityList'>
            {(provided) => (
              <ul className='designer-order-item__city' ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, cityIndex) => (
                  <Draggable draggableId={item.name} index={cityIndex} key={item.name}>
                    {(provided, snapshot) => {
                      const style = {
                        ...provided.draggableProps.style,
                        opacity: snapshot.isDragging ? 0.7 : 1,
                      };
                      return (
                        <li
                          key={item.name}
                          className='designer-order-item__main designer-order-item__main_city'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={style}
                          onClick={(e) => handleSelectLocation(e, item)}
                        >
                          <div className='designer-order-item__index'>{`${item.citySequence}.${item.sequence}`}</div>
                          <div className='designer-order-item__name'>
                            <span>{item.name}</span>
                          </div>
                          <div className='designer-order-item__delete'>
                            <div className='item-delete'>
                              <CloseIcon className='item-delete__icon' onClick={(e) => removeCard(e, item, cityIndex)} />
                            </div>
                          </div>
                          <div className='designer-order-list__drag'>
                            <div className='list-drag' {...provided.dragHandleProps}>
                              <DragIcon className='list-drag__icon' />
                            </div>
                          </div>
                        </li>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default memo(TourDesignerOrderItem);
