import { TClusterData } from 'shared/types/location.types';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import LineString from 'ol/geom/LineString';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Stroke, Style } from 'ol/style';

export const generatePath = (routes: TClusterData[]) => {
  const points = routes.map(({ coords }) => {
    return fromLonLat(coords);
  });

  const lineFeature = new Feature(new LineString(points));
  const pathSource = new VectorSource({
    features: [lineFeature],
  });

  const style = new Style({
    stroke: new Stroke({
      color: 'rgba(219, 41, 71, 0.4)',
      width: 5,
    }),
  });

  const newPath = new VectorLayer({
    source: pathSource,
    style,
    zIndex: 0,
  });
  return newPath;
};
