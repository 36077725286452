import classNames from 'classnames';
import { TextareaHTMLAttributes } from 'react';

interface IBaseTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  rootClassName?: string;
}

export const BaseTextarea = ({ rootClassName, maxLength, ...textareaAttributes }: IBaseTextareaProps) => {
  const currentLength = textareaAttributes.value ? String(textareaAttributes.value).length : 0;

  return (
    <div className={classNames('textarea-group', rootClassName)}>
      <textarea className='textarea-group__textarea' maxLength={maxLength} {...textareaAttributes}></textarea>
      {maxLength && (
        <div className='textarea-group__helper'>
          {currentLength}/{maxLength}
        </div>
      )}
    </div>
  );
};
