import { TRANSFORM_TEXT } from 'shared/constants/const';
import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { ReactComponent as WinterIcon } from 'assets/images/seasons/winter.svg';
import { ReactComponent as SpringIcon } from 'assets/images/seasons/spring.svg';
import { ReactComponent as SummerIcon } from 'assets/images/seasons/summer.svg';
import { ReactComponent as AutumnIcon } from 'assets/images/seasons/autumn.svg';
import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';

const itemsSeasonTour = [
  {
    value: TRANSFORM_TEXT.season.SUMMER,
    label: TRANSFORM_TEXT.season.SUMMER,
    icon: <SummerIcon />,
  },
  {
    value: TRANSFORM_TEXT.season.AUTUMN,
    label: TRANSFORM_TEXT.season.AUTUMN,
    icon: <AutumnIcon />,
  },
  {
    value: TRANSFORM_TEXT.season.WINTER,
    label: TRANSFORM_TEXT.season.WINTER,
    icon: <WinterIcon />,
  },
  {
    value: TRANSFORM_TEXT.season.SPRING,
    label: TRANSFORM_TEXT.season.SPRING,
    icon: <SpringIcon />,
  },
];

type TToursFiltersBySeason = {
  filters: string[];
  onClick: <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours[TName]) => void;
};

export const ToursFiltersBySeason = ({ filters, onClick }: TToursFiltersBySeason) => {
  const checkActiveSeason = (value: string) => {
    return filters.includes(value);
  };

  const handleClick = (value: string) => {
    const season = [...filters];
    const indexSeason = season.findIndex((item) => item === value);
    indexSeason === -1 ? season.push(value as string) : season.splice(indexSeason, 1);
    onClick('season', season);
  };

  return (
    <ParamsFilterGroup title='Сезон'>
      {itemsSeasonTour.map((season) => (
        <ParamsFilterItem
          key={season.label}
          active={checkActiveSeason(season.value)}
          onClick={() => handleClick(season.value)}
          label={season.label}
          icon={season.icon}
        />
      ))}
    </ParamsFilterGroup>
  );
};
