import classNames from 'classnames';

type TParamsFilterItemProps = {
  active: boolean;
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
};

export const ParamsFilterItem = ({ active, onClick, label, icon }: TParamsFilterItemProps) => {
  return (
    <div
      className={classNames('params-filters-item', { active }, { 'params-filters-item_full': icon })}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </div>
  );
};
