import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from 'components/Loading/Loading';
import { store } from './store';
import './index.scss';
import './i18n';
import App from './App';

let persistor = persistStore(store);

ReactDOM.render(
  <Suspense fallback={<Loading isMain />}>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          <ToastContainer />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
