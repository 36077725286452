import { useCallback, useEffect, useState } from 'react';

export const useClientRect = (): [DOMRect | null, (node: any) => void] => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const [rect, setRect] = useState<DOMRect | null>(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const handleResize = useCallback(() => {
    if (!node) return;
    setRect(node.getBoundingClientRect());
  }, [node]);

  useEffect(() => {
    handleResize();
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [node, handleResize]);

  return [rect, ref];
};
