import { SimpleForm, ReferenceInput, TextInput, required, Labeled } from 'react-admin';
import FileInputAdmin from '../FileInputAdmin';
import { LODGING_FIELDS } from '../../../shared/constants/const';
import TinyMCE from '../TinyMCE';
import SelectCity from '../OsmMapForm/SelectCity';

const LodgindForm = () => {
  return (
    <SimpleForm>
      <Labeled label={LODGING_FIELDS.title} fullWidth>
        <TextInput source='name' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={LODGING_FIELDS.address} fullWidth>
        <TextInput source='address' validate={[required()]} label='' />
      </Labeled>
      <ReferenceInput source='city' reference='city' perPage={Infinity} fullWidth>
        <SelectCity />
      </ReferenceInput>
      <Labeled label={LODGING_FIELDS.description} fullWidth>
        <TinyMCE property='description' required />
      </Labeled>
      <FileInputAdmin source='preview' label={LODGING_FIELDS.preview} multiple={false} />
      <Labeled label={LODGING_FIELDS.time} fullWidth>
        <TextInput source='time' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={LODGING_FIELDS.phone} fullWidth>
        <TextInput source='phone' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={LODGING_FIELDS.email} fullWidth>
        <TextInput source='email' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={LODGING_FIELDS.site} fullWidth>
        <TextInput source='site' label='' />
      </Labeled>
    </SimpleForm>
  );
};

export default LodgindForm;
