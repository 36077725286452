import { Datagrid, List, Pagination, DeleteButton, FunctionField, RaRecord, Labeled } from 'react-admin';
import { Link } from '@mui/material';
import { MENU_ADMIN_LIST, NPA_FIELDS } from '../../../shared/constants/const';

const NpaList = () => (
  <List title={MENU_ADMIN_LIST.npa} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <Labeled label={NPA_FIELDS.title}>
        <FunctionField
          label={false}
          sortable={false}
          render={(record: RaRecord) => (
            <Link href={record.url} target='_blank' rel='noreferrer'>
              {record.caption}
            </Link>
          )}
        />
      </Labeled>
      <DeleteButton />
    </Datagrid>
  </List>
);

export default NpaList;
