import React from 'react'
import { Edit } from 'react-admin'
import BlogForm from './BlogForm'

const BlogEdit = () => (
  <Edit>
    <BlogForm />
  </Edit>
)

export default BlogEdit
