import { SimpleForm, required, Labeled, SelectInput, FormDataConsumer } from 'react-admin';
import { TRANSFORM_TRAVEL_GUIDE, TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { GuideCityForm } from './GuideCityForm';
import { GuideFloraForm } from './GuideFloraForm';

const choicesLocationType = [
  {
    label: TRANSFORM_TRAVEL_GUIDE.tags.city.label,
    value: TRANSFORM_TRAVEL_GUIDE.tags.city.value,
  },
  {
    label: TRANSFORM_TRAVEL_GUIDE.tags.flora.label,
    value: TRANSFORM_TRAVEL_GUIDE.tags.flora.value,
  },
];

export const GuideForm = () => (
  <SimpleForm>
    <Labeled label={TRAVEL_GUIDE_FIELDS.locationType} fullWidth isRequired>
      <SelectInput
        source='type'
        label='Select...'
        optionText='label'
        optionValue='value'
        choices={choicesLocationType}
        fullWidth
        validate={[required()]}
        format={(v: any) => (v ? v : '')}
      />
    </Labeled>
    <FormDataConsumer>
      {({ formData }) => {
        switch (formData.type) {
          case TRANSFORM_TRAVEL_GUIDE.tags.city.value:
            return <GuideCityForm />;
          case TRANSFORM_TRAVEL_GUIDE.tags.flora.value:
            return <GuideFloraForm />;
          default:
            return null;
        }
      }}
    </FormDataConsumer>
  </SimpleForm>
);
