const isContainArray = (where, what) => {
  if (!where?.length) return false;
  if (typeof where === 'string') {
    where = where.split(';');
  }
  for (var i = 0; i < what.length; i++) {
    if (where.indexOf(what[i]) !== -1) return true;
  }
  return false;
};

export default isContainArray;
