import React from 'react';
import { SimpleForm, ReferenceInput, SelectInput, TextInput, required, Labeled } from 'react-admin';
import FileInputAdmin from '../FileInputAdmin';
import { BEAUTY_FIELDS } from '../../../shared/constants/const';
import TinyMCE from '../TinyMCE';

const TourForm = () => (
  <SimpleForm>
    <Labeled label={BEAUTY_FIELDS.title} fullWidth>
      <TextInput source='title' validate={[required()]} label='' />
    </Labeled>
    <Labeled label={BEAUTY_FIELDS.subtitle} fullWidth>
      <TextInput source='subtitle' validate={[required()]} label='' />
    </Labeled>
    <Labeled label={BEAUTY_FIELDS.name} fullWidth>
      <TextInput source='name' validate={[required()]} label='' />
    </Labeled>
    <Labeled label={BEAUTY_FIELDS.location} fullWidth>
      <ReferenceInput source='location.id' reference='locations' perPage={Infinity} fullWidth>
        <SelectInput optionText='name' optionValue='id' label='Select...' fullWidth validate={[required()]} />
      </ReferenceInput>
    </Labeled>
    <Labeled label={BEAUTY_FIELDS.shortDesc} fullWidth>
      <TextInput source='desc' validate={[required()]} multiline={true} minRows='3' maxRows='3' label='' />
    </Labeled>
    <Labeled label={BEAUTY_FIELDS.content} fullWidth>
      <TinyMCE property='content' />
    </Labeled>
    <FileInputAdmin source='preview' label={BEAUTY_FIELDS.preview} multiple={false} />
    <FileInputAdmin source='uploadedFiles' label={BEAUTY_FIELDS.gallery} multiple={true} />
    <Labeled label={BEAUTY_FIELDS.video} fullWidth>
      <TextInput source='video' label='' />
    </Labeled>
  </SimpleForm>
);

export default TourForm;
