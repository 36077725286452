import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header/Header';
import RestorePass from 'components/RestorePass/RestorePass';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import { useAppSelector } from 'shared/hooks/useAppSelector';

const RestorePassPage = () => {
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const userData = useAppSelector((state) => state.user.userData);
  const history = useHistory();

  useEffect(() => {
    if (isAuth && userData && Object.keys(userData).length) history.push('/account');
  }, [isAuth, userData, history]);

  return (
    <>
      <Helmet>
        <title>Восстановление пароля - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <section className='restorePass-page'>
        <Header />
        <div className='container'>
          <RestorePass />
        </div>
        <MobileBottom opacity />
      </section>
    </>
  );
};

export default RestorePassPage;
