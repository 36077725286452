import React from 'react';
import Layout from '../../widgets/layout/ui';
import s from './index.module.scss';
import PageHead from '../../entities/page-head';
import ReviewForm from '../../widgets/review_from';
import ContainerContent from '../../entities/container';
//@ts-ignore
import { useHistory } from 'react-router-dom';

export default function ReviewFormPage() {
  const navigate = useHistory();

  return (
    <Layout classNameMain={s.lay}>
      <PageHead onBack={() => navigate.goBack()} isFixed={false}>
        Форма отзыва
      </PageHead>
      <ContainerContent>
        <ReviewForm />
      </ContainerContent>
    </Layout>
  );
}
