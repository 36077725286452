import { TRANSFORM_TEXT } from 'shared/constants/const';
import { TFilterLocations, TLocation, TLocationSeason } from 'shared/types/location.types';
import { TFiltersTours, TTour } from 'shared/types/tours.types';
import isContainArray from './isContainArray';

export const getSeasonForFilter = (seasonsLocation: Record<string, boolean> | null) => {
  if (!seasonsLocation) {
    return [];
  }
  const seasons = Object.entries(TRANSFORM_TEXT.season);
  const filteredSeasons = seasons.reduce((acc, season) => {
    const [seasonKey, seasonValue] = season;
    if (seasonsLocation[seasonKey.toLowerCase()]) {
      acc.push(seasonValue);
    }
    return acc;
  }, [] as string[]);
  return filteredSeasons;
};

export const filterForLocations = (item: TLocation, values: TFilterLocations) => {
  const seasonsLocation = getSeasonForFilter(item.season);

  if (values.types.length && !isContainArray(item.locationType, values.types)) return false;
  if (values.season.length && !isContainArray(seasonsLocation, values.season)) return false;
  if (values.territory.length && !values.territory.includes(item.cluster?.name || '')) return false;
  if (values.transport.length && !isContainArray(item.availableTransfers, values.transport)) return false;
  return true;
};

const getSeasonTourForFilter = (tour: TTour) => {
  const seasonsTour: TLocationSeason = {
    winter: !!tour.winter,
    autumn: !!tour.autumn,
    spring: !!tour.spring,
    summer: !!tour.summer,
  };
  return getSeasonForFilter(seasonsTour);
};

const getDurationForFilter = (values: TFiltersTours) => {
  const [minDays, maxDays] = values.duration.split(/[\s-]+/, 2);
  return { minDays, maxDays };
};

const checkTypeTour = (tour: TTour, filterTypes: string[]) => {
  const { tourTypeMulti, productType } = tour;
  return !filterTypes.length || isContainArray(tourTypeMulti, filterTypes) || filterTypes.includes(productType);
};

const checkLocationsTour = (tour: TTour, filterLocations: string[]) => {
  return !filterLocations.length || !!tour.locations?.find((location) => filterLocations.includes(location.name));
};

export const customFilterForTours = (tour: TTour, values: TFiltersTours) => {
  const { price, duration } = tour;
  const [minPrice, maxPrice] = values.price;
  const { minDays, maxDays } = getDurationForFilter(values);
  const seasonsTour = getSeasonTourForFilter(tour);
  if (values.duration !== 'Не выбрано' && !(duration >= Number(minDays) && duration <= Number(maxDays))) return false;
  if (!(price >= minPrice && price <= maxPrice)) return false;
  if (values.season.length && !isContainArray(seasonsTour, values.season)) return false;

  if (values.types.length && values.locations.length) {
    return checkLocationsTour(tour, values.locations) || checkTypeTour(tour, values.types);
  } else if (values.types.length || values.locations.length) {
    return checkLocationsTour(tour, values.locations) && checkTypeTour(tour, values.types);
  }
  return true;
};
