import React, { ReactNode } from 'react';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import s from './index.module.scss';
import PopperCustom from 'newProject/entities/popper';
import { Placement } from '@popperjs/core';
type Props = {
  children: ReactNode;
  placement?: Placement;
};

export default function SettingsPopper({ children, placement = 'bottom-end' }: Props) {
  return (
    <PopperCustom render={<div className={s.popper}>{children}</div>} placement={placement}>
      <SettingsIcon />
    </PopperCustom>
  );
}
