import classNames from 'classnames';
import { TRANSFORM_TRAVEL_GUIDE } from 'shared/constants/travelGuide.constants';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { TagEntity } from 'shared/lib/TagEntity/TagEntity';
import { TCategoryTravelGuide, TThemeTravelGuide } from 'shared/types/travelGuide.types';
import { useHistory } from 'react-router-dom';

type TTravelGuideItemIntroProps = {
  title: string;
  tag: TCategoryTravelGuide;
  locationTags: TThemeTravelGuide[];
  backImage: string;
  className: TCategoryTravelGuide;
  territoryTag: string;
};

export const TravelGuideItemIntro = ({
  title,
  tag,
  backImage,
  className,
  locationTags,
  territoryTag,
}: TTravelGuideItemIntroProps) => {
  const history = useHistory();

  const category = tag === 'Городская локация' ? TRANSFORM_TRAVEL_GUIDE.tags.city : TRANSFORM_TRAVEL_GUIDE.tags.flora;

  const backHandler = () => {
    history.push('/travel-guides');
  };

  return (
    <section className={`travel-guide-item__intro ${className}`}>
      <div className='container travel-guide-item__container'>
        <div
          className={classNames('travel-guide-item__image', {
            city: tag === 'Городская локация',
            flora: tag === 'Природная локация',
          })}
          style={{ backgroundImage: `url(${backImage})` }}
        />
        <h1 className='travel-guide-item__title'>{title}</h1>
        <button type='button' className='travel-guide-item__back' onClick={backHandler}>
          <ArrowButton className='travel-guide-item__back__icon' />
          Назад
        </button>
        <TagEntity className='travel-guide-item__type location-tag' tag={category.label} isRed />
        <TagEntity className='travel-guide-item__type location-tag' tag={territoryTag} />
        <div className='travel-guide-item__tags-list'>
          {locationTags.map((tag) => (
            <TagEntity className='travel-guide-item__type location-tag' tag={tag} key={tag} />
          ))}
        </div>
      </div>
    </section>
  );
};
