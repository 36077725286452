import { VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import s from './index.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
} & VariantProps<typeof cvaTag>;

const cvaTag = cva([s.tag], {
  variants: {
    variant: {
      'blue-dark': [s.blueDark],
      gray: [s.gray],
      'gray-light': [s.grayLight],
      outlined: [s.outlined],
      orange: [s.orange],
      green: [s.green],
      'blue-light': [s.blueLight],
      red: [s.red],
    },
    size: {
      m: [s.m],
      s: [s.s],
    },
    font: {
      main: [s.main],
      title: [s.title],
    },
  },
  defaultVariants: {
    variant: 'blue-light',
    size: 'm',
    font: 'title',
  },
});

export default function Tag({ children, className, size, onClick, font, variant }: Props) {
  return (
    <div onClick={onClick} className={clsx(cvaTag({ variant, size, font }), className, onClick && s.pointer)}>
      {children}
    </div>
  );
}
