import { Datagrid, List, TextField, EditButton, ShowButton, Pagination } from 'react-admin';
import { MENU_ADMIN_LIST, QUESTIONS_FIELDS } from '../../../shared/constants/const';

export const QuestionsList = () => (
  <List title={MENU_ADMIN_LIST.locations} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source='title' sortable={false} label={QUESTIONS_FIELDS.title} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
