import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { FeedbackForm } from './FeedbackForm';

export const HomeFeedback = () => {
  return (
    <section className='home-feedback'>
      <div className='container home-feedback__container'>
        <TitleSectionMain title='задать вопрос' isSmall />
        <FeedbackForm />
      </div>
      <div className='home-feedback__bottom'></div>
    </section>
  );
};
