import {CheckFilterIcon} from "./CheckFilterIcon";

export type TpropsCheckbox = {
    isCheck: boolean;
    setCheck: Function;
}

export const SimpleFIlterCheckbox = ({isCheck=false, setCheck=()=>{}}:TpropsCheckbox) => {
    return (
        <div className='simpleFilterCheckbox' onClick={()=>setCheck(!isCheck)}>
            {isCheck && <CheckFilterIcon />}
        </div>
    );
};
