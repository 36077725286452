import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TTouristInfo = { name: string; description: string };

type TInitialState = {
  touristInfo: TTouristInfo | null;
};

const initialState: TInitialState = {
  touristInfo: null,
};

const touristSlice = createSlice({
  name: 'touristSlice',
  initialState,
  reducers: {
    setTouristInfo: (state, { payload }: PayloadAction<TTouristInfo | null>) => {
      state.touristInfo = payload;
    },
  },
});

export const touristReducer = touristSlice.reducer;
export const touristActions = touristSlice.actions;
