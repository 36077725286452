import * as React from 'react';
import { SimpleShowLayout, TextField, EmailField } from 'react-admin';
import { OPERATOR_FIELDS } from '../../../shared/constants/const';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
};

const GuideShow = () => (
  <SimpleShowLayout>
    <p style={{ textAlign: 'center' }}>Общие данные</p>
    <SimpleShowLayout sx={styleInBlock}>
      <TextField source='type' label={OPERATOR_FIELDS.type} />
      <TextField source='registryNumber' label={OPERATOR_FIELDS.registryNumber} />
      <TextField source='lastName' label={OPERATOR_FIELDS.lastName} />
      <TextField source='firstName' label={OPERATOR_FIELDS.firstName} />
      <TextField source='patronymic' label={OPERATOR_FIELDS.patronymic} />
      <TextField source='inn' label={OPERATOR_FIELDS.inn} />
      <TextField source='phone' label={OPERATOR_FIELDS.phone} />
      <TextField source='languages' label={OPERATOR_FIELDS.languages} />
      <EmailField source='email' label={OPERATOR_FIELDS.email} />
    </SimpleShowLayout>
    <p style={{ textAlign: 'center' }}>Документ, удостоверяющий личность</p>
    <SimpleShowLayout sx={styleInBlock}>
      <TextField source='passportSeries' label={OPERATOR_FIELDS.passportSeries} />
      <TextField source='passportNumber' label={OPERATOR_FIELDS.passportNumber} />
      <TextField source='passportIssueDate' label={OPERATOR_FIELDS.passportIssueDate} />
      <TextField source='passportIssuedBy' label={OPERATOR_FIELDS.passportIssuedBy} />
    </SimpleShowLayout>
    <p style={{ textAlign: 'center' }}>Аттестат экскурсовода (гида) или гида-переводчика</p>
    <SimpleShowLayout sx={styleInBlock}>
      <TextField source='certificateSeries' label={OPERATOR_FIELDS.certificateSeries} />
      <TextField source='certificateNumber' label={OPERATOR_FIELDS.certificateNumber} />
      <TextField source='certificateIssuedBy' label={OPERATOR_FIELDS.certificateIssuedBy} />
      <TextField source='certificateExpiry' label={OPERATOR_FIELDS.certificateExpiry} />
    </SimpleShowLayout>
  </SimpleShowLayout>
);

export default GuideShow;
