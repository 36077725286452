import { useEffect, useState } from 'react';
import { TClusterData, TLocation } from 'shared/types/location.types';
import { useAddPoints } from 'widgets/shared/Maps/hooks/useAddPoints';
import { useAddRoutes } from 'widgets/shared/Maps/hooks/useAddRoutes';
import { useExtentByRoutes } from 'widgets/shared/Maps/hooks/useExtentByRoutes';
import { useOsmMap } from 'widgets/shared/Maps/hooks/useOsmMap';
import { TourBalloon } from 'widgets/shared/Maps/lib/Baloons/TourBalloon';
import { useBalloon } from 'widgets/shared/Maps/hooks/useBaloon';
import classNames from 'classnames';
import { useToggleActiveMap } from 'widgets/shared/Maps/hooks/useToggleActiveMap';
import { OsmMap } from 'widgets/shared/Maps/lib/OsmMap';
import { getOsmViewOptions } from 'widgets/shared/Maps/helpers/getOsmViewOptions';

export const TourItemPath = ({ locations }: { locations: TLocation[] | null }) => {
  const viewOptions = getOsmViewOptions({ zoom: 10, minZoom: 1 });
  const [pointsMap, setPointsMap] = useState<TClusterData[]>([]);

  const [map] = useOsmMap('osm-map', viewOptions, { controls: [] });
  const [clusters] = useAddPoints(map, pointsMap, 'tour');
  const [routes] = useAddRoutes(map, pointsMap);
  useExtentByRoutes(map, routes);
  const [balloonInfo, popup] = useBalloon(map, clusters);
  const [isActiveMap, toggleActiveMap] = useToggleActiveMap(map, false);

  useEffect(() => {
    if (!locations) return;
    const points: TClusterData[] = [];
    locations.forEach((loc) => {
      if (loc.lat && loc.lng) {
        const coords = [loc.lng, loc.lat];
        points.push({ coords, infoLoc: loc });
      }
    });
    setPointsMap(points);
  }, [locations]);

  if (!locations) {
    return null;
  }

  return (
    <div className='path-tour-map'>
      <OsmMap map={map}>
        <div ref={popup}>{!!balloonInfo && <TourBalloon pointInfo={balloonInfo} />}</div>
      </OsmMap>
      <button
        className={classNames('path-tour-map__toggle-active', { active: isActiveMap })}
        type='button'
        onClick={toggleActiveMap}
      >
        <span>{isActiveMap ? 'Отключить карту' : 'Включить карту'}</span>
      </button>
    </div>
  );
};
