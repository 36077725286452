import { SimpleForm, TextInput, required, Labeled, SelectInput, ReferenceInput } from 'react-admin';
import { CITY_FIELDS } from '../../../shared/constants/const';
import BrendMapForm from '../BrendMapForm/BrendMapForm';
import OsmMapForm from '../OsmMapForm/OsmMapForm';
import FileInputAdmin from '../FileInputAdmin';

export const CityForm = () => {
  return (
    <SimpleForm>
      <Labeled label={CITY_FIELDS.title} fullWidth isRequired>
        <TextInput source='name' validate={[required()]} label='' />
      </Labeled>
      <FileInputAdmin source='photo' label={CITY_FIELDS.preview} multiple={false} validate={[required()]} />
      <FileInputAdmin source='gallery' label={CITY_FIELDS.gallery} multiple={true} validate={[required()]} />
      <Labeled label={CITY_FIELDS.territory} fullWidth isRequired>
        <ReferenceInput source='cluster.id' reference='cluster' perPage={Infinity} fullWidth>
          <SelectInput
            label='Select...'
            optionText='name'
            optionValue='id'
            fullWidth
            validate={[required()]}
            format={(v) => (v ? v : '')}
          />
        </ReferenceInput>
      </Labeled>
      <Labeled label={`${CITY_FIELDS.description} *`} fullWidth>
        <TextInput source='description' validate={[required()]} multiline={true} minRows='3' maxRows='3' label='' />
      </Labeled>
      <div style={{ width: '100%' }}>
        <BrendMapForm required />
        <OsmMapForm outCenter={null} required />
      </div>
    </SimpleForm>
  );
};
