import Slider, { Settings } from 'react-slick';
import { useSwipe } from 'shared/hooks/useSwipe';
import { TTour } from 'shared/types/tours.types';
import { TourCard } from 'entities/tour/TourCard/TourCard';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { ToursList } from 'widgets/shared/ToursList/ToursList';

const settings: Settings = {
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right dark />,
  prevArrow: <ButtonSlider left dark />,
};

export const TourItemRecommendations = ({ tours }: { tours: TTour[] }) => {
  const [startSwipe, endSwipe, isSwipe] = useSwipe();
  const [isMobile] = useMediaQuery();

  return (
    <div className='recommendations-tour-list'>
      {isMobile ? (
        <ToursList tours={tours} />
      ) : (
        <Slider {...settings} className='recommendations-tour-slider' swipeEvent={startSwipe}>
          {tours.map((tour) => (
            <TourCard tour={tour} key={tour.id} endSwipe={endSwipe} isSwipe={isSwipe} />
          ))}
        </Slider>
      )}
    </div>
  );
};
