import classNames from 'classnames';
import { HTMLAttributes } from 'react';

interface ITagEntity extends HTMLAttributes<HTMLDivElement> {
  tag: string;
  isRed?: boolean;
}

export const TagEntity = ({ className, tag, isRed }: ITagEntity) => {
  return <div className={classNames('tag-entity', { red: isRed }, className)}>{tag}</div>;
};
