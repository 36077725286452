import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRootState } from 'store';
import {TTapeParticipant} from "../../shared/types/common.types";
import {splitAndSerializedArrayInTwo} from "../../helpers/splitAndSerializedArrayInTwo";

type TInitialState = {
  isOpen: boolean;
  isBecomeMemberOpen: boolean,
  tape_participants: TTapeParticipant[],
  partnersListToLeft: string[],
  partnersListToRight:string[],
};

const initialState: TInitialState = {
  isOpen: false,
  isBecomeMemberOpen: false,
  tape_participants: [],
  partnersListToLeft: [],
  partnersListToRight: []
};

export const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
    setIsBecomeMemberOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isBecomeMemberOpen = payload;
    },
    setTapeParticipants: (state, { payload }: PayloadAction<TTapeParticipant[]>) => {
      state.tape_participants = payload;

      let participants = splitAndSerializedArrayInTwo(payload)

      state.partnersListToLeft = participants.first
      state.partnersListToRight = participants.second
    },
  },
});

export const { setIsOpen,setIsBecomeMemberOpen, setTapeParticipants } = slice.actions;

export const selectIsOpen = (state: TRootState) => state.common.isOpen;
export const selectIsBecomeMemberOpen = (state: TRootState) => state.common.isBecomeMemberOpen
export const selectTapeParticipants = (state: TRootState) => state.common.tape_participants
export const selectPartnersListToLeft = (state: TRootState) => state.common.partnersListToLeft
export const selectPartnersListToRight = (state: TRootState) => state.common.partnersListToRight

export default slice.reducer;
