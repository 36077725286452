import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import {Labeled, ReferenceInput, SelectInput, required, ArrayInput, SimpleFormIterator} from 'react-admin';
import {BLOG_FIELDS} from "../../../shared/constants/const";
import Box from "@mui/material/Box";
const styleInBlock = {
    border: '1px solid #cbd5fd',
    borderLeft: '8px solid #cbd5fd',
    padding: '0 20px',
    marginBottom: '20px',
};
export const BlogPlaceMap = () => {
  return (
    <Labeled label={BLOG_FIELDS.locations} fullWidth>
        <Box sx={styleInBlock}>
            <ArrayInput source='locations' label='' fullWidth>
                <SimpleFormIterator disableReordering>
                    <ReferenceInput source='id' reference='locations' perPage={Infinity} fullWidth>
                        <SelectInput
                            optionText='name'
                            optionValue='id'
                            label='Select...'
                            fullWidth
                            format={(v) => (v && v !== 'new' ? v : '')}
                            validate={required()}
                        />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    </Labeled>
  );
};
