import Slider from "react-slick";
import {FactBlogDTO} from "../../../shared/types/types";
import {getUrlImg} from "../../../helpers/getUrlImg";
import {useMediaQuery} from "../../../shared/hooks/useMatchMedia";
import { ReactChild, ReactFragment, ReactPortal } from "react";


export type TGalleryProps = {
    gallery: FactBlogDTO[] | null
}

export const GalleryBlock = ({gallery = []}: TGalleryProps) => {

    function getUrlImgByIndex(index: number) {
        const path = gallery ? gallery[index].photo.path : '';
        const imgSrc = getUrlImg(path);
        return imgSrc
    }

    const getAllSlide = () => {
        return gallery ? gallery.map(imgblock => {
            const path = imgblock.photo.path
            const author = imgblock.description
            const imgSrc = getUrlImg(path);
            return (
                <div className='slide' key={imgblock.id}>
                    <img src={imgSrc} alt=""/>
                    <div className='slide__author'>© Автор: {author}</div>
                </div>
            )
        }) : <></>
    }
    const [isMobile] = useMediaQuery(['(max-width: 900px)']);

    const settingsDesctop = {
        customPaging: function (i: number) {
            return (<img src={getUrlImgByIndex(i)}/>);
        },
        dots: true,
        dotsClass: "slideImgDot",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const settingMobile = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        customPaging: () => (<div className='dotMobile'></div>),
    }

    const settings = isMobile ?settingMobile :settingsDesctop;

    return (
        <div className='galleryBlock'>
            <Slider {...settings}>{getAllSlide()}</Slider>
        </div>
    );
};
