import api from 'http/index';
import { AuthProvider } from 'react-admin';

const adminService: AuthProvider = {
  async checkError({ status }: { status: number }) {
    if (status === 401 || status === 403) {
      await api().post('/user/logout');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  login() {
    return Promise.resolve();
  },
  logout() {
    return Promise.resolve();
  },
  checkAuth() {
    return Promise.resolve();
  },
  getPermissions() {
    return Promise.resolve();
  },
};

export default adminService;
