import {TERRITORY_ENUM} from "../../shared/constants/travelGuide.constants";
import {territoryTagCommon} from "../../features/lib/NewsFilter/NewsFiltersByTag";

export const convertTagTeritoryToEnumTerritory = (tag:string): TERRITORY_ENUM => {
   const find =  territoryTagCommon.find(el=>el.label == tag)
   return  find?find.valueEnum:TERRITORY_ENUM.ALL
}

export const convertEnumTerritoryToLabel = (tag: TERRITORY_ENUM) => {
   const find =  territoryTagCommon.find(el=>el.valueEnum == tag)
   return find ? find.label : territoryTagCommon[0].label
}
