const nouns = {
  day: ['день', 'дня', 'дней'],
  man: ['человек', 'человека', 'человек'],
  cash: ['рубль', 'рубля', 'рублей'],
  year: ['год', 'года', 'лет'],
  age: ['года', 'лет', 'лет'],
  product: ['предложение', 'предложения', 'предложений'],
  variant: ['вариант', 'варианта', 'вариантов'],
  excursion: ['экскурсию', 'экскурсии', 'экскурсий'],
  object: ['объект', 'объекта', 'объектов'],
  search: ['Найден', 'Найдено', 'Найдено'],
};

export type TNounsKey = keyof typeof nouns;

export const declensionNoun = (num: number, noun: TNounsKey) => {
  const variants = nouns[noun];
  const str = String(num);
  const lastNum = Number(str[str.length - 1]);
  if (str.length === 2 && num < 20) return variants[2];
  if (lastNum === 1) return variants[0];
  if (lastNum >= 2 && lastNum <= 4) return variants[1];
  return variants[2];
};
