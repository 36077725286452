import { createSelector } from '@reduxjs/toolkit';
import { TFiltersTravelsGuide } from 'shared/types/travelGuide.types';
import { TRootState } from 'store';

export const selectLoading = (state: TRootState) => state.travelsGuide.isLoading;
export const selectTravelsGuide = (state: TRootState) => state.travelsGuide.travelsGuide;
export const selectFiltersTravelsGuide = (state: TRootState) => state.travelsGuide.filtersTravelsGuide;

export const selectFilteredTravelsGuide = createSelector(
  selectTravelsGuide,
  (state: TRootState, filters: TFiltersTravelsGuide) => filters,
  (travelsGuide, filters) => {
    if (!filters.type && !filters.territory && !filters.theme) {
      return travelsGuide;
    }
    return travelsGuide.filter(({ territoryTag, type, locationTags }) => {
      if (filters.territory && filters.territory !== territoryTag) return false;
      if (filters.theme && !locationTags.includes(filters.theme)) return false;
      if (filters.type && filters.type !== type) return false;
      return true;
    });
  }
);
