import { TTour } from 'shared/types/tours.types';
import { TourCard } from 'entities/tour/TourCard/TourCard';

type TToursList = {
  tours: TTour[];
};

export const ToursList = ({ tours }: TToursList) => {
  return (
    <div className='tours-list'>
      {tours.map((tour) => (
        <TourCard key={tour.id} tour={tour} />
      ))}
    </div>
  );
};
