export const phoneValidation = (value: any) => {
  const re = new RegExp("^\\+7\\s\\d\\d\\d\\s\\d\\d\\d\\s\\d\\d\\s\\d\\d+$", 'i');

  if (!re.test(value)) {
    return false
  } else {
    return value
  }
}

export const phoneValidationEmpty = (value: any) => {
  if (!value || value === `+7 ___ ___ __ __`) {
    return false
  } else {
    return value
  }
}