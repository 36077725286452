import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFiltersTours, TTour } from 'shared/types/tours.types';

type TInitialState = {
  tours: TTour[];
  filtersTours: TFiltersTours;
  isLoading: boolean;
};

export const defaultFiltersTours: TFiltersTours = {
  price: [0, Infinity],
  season: [],
  duration: 'Не выбрано',
  types: [],
  locations: [],
};

const initialState: TInitialState = {
  tours: [],
  filtersTours: defaultFiltersTours,
  isLoading: false,
};

export const slice = createSlice({
  name: 'toursMap',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setTours: (state, { payload }: PayloadAction<TTour[]>) => {
      if (!(state.tours.length === 0 && payload.length === 0)) {
        state.tours = [...payload];
      }
    },

    setFiltersTypes: (state, { payload }: PayloadAction<string[]>) => {
      const filters = state.filtersTours;
      state.filtersTours = { ...filters, types: payload };
    },

    setFilters: (state, { payload }: PayloadAction<TFiltersTours>) => {
      state.filtersTours = { ...payload };
    },

    clearFiltersParams: (state) => {
      const types = state.filtersTours.types;
      state.filtersTours = { ...defaultFiltersTours, types };
    },

    clearFilters: (state) => {
      state.filtersTours = { ...defaultFiltersTours };
    },
  },
});

export const { setTours, setLoading, clearFiltersParams, setFiltersTypes, setFilters, clearFilters } = slice.actions;

export default slice.reducer;
