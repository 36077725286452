import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';
import { TRANSFORM_TRAVEL_GUIDE } from 'shared/constants/travelGuide.constants';
import {
  TFiltersTravelsGuide,
  TNamesParamsTravelsGuideFilter,
  TTerritoryTravelGuide,
} from 'shared/types/travelGuide.types';

const itemsTerritory = [
  {
    value: null,
    label: 'Все',
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.putorana.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.putorana.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.norilsk.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.norilsk.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.dudinka.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.dudinka.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.dikson.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.dikson.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.hatanga.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.hatanga.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.villages.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.villages.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.arcticCoast.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.arcticCoast.label,
  },
  {
    value: TRANSFORM_TRAVEL_GUIDE.territory.anabar.value as TTerritoryTravelGuide,
    label: TRANSFORM_TRAVEL_GUIDE.territory.anabar.label,
  },
];

type TToursFiltersByPriceProps = {
  filters: TTerritoryTravelGuide | null;
  onClick: <TName extends TNamesParamsTravelsGuideFilter>(name: TName, value: TFiltersTravelsGuide[TName]) => void;
};

export const TravelsGuideFiltersByTerritory = ({ filters, onClick }: TToursFiltersByPriceProps) => {
  const checkActive = (value: string | null) => {
    return filters === value;
  };

  return (
    <ParamsFilterGroup title='Территории'>
      {itemsTerritory.map((territory) => (
        <ParamsFilterItem
          key={territory.label}
          active={checkActive(territory.value)}
          onClick={() => onClick('territory', territory.value)}
          label={territory.label}
        />
      ))}
    </ParamsFilterGroup>
  );
};
