import { useEffect, useState } from 'react';
import { BaseSelect } from 'shared/ui/BaseSelect/BaseSelect';
import {
  TCategoryTravelGuide,
  TFiltersTravelsGuide,
  TNamesTypeTravelsGuideFilter,
} from 'shared/types/travelGuide.types';

type TItemFilters = {
  id: string;
  label: string;
  value: TCategoryTravelGuide | '';
};

type TToursTypesSelectProps = {
  filters: TFiltersTravelsGuide;
  clickFilter: (filter: TCategoryTravelGuide | null, fieldFilters: TNamesTypeTravelsGuideFilter) => void;
  itemsFilters: TItemFilters[];
};

export const TravelsGuideTypesSelect = ({ filters, clickFilter, itemsFilters }: TToursTypesSelectProps) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [isOpenSelect, setOpenSelect] = useState(false);

  const changeTypesTours = (filter: TCategoryTravelGuide | '', fieldFilters: TNamesTypeTravelsGuideFilter) => {
    clickFilter(filter ? filter : null, fieldFilters);
  };

  const toggleSelect = () => {
    if (isOpenSelect) {
      setLocalFilters(filters);
    }
    setOpenSelect((prev) => !prev);
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <div>
      <BaseSelect
        title='Тип локации'
        items={itemsFilters}
        handlerSelect={changeTypesTours}
        lightBackground
        filters={localFilters.type ? [localFilters.type] : []}
        isOpenSelect={isOpenSelect}
        toggleSelect={toggleSelect}
      />
    </div>
  );
};
