import { selectReviewsList } from 'manageStore/reviews/reviews.select';
import { NoRequests } from 'components/Account/NoRequests';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { ReviewItem } from './ReviewItem';

const MainReviews = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const reviewsList = selectReviewsList();

  if (!reviewsList.length) {
    return <NoRequests title='У вас нет отзывов' type={userData?.type || 'TOURIST'} />;
  }

  return (
    <ul className='Amain-reviews'>
      {reviewsList && reviewsList.length
        ? reviewsList.map((item, index) => <ReviewItem reviewItem={item} key={index} />)
        : null}
    </ul>
  );
};

export default MainReviews;
