import { useEffect, useState } from 'react';
import { selectCity } from 'manageStore/places/placesSlice';
import { useSelector } from 'react-redux';
import { selectFood, selectLodgings, selectAttractions } from 'manageStore/places/placesSlice';
import PropTypes from 'prop-types';
import OsmMap from '../OsmMap/OsmMap';
import { TPointOsmMap } from 'shared/types/location.types';
import { PLACE_TYPES } from 'shared/constants/const';

type TTouristMapProps = {
  activeBtn: string;
};

const TouristMap = ({ activeBtn }: TTouristMapProps) => {
  const [isShowMap, setShowMap] = useState(false);
  const [activePoints] = useState<TPointOsmMap[]>([]);
  const [center, setCenter] = useState([69.34398, 88.21039]);

  const activeCity = useSelector(selectCity);
  const food = useSelector(selectFood);
  const lodgings = useSelector(selectLodgings);
  const attractions = useSelector(selectAttractions);

 /* const getActivePoints = (obgs: Array<TLodging | TFood | TAttraction>, locationType: string[]) => {
    // return obgs
    //   .filter((item) => item.city === activeCity?.id)
    //   .map((loc) => ({ lat: loc.lat, lng: loc.lng, infoLoc: { ...loc, locationType } }));
  };*/

  useEffect(() => {
    if (activeBtn === PLACE_TYPES.lodging && lodgings.length) {
      // setActivePoints(getActivePoints(lodgings, [PLACE_TYPES.lodging]));
    }
    if (activeBtn === PLACE_TYPES.food && food.length) {
      // setActivePoints(getActivePoints(food, [PLACE_TYPES.food]));
    }
    if (activeBtn === PLACE_TYPES.attraction && attractions.length) {
      // setActivePoints(getActivePoints(attractions, [PLACE_TYPES.attraction]));
    }
  }, [activeBtn, lodgings, food, attractions, activeCity]);

  useEffect(() => {
    if (!activeCity) return;
    const { lat, lng } = activeCity;
    if (lat && lng) {
      setCenter([lat, lng]);
    }
  }, [activeCity]);

  useEffect(() => {
    setTimeout(() => {
      setShowMap(true);
    }, 300);
  }, []);

  return (
    <section className='tourist-map'>
      <div className='container'>
        <div className='tourist-map__inner'>
          {isShowMap && <OsmMap locations={activePoints} center={center} tourist />}
        </div>
      </div>
    </section>
  );
};

TouristMap.propTypes = {
  lodgings: PropTypes.array,
  food: PropTypes.array,
  attractions: PropTypes.array,
};

export default TouristMap;
