//@ts-nocheck
import { ReactNode, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import s from './index.module.scss';
import { Placement } from '@popperjs/core';
import { useClickAway } from 'react-use';
import Portal from 'newProject/features/portal/ui';

interface PopperCustomProps {
  children: ReactNode;
  placement: Placement;
  render: ReactNode;
}

const PopperCustom = ({ children, render, placement = 'auto' }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const ref = useRef(popperElement);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [{ name: 'preventOverflow', enabled: false }],
    strategy: 'fixed',
  });
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    ref.current = popperElement;
  }, [popperElement]);
  useClickAway(ref, (e) => {
    if (referenceElement && !referenceElement.contains(e.target)) {
      setOpen(false);
    }
  });
  return (
    <>
      <button onClick={() => setOpen(!isOpen)} ref={setReferenceElement}>
        {children}
      </button>
      {isOpen && (
        <Portal>
          <div className={s.popper} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {render}
          </div>
        </Portal>
      )}
    </>
  );
};

export default PopperCustom;
