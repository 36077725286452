import * as React from 'react';
import { Show, SimpleShowLayout, FunctionField } from 'react-admin';
import { GALLERY_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const GalleryShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <FunctionField
          label={GALLERY_FIELDS.uploadedFiles}
          render={(record) => {
            if (!Array.isArray(record?.uploadedFiles)) return null;
            return record?.uploadedFiles?.map((uploadedFile, index) => (
              <img
                key={`beauty_${index}`}
                className='admin-image admin-image__multi'
                src={`${URL_FOR_IMG}/${uploadedFile.path}`}
                alt="beauty"
              />
            ));
          }}
        />
      </SimpleShowLayout>
    </Show>
  </div>
);

export default GalleryShow;
