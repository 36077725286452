import { cva } from 'class-variance-authority';
import React, { useEffect, useRef } from 'react';
import ApplicationsActions from '../application-actions/ui';
import ApplicationChat from '../application-chat/ui';
import ContainerContent from '../../../entities/container';
import { useMedia } from 'react-use';
import s from './index.module.scss';
import { useAppSelector } from '../../../../shared/hooks/useAppSelector';
import { selectCurrentRequest, selectMessageList } from '../../../../manageStore/request/request.select';
import { TDocFetchWithUser } from 'shared/types/common.types';
import { TChatMessage } from 'shared/types/request.types';

const cvaApplication = cva([s.application]);
type Props = {
  docs?: TDocFetchWithUser[];
  messages: TChatMessage[] | null
};
export default function Application({ docs, messages }: Props) {
  const isLg = useMedia('(min-width: 1240px)');
  const request = selectCurrentRequest();

  return (
    <ContainerContent noMobile className={cvaApplication()}>
      {messages && request && (
        <ApplicationChat
          request={request}
          isClose={request?.status === 'DELETED' || request?.status === 'DONE'}
          messages={messages}
        />
      )}
      {isLg && messages && request && (
        <div>
          <ApplicationsActions application={request} files={docs} />
        </div>
      )}
    </ContainerContent>
  );
}
