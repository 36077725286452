import { useController } from 'react-hook-form';
import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { ArrayInput, FormDataConsumer, Labeled, SimpleFormIterator, TextInput, required } from 'react-admin';
import Box from '@mui/material/Box';
import FileInputAdmin from '../FileInputAdmin';
import {BLOG_FIELDS} from "../../../shared/constants/const";

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '0 20px',
  marginBottom: '20px',
};

export const BlogGallery = () => {
  useController({ name: 'galleryWithAuthor', rules: { required: 'Required' } });

  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <Labeled label={BLOG_FIELDS.galleryWithAuthor} fullWidth >
            <Box sx={styleInBlock}>
              <ArrayInput source='galleryWithAuthor' label='' fullWidth>
                <SimpleFormIterator disableReordering disableAdd={formData?.galleryWithAuthor?.length > 4}>
                  <FileInputAdmin source='photo' label='Фотография' multiple={false} validate={[required()]} />
                  <TextInput
                    source='description'
                    label='Автор'
                    fullWidth
                    validate={[required()]}
                    format={(v) => (v ? v : '')}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Labeled>
        );
      }}
    </FormDataConsumer>
  );
};
