import { URL_FOR_IMG } from '../../shared/constants/const';

const PointReadyTourBalloon = ({ loc }) => {
  return (
    <div className='balloon-content'>
      <h4 className='balloon-title'>{loc.name}</h4>
      {loc.preview?.path ? <img src={`${URL_FOR_IMG}/${loc.preview.path}`} alt='Изображение локации' /> : null}
      <p className='balloon-text'>{loc.description}</p>
    </div>
  );
};

export default PointReadyTourBalloon;
