import { TFiltersTours, TNamesParamsTourFilter } from '../../../shared/types/tours.types';
import {useDispatch, useSelector} from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useOnScreen } from '../../../shared/hooks/useOnScreen';
import { useAppSelector } from '../../../shared/hooks/useAppSelector';
import classNames from 'classnames';
import { ButtonFilter, PortalFilter } from '../../shared';
import {itemsTypeNews, NewsFiltersByType} from './NewsFiltersByType';
import {
  getPublishedNews, selectBlogList, selectfilterBlogs,
  selectNewsList,
  selectSelectedTag,
  setFilteredNews,
  setSelectedTag, tempSelectfilterBlogs
} from '../../../manageStore/news/newsSlice';
import { NewsFiltersByTag } from './NewsFiltersByTag';
import { TNew } from '../../../shared/types/types';

type TToursFiltersProps = {
  filters: TFiltersTours;
  changeFiltersTours: (type: string) => void;
  withParams?: boolean;
  withFixed?: boolean;
  isMulti?: boolean;
};

export const NewsFilters = ({ filters, changeFiltersTours, withParams, withFixed }: TToursFiltersProps) => {
  const dispatch = useDispatch();
  const filtersRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(filtersRef);

  const [isOpenPortal, setOpenPortal] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);

  const [filterNewsList, setFilterNewsList] = useState<TNew[]>([]);

  const news = useAppSelector(selectNewsList);
  const blogs = useAppSelector(tempSelectfilterBlogs);

  const selectedTag = useSelector(selectSelectedTag)

  const isActiveFiltersParams = !!filterNewsList.length;

  const isActiveClearButton = !!localFilters.season.length || !!selectedTag.length;

  const togglePortal = () => {
    if (isOpenPortal) {
      setLocalFilters(filters);
    }
    setOpenPortal((prev) => !prev);
  };

  const changeParamsTours = <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours['season']) => {
    dispatch(setSelectedTag(value));
  };

  const clearParamsFilters = () => {
    setLocalFilters((prev) => ({ ...prev, season: [] }));
    dispatch(setSelectedTag([itemsTypeNews[0].label]));
  };

  const showNews = () => {
    togglePortal();

  };
  useEffect(()=>{
    dispatch(setSelectedTag([itemsTypeNews[0].label]));
  }, [])

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <>
      <div className={classNames('tours-filters', { 'tours-filters_full': !withParams })} ref={filtersRef}>
        <div className={classNames('tours-filters__container', { fixed: withFixed && !isOnScreen })}>
          <div className='tours-filters__content'>
            <div className='tours-filters__types-list'>
              <NewsFiltersByType filters={filters} clickFilter={changeFiltersTours} isMulti={true} />
            </div>
            {withParams && <ButtonFilter openFilters={togglePortal} isActiveFiltersParams={isActiveFiltersParams} />}
          </div>
        </div>
      </div>
      {isOpenPortal && (
        <PortalFilter
          togglePortal={togglePortal}
          clearFilters={clearParamsFilters}
          applyFilters={showNews}
          countVariants={blogs.length}
          isActiveClearButton={isActiveClearButton}
        >
          <NewsFiltersByTag filters={localFilters.season} onClick={changeParamsTours} />
        </PortalFilter>
      )}
    </>
  );
};
