import { ViewOptions } from 'ol/View';
import { fromLonLat } from 'ol/proj';

export const getOsmViewOptions = ({ center, ...args }: ViewOptions): ViewOptions => {
  return {
    enableRotation: false,
    extent: [8300000, 10200000, 13000000, 17000000], //ldrt
    center: fromLonLat(center || [88.224851, 69.346734]),
    maxZoom: 17,
    zoom: 10,
    ...args,
  };
};
