import { Datagrid, List, TextField, EditButton, Pagination } from 'react-admin';
import {MENU_ADMIN_LIST, CLASTER_FIELD} from '../../../shared/constants/const';

export const ClustorList = () => (
  <List title={MENU_ADMIN_LIST.cluster} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source='name' sortable={false} label={CLASTER_FIELD.name} />
      <EditButton />
    </Datagrid>
  </List>
);
