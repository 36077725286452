import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import TravelGuideService from 'services/travelGuide.service';
import { setLoading, setTravelsGuide } from './travelsGuide.slice';

export const fetchTravelsGuideService = createAsyncThunk('fetchTravelsGuideService', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await TravelGuideService.getTravelsGuide();
    dispatch(setTravelsGuide(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});
