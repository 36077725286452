import { useRef } from 'react';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { PartnersFiltersByType } from './PartnersFiltersByType';
import { TCategoryPartner, TFiltersPartners, TNamesTypePartnersFilter } from 'shared/types/partner.types';
import classNames from 'classnames';

type TPartnersFiltersProps = {
  filters: TFiltersPartners;
  changeFiltersPartners: (type: TCategoryPartner, fieldFilters: TNamesTypePartnersFilter) => void;
};

export const PartnersFilters = ({ filters, changeFiltersPartners }: TPartnersFiltersProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const isOnscreen = useOnScreen(listRef);

  return (
    <div className='partners-filters' ref={listRef}>
      <div className={classNames('partners-filters__types-list', { fixed: !isOnscreen })}>
        <PartnersFiltersByType filters={filters} clickFilter={changeFiltersPartners} />
      </div>
    </div>
  );
};
