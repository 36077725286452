import { VariantProps, cva } from "class-variance-authority";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import s from './index.module.scss'

type Props = {
  icon: ReactNode;
  children: ReactNode;
  href?: string;
  onClick?: () => void;
} & VariantProps<typeof cvaIconText>;

const cvaIconText = cva([s.iconText], {
  variants: {
    color: {
      green: [s.green],
      red: [s.red],
    },
  },
});

export default function IconText({
  children,
  icon,
  color,
  href,
  onClick,
}: Props) {
  return href ? (
    <Link to={href} className={cvaIconText({ color })}>
      {icon} <span>{children}</span>
    </Link>
  ) : (
    <div onClick={onClick} className={cvaIconText({ color })}>
      {icon} <span>{children}</span>
    </div>
  );
}
