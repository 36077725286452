import * as React from 'react';
import { Datagrid, List, TextField, Pagination } from 'react-admin';
import { MENU_ADMIN_LIST } from '../../../shared/constants/const';

const MessageList = () => {
  return (
    <List title={MENU_ADMIN_LIST.tours} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
      <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
        <TextField source='name' sortable={false} label={'Имя'} />
        <TextField source='email' sortable={false} label={'Email'} />
        <TextField source='message' sortable={false} label={'Сообщение'} />
      </Datagrid>
    </List>
  );
};

export default MessageList;
