import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
  ImageField,
  Pagination,
  FunctionField,
  RaRecord,
} from 'react-admin';
import { Chip } from '@mui/material';
import { MENU_ADMIN_LIST } from '../../../shared/constants/const';
import { LOCATIONS_FIELDS } from 'shared/constants/locations.constants';

export const LocationsList = () => (
  <List title={MENU_ADMIN_LIST.locations} exporter={false} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}>
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source='name' sortable={false} label={LOCATIONS_FIELDS.title} />
      <FunctionField
        label={LOCATIONS_FIELDS.locationActive}
        render={(record: RaRecord) => {
          return record.locationType?.map((type: string) => <Chip label={type} key={type} />);
        }}
        sx={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}
      />
      <ImageField source='preview' sortable={false} label={LOCATIONS_FIELDS.preview} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
