import { useCallback, useEffect, useState, useMemo } from 'react';
import Map from 'ol/Map';
import { defaults } from 'ol/interaction/defaults';

export const useToggleActiveMap = (map: Map | null, initActive: boolean): [boolean, () => void] => {
  const [isActiveMap, setActiveMap] = useState(initActive);
  const defaultsInteractions = useMemo(() => defaults().getArray(), []);

  const toggleActiveMap = useCallback(() => {
    setActiveMap((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!map) return;
    const interactions = map.getInteractions();
    isActiveMap ? interactions.extend(defaultsInteractions) : interactions.clear();
  }, [map, isActiveMap]);

  return [isActiveMap, toggleActiveMap];
};
