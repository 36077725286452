import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import RequestService from 'services/request.service';
import { requestActions } from './request.slice';

export const getRequestByIdThunk = createAsyncThunk('getRequestsList', async (id: number, { dispatch }) => {
  const { setIsLoading, setCurrentRequest, setMessagesList } = requestActions;
  dispatch(setIsLoading(true));
  try {
    const { data } = await RequestService.fetchById(id);
    dispatch(setCurrentRequest(data));
    const messagesList = [...data.messages];
    messagesList.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());
    dispatch(setMessagesList(messagesList));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setIsLoading(false));
  }
});
