import React, { ChangeEvent } from 'react';
import { Field } from 'formik';
import { cvaInput, cvaLabel } from '../input_styles';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import s from './index.module.scss';

type Props = {
  asFormikField?: boolean;
  hasError?: unknown;
  placeholder?: string;
  error?: string;
  className?: string;
  value?: string;
  disabled?: boolean;
  type?: 'text' | 'password';
  mask?: string;
  label?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
};

export default function CustomField({
  placeholder,
  onChange,
  name,
  hasError,
  error,
  mask,
  value,
  disabled,
  label,
  type = 'text',
  required,
  className,
  asFormikField,
}: Props) {
  return (
    <div className={className}>
      {mask ? (
        <div>
          {label && <div className={cvaLabel({ required: required })}>{label}</div>}
          <InputMask
            mask={mask}
            value={value}
            disabled={disabled}
            className={clsx(cvaInput({ hasError: Boolean(hasError), disabled }))}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      ) : asFormikField ? (
        <div>
          {label && <div className={cvaLabel({ required: required })}>{label}</div>}
          <Field
            value={value}
            disabled={disabled}
            className={clsx(cvaInput({ hasError: Boolean(hasError), disabled }))}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      ) : (
        <div>
          {label && <div className={cvaLabel({ required: required })}>{label}</div>}
          <input
            value={value}
            disabled={disabled}
            className={clsx(cvaInput({ hasError: Boolean(hasError), disabled }))}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      )}
      {Boolean(hasError) && <div className={s.err}>{error}</div>}
    </div>
  );
}
