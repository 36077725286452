import { Helmet } from 'react-helmet';
import Footer from 'widgets/lib/Footer/Footer';
import Header from 'components/Header/Header';
import { PartnerItemMain } from 'widgets/lib/partnerItemMain/PartnerItemMain/PartnerItemMain';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';
import { useDispatch, useSelector } from 'react-redux';
import { selectPartners } from 'manageStore/partners/partners.select';
import { useEffect } from 'react';
import { fetchPartners } from 'manageStore/partners/partners.thunk';

export const PartnerItemPage = () => {
  const dispatch = useDispatch();
  const partners = useSelector(selectPartners);

  useEffect(() => {
    if (!partners) {
      dispatch(fetchPartners());
    }
  }, [partners]);

  return (
    <>
      <Helmet>
        <title>Организаторы путешествий - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isFixed />
      <AuthPortal />
      <PartnerItemMain />
      <Footer />
    </>
  );
};
