import { PrivilegeCard } from './PrivilegeCard';
import { ReactComponent as BookIcon } from 'assets/images/trk/trk-book.svg';
import BookBg from 'assets/images/trk/trk-bg-book.jpeg';
import { TPrivilegeItem } from '../../../../../shared/types/aboutUs.types';

type TTPrivilegesProps = {
  privilegeItems: TPrivilegeItem[];
};
export const PrivilegesSectionDesktop = ({privilegeItems}:TTPrivilegesProps) => {
  return (
      <>
        <div className='privileges-content__grid-2'>
          {privilegeItems.map((privilegeItem) => (
              <PrivilegeCard key={privilegeItem.id} privilegeItem={privilegeItem} />
          ))}
        </div>
        <div className='privileges-card book-card'>
          <div><BookIcon className='privileges-card__icon' /></div>
          <p className='privileges-card__label'> Обучение и участие <br/> в мероприятиях Кластера</p>
          <img src={BookBg} alt='Обучение' className='book-card__img' />
        </div>
      </>
  );
};
