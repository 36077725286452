import * as React from 'react';
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Pagination,
  BooleanField
} from 'react-admin';
import { MENU_ADMIN_LIST, BLOG_FIELDS } from '../../../shared/constants/const';

const BlogList = () => (
  <List
    title={MENU_ADMIN_LIST.blog}
    exporter={false}
    pagination={<Pagination rowsPerPageOptions={[]} />}
  >
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField
        source="name"
        sortable={false}
        label={BLOG_FIELDS.name}
      />
      <BooleanField
        source="isImportant"
        sortable={false}
        label={BLOG_FIELDS.isImportant}
      />
      <BooleanField
        source="isPublished"
        sortable={false}
        label={BLOG_FIELDS.isPublished}
      />
      <EditButton />
    </Datagrid>
  </List>
);

export default BlogList;
