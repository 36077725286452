import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import React from "react";
import styles from './index.module.scss'

type Props = {
  src?: string | null;
  alt?: string;
  name?: string;
  surname?: string;
} & VariantProps<typeof cvaAvatar>;

const cvaAvatar = cva([styles.avatar], {
  variants: {
    size: {
      22: [styles.avatarSize22],
      40: [styles.avatarSize40],
      90: [styles.avatarSize90],
    },
    intent: {
      dark: [styles.avatarDark],
      active: [styles.avatarActive],
      light: [styles.avatarLight],
    },
  },
  defaultVariants: {
    size: 40,
    intent: "dark",
  },
});

export default function Avatar({
     src,
     size = 40,
     alt,
     intent,
     name,
     surname,
   }: Props) {
  const firstSymbol = (str?: string): string =>
    str?.charAt(0).toUpperCase() ?? "";

  return (
    <div
      className={clsx(
        cvaAvatar({ size, intent }),
        !src && styles.flexCenter
      )}
    >
      {src ? (
        <img
          className={styles.image}
          width={size?.toString()}
          height={size?.toString()}
          src={src}
          alt={alt}
        />
      ) : (
        <span className={styles.spanClass}>
          {firstSymbol(name) + firstSymbol(surname)}
        </span>
      )}
    </div>
  );
}
