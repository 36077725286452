import { useEffect, useState } from 'react';

export const useMediaQuery = (queries = ['(max-width: 1123px)']) => {
  const mediaQueryLists = queries.map((query) => matchMedia(query));
  const getMatches = () => mediaQueryLists.map((list) => list.matches);

  const [matches, setMatches] = useState<boolean[]>(getMatches());

  const handleChange = () => {
    setMatches(getMatches());
  };

  useEffect(() => {
    handleChange();

    mediaQueryLists.forEach((list) => list.addEventListener('change', handleChange));

    return () => {
      mediaQueryLists.forEach((list) => list.removeEventListener('change', handleChange));
    };
  }, []);

  return matches;
};
