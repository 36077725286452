import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { selectTravelsGuide } from 'manageStore/travelsGuide/travelsGuide.select';
import { fetchTravelsGuideService } from 'manageStore/travelsGuide/travelsGuide.thunk';
import { clearFilters } from 'manageStore/travelsGuide/travelsGuide.slice';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import { TravelGuidesMain } from 'widgets/lib/travelGuidesMain/TravelGuidesMain/TravelGuidesMain';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

export const TravelGuidesPage = () => {
  const dispatch = useDispatch();
  const travelsGuide = useSelector(selectTravelsGuide);

  useEffect(() => {
    if (travelsGuide && !travelsGuide.length) {
      dispatch(fetchTravelsGuideService());
    }
  }, [travelsGuide]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Путеводитель - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isFixed />
      <AuthPortal />
      <TravelGuidesMain />
      <Footer />
    </>
  );
};
