import { FunctionField, Labeled } from 'react-admin';
import OsmMap from 'components/OsmMap/OsmMap';
import BrendMap from 'components/BrendMap/BrendMap';
import { LOCATIONS_FIELDS } from 'shared/constants/locations.constants';

const OnceMap = ({ record }) => {
  const { city, lat, lng, map2DLat, map2DLng } = record;
  const center = city ? [lat, lng] : [map2DLat, map2DLng];
  const locations = city ? [{ lat, lng }] : [{ map2DLat, map2DLng }];
  return (
    <div style={{ width: '100%', height: '500px' }}>
      {record?.city ? (
        <OsmMap center={center} locations={locations} admin />
      ) : (
        <BrendMap center={center} locations={locations} admin />
      )}
    </div>
  );
};

const BothMaps = ({ record }) => {
  const { lat, lng, map2DLat, map2DLng } = record;
  return (
    <>
      <div style={{ width: '100%', height: '500px' }}>
        <OsmMap center={[lat, lng]} locations={[{ lat, lng }]} admin />
      </div>
      <div style={{ width: '100%', height: '500px' }}>
        <BrendMap center={[map2DLat, map2DLng]} locations={[{ map2DLat, map2DLng }]} admin />
      </div>
    </>
  );
};

const CoordsShow = ({ bothMaps }) => {
  return (
    <Labeled label={LOCATIONS_FIELDS.coords} fullWidth>
      <FunctionField render={(record) => (bothMaps ? <BothMaps record={record} /> : <OnceMap record={record} />)} />
    </Labeled>
  );
};

export default CoordsShow;
