import { Datagrid, List, TextField, EditButton, ShowButton, Pagination, FunctionField } from 'react-admin';
import { MENU_ADMIN_LIST, PARTNERS_FIELDS, TRANSFORM_TEXT } from '../../../shared/constants/const';
import { TPartner } from 'shared/types/partner.types';

export const PartnersList = () => (
  <List title={MENU_ADMIN_LIST.locations} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source='name' sortable={false} label={PARTNERS_FIELDS.title} />
      <FunctionField
        label={PARTNERS_FIELDS.category}
        render={({ tag }: TPartner) => TRANSFORM_TEXT.partners[tag].label}
      />
      <TextField source='contactPhone' sortable={false} label={PARTNERS_FIELDS.phone} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
