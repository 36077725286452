import { useEffect, useState } from 'react';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer.js';
import { composeCssTransform } from 'ol/transform.js';
import territoryImage from 'assets/map/territory.svg';

export const useTerritoryMap = (map: Map | null) => {
  const [territoryLayer, setTerritoryLayer] = useState<Layer | null>(null);

  useEffect(() => {
    if (!map || territoryLayer) return;
    const svgContainer = document.createElement('div');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', territoryImage);
    xhr.addEventListener('load', function () {
      const xml = xhr.responseXML;
      if (xml) {
        const svg = xhr.responseXML?.documentElement;
        svgContainer.ownerDocument.importNode(svg);
        svgContainer.appendChild(svg);
      }
    });
    xhr.send();

    const width = 130;
    const height = 137;
    const svgResolution = 400 / width;
    svgContainer.style.width = width + 'px';
    svgContainer.style.height = height + 'px';
    svgContainer.style.transformOrigin = 'top left';
    svgContainer.className = 'svg-territory-layer';

    const layer = new Layer({
      visible: false,
      render: function (frameState) {
        const scale = svgResolution / frameState.viewState.resolution;
        const center = frameState.viewState.center;
        const size = frameState.size;
        const cssTransform = composeCssTransform(
          size[0] / 2,
          size[1] / 2,
          scale,
          scale,
          frameState.viewState.rotation,
          -center[0] / svgResolution - width / 2,
          center[1] / svgResolution - height / 2
        );
        svgContainer.style.transform = cssTransform;
        return svgContainer;
      },
    });
    map.addLayer(layer);
    setTerritoryLayer(layer);
  }, [map, territoryLayer]);

  return [territoryLayer];
};
