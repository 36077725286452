import { useEffect, useRef } from 'react';
import { selectCurrentRequest, selectMessageList } from 'manageStore/request/request.select';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { REQUEST_STATUSSES, USER_TYPES } from 'shared/constants/const';
import { MessageItem } from './MessageItem';
import { AutoMessageReview } from './AutoMessages/AutoMessageReview';

export const MessagesList = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const messages = selectMessageList();
  const request = selectCurrentRequest();

  const messagesWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!messagesWrapper.current) return;
    const scrollHeight = messagesWrapper.current.scrollHeight;
    messagesWrapper.current.scrollTo({ top: scrollHeight, behavior: 'smooth' });
  }, [messages]);

  return (
    <div className='chat-body__messages' ref={messagesWrapper}>
      {messages?.map((msg) => (
        <MessageItem key={msg.id} msg={msg} />
      ))}
      {request?.status === REQUEST_STATUSSES.done && userData?.type === USER_TYPES.tourist && <AutoMessageReview />}
    </div>
  );
};
