import classNames from 'classnames';
import { InputHTMLAttributes, PropsWithChildren } from 'react';

interface TBaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  showError: boolean;
  errorText: string | undefined;
  rootClassName?: string;
}

export const BaseInput = ({
  showError,
  errorText,
  rootClassName,
  children,
  ...inputAttributes
}: PropsWithChildren<TBaseInputProps>) => {
  return (
    <div className={classNames('input-group', rootClassName)}>
      <input className='input-group__input' placeholder='E-mail' id='email' type='email' {...inputAttributes} />
      {children}
      {showError ? <div className='input-group__error'>{errorText}</div> : null}
    </div>
  );
};
