type TYoutubeVideoProps = {
  link: string;
};

export const YoutubeVideo = ({ link }: TYoutubeVideoProps) => {
  return (
    <iframe
      title='Видео'
      src={`https://www.youtube-nocookie.com/embed/${link}?rel=0&showinfo=0&autoplay=1`}
      frameBorder='0'
      allowFullScreen
    />
  );
};
