import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { TextSplitParagraph } from 'widgets/shared/TextSplitParagraph/TextSplitParagraph';

type TTravelGuideItemDescriptionProps = {
  description: string;
  className: string;
};

export const TravelGuideItemDescription = ({
  children,
  className,
  description,
}: PropsWithChildren<TTravelGuideItemDescriptionProps>) => {
  return (
    <section className={classNames('travel-guide-item-description', className)}>
      <div className='container travel-guide-item__container description'>
        <div className='description__content'>
          <h2 className='description__title'>Описание</h2> 
          <div className='description__text'>
            <TextSplitParagraph text={description} color='white' />
          </div>
        </div>
        <div className='description__params'>{children}</div>
      </div>
    </section>
  );
};
