import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOKENS_CESIUM } from 'shared/constants/const';
import { TTilesetData, TToken } from 'shared/types/cesiumMap.types';

type TInitialState = {
  isLoading: boolean;
  history: boolean;
  token: TToken;
  assets: never[];
  tilesetsData: TTilesetData[];
};

const initialState: TInitialState = {
  isLoading: false,
  history: false,
  token: TOKENS_CESIUM.dudinka,
  assets: [],
  tilesetsData: [],
};

export const cesiumSlice = createSlice({
  name: 'cesium',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.token = payload ? TOKENS_CESIUM.dudinka_history : TOKENS_CESIUM.dudinka;
      state.history = payload;
    },
    setAssets: (state, { payload }: PayloadAction<never[]>) => {
      state.assets = payload;
    },
    setTilesetsData: (state, { payload }: PayloadAction<TTilesetData[]>) => {
      state.tilesetsData = payload;
    },
  },
});

export const cesiumActions = cesiumSlice.actions;
export const cesiumReducer = cesiumSlice.reducer;
