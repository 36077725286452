import api from 'http/index';
import { store } from '../store';
import { logout, refreshTokens } from 'manageStore/user/userSlice';
import { TUserProfile } from 'shared/types/types';

export default class UserService {
  static async fetchUsers() {
    return api(store.dispatch, logout, refreshTokens).get('/user');
  }

  static async savePhoto(file: File) {
    const formData = new FormData();
    formData.append('data', file);
    return api(store.dispatch, logout, refreshTokens).post('/file', formData);
  }

  static async updateProfileData(sendParams: TUserProfile) {
    return api(store.dispatch, logout, refreshTokens).post('/user/update/profile', sendParams);
  }

  static async updatePassword(id: number, oldPass: string, newPass: string, newPass2: string) {
    return api(store.dispatch, logout, refreshTokens).post('/user/change/password', { id, oldPass, newPass, newPass2 });
  }
}
