import { FileInput, FunctionField, FileField, Validator, RaRecord } from 'react-admin';
import { useFormState, FieldErrorsImpl, DeepRequired, FieldValues } from 'react-hook-form';
import { URL_FOR_IMG } from '../../shared/constants/const';
import { TSingleFile } from 'shared/types/common.types';

const SIZE_PHOTO = { maxWidth: '250px', maxHeight: '250px' };

type TNewFile = {
  rawFile: File;
};

const maxSize =
  (message = 'Maximum file size is 5 MB') =>
  (value: TSingleFile | TNewFile | Array<TSingleFile | TNewFile | string> | null | string) => {
    if (!value || typeof value === 'string') return;
    if (Array.isArray(value)) {
      const notValid = value.some((f) => {
        if (!f || typeof f === 'string') return false;
        if ('size' in f) {
          return f.size ? f.size > 5242880 : false;
        }
        return f.rawFile.size ? f.rawFile.size > 5242880 : false;
      });
      return notValid ? message : undefined;
    }
    if ('size' in value) {
      return value.size && value.size > 5242880 ? message : undefined;
    } else {
      return value.rawFile.size && value.rawFile.size > 5242880 ? message : undefined;
    }
  };

type TFileInputAdminProps = {
  source: string;
  label: string;
  multiple?: boolean;
  validate?: Validator[];
  accept?: string;
  isDoc?: boolean;
};

const FileInputAdmin = ({ source, label, multiple, validate, accept, isDoc }: TFileInputAdminProps) => {
  const { errors } = useFormState();
  const styles: Record<string, Record<string, string>> = {
    '& .MuiFormHelperText-root': { margin: '4px 14px 0', color: '#d32f2f' },
  };
  const inputClass = `& .RaFileInput-dropZone`;

  const error = source
    .split('.')
    .reduce<FieldErrorsImpl<DeepRequired<FieldValues>> | undefined>((acc, item) => (acc ? acc[item] : acc), errors);

  if (error) {
    styles[inputClass] = { borderBottom: '2px solid #d32f2f' };
  }

  return (
    <FileInput
      sx={styles}
      source={source}
      label={label}
      multiple={multiple}
      accept={accept || 'image/png, image/jpg, image/jpeg'}
      placeholder={
        <p style={{ padding: '20px 0' }}>
          <span style={{ fontSize: '12px' }}>Pick or Drop File here to upload it.</span>
          <br />
          <span style={{ fontSize: '11px' }}>maximum file size is 5 MB</span>
          <br />
          <span style={{ fontSize: '11px' }}>{accept || 'image/png, image/jpg, image/jpeg'}</span>
        </p>
      }
      fullWidth
      validate={validate ? [...validate, maxSize()] : [maxSize()]}
    >
      {isDoc ? (
        <FileField source='undefined' title='title' />
      ) : (
        <FunctionField
          render={(record: RaRecord) => {
            if (record.rawFile) {
              return <img src={record.undefined} alt={source} style={SIZE_PHOTO} />;
            }
            return <img src={`${URL_FOR_IMG}/${record.path}`} alt={source} style={SIZE_PHOTO} />;
          }}
        />
      )}
    </FileInput>
  );
};

export default FileInputAdmin;
