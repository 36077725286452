import { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPlaces,
  getCities,
  setActiveCity,
  selectFood,
  selectLodgings,
  selectAttractions,
  selectCities,
} from 'manageStore/places/placesSlice';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import Tourist from 'components/Tourist/Tourist';
import TouristMap from 'components/TouristMap/TouristMap';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import { TouristInfo } from 'components/TouristInfo/TouristInfo';

const TouristPage = () => {
  const dispatch = useDispatch();
  const food = useSelector(selectFood);
  const lodgings = useSelector(selectLodgings);
  const attractions = useSelector(selectAttractions);
  const cities = useSelector(selectCities);

  const [activeBtn, setActiveBtn] = useState('lodging');

  const btnsHandler = useCallback((e) => {
    setActiveBtn(e.currentTarget.dataset.name);
  }, []);

  useEffect(() => {
    if (!cities.length) {
      dispatch(getCities());
    } else {
      const city = cities.find((item) => item.name === 'Норильск');
      if (city) dispatch(setActiveCity(city));
    }
  }, [cities, dispatch]);

  useEffect(() => {
    if (activeBtn === 'lodging' && !lodgings.length) {
      dispatch(getPlaces('lodging'));
    }
    if (activeBtn === 'food' && !food.length) {
      dispatch(getPlaces('food'));
    }
    if (activeBtn === 'attraction' && !attractions.length) {
      dispatch(getPlaces('attraction'));
    }
  }, [activeBtn, lodgings, food, attractions, dispatch]);

  return (
    <>
      <Helmet>
        <title>Для туриста - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <div className='content tourist-page'>
        <Header />
        <Tourist activeBtn={activeBtn} btnsHandler={btnsHandler} />
        <TouristMap activeBtn={activeBtn} />
        <TouristInfo />
        <MobileBottom />
      </div>
      <Footer />
    </>
  );
};

export default TouristPage;
