import { cva } from "class-variance-authority";
import clsx from "clsx";
import s from './index.module.scss'
import React from "react";

const cvaSeparator = cva([s.separator]);
type Props = {
  className?: string;
};

export default function Separator({ className }: Props) {
  return <div className={clsx(cvaSeparator(), className)}></div>;
}
