import { TReviewFetchRequest, TReviewFetchResponse } from 'shared/types/review.types';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { ReviewHeader } from './ReviewHeader';
import { ReviewContent } from './ReviewContent';
import { ReviewForm } from './ReviewForm';
import { editReviewThunk } from 'manageStore/reviews/reviews.thunk';
import { reviewsActions } from 'manageStore/reviews/reviews.slice';
import { selectEdittedReview } from 'manageStore/reviews/reviews.select';
import { useEffect, useState } from 'react';

type TReviewItemProps = { reviewItem: TReviewFetchResponse };

export const ReviewItem = ({ reviewItem }: TReviewItemProps) => {
  const dispatch = useAppDispatch();
  const { setEdittedReview } = reviewsActions;

  const userData = useAppSelector((state) => state.user.userData);
  const edittedReview = selectEdittedReview();

  const [editItem, setEditItem] = useState(false);

  const handleEdit = () => {
    dispatch(setEdittedReview(reviewItem));
  };

  const cancelEditing = () => {
    dispatch(setEdittedReview(null));
  };

  const sendReview = async (newReview: TReviewFetchRequest) => {
    await dispatch(editReviewThunk({ ...newReview, reviewId: reviewItem.id }));
  };

  useEffect(() => {
    setEditItem(reviewItem.id === edittedReview?.id);
  }, [reviewItem, edittedReview]);

  if (!userData) {
    return null;
  }

  return (
    <li className='Amain-review'>
      <ReviewHeader reviewItem={reviewItem} reviewEdit={editItem} handleEdit={handleEdit} />
      {editItem ? (
        <ReviewForm review={edittedReview} closeForm={cancelEditing} handleSubmit={sendReview} />
      ) : (
        <ReviewContent reviewItem={reviewItem} />
      )}
    </li>
  );
};
