import { useEffect, useState } from 'react';
import OsmMap from 'components/OsmMap/OsmMap';
import { toLonLat } from 'ol/proj';
import { useFormContext, useFormState, useController } from 'react-hook-form';
import { Labeled, useRecordContext } from 'react-admin';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { LOCATIONS_FIELDS } from 'shared/constants/locations.constants';

const OsmMapForm = ({ outCenter, required }) => {
  const { errors } = useFormState();
  const defaultCenter = [69.34398, 88.21039];
  const record = useRecordContext();
  const { setValue, clearErrors } = useFormContext();
  useController({ name: 'lat', rules: { required: true } });

  const isCoords = record?.lat && record?.lng;
  const [center, setCenter] = useState(outCenter || isCoords ? [record?.lat, record?.lng] : defaultCenter);
  const [locations, setLocation] = useState(isCoords ? [{ lat: record?.lat, lng: record?.lng }] : []);

  const handleClickOsmMap = (event) => {
    const coordinate = event.coordinate;
    const [lng, lat] = toLonLat(coordinate);
    setLocation([{ lat, lng }]);
    setValue('lat', lat, { shouldDirty: true });
    setValue('lng', lng, { shouldDirty: true });
    if (errors.lat) clearErrors('lat');
  };

  useEffect(() => {
    if (!outCenter) return;
    setCenter(outCenter);
  }, [outCenter]);

  return (
    <>
      <Labeled label={`${LOCATIONS_FIELDS.coords}${required ? ' *' : null}`} fullWidth sx={{ height: '500px' }}>
        <OsmMap center={center} locations={locations} handleClickMap={handleClickOsmMap} />
      </Labeled>
      <ErrorMessage message={errors?.lat ? 'Required' : null} />
    </>
  );
};
export default OsmMapForm;
