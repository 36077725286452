import { TStepItem } from '../../../../../shared/types/aboutUs.types';
import { StepCard } from './StepCard';

type TStepsProps = {
  stepsItems: TStepItem[];
};
export const StepsSectionDesktop = ({ stepsItems }: TStepsProps) => {
  return (
    <>
      <div className='steps-list'>
        {stepsItems.map((privilegeItem) => (
          <StepCard key={privilegeItem.id} stepItem={privilegeItem} />
        ))}
      </div>
    </>
  );
};
