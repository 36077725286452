import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TQuestion } from 'shared/types/question.types';

type TInitialState = {
  questions: TQuestion[] | null;
  isLoading: boolean;
};

const initialState: TInitialState = {
  questions: null,
  isLoading: false,
};

export const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setQuestions: (state, { payload }: PayloadAction<TQuestion[]>) => {
      if (!(!state.questions?.length && payload.length === 0)) {
        state.questions = [...payload];
      }
    },
  },
});

export const { setQuestions, setLoading } = slice.actions;

export default slice.reducer;
