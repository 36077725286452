import { cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import s from './index.module.scss'
import clsx from "clsx";

type Props = {
  icon: ReactNode;
  children: ReactNode;
  active?: boolean;
  href?: string;
  onClick?: () => void;
  isDefaultLink?: boolean;
};

const cvaLink = cva([
  s.link
]);

export default function CustomLink({ icon, href, active, isDefaultLink, children, onClick }: Props) {
  return href ? (
    isDefaultLink ? (
      <Link to={href} className={clsx(cvaLink(), active && s.active)}>
        {icon}
        <span>{children}</span>
      </Link>
    ) : (
      <NavLink to={href} className={cvaLink()}>
        {icon}
        <span>{children}</span>
      </NavLink>
    )
  ) : (
    <button onClick={onClick} className={cvaLink()}>
      {icon}
      <span>{children}</span>
    </button>
  );
}
