import { Create } from 'react-admin';
import NpaForm from './NpaForm';

const NpaCreate = () => (
  <Create>
    <NpaForm />
  </Create>
);

export default NpaCreate;
