import { useCallback, useEffect, useRef, useState } from 'react';
import Map, { MapOptions } from 'ol/Map';
import View, { ViewOptions } from 'ol/View';
import { getBrandMapLayer } from '../helpers/getBrandMapLayer';
import { getOsmMapLayer } from '../helpers/getOsmMapLayer';
import { getOverviewMapControl } from '../helpers/getOverviewMapControl';

export const useOsmMap = (
  target: 'brand-map' | 'osm-map',
  viewOptions: ViewOptions,
  mapSettings?: MapOptions & { withOverviewMapControl?: boolean; }
): [Map | null, (el: any) => void] => {
  const [svgContainerEl, setSvgContainerEl] = useState<HTMLDivElement | null>(null);
  const [map, setMap] = useState<Map | null>(null);
  const addOverviewMapControl = useRef(false);

  const setRef = useCallback((el) => {
    if (el) {
      setSvgContainerEl(el);
    }
  }, []);

  useEffect(() => {
    const layers = [];
    switch (target) {
      case 'brand-map':
        if (svgContainerEl) {
          layers.push(getBrandMapLayer(svgContainerEl));
        }
        break;
      case 'osm-map':
        layers.push(getOsmMapLayer());
        break;
    }
    if (!layers.length) return;

    const map = new Map({
      target,
      layers,
      view: new View(viewOptions),
      ...mapSettings,
    });
    setMap(map);
  }, [svgContainerEl, target]);

  useEffect(() => {
    if (!mapSettings?.withOverviewMapControl || !map || addOverviewMapControl.current) return;
    if (svgContainerEl && target === 'brand-map') {
      const cloneContainer = svgContainerEl.cloneNode(true) as HTMLDivElement;
      const overviewLayer = getOverviewMapControl([getBrandMapLayer(cloneContainer)]);
      map.addControl(overviewLayer);
      addOverviewMapControl.current = true;
    } else if (target === 'osm-map') {
      const overviewLayer = getOverviewMapControl([getOsmMapLayer()]);
      map.addControl(overviewLayer);
      addOverviewMapControl.current = true;
    }
  }, [map, svgContainerEl, mapSettings, target]);

  return [map, setRef];
};
