import { useMemo } from 'react';

type TTextSplitParagraph = {
  text: string;
  color?: 'white';
};

export const TextSplitParagraph = ({ text, color }: TTextSplitParagraph) => {
  const paragraphs = useMemo(() => text.split('\n'), [text]);

  return (
    <div className={`text-split-paragraph ${color}`}>
      {paragraphs.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
    </div>
  );
};
