import classNames from 'classnames';
import { URL_FOR_IMG } from '../../shared/constants/const';

const PointDesignBalloon = ({ loc, addToOrder, showDescLoc }) => {
  return (
    <div className='balloon-content'>
      <h4 className='balloon-title'>{loc.name}</h4>
      {loc.preview?.path ? <img src={`${URL_FOR_IMG}/${loc.preview.path}`} alt='Изображение локации' /> : null}
      <p className='balloon-text'>{loc.description}</p>
      <div className='balloon-controlls'>
        <button className={classNames('balloon-controlls-btn', { red: !!loc.inOrder })} onClick={() => addToOrder(loc)}>
          {loc.inOrder ? 'Добавлено' : 'Добавить'}
        </button>
        {/* <button className='balloon-controlls-btn red' onClick={() => showDescLoc(loc)}>
          Подробнее
        </button> */}
      </div>
    </div>
  );
};

export default PointDesignBalloon;
