import { reviewsActions } from 'manageStore/reviews/reviews.slice';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { MessageItem } from '../MessageItem';

const initialReview = {
  rating: 0,
  mostliked: [],
  advantages: '',
  disadvantages: '',
  comment: '',
};

export const AutoMessageReview = () => {
  const dispatch = useAppDispatch();

  const { setEdittedReview } = reviewsActions;

  const handleReview = () => {
    dispatch(setEdittedReview(initialReview));
  };

  return (
    <MessageItem>
      <div className='chat-automessage'>
        <h4>Запрос отзыва на тур</h4>
        <p className='chat-automessage__text'>Поздравляем! Ваш тур завершен.</p>
        <p className='chat-automessage__text'>
          Надеемся, все было прекрасно, и вы остались довольны. Приходите к нам снова с семьей и друзьями. Также, нам
          очень важно ваше мнение о туре. Пожалуйста, поставьте оценку и напишите как проходило ваше приключение.
        </p>
        <div className='chat-automessage__buttons'>
          <button className='btn chat-automessage__btn' onClick={handleReview}>
            Оставить отзыв
          </button>
        </div>
      </div>
    </MessageItem>
  );
};
