import React from 'react'
import { Edit } from 'react-admin'
import LodgindForm from './LodgindForm'

const LodgingEdit = () => (
  <Edit>
    <LodgindForm />
  </Edit>
)

export default LodgingEdit
