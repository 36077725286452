import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { getPublishedNews, selectNewsList, selectIsLoading } from 'manageStore/news/newsSlice';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import Loading from 'components/Loading/Loading';
import CommonService from 'services/common.service';
import { TNew } from 'shared/types/types';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

type TSingleNewsBodyProps = {
  news: TNew | null;
};

const SingleNewsPage = () => {
  const dispatch = useDispatch();
  const news = useSelector(selectNewsList);
  const isLoading = useSelector(selectIsLoading);

  const { id: newsId } = useParams<{ id: string }>();
  const [singleNews, setSingleNews] = useState<TNew | null>(null);

  useEffect(() => {
    if (!news?.length) {
      dispatch(getPublishedNews());
    } else {
      const single = news.find((item) => item.id === Number(newsId));
      if (single !== undefined) {
        CommonService.postViewsNews(Number(newsId), {
          views: single.views,
        });
        setSingleNews(single);
      }
    }
  }, [news, dispatch, newsId]);

  const SingleNewsBody = ({ news }: TSingleNewsBodyProps) => {
    return news ? (
      <>
        <h1 className='single-news__title title'>{news.title}</h1>
        <div className='single-news__content ornament ornament_big'>{parse(news.content || '', { trim: true })}</div>
      </>
    ) : (
      <>
        <h1 className='single-news__error'>Ууупс! Кажется, произошла ошибка!</h1>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{singleNews ? `${singleNews.title} - ` : ''}Туркластер Арктический</title>
        <meta name='description' content={singleNews && singleNews.metaDesc ? singleNews.metaDesc : ''} />
        <meta name='keywords' content={singleNews && singleNews.metaKeywords ? singleNews.metaKeywords : ''} />
      </Helmet>
      <div className='content'>
        <Header />
        <AuthPortal />
        <section className='single-news ornament ornament_big'>
          <div className='container'>
            <article>{isLoading ? <Loading /> : <SingleNewsBody news={singleNews} />}</article>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SingleNewsPage;
