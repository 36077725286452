export const TRAVEL_GUIDE_FIELDS = {
  id: 'id',
  locationType: 'Тип локации',
  preview: 'Основная фотография',
  title: 'Название локации',
  description: 'Описание локации',
  territory: 'Территория',
  sort: 'Вид локации',
  characteristics: 'Характеристика локации',
  route: 'Как добраться',
  history: 'История',
  facts: 'Факты',
  advantageLocation: 'Почему стоит посетить',
  placeMap: 'Место на карте',
  similarPlaces: 'Похожие места',
  digits: 'Цифры и факты',
  tours: 'Туры и экскурсии',
  faces: 'История в лицах',
  video: 'Код видео',
  gallery: 'Галерея',
  fauna: 'Животный мир',
  flora: 'Растения',
};


export enum TERRITORY_ENUM {
  ALL ='',
  NORILSK='NORILSK',
  DUDINKA='DUDINKA',
  DIKSON='DIKSON',
  HATANGA='HATANGA',
  OTDALENNYE_POSELKI='OTDALENNYE_POSELKI',
  PLATO_PUTORANA='PLATO_PUTORANA',
  PLATO_ANABAR='PLATO_ANABAR',
  ARKTICHESKOE_POBEREZHE='ARKTICHESKOE_POBEREZHE'
}

export const TRANSFORM_TRAVEL_GUIDE = {
  tags: {
    city: {
      label: 'Городская локация',
      value: 'Городская локация',
    },
    flora: {
      label: 'Природная локация',
      value: 'Природная локация',
    },
  },
  theme: {
    industry: {
      label: 'Промышленность',
      value: 'Промышленность',
    },
    gastronomy: {
      label: 'Гастрономия',
      value: 'Гастрономия',
    },
    attractions: {
      label: 'Достопримечательность',
      value: 'Достопримечательность',
    },
    history: {
      label: 'История',
      value: 'История',
    },
    ethnicity: {
      label: 'Этника',
      value: 'Этника',
    },
    entertainment: {
      label: 'Развлечение',
      value: 'Развлечение',
    },
    mountains: {
      label: 'Горы',
      value: 'Горы',
    },
    water: {
      label: 'Водная стихия',
      value: 'Водная стихия',
    },
    touristArea: {
      label: 'Туристическая зона',
      value: 'Туристическая зона',
    },
  },
  territory: {
    putorana: {
      label: 'Плато Путорана',
      value: 'Плато Путорана',
      valEnum: TERRITORY_ENUM.PLATO_PUTORANA
    },
    norilsk: {
      label: 'Норильск',
      value: 'Норильск',
      valEnum: TERRITORY_ENUM.NORILSK
    },
    dudinka: {
      label: 'Дудинка',
      value: 'Дудинка',
      valEnum: TERRITORY_ENUM.DUDINKA
    },
    dikson: {
      label: 'Диксон',
      value: 'Диксон',
      valEnum: TERRITORY_ENUM.DIKSON
    },
    hatanga: {
      label: 'Хатанга',
      value: 'Хатанга',
      valEnum: TERRITORY_ENUM.HATANGA
    },
    villages: {
      label: 'Отдаленные поселки',
      value: 'Отдаленные поселки',
      valEnum: TERRITORY_ENUM.OTDALENNYE_POSELKI
    },
    arcticCoast: {
      label: 'Арктическое побережье',
      value: 'Арктическое побережье',
      valEnum: TERRITORY_ENUM.ARKTICHESKOE_POBEREZHE
    },
    anabar: {
      label: 'Плато Анабар',
      value: 'Плато Анабар',
      valEnum: TERRITORY_ENUM.PLATO_ANABAR
    },
  },
} as const;


