import { useState } from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { toLonLat } from 'ol/proj';
import { useFormContext, useFormState, useController } from 'react-hook-form';
import BrendMap from '../../BrendMap/BrendMap';
import { LOCATIONS_FIELDS } from 'shared/constants/locations.constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const BrendMapForm = ({ required }) => {
  const record = useRecordContext();
  const { errors } = useFormState();
  const { setValue, clearErrors } = useFormContext();
  useController({ name: 'map2DLat', rules: { required: true } });
  const isCoords = record?.map2DLat && record?.map2DLng;
  const center = isCoords ? [record.map2DLat, record.map2DLng] : null;
  const [locations, setLocation] = useState(isCoords ? [{ map2DLat: record.map2DLat, map2DLng: record.map2DLng }] : []);

  const handleClickBrendMap = (event) => {
    const coordinate = event.coordinate;
    const [map2DLng, map2DLat] = toLonLat(coordinate);
    setLocation([{ map2DLat, map2DLng }]);
    setValue('map2DLat', map2DLat, { shouldDirty: true });
    setValue('map2DLng', map2DLng, { shouldDirty: true });
    if (errors.map2DLat) clearErrors('map2DLat');
  };

  return (
    <>
      <Labeled label={`${LOCATIONS_FIELDS.coords}${required ? ' *' : ''}`} fullWidth sx={{ height: '500px' }}>
        <BrendMap locations={locations} center={center} handleClickMap={handleClickBrendMap} />
      </Labeled>
      <ErrorMessage message={errors?.map2DLat ? 'Required' : null} />
    </>
  );
};

export default BrendMapForm;
