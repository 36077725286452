import { ReactComponent as ArrowButton } from 'assets/images/arrow-button-no-color.svg';
import {Link, useHistory} from "react-router-dom";
import {TNew} from "../../../../shared/types/types";
import {URL_FOR_IMG} from "../../../../shared/constants/const";

export type TAboutClusterNews = {
  id: number;
  label: string;
  link: string;
  imag: string | null;
};

type TProps = {
  newsItem: TNew;
};
export const AboutClusterNewsCard = ({ newsItem }: TProps) => {
  const {  desc,id, preview } = newsItem;
  const history = useHistory();
  const imageUrl = `${URL_FOR_IMG}/${preview && preview.path ? preview.path : null}`;
  const openNewItem = () => {
    history.push(`/news/${id}`);
  };
  return (
    <div className='about-news-card '>
      {imageUrl && <img src={imageUrl} alt='news' />}
      <div>
        <div className='about-news-card__desciption'>
          <p>{desc}</p>
        </div>
        <button onClick={openNewItem} className='about-news-card__btn' >
          <span>Подробнее</span>
          <ArrowButton />
        </button>
      </div>
    </div>
  );
};
