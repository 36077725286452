import {
  List,
  Datagrid,
  TextField,
  Pagination,
  ShowButton,
} from 'react-admin';
import {
  MENU_ADMIN_LIST,
  PARTICIPANT_FIELDS,
} from '../../../shared/constants/const';


const ParticipantsList = () => {
  return (
      <List
          title={MENU_ADMIN_LIST.participants}
          exporter={false}
          pagination={<Pagination rowsPerPageOptions={[]} />}
      >
        <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
          <TextField source="name" label={PARTICIPANT_FIELDS.name} />
          <ShowButton />
        </Datagrid>
      </List>
  );
};

export default ParticipantsList;
