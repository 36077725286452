import { PropsWithChildren } from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { FiltersManageButtons } from '../FiltersManageButtons/FiltersManageButtons';

type TPortalFilterProps = {
  togglePortal: () => void;
  clearFilters: () => void;
  applyFilters: () => void;
  countVariants: number;
  isActiveClearButton: boolean;
};

export const PortalFilter = ({
  children,
  togglePortal,
  clearFilters,
  applyFilters,
  countVariants,
  isActiveClearButton,
}: PropsWithChildren<TPortalFilterProps>) => {
  return (
    <div className='portal-filters'>
      <div className='portal-filters__overlay' onClick={togglePortal}></div>
      <div className='portal-filters__wrapper'>
        <div className='portal-filters__header'>
          <button className='portal-filters__close' type='button' onClick={togglePortal}>
            <CloseIcon className='portal-filters__close__icon' />
          </button>
          Фильтры
        </div>
        <div className='portal-filters__content'>
          <div className='portal-filters__inner'>{children}</div>
        </div>
        <FiltersManageButtons
          clearFilters={clearFilters}
          applyFilters={applyFilters}
          countVariants={countVariants}
          isActiveClearButton={isActiveClearButton}
        />
      </div>
    </div>
  );
};
