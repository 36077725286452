import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useFirstView } from 'shared/hooks/useFirstView';
import Notification from 'shared/lib/notification';
import RequestService from 'services/request.service';
import { operatorNavs, mobileOperatorNavs } from './navigation';
import Aside from 'components/Account/Aside';
import MainWorkflow from 'components/Account/MainWorkflow';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import Loading from 'components/Loading/Loading';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { TRequestFetch } from 'shared/types/request.types';
import AlternativeHeader from 'components/Header/AlternativeHeader';

const BProcessPage = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();
  const firstView = useFirstView(userData);

  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    } else if (userData.type !== 'OPERATOR') {
      history.push('/account');
    }
  }, [isAuth, userData, history]);

  const [requests, setRequests] = useState<TRequestFetch[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userData) return;
    const reqData = {
      status: ['NEW', 'PROCESSING', 'DONE', 'DENIED'],
      id: userData.id,
      type: userData.type.toLowerCase(),
    };

    setLoading(true);
    RequestService.fetchRequests(reqData)
      .then(({ data }) => {
        setRequests(data);
      })
      .catch((error) => {
        setRequests([]);
        Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userData]);

  return (
    <>
      <Helmet>
        <title>Бизнес-процесс - Туркластер Арктический</title>
      </Helmet>
      <section className='account'>
        <AlternativeHeader />
        <Aside navs={operatorNavs} mobileNavs={mobileOperatorNavs} unreadStatus={firstView} />
        <section className='Amain'>
          {loading ? <Loading /> : <MainWorkflow requests={requests} />}
          <MobileBottom />
        </section>
      </section>
    </>
  );
};

export default BProcessPage;
