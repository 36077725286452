import { TPrivilegeItem } from '../../../../../shared/types/aboutUs.types';

type TTPrivilegeCardProps = {
  privilegeItem: TPrivilegeItem;
};
export const PrivilegeCard = ({ privilegeItem }: TTPrivilegeCardProps) => {
  return (
    <div className='privileges-card'>
      {privilegeItem.icon}
      <p className='privileges-card__label'>{privilegeItem.label}</p>
    </div>
  );
};
