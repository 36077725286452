import { cva } from "class-variance-authority";
import React from "react";
import { convertPrice } from "../../../shared/utils/convert-price";
import clsx from "clsx";
import { convertDate } from "../../../shared/utils/convert-date";
import s from './index.module.scss'
const cvaPrice = cva([s.price]);
const cvaGroup = cva([s.group]);
const cvaGroups = cva([s.groups]);
const cvaGroupDd = cva([s.groupDd]);
const cvaGroupDt = cva([s.groupDt]);

type Props = {
  groupSize: number;
  price: number;
  duration: number;
  dateStart: string;
};

export default function InfoApplication({
  price,
  groupSize,
  duration,
  dateStart,
}: Props) {
  return (
    <>
      <div className={clsx(cvaPrice(), !price && s.text)}>
        {price ? convertPrice(price) : "-"}
      </div>
      <div className={cvaGroups()}>
        <dl className={cvaGroup()}>
          <dd className={cvaGroupDd()}>Группа</dd>
          <dt className={cvaGroupDt()}>{groupSize}</dt>
        </dl>
        <dl className={cvaGroup()}>
          <dd className={cvaGroupDd()}>ДНЕЙ</dd>
          <dt className={cvaGroupDt()}>{duration}</dt>
        </dl>
        <dl className={cvaGroup()}>
          <dd className={cvaGroupDd()}>НАЧАЛО</dd>
          <dt className={cvaGroupDt()}>
            {dateStart ? convertDate(dateStart) : "-"}
          </dt>
        </dl>
      </div>
    </>
  );
}
