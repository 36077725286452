import React, { useEffect, useState } from 'react';
import Layout from '../../widgets/layout/ui';
import Application from '../../widgets/application/ui';
import PageHead from '../../entities/page-head';
import { useAsync, useMedia } from 'react-use';
import 'app/styles/null.scss';
import s from './index.module.scss';
import { useParams } from 'react-router-dom';
import {
  selectIsLoadingRequest,
  selectCurrentRequest,
  selectMessageList,
} from '../../../manageStore/request/request.select';
import { useAppDispatch } from '../../../shared/hooks/useAppDispatch';
import { getRequestByIdThunk } from '../../../manageStore/request/request.thunk';
import useCheckRegister from '../../shared/hooks/useCheckRegister';
import SettingsPopper from 'newProject/features/settings-popper';
import Loading from 'components/Loading/Loading';
import Portal from 'newProject/features/portal/ui';
import Popup from 'newProject/features/popup/ui';
import RequestStatus, { RequestStatusPaid } from 'newProject/widgets/application/application-actions/status-tag';
import InfoApplication from '../../features/application-preview/info-application';
import { cvaName } from '../../widgets/application/application-actions/ui/index';
import Button from 'newProject/shared/ui/button';
import clsx from 'clsx';
import DeleteReqPopup from '../../widgets/application/delete-popup/index';
import FileReqPopup from 'newProject/widgets/application/file-popup';
import RequestService from 'services/request.service';
import Notification from 'shared/lib/notification';
import { TDocFetchWithUser } from 'shared/types/common.types';

interface ChatPageNewProps {
  type: string;
}

export default function ChatPageNew({ type }: ChatPageNewProps) {
  const isMd = useMedia('(max-width: 1023px)');
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const showActions = useMedia('(max-width: 1420px)');
  useCheckRegister(type);
  const request = selectCurrentRequest();
  const [openDelete, setOpenDelete] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [docs, setDocs] = useState<TDocFetchWithUser[]>([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getRequestByIdThunk(Number(id)));
      setLoading(false);
    })();
    dispatch(getRequestByIdThunk(Number(id)));
  }, [id]);

  const messages = selectMessageList();

  useEffect(() => {
    (async () => {
      if (request?.id) {
        try {
          const { data } = await RequestService.getDocsList(request?.id);
          setDocs(data ?? []);
        } catch {
          Notification.error('Произошла ошибка при загрузке документов');
        }
      }
    })();
  }, [request?.id, messages?.filter(m => m.documents.length > 0).length]);

  return (
    <Layout hideMenu classNameMain={s.layout}>
      <PageHead hrefBack={isMd ? '/account/tourist/requests/' : ''}>
        {showActions ? (
          <div className={s.check}>
            <span>Просмотр заявки</span>
            <SettingsPopper>
              <button onClick={() => setOpenModal(true)}>Подробнее о заявке</button>
              <button onClick={() => setOpenFiles(true)}>Вложенные файлы</button>
            </SettingsPopper>
          </div>
        ) : (
          'Мои заявки'
        )}
      </PageHead>
      {isLoading ? <Loading /> : <Application messages={messages} docs={docs} />}
      {openModal && showActions && request && (
        <Portal>
          <Popup classNameInner={s.null} withClose onClose={() => setOpenModal(false)}>
            <div className={clsx(s.popupContainer, s.bb)}>
              <div className='h1'>Подробнее</div>
              <div className={s.statuses}>
                <RequestStatus status={request.status} />
                <RequestStatusPaid isPaid={request.isPaid} />
              </div>
              <div className={cvaName()}>{request.tour.name}</div>
              <InfoApplication
                groupSize={request.groupSize}
                price={request.tour.price}
                duration={request.tour.duration}
                dateStart={request.dateStart}
              />
            </div>
            <div className={s.popupContainer}>
              <Button onClick={() => setOpenDelete(true)} className={s.cancel} stretched>
                Отменить заявку
              </Button>
            </div>
          </Popup>
        </Portal>
      )}
      {openDelete && request?.id && (
        <Portal>
          <DeleteReqPopup id={request.id} onClose={() => setOpenDelete(false)} />
        </Portal>
      )}
      {openFiles && docs && messages && request && (
        <FileReqPopup idReq={request?.id} onClose={() => setOpenFiles(false)} files={docs} />
      )}
    </Layout>
  );
}
