import Feature, { FeatureLike } from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import Point from 'ol/geom/Point';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Circle as CircleStyle, Fill, Icon, Style, Text } from 'ol/style';
import { TRANSFORM_LOCATIONS } from 'shared/constants/locations.constants';
import compass from 'assets/images/common/compass.svg';
// import cityIcon from 'assets/images/locations/city.svg';
// import birdIcon from 'assets/images/locations/bird.svg';
// import northernLightsIcon from 'assets/images/locations/northern_lights.svg';
// import photoIcon from 'assets/images/locations/photo.svg';
// import quadBikeIcon from 'assets/images/locations/quad_bike.svg';

import natureIcon from 'assets/images/locations/nature.svg';
import surfIcon from 'assets/images/locations/surf.svg';
import mountainIcon from 'assets/images/locations/mountain.svg';
import jeepIcon from 'assets/images/locations/jeep.svg';
import industryIcon from 'assets/images/locations/fabric.svg';
import helicopterIcon from 'assets/images/locations/helicopter.svg';
import orderIcon from 'assets/images/map/order.svg';

type TClusterData = {
  coords: number[];
  infoLoc?: any;
};

const getIconPoint = (type: string) => {
  if (!type) {
    return null;
  }
  switch (type) {
    case TRANSFORM_LOCATIONS.type.NATURE.value:
      return natureIcon;
    case TRANSFORM_LOCATIONS.type.WATER.value:
      return surfIcon;
    case TRANSFORM_LOCATIONS.type.MOUNT.value:
      return mountainIcon;
    case TRANSFORM_LOCATIONS.type.SPECIAL_TRANSFER.value:
      return jeepIcon;
    case TRANSFORM_LOCATIONS.type.DEVELOP.value:
      return industryIcon;
    case TRANSFORM_LOCATIONS.type.HELICOPTER.value:
      return helicopterIcon;
    default:
      return natureIcon;
  }
};

const getIconCluster = (locationType: string, isActive: boolean) => {
  const iconPoint = getIconPoint(locationType);
  return iconPoint
    ? new Style({
        image: new Icon({
          src: iconPoint,
          color: isActive ? '#db2947' : '#4f8ecc',
        }),
        zIndex: 1,
      })
    : [];
};

const redOuterCircle = new CircleStyle({
  radius: 24,
  fill: new Fill({
    color: 'rgba(162, 22, 45, 0.8)',
  }),
});

const redInnerCircle = new CircleStyle({
  radius: 20,
  fill: new Fill({
    color: '#db2947',
  }),
});

const whiteInnerCircle = new CircleStyle({
  radius: 20,
  fill: new Fill({
    color: '#ffffff',
  }),
});

const blueOuterCircle = new CircleStyle({
  radius: 24,
  fill: new Fill({
    color: '#4f8ecc',
  }),
});

const redClusterStyles = [
  new Style({
    image: redOuterCircle,
    zIndex: 1,
  }),
  new Style({
    image: redInnerCircle,
    zIndex: 1,
  }),
];

const whiteBlueClusterStyles = [
  new Style({
    image: blueOuterCircle,
    zIndex: 1,
  }),
  new Style({
    image: whiteInnerCircle,
    zIndex: 1,
  }),
];

const whiteRedClusterStyles = [
  new Style({
    image: new CircleStyle({
      radius: 24,
      fill: new Fill({
        color: '#db2947',
      }),
    }),
    zIndex: 1,
  }),
  new Style({
    image: whiteInnerCircle,
    zIndex: 1,
  }),
];

const opacityClusterStyle = new Style({
  image: new CircleStyle({
    radius: 24,
    fill: new Fill({
      color: 'rgb(255 255 255 / 50%)',
    }),
  }),
  zIndex: 10,
});

const getOrderClusterStyle = (sequence: number) => {
  return new Style({
    text: new Text({
      text: String(sequence),
      offsetY: -18,
      offsetX: 18,
      font: 'ultra-expanded 12px "CoFoPeshkaV0.6", sans-serif',
      fill: new Fill({
        color: '#ffffff',
      }),
    }),
    image: new Icon({
      src: orderIcon,
      displacement: [18, 18],
    }),
    zIndex: 20,
  });
};

class ClusterStyles {
  static travelGuide() {
    return [
      ...redClusterStyles,
      new Style({
        image: new Icon({
          src: compass,
        }),
      }),
    ];
  }

  static tour(locations: TClusterData[]) {
    const stylesCash: Record<string, any> = {};
    return (feature: FeatureLike) => {
      const clusterMembers = feature.get('features');
      const infoLoc = clusterMembers[0].get('infoLoc');
      const indexRoute = locations.findIndex((loc) => loc.infoLoc.name === infoLoc.name);
      if (!stylesCash[indexRoute]) {
        const style = [
          ...redClusterStyles,
          new Style({
            text: new Text({
              text: (indexRoute + 1).toString(),
              font: '500 22px AkzidenzGroteskPro, sans-serif',
              offsetY: 1,
              fill: new Fill({
                color: '#ffffff',
              }),
            }),
          }),
        ];
        stylesCash[indexRoute] = style;
      }
      return stylesCash[indexRoute];
    };
  }

  static designer() {
    const stylesCash: Record<string, Array<any>> = {};
    return (feature: FeatureLike) => {
      const clusterMembers = feature.get('features');
      const featuresLength = clusterMembers.length - 1;
      if (featuresLength === 0) {
        const infoLoc = clusterMembers[0].get('infoLoc');
        const isActive = clusterMembers[0].get('isActive');
        const inFilter = clusterMembers[0].get('inFilter');
        const sequence = clusterMembers[0].get('sequence');
        const colorCluster = isActive ? whiteRedClusterStyles : whiteBlueClusterStyles;
        const key = infoLoc?.locationEnum ? infoLoc?.locationEnum : null;
        if (!key) {
          return colorCluster;
        }
        const keyCash = isActive ? key + '_isActive' : key;
        if (!stylesCash[keyCash]) {
          const iconCluster = getIconCluster(key, isActive);
          stylesCash[keyCash] = [iconCluster];
        }
        const styles = [...colorCluster, ...stylesCash[keyCash]];
        if (inFilter === false) {
          styles.push(opacityClusterStyle);
        }
        if (sequence !== undefined) {
          styles.push(getOrderClusterStyle(sequence));
        }
        return styles;
      } else {
        const isActive: boolean | undefined = clusterMembers[0].get('isActive');
        const inFilter: boolean = clusterMembers.some((cluster: Cluster) => {
          return cluster.get('inFilter');
        });
        const sequence: number | undefined = clusterMembers
          .find((cluster: Cluster) => !cluster.get('infoLoc')?.city)?.get('sequence');
        const colorCluster = isActive ? whiteRedClusterStyles : whiteBlueClusterStyles;
        if (!stylesCash[featuresLength]) {
          const style = new Style({
            text: new Text({
              text: featuresLength === 1 ? '2' :  featuresLength < 100 ? featuresLength.toString() : '99+',
              offsetY: 1,
              font: 'ultra-expanded 20px "CoFoPeshkaV0.6", sans-serif',
              fill: new Fill({
                color: isActive === undefined ? '#2b4761' : 'rgb(43 71 97 / 50%)',
              }),
            }),
            zIndex: 1,
          });
          stylesCash[featuresLength] = [style];
        }
        const styles = [...colorCluster, ...stylesCash[featuresLength]];
        if (inFilter === false) {
          styles.push(opacityClusterStyle);
        }
        if (sequence !== undefined) {
          styles.push(getOrderClusterStyle(sequence));
        }
        return styles;
      }
    };
  }
}

export type TClusterStyle = keyof Omit<typeof ClusterStyles, 'prototype'>;

export const generateClusters = (clusterData: TClusterData[], clusterStyle: TClusterStyle) => {
  const features = clusterData.map(({ coords, infoLoc }) => {
    return new Feature({
      geometry: new Point(fromLonLat(coords)),
      infoLoc,
    });
  });
  const clusterSource = new Cluster({
    distance: 50,
    minDistance: 40,
    source: new VectorSource({ features }),
  });

  const style = ClusterStyles[clusterStyle](clusterData);

  const newClusters = new VectorLayer({
    source: clusterSource,
    style,
  });

  return [newClusters, newClusters.getSource()?.getSource()?.getFeatures()] as const;
};
