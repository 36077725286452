import { useState, useEffect, ChangeEvent } from 'react';
import RequestService from 'services/request.service';
import Notification from 'shared/lib/notification';
import classnames from 'classnames';
import { TSharedDocs } from 'shared/types/common.types';

type TFilesharingProps = {
  id: number | undefined;
  isOpen: boolean;
  toggleFilesharing: () => void;
};

const randomKey = () => {
  return Math.random().toString(36);
};

const Filesharing = ({ id, isOpen, toggleFilesharing }: TFilesharingProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [inputKey, setInputKey] = useState(randomKey());
  const [uploadedFiles, setUploadedFiles] = useState<TSharedDocs[]>([]);
  const [fileName, setFileName] = useState('');
  const [valideName, setValideName] = useState(true);

  const loadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const resetFile = () => {
    setValideName(true);
    setFileName('');
    setInputKey(randomKey());
    setFile(null);
  };

  const sendFile = () => {
    if (!fileName) {
      setValideName(false);
    } else {
      if (!file || !id) return;
      const formData = new FormData();
      formData.append('data', file);
      RequestService.sendDoc(id, formData, fileName)
        .then((resp) => {
          if (!resp?.data || !resp?.data.length) {
            Notification.warning('Что-то пошло не так...');
            return;
          }
          const [respFile] = resp.data;
          setUploadedFiles((prev) => [...prev, { ...respFile }]);
          resetFile();
        })
        .catch((error) => {
          Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
        });
    }
  };

  const handlerNameFile = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setValideName(!!name);
    setFileName(name);
  };

  useEffect(() => {
    if (!id) return;
    let isActual = true;
    RequestService.getDocsList(id)
      .then((resp) => {
        if (isActual) {
          setUploadedFiles(resp?.data || []);
        }
      })
      .catch((error) => {
        if (isActual) {
          Notification.error(error?.response?.data?.message || error?.message || 'Произошла ошибка!');
        }
      });
    return () => {
      isActual = false;
    };
  }, [id]);

  return (
    <div className={classnames('chat-filesharing', { 'chat-filesharing__hide': !isOpen })}>
      <div className='chat-filesharing__content'>
        <div className='chat-filesharing__upload'>
          <div className='chat-filesharing__head'>
            <h4 className='chat-filesharing__title'>
              Доступные вложения <span>({uploadedFiles?.length || 0})</span>
            </h4>
            <div className='chat-filesharing__close' onClick={toggleFilesharing}></div>
          </div>
          <div className='chat-filesharing__uploaded'>
            <input
              className='chat-filesharing__file-name'
              type='text'
              placeholder='Название файла'
              value={fileName}
              onChange={handlerNameFile}
            />
            <div className='chat-filesharing__error'>{!valideName ? 'Введите название файла' : ''}</div>
            {file && (
              <>
                <span className='chat-filesharing__cancel' onClick={resetFile}></span>
              </>
            )}
          </div>

          <input id='chatFile' type='file' key={inputKey} hidden onChange={loadFile} />
          {file ? (
            <>
              <button className='btn chat-filesharing__btn' onClick={sendFile}>
                Отправить
              </button>
            </>
          ) : (
            <label htmlFor='chatFile' className='btn chat-filesharing__btn'>
              Загрузить
            </label>
          )}
        </div>
        <div className='chat-filesharing__download'>
          {uploadedFiles.map((file) => (
            <div className='chat-body__message chat-body__incoming chat-filesharing__message' key={file.id}>
              <div className='chat-body__text'>
                <div className='chat-filesharing__file-name'>{file.caption}</div>
              </div>
              <a href={file.url} target='_blank' className='btn chat-filesharing__btn download' rel='noreferrer'>
                Скачать
              </a>
            </div>
          ))}
        </div>
        <div className='chat-filesharing__footer'></div>
      </div>
    </div>
  );
};

export default Filesharing;
