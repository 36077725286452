import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Notification from 'shared/lib/notification';
import TourService from 'services/tour.service';
import Loading from 'components/Loading/Loading';
import settings from 'assets/images/account/settings.svg';
import denied from 'assets/images/account/denied.svg';
import checked from 'assets/images/account/checked.svg';
import waiting from 'assets/images/account/waiting.svg';
import { STATUS_TYPE, URL_FOR_IMG } from '../../../shared/constants/const';
import { TTour } from 'shared/types/tours.types';

type TWorkflowItemProps = {
  item: TTour;
};

const WorkflowItem = ({ item }: TWorkflowItemProps) => {
  let icon = '';
  let link = `/account/operator/tours/${item.id}`;
  switch (item.status) {
    case STATUS_TYPE.NEW:
      icon = waiting;
      break;
    case STATUS_TYPE.APPROVED:
      icon = checked;
      break;
    case STATUS_TYPE.REJECTED:
      icon = denied;
      break;
    case STATUS_TYPE.DRAFT:
      icon = settings;
      link = `/account/operator/tours/draft/${item.id}`;
      break;
  }

  return (
    <li className='Amain__workflow-item'>
      <Link className='Amain__workflow-link' to={link}>
        <div className='Amain__workflow-user'>
          <div className='Amain__workflow-user__img'>
            {item?.preview?.path && item.status !== STATUS_TYPE.DRAFT ? (
              <img src={`${URL_FOR_IMG}/${item.preview.path}`} alt='Фото' />
            ) : null}
          </div>
          <div className='Amain__workflow-user__data'>
            <p className='Amain__workflow-username'>{!!item?.title && item.title !== 'stub' ? item.title : ''}</p>
          </div>
        </div>
        <p className='Amain__workflow-item__title'>
          {item?.productType && item.productType !== 'stub' ? `Тип: ${item?.productType}` : ''}
        </p>
        <div className='Amain__workflow-item__price'>
          <p>
            {!!item.price && (
              <>
                <span>{item.price}</span> руб.
              </>
            )}
          </p>
        </div>
        <div className='Amain__workflow-item__bottom'>
          <p className='Amain__workflow-item__price'>
            {!!item.price && (
              <>
                Стоимость: <span>{item.price}</span>
              </>
            )}
          </p>
          <p className='Amain__workflow-item__date'>{new Date(item?.createdDate || '').toLocaleDateString()}</p>
          <img className='Amain__workflow-item__icon' src={icon} alt={item.status || ''} />
        </div>
      </Link>
    </li>
  );
};

const MainTours = () => {
  const [newItems, setNewItems] = useState<TTour[]>([]);
  const [approvedItems, setApprovedItems] = useState<TTour[]>([]);
  const [publishedItems, setPublishedItems] = useState<TTour[]>([]);
  const [rejectedItems, setRejectedItems] = useState<TTour[]>([]);
  const [draftItems, setDraftItems] = useState<TTour[]>([]);
  const [loading, setLoading] = useState(false);

  const setViewData = (requests: TTour[]) => {
    if (requests && requests.length) {
      const newR: TTour[] = [];
      const rejectedR: TTour[] = [];
      const approvedR: TTour[] = [];
      const publishedR: TTour[] = [];
      const draftR: TTour[] = [];

      requests.forEach((item) => {
        switch (item.status) {
          case STATUS_TYPE.NEW:
            newR.push(item);
            break;
          case STATUS_TYPE.REJECTED:
            rejectedR.push(item);
            break;
          case STATUS_TYPE.APPROVED:
            item.isPublished ? publishedR.push(item) : approvedR.push(item);
            break;
          case STATUS_TYPE.DRAFT:
            draftR.push(item);
            break;
          default:
        }
      });
      setNewItems(newR);
      setApprovedItems(approvedR);
      setPublishedItems(publishedR);
      setRejectedItems(rejectedR);
      setDraftItems(draftR);
    }
  };

  useEffect(() => {
    setLoading(true);
    TourService.getUserTour()
      .then(({ data }) => {
        setViewData(data);
      })
      .catch((e) => {
        Notification.error(e?.response?.data?.message || e?.message || 'Произошла ошибка!');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='Amain__workflow Amain__workflow_tours'>
          <div className='Amain__workflow-column'>
            <div className='Amain__workflow-column__title blue'>
              <Link to={`/account/operator/tours/new`}>
                <div className='plus-button plus-button__Amain-title'>+</div>
              </Link>
              <span>Новые ({newItems.length})</span>
            </div>
            <ul className='Amain__workflow-box'>
              {newItems.length ? newItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
            </ul>
          </div>
          <div className='Amain__workflow-column'>
            <div className='Amain__workflow-column__title green'>Опубликованные ({publishedItems.length})</div>
            <ul className='Amain__workflow-box'>
              {publishedItems.length ? publishedItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
            </ul>
          </div>
          <div className='Amain__workflow-column'>
            <div className='Amain__workflow-column__title yellow'>Неопубликованные ({approvedItems.length})</div>
            <ul className='Amain__workflow-box'>
              {approvedItems.length ? approvedItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
            </ul>
          </div>
          <div className='Amain__workflow-column'>
            <div className='Amain__workflow-column__title red'>Отклоненные ({rejectedItems.length})</div>
            <ul className='Amain__workflow-box'>
              {rejectedItems.length ? rejectedItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
            </ul>
          </div>
          <div className='Amain__workflow-column'>
            <div className='Amain__workflow-column__title black'>Черновики ({draftItems.length})</div>
            <ul className='Amain__workflow-box'>
              {draftItems.length ? draftItems.map((item) => <WorkflowItem item={item} key={item.id} />) : null}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default MainTours;
