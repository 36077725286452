import { useCallback, useState } from 'react';

export const useSwipe = (): [() => void, () => void, boolean] => {
  const [isSwipe, setIsSwipe] = useState(false);

  const startSwipe = useCallback(() => {
    setIsSwipe(true);
  }, []);

  const endSwipe = useCallback(() => {
    setIsSwipe(false);
  }, []);

  return [startSwipe, endSwipe, isSwipe];
};
