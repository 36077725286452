import React from 'react'
import { Edit } from 'react-admin'
import UserForm from './UserForm'

const UserEdit = () => (
  <Edit>
    <UserForm />
  </Edit>
)

export default UserEdit
