import React from 'react';
import { SimpleForm, TextInput, required, Labeled } from 'react-admin';
import {PARTICIPANT_FIELDS} from '../../../shared/constants/const';


const ParticipantsForm = () => (
  <SimpleForm>
    <Labeled label={PARTICIPANT_FIELDS.name} fullWidth>
      <TextInput source='name' validate={[required()]} label='' />
    </Labeled>
  </SimpleForm>
);

export default ParticipantsForm;
