import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import classNames from 'classnames';

type TButtonSliderProps = {
  dark?: boolean;
  light?: boolean;
  right?: boolean;
  left?: boolean;
  className?: string;
  onClick?: () => void;
};

export const ButtonSlider = ({ dark, light, left, right, className, onClick }: TButtonSliderProps) => {
  return (
    <div onClick={onClick} className={classNames('button-slider', { dark, light, left, right }, className)}>
      <div
        className={classNames({
          'button-slider__dark': dark,
          'button-slider__light': light,
          'button-slider__left': left,
          'button-slider__right': right,
        })}
      >
        <ArrowButton />
      </div>
      <div className='button-slider__back'></div>
    </div>
  );
};
