import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCategoryTravelGuide, TTravelGuide, TFiltersTravelsGuide } from 'shared/types/travelGuide.types';

type TInitialState = {
  travelsGuide: TTravelGuide[];
  filtersTravelsGuide: TFiltersTravelsGuide;
  isLoading: boolean;
};

export const defaultFiltersTravelsGuide: TFiltersTravelsGuide = {
  type: null,
  theme: null,
  territory: null,
};

const initialState: TInitialState = {
  travelsGuide: [],
  filtersTravelsGuide: defaultFiltersTravelsGuide,
  isLoading: false,
};

export const slice = createSlice({
  name: 'travelsGuide',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setTravelsGuide: (state, { payload }: PayloadAction<TTravelGuide[]>) => {
      if (!(state.travelsGuide.length === 0 && payload.length === 0)) {
        state.travelsGuide = [...payload];
      }
    },

    setFiltersTypes: (state, { payload }: PayloadAction<TCategoryTravelGuide | null>) => {
      const filters = state.filtersTravelsGuide;
      state.filtersTravelsGuide = { ...filters, type: payload };
    },

    setFilters: (state, { payload }: PayloadAction<TFiltersTravelsGuide>) => {
      state.filtersTravelsGuide = { ...payload };
    },

    clearFilters: (state) => {
      state.filtersTravelsGuide = { ...defaultFiltersTravelsGuide };
    },
  },
});

export const { setTravelsGuide, setLoading, setFiltersTypes, setFilters, clearFilters } = slice.actions;

export default slice.reducer;
