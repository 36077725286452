import arrowReviews from 'assets/images/account/arrow-reviews.svg';
import arrowReviewsWhite from 'assets/images/account/arrow-reviews-white.svg';
import bProcess from 'assets/images/account/b-process.svg';
import bProcessWhite from 'assets/images/account/bprocess-white.svg';
import openBox from 'assets/images/account/open-box.svg';
import checkedList from 'assets/images/account/checked-list.svg';
import checkedListWhite from 'assets/images/account/checked-list-white.svg';
import circleArrow from 'assets/images/account/circle-arrow.svg';
import circleArrowWhite from 'assets/images/account/circle-arrow-white.svg';
import denied from 'assets/images/account/denied.svg';
import stats from 'assets/images/account/stats.svg';
import statsWhite from 'assets/images/account/stats-white.svg';
import norequests from 'assets/images/account/norequests.svg';
import norequestsWhite from 'assets/images/account/norequestsWhite.svg';

export const operatorNavs = [
  [
    { link: '/account/operator/stats', text: 'Статистика продаж', icon: stats },
    { link: '/account/operator/bprocess', text: 'Бизнес-процесс', icon: bProcess },
    { link: '/account/operator/tours', text: 'Управление заказами', icon: norequests },
  ],
  [
    { link: '/account/operator/new-requests', text: 'Доступные заявки', icon: openBox },
    { link: '/account/operator/processing-requests', text: 'Текущие заявки', icon: circleArrow },
    { link: '/account/operator/done-requests', text: 'Завершенные заявки', icon: checkedList },
    { link: '/account/operator/denied-requests', text: 'Непринятые заявки', icon: denied },
    { link: '/account/operator/reviews', text: 'Отзывы на мои продукты', icon: arrowReviews },
  ],
];

export const mobileOperatorNavs = [
  { link: '/account/operator/stats', text: 'Отчеты', icon: statsWhite },
  { link: '/account/operator/tours', text: 'Мои туры', icon: norequestsWhite },
  { link: '/account/operator/bprocess', text: 'Воронка', icon: bProcessWhite },
  { link: '/account/operator/processing-requests', text: 'В работе', icon: circleArrowWhite },
  { link: '/account/operator/new-requests', text: 'Заявки', icon: checkedListWhite },
  { link: '/account/operator/reviews', text: 'Отзывы', icon: arrowReviewsWhite },
];
