import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TChatMessage, TRequestFetch } from 'shared/types/request.types';

type TInitialState = {
  isLoading: boolean;
  isUploading: boolean;
  requestsList: TRequestFetch[] | null;
  currentRequest: TRequestFetch | null;
  messagesList: TChatMessage[] | null;
};

const initialState: TInitialState = {
  isLoading: false,
  isUploading: false,
  requestsList: null,
  currentRequest: null,
  messagesList: null,
};

const requestSlice = createSlice({
  name: 'requestSlice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsUploading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUploading = payload;
    },
    setRequestsList: (state, { payload }: PayloadAction<TRequestFetch[]>) => {
      state.requestsList = payload;
    },
    setCurrentRequest: (state, { payload }: PayloadAction<TRequestFetch>) => {
      state.currentRequest = payload;
    },
    setMessagesList: (state, { payload }: PayloadAction<TChatMessage[]>) => {
      state.messagesList = payload;
    },
    setNewMessage: (state, { payload }: PayloadAction<TChatMessage>) => {
      if (state.messagesList) {
        state.messagesList.push(payload);
      } else {
        state.messagesList = [payload];
      }
    },
  },
});

export const requestActions = requestSlice.actions;
export const requestReduser = requestSlice.reducer;
