import Chart from 'react-apexcharts';
import { TStatsData } from 'shared/types/types';

const MainStats = ({ info, type = 'donut', series = [100], options = { labels: ['Нет данных'] } }: TStatsData) => {
  return (
    // <div className={`Amain__stats ${info.cName ? info.cName : ''}`}>
    <div className='Amain__stats'>
      <div className='Amain__stats-header'>
        <p className='Amain__stats-name'>{info.name}</p>
        {/* <p className='Amain__stats-period'>
          Период:
          <select className='Amain__stats-select'>
            <option value='day'>За день</option>
            <option value='week'>За неделю</option>
            <option value='month'>За месяц</option>
            <option value='year'>За год</option>
            <option value='all'>Все время</option>
          </select>
        </p>
        <button className='Amain__stats-close' /> */}
      </div>
      <div className='Amain__stats-body'>
        <Chart
          type={type}
          series={series}
          options={{
            ...options,
            colors: ['#338fd1', '#db2947', '#32D193', '#A824F0', '#D4C322', '#bdb5aa'],
            stroke: { width: 1, lineCap: 'round' },
            plotOptions: {
              bar: { borderRadius: 5, columnWidth: '50%' },
              pie: {
                donut: { size: '70%' },
              },
            },
          }}
          width='100%'
          height='100%'
        />
        {/* <Chart type='pie' series={[5, 7, 9]} options={{labels: ['A', 'B', 'C']}} width='90%' height='100%' /> */}
        {/* <Chart type='bar' series={[{name: 'Test', data: [5, 7, 9]}]} options={{ labels: ['A', 'B', 'C'] }} width='90%' height='100%' /> */}
      </div>
    </div>
  );
};

export default MainStats;
