export type TClusterItem = {
  id: number;
  key: JSX.Element;
  value: string;
  icon: JSX.Element | null;
};

type TProps = {
  clusterItem: TClusterItem
}
export const ClusterItemCard = ({clusterItem}: TProps)=> {
  return(
    <div key={clusterItem.id} className='metrics-content__grid__item' style={{ minWidth: '288px' }}>
      <h4>{clusterItem.value}</h4>
      {clusterItem.icon && clusterItem.icon}
      <p>{clusterItem.key}</p>
    </div>
  )
}