import classnames from 'classnames';
import { TReviewFetchResponse } from 'shared/types/review.types';

type TReviewContentProps = { reviewItem: TReviewFetchResponse };

export const ReviewContent = ({ reviewItem }: TReviewContentProps) => {
  return (
    <div className='Amain-review__body'>
      <p className='Amain-review__subtitle'>Больше всего понравилось:</p>
      <ul className='Amain-review__mostliked-list'>
        <li
          className={classnames('Amain-review__mostliked-item', {
            active: reviewItem.mostliked.includes('Маршрут'),
          })}
        >
          Маршрут
        </li>
        <li className={classnames('Amain-review__mostliked-item', { active: reviewItem.mostliked.includes('Гиды') })}>
          Гиды
        </li>
        <li
          className={classnames('Amain-review__mostliked-item', {
            active: reviewItem.mostliked.includes('Сервис'),
          })}
        >
          Сервис
        </li>
        <li className={classnames('Amain-review__mostliked-item', { active: reviewItem.mostliked.includes('Жилье') })}>
          Жилье
        </li>
      </ul>
      <p className='Amain-review__subtitle'>Достоинства:</p>
      <p className='Amain-review__text'>{reviewItem.advantages}</p>
      <p className='Amain-review__subtitle'>Недостатки:</p>
      <p className='Amain-review__text'>{reviewItem.disadvantages}</p>
      <p className='Amain-review__subtitle'>Комментарий:</p>
      <p className='Amain-review__text'>{reviewItem.comment}</p>
    </div>
  );
};
