import React, {useEffect} from "react";
import ReviewItem from "../../widgets/review/ui";
import Layout from "../../widgets/layout/ui";
import ContainerContent from "../../entities/container";
import s from './index.module.scss'
import 'app/styles/null.scss'
import PageHead from "../../entities/page-head";
import useCheckRegister from "../../shared/hooks/useCheckRegister";
import {USER_TYPES} from "../../../shared/constants/const";
import {getTouristReviewsListThunk} from "../../../manageStore/reviews/reviews.thunk";
import {useAppSelector} from "../../../shared/hooks/useAppSelector";
import {useAppDispatch} from "../../../shared/hooks/useAppDispatch";
import {selectIsLoadingReviews, selectReviewsList} from "../../../manageStore/reviews/reviews.select";
import { TReviewFetchResponse } from "shared/types/review.types";

export default function TouristReviewsPageNew() {

  useCheckRegister(USER_TYPES.tourist)
  const dispatch = useAppDispatch();
  const isLoading = selectIsLoadingReviews();
  const reviewsList = selectReviewsList();

  useEffect(() => {
    dispatch(getTouristReviewsListThunk());
  }, [dispatch]);

  const compareDatesDesc = (a: TReviewFetchResponse, b: TReviewFetchResponse) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
  
    return (dateB as any) - (dateA as any); // изменение порядка
  };
  

  return (
    <Layout classNameMain={"bg-c-blue-light"}>
      <PageHead isFixed={false}>Мои отзывы</PageHead>
      <ContainerContent>
        {reviewsList && reviewsList.length > 0 ? [...reviewsList].sort(compareDatesDesc).map((review) =>
          <ReviewItem
            key={review.id}
            review={review}
          />
        ) : null}
      </ContainerContent>
    </Layout>
  );
}
