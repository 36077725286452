import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { TFilterLocations } from 'shared/types/location.types';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import {
  selectActiveCity,
  selectActiveLocation,
  selectLocations,
  selectPopularLocations,
  selectSearchedLocations,
  selectSortedLocations,
} from 'manageStore/designer/designer.select';
import { sortLocations } from 'manageStore/designer/designer.thunk';
import { ReactComponent as HomeIcon } from 'assets/images/common/home.svg';
import { ReactComponent as FilterIcon } from 'assets/images/common/filter.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/common/select/arrow-select.svg';
import { ReactComponent as ListIcon } from 'assets/images/map/list.svg';
import { ReactComponent as MapIcon } from 'assets/images/map/map-icon.svg';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { TourDesignerLocationsList } from '../TourDesignerLocationsList/TourDesignerLocationsList';
import { TourDesignerLocation } from '../TourDesignerLocation/TourDesignerLocation';
import TourDesignerOrderList from '../TourDesignerOrderList/TourDesignerOrderList';
import { TourDesignerFilters } from 'features/lib/TourDesignerFilters/TourDesignerFilters';
import { TourDesignerSearch } from 'features/lib/TourDesignerSearch/TourDesignerSearch';
import { TourDesignerSearchList } from '../TourDesignerSearchList/TourDesignerSearchList';
import { TourDesignerApplication } from 'features';

type TField = keyof TFilterLocations;
export type TCurrentList = 'designer' | 'order';

type TTourDesignerManagerBoxProps = {
  currentList: TCurrentList;
  setCurrentList: (list: TCurrentList) => void;
  onChangeMap: () => void;
  nowActiveMap: string;
};

export const TourDesignerManagerBox = ({
  currentList,
  setCurrentList,
  onChangeMap,
  nowActiveMap,
}: TTourDesignerManagerBoxProps) => {
  const dispatch = useDispatch();
  const activeCity = useSelector(selectActiveCity);
  const activeLocation = useSelector(selectActiveLocation);
  const locations = useSelector(selectLocations);
  const sortedLocations = useSelector(selectSortedLocations);
  const searchedLocations = useSelector(selectSearchedLocations);
  const popularLocations = useSelector(selectPopularLocations);

  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const [hideBox, setHideBox] = useState(isMobile);
  const [openFilters, setOpenFilters] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [countFilters, setCountFilters] = useState(0);
  const [filters, setFilters] = useState<TFilterLocations>({
    season: [],
    types: [],
    territory: [],
    transport: [],
  });

  const showBackButton = isMobile && (activeCity || activeLocation) && hideBox;
  const showBackBtnDesctop = !isMobile && nowActiveMap === 'osm';
  const handleChangeForm = (field: TField, value: string | TFilterLocations) => {
    if (typeof value === 'string') {
      const isChecked = filters[field].includes(value);
      setFilters((prev) =>
        isChecked
          ? { ...prev, [field]: [...prev[field].filter((item) => item !== value)] }
          : { ...prev, [field]: [...prev[field], value] }
      );
    } else {
      setFilters(value);
    }
  };

  const clearFilters = () => {
    setFilters({
      season: [],
      types: [],
      territory: [],
      transport: [],
    });
  };

  const hanldeClickNav = (list: typeof currentList) => {
    setCurrentList(list);
    if (list === 'designer' && isMobile) {
      setOpenList((prev) => !prev);
      setHideBox((prev) => !prev);
    } else if (list === 'order' && isMobile) {
      setHideBox(false);
    }
  };

  const handelGoToBrandMap = () => {
    onChangeMap();
  };

  const handleCloseOrder = () => {
    setCurrentList('designer');
  };

  const handleToggleBox = () => {
    if (!hideBox) {
      setOpenList(false);
    }
    setHideBox((prev) => !prev);
  };

  const handleBackBox = () => {
    setHideBox(false);
  };

  const handleToggleFilters = () => {
    setOpenFilters((prev) => !prev);
  };

  useEffect(() => {
    if (locations.length) {
      dispatch(sortLocations(filters));
    }
  }, [filters, locations]);

  useEffect(() => {
    const fields = Object.keys(filters) as TField[];
    const countFilters = fields.reduce((acc, field) => acc + filters[field].length, 0);
    setCountFilters(countFilters);
  }, [filters]);

  useEffect(() => {
    if (activeLocation || activeCity) {
      setHideBox(false);
      setOpenFilters(false);
    }
  }, [activeLocation, activeCity]);

  useEffect(() => {
    if (openFilters || searchedLocations) {
      setHideBox(false);
    }
  }, [openFilters, searchedLocations]);

  useEffect(() => {
    if (
      isMobile &&
      !activeLocation &&
      !activeCity &&
      !openList &&
      !openFilters &&
      currentList === 'designer' &&
      !searchedLocations
    ) {
      setHideBox(true);
    }
  }, [isMobile, activeLocation, activeCity, openList, openFilters, currentList, searchedLocations]);

  return (
    <div className={classNames('designer-manager-box', { 'designer-manager-box-hidden': hideBox })}>
      <div className={classNames('designer-manager-box__header', { hidden: showBackButton })}>
        <button type='button' className='designer-manager-box__toggle-button' onClick={handleToggleBox}>
          <ArrowIcon className={classNames('designer-manager-box__toggle-icon', { rotate: !hideBox })} />
        </button>
        <Link className='link-to-home' to='/'>
          <HomeIcon />
        </Link>
        <div className='filters-wrapper'>
          <TourDesignerSearch />
          <div className='filter-locations'>
            <button className='filter-locations__button' type='button' onClick={handleToggleFilters}>
              <FilterIcon className='filter-locations__icon' />
              {!!countFilters && (
                <div className='filter-locations__count'>
                  <span>{countFilters}</span>
                </div>
              )}
            </button>
          </div>
        </div>
        {showBackBtnDesctop && (
          <div className='backbtn' onClick={handelGoToBrandMap}>
            <ArrowButton />
          </div>
        )}
      </div>
      <div
        className={classNames('designer-manager-box__main', {
          hidden: hideBox,
          'under-layer':
            openList &&
            !activeCity &&
            !activeLocation &&
            !openFilters &&
            currentList === 'designer' &&
            !searchedLocations,
        })}
      >
        <div className={classNames('designer-manager-box__nav', { hidden: showBackButton })}>
          {openList ? (
            <button
              type='button'
              className={classNames('list-button', { active: currentList === 'designer' })}
              onClick={() => hanldeClickNav('designer')}
            >
              <MapIcon />
              <span>Карта</span>
            </button>
          ) : (
            <button
              type='button'
              className={classNames('list-button', { active: currentList === 'designer' })}
              onClick={() => hanldeClickNav('designer')}
            >
              <ListIcon />
              <span>Список</span>
            </button>
          )}
          <button
            type='button'
            className={classNames('popular-button', { active: currentList === 'designer' })}
            onClick={() => hanldeClickNav('designer')}
          >
            Популярные объекты
          </button>
          <button
            type='button'
            className={classNames({ active: currentList === 'order' })}
            onClick={() => hanldeClickNav('order')}
          >
            Мой маршрут
          </button>
        </div>
        {currentList === 'designer' && !isMobile && <TourDesignerLocationsList locations={popularLocations} />}
        {currentList === 'designer' && isMobile && openList && (
          <div className='designer-manager-box__locations-list'>
            <TourDesignerLocationsList
              locations={sortedLocations}
              className='designer-manager-box__locations-list-mobile'
            />
          </div>
        )}
        {currentList === 'order' && <TourDesignerOrderList onClose={handleCloseOrder} />}

        {!!activeLocation ? (
          <div className='designer-manager-box__location'>
            <TourDesignerLocation location={activeLocation} />
          </div>
        ) : !!activeCity ? (
          <div className='designer-manager-box__location'>
            <TourDesignerLocation location={activeCity} />
          </div>
        ) : null}

        <TourDesignerSearchList />

        {openFilters && (
          <TourDesignerFilters
            filters={filters}
            onChange={handleChangeForm}
            onClose={handleToggleFilters}
            showClear={!!countFilters}
            clearFilters={clearFilters}
          />
        )}
      </div>
      <TourDesignerApplication />
      {showBackButton && (
        <button className='designer-manager-box__back-mobile' onClick={handleBackBox}>
          <ArrowButton />
        </button>
      )}
    </div>
  );
};
