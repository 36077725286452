import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import { createEmpty, extend } from 'ol/extent';
import VectorSource from 'ol/source/Vector';
import { LineString } from 'ol/geom';
import { useEffect } from 'react';

export const useExtentByRoutes = (map: Map | null, routes: VectorLayer<VectorSource<LineString>> | null) => {
  useEffect(() => {
    if (!map || !routes) return;
    setTimeout(() => {
      const features = routes.getSource()?.getFeatures();
      const extent = createEmpty();
      features?.forEach((feature) => {
        const newExtent = feature?.getGeometry()?.getExtent();
        if (newExtent) {
          extend(extent, newExtent);
        }
      });
      const view = map.getView();
      if (view.getZoom() !== view.getMaxZoom() && extent) {
        view.fit(extent, { duration: 500, padding: [100, 50, 100, 50] });
      }
    }, 1000);
  }, [map, routes]);
};
