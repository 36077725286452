import React from "react";
import ProfileData from "../../../features/profile_data/ui";
import Separator from "../../sidebar/separator";
import ChangePassword from "../../../features/change-password/ui";
import { cva } from "class-variance-authority";
import s from './index.module.scss'
import clsx from "clsx";
import { TSingleFile } from "shared/types/common.types";

type Props = {
  name: string;
  image?: string | null;
  imageData: TSingleFile | null;
  email: string;
  phone: string;
  gender: string;
};

const cvaProfile = cva([
  s.profile,
]);

const cvaForm = cva([s.form]);
export default function ProfileComponent({
  name,
  image,
  email,
  phone,
  imageData,
  gender,
}: Props) {
  return (
    <div className={cvaProfile()}>
      <h3 className={clsx("subtitle", s.title)}>ДАННЫЕ ПРОФИЛЯ</h3>
      <ProfileData
        email={email}
        imageData={imageData}
        phone={phone}
        image={image}
        gender={gender}
        name={name}
      />
      <Separator className={s.separator}/>
      <div className={cvaForm()}>
        <h3 className={clsx("subtitle", s.change)}>СМЕНА ПАРОЛЯ</h3>
        <ChangePassword />
      </div>
    </div>
  );
}
