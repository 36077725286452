import AlternativeHeader from 'components/Header/AlternativeHeader';
import {AppBar, ExportButton, TopToolbar} from 'react-admin';
import {MENU_ADMIN_LIST} from "../../../shared/constants/const";


const Wrapper = (props) => <>{props.children}</>;
const DownloadAnalytics = () => (
  <button className='tour-application-form__submit' type='submit'>
    Оставить заявку
  </button>
);
const AdminHeader = (props) => (
    <AppBar
        sx={{
            maxWidth: '1920px',
            boxShadow: 'none',
            borderBottom: '1px solid #ebeff2',
            backgroundColor: '#ffffff',
            color: '#33404f',
            right: 'auto',
        }}
        {...props}
        userMenu={false}
        container={Wrapper}
    >
        <AlternativeHeader/>
    </AppBar>
);

export default AdminHeader;
