import React from 'react';
import {
    DateInput,
    Labeled,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {BEAUTY_FIELDS, BLOG_FIELDS, NEWS_FIELDS} from '../../../shared/constants/const';
import CheckBoxControl from '../CheckBoxControl';
import FileInputAdmin from '../FileInputAdmin';
import TinyMCE from '../TinyMCE';
import {BlogGallery} from "./BlogGallery";
import {BlogTours} from "./BlogTours";
import {BlogGuidline} from "./BlogGuidline";
import {BlogPlaceMap} from "./BlogPlaceMap";
import {BlogBlock} from "./BlogBlock";
import moment from "moment";
import {itemsTypeNews} from "../../../features/lib/NewsFilter/NewsFiltersByType";
import {territoryTagCommon} from "../../../features/lib/NewsFilter/NewsFiltersByTag";


const format = value => {
    let test = moment(value, 'YYYY-MM-DD')
    let toformat = test.format('YYYY-MM-DDTHH:mm:ss')
    return value == null ? '' : toformat
}
const parse = value => {
    let test = moment(value)
    let toformat = test.format('YYYY-MM-DD')
    return value === '' ? null : toformat
}
const today = moment().format('YYYY-MM-DDTHH:mm:ss')
const BlogForm = () => (
    <SimpleForm>
        <CheckBoxControl name='isImportant' label={BLOG_FIELDS.isImportant}/>
        <CheckBoxControl name='isPublished' label={BLOG_FIELDS.isPublished}/>
        <Labeled label={BLOG_FIELDS.createdDate} fullWidth>
            <DateInput source="createdDate" format={parse} parse={format} defaultValue={today}/>
        </Labeled>
        <FileInputAdmin source='preview' label={BLOG_FIELDS.preview} multiple={false} validate={[required()]}/>

        <Labeled label={BLOG_FIELDS.name} fullWidth>
            <TextInput source='name' validate={[required()]} label=''/>
        </Labeled>
        <Labeled label={NEWS_FIELDS.metaDesc} fullWidth>
            <TextInput source='metaDesc' multiline={true} minRows='3' maxRows='3' label=''/>
        </Labeled>
        <Labeled label={NEWS_FIELDS.metaKeywords} fullWidth>
            <TextInput source='metaKeywords' multiline={true} minRows='3' maxRows='3' label=''/>
        </Labeled>
        <Labeled label={BLOG_FIELDS.description} fullWidth>
            <TinyMCE property='description' required/>
        </Labeled>
        <BlogGallery/>
        <Labeled label={BLOG_FIELDS.facts} fullWidth>
            <TinyMCE property='facts' required/>
        </Labeled>
        <FileInputAdmin source='photo' label={BLOG_FIELDS.photo} multiple={false} validate={[required()]}/>
        <Labeled label={BLOG_FIELDS.author} fullWidth>
            <TextInput source='author' validate={[required()]} label=''/>
        </Labeled>
        <Labeled label={BLOG_FIELDS.territoryTag} fullWidth>
            <SelectInput
                source='territoryTag'
                label='Select...'
                optionText='label'
                optionValue='valueEnum'
                choices={territoryTagCommon}
                fullWidth
                validate={[required()]}
                format={(v) => (v ? v : '')}
            />
        </Labeled>
        <Labeled label={BLOG_FIELDS.typeTags} fullWidth>
            <SelectArrayInput
                source='typeTags'
                label='Select...'
                optionText='label'
                optionValue='value'
                choices={itemsTypeNews}
                fullWidth
                validate={[required()]}
                format={(v) => (v ? v : [])}
                parse={(v) => (v ? v.slice(0, 3) : [])}
            />
        </Labeled>
        <Labeled label={BLOG_FIELDS.interest} fullWidth>
            <TinyMCE property='interest'/>
        </Labeled>
        <Labeled label={BLOG_FIELDS.video} fullWidth>
            <TextInput source='video' label=''/>
        </Labeled>

        <BlogGuidline/>
        <BlogTours/>


        <BlogPlaceMap/>
        <BlogBlock/>

        <Labeled label={BLOG_FIELDS.nextBlog} fullWidth>
            <ReferenceInput source='nextBlog.id' reference='blog' perPage={Infinity} fullWidth>
                <SelectInput optionText='name' optionValue='id' label='Select...' fullWidth  />
            </ReferenceInput>
        </Labeled>

    </SimpleForm>
);

export default BlogForm;
