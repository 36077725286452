import { TRAVEL_GUIDE_FIELDS } from 'shared/constants/travelGuide.constants';
import { Labeled, ReferenceInput, SelectInput, required } from 'react-admin';

export const GuidePlaceMap = () => {
  return (
    <Labeled label={TRAVEL_GUIDE_FIELDS.placeMap} fullWidth isRequired>
      <ReferenceInput source='locations.id' reference='locations' perPage={Infinity} fullWidth>
        <SelectInput
          optionText='name'
          optionValue='id'
          label='Select...'
          fullWidth
          format={(v) => (v && v !== 'new' ? v : '')}
          validate={required()}
        />
      </ReferenceInput>
    </Labeled>
  );
};
