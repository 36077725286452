import { ReactNode } from 'react';
import PopupInfoTitle from './PopupInfoTitle';

type TPopupInfoProps = {
  title?: string;
  children?: ReactNode;
};

const PopupInfo = ({ title, children }: TPopupInfoProps) => {
  return (
    <section className='popupInfo'>
      <PopupInfoTitle cName={'--top'}>{title ? title : null}</PopupInfoTitle>
      {children ? children : null}
    </section>
  );
};

export default PopupInfo;
