import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';

const itemsDurationTour = [
  { value: '1-4', label: '1-4' },
  { value: '5-10', label: '5-10' },
  { value: '11-15', label: '11-15' },
  { value: '16-20', label: '16-20' },
  { value: '21-25', label: '21-25' },
  { value: '26-30', label: '26-30' },
];

type TToursFiltersByDurationProps = {
  filters: string;
  onClick: <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours[TName]) => void;
};

export const ToursFiltersByDuration = ({ filters, onClick }: TToursFiltersByDurationProps) => {
  const checkActiveDuration = (value: string) => {
    return filters === value;
  };

  return (
    <ParamsFilterGroup title='Количество дней'>
      {itemsDurationTour.map((duration) => (
        <ParamsFilterItem
          key={duration.label}
          active={checkActiveDuration(duration.value)}
          onClick={() => onClick('duration', duration.value)}
          label={duration.label}
        />
      ))}
    </ParamsFilterGroup>
  );
};
