import * as React from 'react';
import { Show, SimpleShowLayout, TextField} from 'react-admin';
import { PARTICIPANT_FIELDS, } from '../../../shared/constants/const';

const ParticipantsShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={PARTICIPANT_FIELDS.name} />
      </SimpleShowLayout>
    </Show>
  </div>
);

export default ParticipantsShow;
