import {
  ArrayInput,
  Edit,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  FormDataConsumer,
} from 'react-admin';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
};

const PostEditToolbar = (props: any) => {
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <SaveButton />
    </Toolbar>
  );
};

export const Recommendations = () => {
  return (
    <Edit>
      <SimpleForm toolbar={<PostEditToolbar />}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <ArrayInput source='recommendations' label='Рекомендации' fullWidth sx={styleInBlock}>
                <SimpleFormIterator disableReordering disableAdd={formData?.recommendations?.length > 5}>
                  <ReferenceInput source='id' reference='tours/published' perPage={Infinity} fullWidth>
                    <SelectInput
                      multiline
                      optionText='title'
                      optionValue='id'
                      label='Select...'
                      fullWidth
                      validate={[required()]}
                    />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
