import { cva } from 'class-variance-authority';
import { useState } from 'react';
import Button from '../../../shared/ui/button';
import { ReactComponent as BackIcon } from '../assets/back-arrw.svg';
import Portal from '../../portal/ui';
import Popup from '../../popup/ui';
import s from './index.module.scss';
import { useMedia } from 'react-use';

type Props = {
  onClose: () => void;
  slides: {
    description: string;
    title: string;
  }[];
};

const cvaText = cva([s.text]);
const cvaButtons = cva([s.buttons]);
const cvaButtonsLight = cva([s.buttonsLight]);
const cvaTitle = cva(['h1', s.title]);
const cvaThumbnails = cva([s.thumbs]);
const cvaThumb = cva([s.thumb], {
  variants: {
    position: {
      prev: [s.prev],
      active: [s.active],
      next: [s.next],
    },
  },
  defaultVariants: {
    position: 'next',
  },
});

export default function Onboarding({ slides, onClose }: Props) {
  const [activeSlide, setActiveSlide] = useState(0);
  const isLastIndex = activeSlide === slides.length - 1;
  const isMobile = useMedia('(max-width:1023px)');
  return slides[activeSlide] ? (
    <Portal>
      <Popup classNameInner={s.popup} onClose={onClose}>
        <div className={cvaTitle()}>
          Шаг {activeSlide + 1}/{slides.length} {slides[activeSlide].title}
        </div>
        <div className={cvaText()}>{slides[activeSlide].description}</div>
        <div className={cvaButtons()}>
          <div className={cvaButtonsLight()}>
            {activeSlide != 0 && (
              <button onClick={() => setActiveSlide((prev) => prev - 1)} className={s.back}>
                <BackIcon />
              </button>
            )}
            <Button
              className={s.btn}
              onClick={() => {
                if (isLastIndex) {
                  onClose();
                }
                setActiveSlide((prev) => prev + 1);
              }}
            >
              {isLastIndex ? (isMobile ? 'Далле' : 'Приступить к работе') : 'Далее'}
            </Button>
          </div>
          {!isLastIndex && !isMobile && (
            <Button onClick={onClose} intent={'secondary'}>
              Пропустить обучение
            </Button>
          )}
        </div>
        {isMobile && (
          <div className={cvaThumbnails()}>
            {slides.map((e, i) => (
              <div
                className={cvaThumb({
                  position: i == activeSlide ? 'active' : i < activeSlide ? 'prev' : 'next',
                })}
              ></div>
            ))}
          </div>
        )}
      </Popup>
    </Portal>
  ) : null;
}
