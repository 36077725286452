import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import mobileHome from 'assets/images/mobile-home.svg';
import mobileTours from 'assets/images/mobile-tours.svg';
import mobileNews from 'assets/images/mobile-news.svg';
import mobileProfile from 'assets/images/mobile-profile.svg';

type MobileMenuProps = {
  opacity?: boolean;
};

const MobileBottom = ({ opacity }: MobileMenuProps) => {
  const { t } = useTranslation();
  return (
    <nav className={opacity ? 'mobile-botom opacity' : 'mobile-botom'}>
      <ul className='mobile-bottom__list'>
        <li className='mobile-bottom__item'>
          <NavLink className='mobile-bottom_link' exact to='/'>
            <img className='mobile-botoom__icon' src={mobileHome} alt='' />
            {t('headerMenu.mobile.home')}
            {/* Арктика */}
          </NavLink>
        </li>
        <li className='mobile-bottom__item'>
          <NavLink className='mobile-bottom_link' to='/tours'>
            <img className='mobile-botoom__icon' src={mobileTours} alt='' />
            {t('headerMenu.mobile.tours')}
          </NavLink>
        </li>
        <li className='mobile-bottom__item'>
          <NavLink className='mobile-bottom_link' to='/news'>
            <img className='mobile-botoom__icon' src={mobileNews} alt='' />
            {t('headerMenu.NewsIntro')}
          </NavLink>
        </li>
        <li className='mobile-bottom__item'>
          <NavLink className='mobile-bottom_link' to='/account'>
            <img className='mobile-botoom__icon' src={mobileProfile} alt='' />
            {t('headerMenu.mobile.profile')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileBottom;
