import React from 'react'
import { Edit } from 'react-admin'
import FoodForm from './FoodForm'

const FoodEdit = () => (
  <Edit>
    <FoodForm />
  </Edit>
)

export default FoodEdit
