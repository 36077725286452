import { ReactComponent as BookIcon } from 'assets/images/trk/trk-book.svg';
import { TPrivilegeItem } from '../../../../../shared/types/aboutUs.types';
import {PrivilegeCardMobile} from "./PrivilegeCardMobile";

type TTPrivilegesProps = {
  privilegeItems: TPrivilegeItem[];
};


const privilegeBookItem: TPrivilegeItem = {
  id: 'book',
  label: 'Обучение и участие в мероприятиях Кластера',
  icon: <BookIcon className='privileges-card__icon' />,
};

export const PrivilegesSectionMobile = ({privilegeItems}: TTPrivilegesProps) => {
  return (
      <>
        <div className='privileges-content-sm'>
          {[...privilegeItems, privilegeBookItem].map((privilegeItem) => (
              <PrivilegeCardMobile key={privilegeItem.id} privilegeItem={privilegeItem} />
          ))}
        </div>
      </>
  );
};
