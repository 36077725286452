import { api as apiNoAuth } from 'http/commonApi';
import { TQuestion } from 'shared/types/question.types';

export default class QuestionService {
  static async getQuestions() {
    return await apiNoAuth().get<TQuestion[]>('/questions');
  }
  static async getQuestionById(id: number) {
    return await apiNoAuth().get<TQuestion[]>(`/questions/${id}`);
  }
}
