import { NavLink } from 'react-router-dom';
import {FactBlogDTO, TBlog} from "../../../shared/types/types";
import moment from "moment/moment";


export type TRelateBlogProps = {
    blogs :TBlog[] |null
}


export const RelateBlog = ({blogs=[]}:TRelateBlogProps) => {
    const getLinks = ()=>{
     return  blogs && blogs.length > 0 ?
            blogs.map(blog => {
                const link  = `/blog/${blog.id}`
                const convertData = moment(blog.createdDate).format('DD MMMM YYYY')
                return (
                    <NavLink to={link} className='relateBlog__item' key={link}>
                        {blog.name}
                        <span>{convertData}</span>
                    </NavLink>
                      )}
            ) : <></>
     }

    return (
        <div className='relateBlog'>
            <div className='lineTop'> </div>
            <div className="lineHidden"></div>

            <div className="relateBlog__title">Другие статьи</div>
            <div className="relateBlog__list">
                {getLinks()}
            </div>
       </div>
    );
};
