import { defaultTheme } from 'react-admin';

const primaryTextStyle = {
  fontSize: '14px',
  color: '#33404f',
  '@media (max-width: 500px)': {
    fontSize: '12px',
  },
};

export const adminTheme = {
  ...defaultTheme,
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 500,
      md: 768,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    ...defaultTheme.palette,
    error: { main: '#db2947' },
  },
  components: {
    ...defaultTheme.components,
    MuiTypography: {
      styleOverrides: {
        root: {
          ...primaryTextStyle,
          '@media (max-width: 500px)': {
            '& img': { maxWidth: '200px !important' },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          marginTop: 0,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          marginTop: '0',
          marginBottom: '16px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ...primaryTextStyle,
          '&::after': {
            borderColor: '#285B7D',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#285B7D',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '80vh',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...primaryTextStyle,
          minHeight: 'auto',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '&.RaToolbar-mobileToolbar': {
            position: 'relative !important',
            justifyContent: 'space-between',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['AkzidenzGroteskPro', 'sans-serif'].join(','),
    htmlFontSize: 16,
    fontSize: 12.25,
  },
};
