import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { restorePass } from 'manageStore/user/userSlice';
import logo from 'assets/images/header/logo_top.svg';

const RestorePass = () => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const handleSaving = () => setSaving((prev) => !prev);

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        dispatch(restorePass({ ...values }));
      } catch (e) {
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className='registration-page__inner restore-pass ornament'>
      <h1 className='registration-title'>Восстановление пароля</h1>
      <p className='restorePass-text'>
        Введите свой адрес электронной почты. Новый пароль будет отправлен на указанный адрес.
      </p>
      <Link className='registration-logo' to='/'>
        <img src={logo} alt='На главную' />
      </Link>
      <form className='registration-form ornament' onSubmit={formik.handleSubmit}>
        <label className='registration-label email'>
          <input
            className='registration-input'
            id='email'
            type='email'
            placeholder='Email'
            {...formik.getFieldProps('email')}
          />
        </label>
        <p className='profile-error'>{formik.touched.email && formik.errors.email ? formik.errors.email : ''}</p>
        <button className='registration-btn btn lone' type='submit' disabled={saving}>
          Восстановить пароль
        </button>
      </form>
    </div>
  );
};

export default RestorePass;
