import { useState } from 'react';
import { YoutubeCover } from './YoutubeCover';
import { YoutubeVideo } from './YoutubeVideo';

type TYoutubeBlockProps = {
  link: string;
};

export const YoutubeBlock = ({ link }: TYoutubeBlockProps) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => setClicked(true);

  return (
    <div className='yvideo-app' onClick={handleClick}>
      {!clicked ? <YoutubeCover onClick={handleClick} link={link} /> : <YoutubeVideo link={link} />}
    </div>
  );
};
