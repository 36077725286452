import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuth } from 'manageStore/user/userSlice';
import { setReadyOrder } from 'manageStore/designer/designer.slice';
import { selectOrderLocations, selectReadyOrder } from 'manageStore/designer/designer.select';
import { Portal } from 'shared/lib/Portal/Portal';
import { TourApplicationForm } from './TourApplicationForm/TourApplicationForm';
import { TourDesignerPopup } from 'features/shared/TourDesignerPopup/TourDesignerPopup';

const defaultTour = {
  title: 'Тур из конструктора туров',
  duration: '',
  price: '',
  locations: [],
};

export const TourDesignerApplication = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = useSelector(selectIsAuth);
  const isReadyOrder = useSelector(selectReadyOrder);
  const locations = useSelector(selectOrderLocations);
  const [tour, setTour] = useState<Record<string, unknown>>(defaultTour);

  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess((prev) => !prev);

  const closePortal = () => {
    dispatch(setReadyOrder(false));
  };

  const annotationPopup = !isAuth
    ? 'Оставьте ваши контакты, наши операторы свяжутся с вами в ближайшее время. Не является публичной офертой. Точные условия по предложению предоставит Туроператор после оформления заявки.'
    : 'Вы готовы оставить заявку?';

  useEffect(() => {
    const tour = { ...defaultTour, locations: Object.values(locations) };
    setTour(tour);
  }, [locations]);

  useEffect(() => {
    if (success && isAuth) {
      history.push('/account');
    }
  }, [success, isAuth]);

  return (
    <Portal isOpen={isReadyOrder} onClose={closePortal}>
      <TourDesignerPopup
        title='Заказать путешествие'
        onClose={closePortal}
        annotation={annotationPopup}
        className='tour-application'
      >
        <TourApplicationForm handleSuccess={handleSuccess} onClose={closePortal} tour={tour} tourType='Designer' />
      </TourDesignerPopup>
    </Portal>
  );
};
