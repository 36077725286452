import { useEffect, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

type T = {
  history: History;
  children?: ReactNode;
};

function ScrollToTop({ history, children }: T) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
}

export default withRouter(ScrollToTop);
