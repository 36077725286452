import React from 'react';
import { SimpleForm, TextInput, required, Labeled } from 'react-admin';
import { NEWS_FIELDS } from '../../../shared/constants/const';
import CheckBoxControl from '../CheckBoxControl';
import FileInputAdmin from '../FileInputAdmin';
import TinyMCE from '../TinyMCE';

const NewsForm = () => (
  <SimpleForm>
    <Labeled label={NEWS_FIELDS.title} fullWidth>
      <TextInput source='title' validate={[required()]} label='' />
    </Labeled>
    <Labeled label={NEWS_FIELDS.metaDesc} fullWidth>
      <TextInput source='metaDesc' multiline={true} minRows='3' maxRows='3' label='' />
    </Labeled>
    <Labeled label={NEWS_FIELDS.metaKeywords} fullWidth>
      <TextInput source='metaKeywords' multiline={true} minRows='3' maxRows='3' label='' />
    </Labeled>
    <Labeled label={NEWS_FIELDS.shortDesc} fullWidth>
      <TextInput source='desc' multiline={true} minRows='3' maxRows='3' label='' validate={[required()]} />
    </Labeled>
    <Labeled label={NEWS_FIELDS.content} fullWidth>
      <TinyMCE property='content' required />
    </Labeled>
    <FileInputAdmin source='preview' label={NEWS_FIELDS.preview} multiple={false} />
    <CheckBoxControl name='important' label='Это важно' />
    <CheckBoxControl name='isPublished' label='Опубликовано' />
  </SimpleForm>
);

export default NewsForm;
