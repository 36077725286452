import { declensionNoun, TNounsKey } from './declensionNoun';

export const getTextFromMinMaxValue = (
  min: number | null,
  max: number | null,
  { key, label }: { key?: TNounsKey; label?: string }
) => {
  let result = '';
  if (min !== null && max !== null) {
    result = `${min}-${max}`;
  } else {
    if (min !== null) {
      result = `от ${min}`;
    }
    if (max !== null) {
      result += result ? ` до ${max}` : `до ${max}`;
    }
  }
  if (!result) {
    return '-';
  } else if (key) {
    const lastValue = Number(result[result.length - 1]);
    result += ` ${declensionNoun(lastValue, key)}`;
  } else if (label) {
    result += ` ${label}`;
  }
  return result;
};
