import cn from 'classnames';
import { Link } from 'react-router-dom';
import notification from 'assets/images/account/notification.svg';
import settings from 'assets/images/account/settings.svg';
import waiting from 'assets/images/account/waiting.svg';
import denied from 'assets/images/account/denied.svg';
import checked from 'assets/images/account/checked.svg';
import { REQUEST_STATUSSES, URL_FOR_IMG } from '../../shared/constants/const';
import { TRequestFetch } from 'shared/types/request.types';
import { TUserType } from 'shared/types/types';

type TMainRequestsProps = {
  requests: TRequestFetch[];
  type: TUserType;
};

type TItemProps = {
  item: TRequestFetch;
};

const MainRequests = ({ requests, type }: TMainRequestsProps) => {
  const person = type === 'OPERATOR' ? 'клиента' : 'оператора';
  const chatUser = type === 'OPERATOR' ? 'tourist' : 'operator';
  const typeUser = type === 'OPERATOR' ? 'operator' : 'tourist';

  const Item = ({ item }: TItemProps) => {
    const photoUserPath = item[chatUser].photo?.path;
    let icon = null;
    if (item.status === REQUEST_STATUSSES.new) icon = waiting;
    if (item.status === REQUEST_STATUSSES.processing) icon = notification;
    // if (item.status === 'SETTINGS') icon = settings
    if (item.status === REQUEST_STATUSSES.done) icon = checked;
    if (item.status === REQUEST_STATUSSES.denied || item.status === REQUEST_STATUSSES.deleted) icon = denied;
    const status = item.status === REQUEST_STATUSSES.deleted ? REQUEST_STATUSSES.denied : item.status;
    return (
      <Link
        className={cn('Amain__processing-item', {
          unread: item.status === 'NEW' && item.firstView && item.firstView[typeUser] === false,
        })}
        to={`/account/${type.toLowerCase()}/${status.toLowerCase()}-requests/${item.id}`}
      >
        <div className='Amain__processing-item__icon'>{icon ? <img src={icon} alt={item.status} /> : null}</div>
        <div className='Amain__processing-item__photo'>
          {photoUserPath ? (
            <img src={`${URL_FOR_IMG}/${photoUserPath}`} alt='Фото' />
          ) : (
            <div>{item[chatUser].login[0]}</div>
          )}
        </div>
        <div className='Amain__processing-item__username'>{item[chatUser].login}</div>
        <div className='Amain__processing-item__usermail'>{item[chatUser].email}</div>
        <div className='Amain__processing-item__tourname'>{item.tour.name}</div>
        {type === 'OPERATOR' ? (
          <div className='Amain__processing-item__price'>
            Стоимость: <span>{item.tour.price ? item.tour.price : ''}</span>
          </div>
        ) : null}
        {type === 'OPERATOR' ? (
          <div className='Amain__processing-item__datestart'>
            Дата начала: <span>{item.tour.dateStart ? new Date(item.tour.dateStart).toLocaleDateString() : ''}</span>
          </div>
        ) : null}
        <div
          className='Amain__processing-item__time'
          style={type === 'TOURIST' ? { paddingRight: 0, textAlign: 'left' } : {}}
        >
          {new Date(item.lastMessage).toLocaleString()}
        </div>
      </Link>
    );
  };

  return (
    <div className='Amain__processing-table'>
      <div className='Amain__processing-head'>
        <li className='Amain__processing-item table-head'>
          <div className='Amain__processing-item__icon'>
            <img src={settings} alt='Шестеренка' />
          </div>
          <div className='Amain__processing-item__photo'></div>
          <div className='Amain__processing-item__username'>По имени {person}</div>
          <div className='Amain__processing-item__usermail'>По E-MAIL {person}</div>
          <div className='Amain__processing-item__tourname'>По имени тура</div>
          {type === 'OPERATOR' ? <div className='Amain__processing-item__price'>По стоимости</div> : null}
          {type === 'OPERATOR' ? <div className='Amain__processing-item__datestart'>По дате начала</div> : null}
          <div
            className='Amain__processing-item__time'
            style={type === 'TOURIST' ? { paddingRight: 0, textAlign: 'left' } : {}}
          >
            По времени
          </div>
          {/* {type === 'TOURIST' ? <div className='Amain__processing-item__review'></div> : null} */}
        </li>
      </div>
      <ul className='Amain__processing-list'>
        {requests && requests.length ? requests.map((item, index) => <Item item={item} key={index} />) : null}
      </ul>
    </div>
  );
};

export default MainRequests;
