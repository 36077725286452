import React, { useEffect, useState } from 'react';
import { SelectInput, required, Labeled, useRecordContext, useChoicesContext } from 'react-admin';
import OsmMapForm from './OsmMapForm';

const SelectCity = () => {
  const { selectedChoices } = useChoicesContext();
  const record = useRecordContext();
  const isCoords = record?.lat && record?.lng;
  const [center, setCenter] = useState(isCoords ? [record.lat, record.lng] : [69.34398, 88.21039]);
  const [idCity, setIdCity] = useState(selectedChoices?.city?.id || null);

  useEffect(() => {
    const [city] = selectedChoices;
    if (!city || city.id === idCity) return;
    const { lat, lng } = city;
    const coords = [lat, lng];
    setIdCity(city.id);
    setCenter(coords);
  }, [selectedChoices]);

  return (
    <>
      <Labeled label='Город' fullWidth>
        <SelectInput
          optionText='name'
          optionValue='id'
          label='Select...'
          fullWidth
          format={(v) => (v ? v : '')}
          validate={[required()]}
        />
      </Labeled>
      <div style={{ width: '100%' }}>
        <OsmMapForm outCenter={center} required />
      </div>
    </>
  );
};

export default SelectCity;
