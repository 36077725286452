import { cva } from 'class-variance-authority';
import React from 'react';
import { number, object, string } from 'yup';
import { Form, Formik } from 'formik';
import Rating from '../../entities/rating/ui';
import { cvaReview, cvaSeparator, cvaSubtitle, cvaTitle } from '../review/ui';
import Separator from '../sidebar/separator';
import Tag from '../../entities/tag';
import { cvaInput } from '../../entities/input_styles';
import s from './index.module.scss';
import clsx from 'clsx';
import Button from '../../shared/ui/button';
//@ts-ignore
import { useHistory, useParams } from 'react-router-dom';
import { TReviewFetchRequest, TReviewTour } from '../../../shared/types/review.types';
import { newReviewThunk } from '../../../manageStore/reviews/reviews.thunk';
import { useAppDispatch } from '../../../shared/hooks/useAppDispatch';
import { selectCurrentRequest } from '../../../manageStore/request/request.select';
import { useSelector } from 'react-redux';
import { selectUserData } from 'manageStore/user/userSlice';
import RequestService from 'services/request.service';
import { useAsync } from 'react-use';
import Notification from 'shared/lib/notification';
const cvaTextarea = cva([s.textarea]);
const cvaSymbols = cva([s.symbs]);

interface ReviewFormProps {
  review?: TReviewFetchRequest;
}

export default function ReviewForm({ review }: ReviewFormProps) {
  const navigate = useHistory();
  const dispatch = useAppDispatch();


  const formInitialValues = {
    advantages: review?.advantages ?? '',
    disadvantages: review?.disadvantages ?? '',
    comment: review?.comment ?? '',
    rating: review?.rating ?? 0,
    mostliked: review?.mostliked ?? [],
  };
  const params = useParams<{ id?: string }>();
  const userSchema = object({
    rating: number().min(1, 'Поставьте рейтинг'),
    advantages: string()
    .required('Заполните поле')
    .matches(/^(?!\s+$)/, 'Заполните поле'),

    disadvantages: string()
      .required('Заполните поле')
      .matches(/^(?!\s+$)/, 'Заполните поле'),

    comment: string()
  });
  const user = useSelector(selectUserData);

  const { value: isRequest } = useAsync(async () => {
    try {
      if (params.id) {
        const requests = await Promise.all(
          [
            RequestService.fetchRequests({
              status: "DONE",
              id: user?.id ?? 0,
              type: user?.type.toLowerCase() ?? '',
            }),
            RequestService.fetchRequests({
              status: "PROCESSING",
              id: user?.id ?? 0,
              type: user?.type.toLowerCase() ?? '',
            }),
          ]
        );
        const flatArrRequests = requests.map(data => data.data).flat(1)
        return flatArrRequests.some(req => req.tour.id === Number(params.id))
      } 
    } catch (error: any) {
      return
    }
  }, [params.id]);


  const onSubmit = async (v: TReviewFetchRequest) => {
    if (!params.id) return;
    if (isRequest) {
      await dispatch(newReviewThunk({ ...v, 
        disadvantages: v.disadvantages.replace(/^\s+/, ''),
        advantages: v.advantages.replace(/^\s+/, ''),
        comment: v.comment.replace(/^\s+/, ''),
        tourId: Number(params.id) }));

      navigate.push('/account/tourist/my-reviews')
    } else {
      Notification.warning("Вы не можете оставить отзыв этому туру")
    }
  }
    

  const onChangeTag = (v: string, values: string[], setFiledValue: (name: string, v: string[]) => void) => {
    if (values.includes(v)) {
      setFiledValue(
        'mostliked',
        values.filter((e) => e != v)
      );
    } else {
      setFiledValue('mostliked', [...values, v]);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={formInitialValues}
        validationSchema={userSchema}
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={cvaReview()}>
            <h3 className={cvaTitle()}>Общая оценка</h3>
            <Rating onChange={(e) => setFieldValue('rating', e)} rating={values.rating} />
            {errors.rating && touched.rating && <div className={s.error}>{errors.rating}</div>}
            <Separator className={cvaSeparator()} />
            <div className={s.group}>
              <div className={cvaSubtitle()}>Больше всего понравилось:</div>
              <div className={s.flex}>
                <Tag
                  onClick={() => onChangeTag('Маршрут', values.mostliked, setFieldValue)}
                  variant={values.mostliked.includes('Маршрут') ? 'blue-light' : 'outlined'}
                >
                  Маршрут
                </Tag>
                <Tag
                  onClick={() => onChangeTag('Гиды', values.mostliked, setFieldValue)}
                  variant={values.mostliked.includes('Гиды') ? 'blue-light' : 'outlined'}
                >
                  Гиды
                </Tag>
                <Tag
                  onClick={() => onChangeTag('Сервис', values.mostliked, setFieldValue)}
                  variant={values.mostliked.includes('Сервис') ? 'blue-light' : 'outlined'}
                >
                  Сервис
                </Tag>
                <Tag
                  onClick={() => onChangeTag('Жилье', values.mostliked, setFieldValue)}
                  variant={values.mostliked.includes('Жилье') ? 'blue-light' : 'outlined'}
                >
                  Жилье
                </Tag>
              </div>
            </div>
            <div className={s.group}>
              <div className={cvaSubtitle()}>Достоинства</div>
              <div className='relative'>
                <textarea
                  name='advantages'
                  value={values.advantages}
                  onChange={(e) => setFieldValue('advantages', e.target.value)}
                  className={clsx(
                    cvaInput({ hasError: Boolean(errors.advantages && touched.advantages) }),
                    cvaTextarea(),
                    'scrollbar'
                  )}
                />
                <div className={cvaSymbols()}>{values.advantages.length}/2500</div>
              </div>
              {errors.advantages && touched.advantages && <div className={s.error}>{errors.advantages}</div>}
            </div>
            <div className={s.group}>
              <div className={cvaSubtitle()}>Недостатки</div>
              <div className='relative'>
                <textarea
                  name='disadvantages'
                  value={values.disadvantages}
                  onChange={(e) => setFieldValue('disadvantages', e.target.value)}
                  className={clsx(
                    cvaInput({ hasError: Boolean(errors.disadvantages && touched.disadvantages) }),
                    cvaTextarea(),
                    'scrollbar'
                  )}
                />
                <div className={cvaSymbols()}>{values.disadvantages.length}/2500</div>
              </div>
              {errors.disadvantages && touched.disadvantages && <div className={s.error}>{errors.disadvantages}</div>}
            </div>
            <div className={s.group}>
              <div className={cvaSubtitle()}>Комментарий</div>
              <div className='relative'>
                <textarea
                  name='comment'
                  value={values.comment}
                  onChange={(e) => setFieldValue('comment', e.target.value)}
                  className={clsx(cvaInput(), cvaTextarea(), 'scrollbar')}
                />
                <div className={cvaSymbols()}>{values.comment.length}/2500</div>
              </div>
              {errors.comment && touched.comment && <div className={s.error}>{errors.comment}</div>}
            </div>
            <div className={s.btns}>
              <Button type='submit' intent={'dark'}>
                Отправить
              </Button>
              <Button onClick={() => navigate.goBack()} type='submit' intent={'outlined'}>
                Отмена
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
