import React, { useEffect, useState } from 'react';
import s from './index.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/x.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/sucess.svg';
import { ReactComponent as CircleIcon } from 'assets/icons/animation-circle.svg';
import { useTimeout } from 'react-admin';

type Props = {
  name: string;
  onDelete: () => void;
};

export default function LoadFile({ name, onDelete }: Props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 600);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className={s.wrapper}>
      <div className={s.loading}>{loading ? <CircleIcon className={s.animation} /> : <SuccessIcon />}</div>
      <span>{name}</span>
      <CloseIcon className={s.xIcon} onClick={onDelete} />
    </div>
  );
}
