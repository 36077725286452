import { declensionNoun } from 'helpers/declensionNoun';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';

type TButtonsManageFiltersProps = {
  clearFilters: () => void;
  applyFilters: () => void;
  countVariants?: number;
  isActiveClearButton: boolean;
};

export const FiltersManageButtons = ({
  clearFilters,
  applyFilters,
  countVariants,
  isActiveClearButton,
}: TButtonsManageFiltersProps) => {
  const textButton =
    countVariants === undefined
      ? 'Применить'
      : countVariants === 0
      ? `Варианты отсутствуют`
      : `Показать ${countVariants} ${declensionNoun(countVariants, 'variant')}`;

  return (
    <div className='filters-manage'>
      <button
        className='filters-button filters-button_clear light'
        type='button'
        disabled={!isActiveClearButton}
        onClick={clearFilters}
      >
        <span>очистить все</span>
      </button>
      <button className='filters-button dark full' type='button' onClick={applyFilters} disabled={countVariants === 0}>
        <span>{textButton}</span>
        <ArrowButton />
      </button>
    </div>
  );
};
