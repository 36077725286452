export const LOCATIONS_FIELDS = {
  id: 'id',
  title: 'Название локации',
  locationActive: 'Активности',
  locationType: 'Тип локации',
  level: 'Уровень',
  preview: 'Превью',
  coords: 'Координаты',
  shortDesc: 'Краткое описание',
  desc: 'Полное описание',
  commentLevel: 'Комментарий',
  season: 'Сезон проведения',
  groupSize: 'Размер группы для локации',
  guidebook: 'Cсылка на статью',
  territory: 'Кластер',
  transport: 'Транспорт',
  gallery: 'Галерея',
  popular: 'Популярная локация',
};

export const TRANSFORM_LOCATIONS = {
  territory: {
    PLATO_PUTORANA: {
      label: 'Плато Путорана',
      value: 'Плато Путорана',
    },
    NORILSK: {
      label: 'Норильск',
      value: 'Норильск',
    },
    ENISEY_ZALIV: {
      label: 'Енисейский залив',
      value: 'Енисейский залив',
    },
    HATANG_ZALIV: {
      label: 'Хатангский залив',
      value: 'Хатангский залив',
    },
  },
  type: {
    NATURE: {
      label: 'Природная локация',
      value: 'NATURE',
    },
    WATER: {
      label: 'Водная локация',
      value: 'WATER',
    },
    MOUNT: {
      label: 'Горная локация',
      value: 'MOUNT',
    },
    SPECIAL_TRANSFER: {
      label: 'Локация для специального транспорта',
      value: 'SPECIAL_TRANSFER',
    },
    DEVELOP: {
      label: 'Промышленная локация',
      value: 'DEVELOP',
    },
    HELICOPTER: {
      label: 'Локация для вертолета',
      value: 'HELICOPTER',
    },
  },
  transport: {
    OFFROAD: {
      label: 'Внедорожник',
      value: 'OFFROAD',
    },
    HELICOPTER: {
      label: 'Вертолет',
      value: 'HELICOPTER',
    },
    HIKING: {
      label: 'Пешая доступность',
      value: 'HIKING',
    },
    AUTO: {
      label: 'Автомобиль',
      value: 'AUTO',
    },
    WATER: {
      label: 'Водный транспорт',
      value: 'WATER',
    },
  },
  active: {
    city: 'Город',
    industry: 'Промышленность',
    jeeps: 'Джипы',
    expedition: 'Экспедиция',
    northernLights: 'Охота за северным сиянием',
    birdwatching: 'Бердвотчинг',
    quadBikes: 'Квадроциклы',
    sapsurfing: 'Сапсерфинг',
    photo: 'Фото',
    mountaineering: 'Альпинизм',
  },
} as const;
