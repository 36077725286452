import { ReactComponent as FilterIcon } from 'assets/images/common/filter.svg';

type TButtonFilterProps = {
  isActiveFiltersParams: boolean;
  openFilters: () => void;
};

export const ButtonFilter = ({ isActiveFiltersParams, openFilters }: TButtonFilterProps) => {
  return (
    <div className='button-filter-wrapper'>
      <div className='button-filter' onClick={openFilters}>
        <FilterIcon className='button-filter__icon' />
        <div className='button-filter__text'>Фильтры</div>
      </div>
      {isActiveFiltersParams && <div className='button-filter__active'></div>}
    </div>
  );
};
