import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import VectorLayer from 'ol/layer/Vector';
import { Cluster } from 'ol/source';
import Feature from 'ol/Feature';
import { TLocation } from 'shared/types/location.types';
import { generateOverlay } from '../helpers/generateOverlay';
import { usePointer } from './usePointer';

export const useBalloon = (
  map: Map | null,
  clusters: VectorLayer<Cluster> | null
): [TLocation | null, RefObject<HTMLDivElement>] => {
  const popup = useRef<HTMLDivElement>(null);

  const [balloonInfo, setBalloonInfo] = useState<TLocation | null>(null);

  const handleClickCluster = useCallback((event: MapBrowserEvent<any>, clusters: VectorLayer<Cluster>, map: Map) => {
    const overlay = map.getOverlays().getArray()[0];
    if (!overlay || !map) return;
    overlay.setPosition(undefined);
    clusters.getFeatures(event.pixel).then((features) => {
      if (features.length === 0) return;
      const clusterMembers: Feature[] = features[0].get('features');
      const infoLoc: TLocation = clusterMembers[0].get('infoLoc');
      const coords = features[0].getGeometry()?.getExtent();
      setBalloonInfo(infoLoc);
      overlay.setPosition(coords);
    });
  }, []);

  usePointer(map, clusters, handleClickCluster);

  useEffect(() => {
    if (!map || !popup.current) return;
    const overlayPopup = generateOverlay(popup.current);
    map.addOverlay(overlayPopup);
  }, [map]);

  return [balloonInfo, popup];
};
