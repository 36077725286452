import { TravelGuideParamsGroup } from './TravelGuideParamsGroup';
import { TTravelGuideAttributes } from 'shared/types/travelGuide.types';

type TTravelGuideFloraParamsProps = {
  params: TTravelGuideAttributes[];
  transport: string;
  nearCity: string;
  coords: string;
};

export const TravelGuideFloraParams = ({ params, transport, nearCity, coords }: TTravelGuideFloraParamsProps) => {
  return (
    <>
      <div className='travel-guide-item-params flora'>
        <div className='address-title flora'>О локации</div>
        {params.map(({ id, name, value }) => (
          <TravelGuideParamsGroup className='flora' title={name} description={value} key={id} />
        ))}
        {/* <TravelGuideParamsGroup className='flora' title='Территория' description={params.territory} /> */}
        {/* <TravelGuideParamsGroup className='flora' title='Площадь' description={params.square} /> */}
        {/* <TravelGuideParamsGroup className='flora' title='Климат' description={params.climate} /> */}
        {/* <TravelGuideParamsGroup className='flora' title='Сезон для посещения' description={params.season} /> */}
        {/* <TravelGuideParamsGroup className='flora' title='Уровень подготовки' description={params.level} /> */}
      </div>
      <div className='travel-guide-item-address flora'>
        <div className='address-title flora'>Как добраться</div>
        <TravelGuideParamsGroup className='flora' title='Варианты транспорта' description={transport} />
        <TravelGuideParamsGroup className='flora' title='Ближайший населенный пункт' description={nearCity} />
        <TravelGuideParamsGroup className='flora' title='Координаты' description={coords} />
      </div>
    </>
  );
};
