import { TravelGuideParamsGroup } from './TravelGuideParamsGroup';
import { ReactComponent as AimIcon } from 'assets/images/common/aim.svg';
import { ReactComponent as CompassIcon } from 'assets/images/common/compass.svg';
import { TTravelGuideAttributes } from 'shared/types/travelGuide.types';

type TTravelGuideCityParamsProps = {
  params: TTravelGuideAttributes[];
  address: string;
  howToReach: string;
};

export const TravelGuideCityParams = ({ params, address, howToReach }: TTravelGuideCityParamsProps) => {
  return (
    <>
      <div className='travel-guide-item-params city'>
        {params.map(({ id, value, name }) => (
          <TravelGuideParamsGroup className='city' title={name} description={value} key={id} />
        ))}
      </div>
      <div className='travel-guide-item-address city'>
        <div className='address-title city'>Как добраться</div>
        <div className='city-address-group'>
          <CompassIcon className='city-address-group__icon address' />
          <TravelGuideParamsGroup className='city' description={address} />
        </div>
        <div className='city-address-group'>
          <AimIcon className='city-address-group__icon' />
          <TravelGuideParamsGroup className='city' description={howToReach} />
        </div>
      </div>
    </>
  );
};
