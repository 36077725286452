import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { getPublishedNews, selectNewsList, selectImportant, selectImportantBlogs } from 'manageStore/news/newsSlice';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import { NewsMain } from '../../widgets/lib/newsMain/newsMain/NewsMain';
import { NewsIntroSlider } from '../../features/lib/NewsIntro/NewIntroSlider';
import { useMediaQuery } from '../../shared/hooks/useMatchMedia';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';
// import MobileBottom from 'components/MobileBottom/MobileBottom';

const BlogsPage = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);
  const handleScroll = () => {
    const needScroll = isMobile ? 50 : 500;
    if (window.scrollY > needScroll) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Блог - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isWhiteLink={isMobile && !scrolled} isFixed />
      <AuthPortal />
      <NewsIntroSlider news={[]} />
      <NewsMain />
      <Footer />
    </>
  );
};

export default BlogsPage;
