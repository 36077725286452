import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';

type TOptions = {
  zoom?: number;
  center?: number[];
};

export const moveMap = (map: Map, { zoom, center }: TOptions) => {
  const viewMap = map.getView();
  if (center) {
    viewMap.setCenter(fromLonLat(center));
  }
  if (zoom) {
    viewMap.setZoom(zoom);
  }
};
