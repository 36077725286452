import React from 'react'
import { Create } from 'react-admin'
import ParticipantsForm from './ParticipantsForm'

const ParticipantsCreate = () => (
  <Create>
    <ParticipantsForm />
  </Create>
)

export default ParticipantsCreate
