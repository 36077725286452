import React from 'react';
import Layout from '../../widgets/layout/ui';
import ApplicationList, { TUiStatusRequest } from '../../widgets/application-list/ui';
import Onboarding from '../../features/onboarding/ui';
import PageHead from '../../entities/page-head/index';
import ContainerContent from '../../entities/container';
import { TUserType } from '../../../shared/types/types';
import { useAsync, useLocalStorage } from 'react-use';
import s from './index.module.scss';
import { useAppSelector } from '../../../shared/hooks/useAppSelector';
import { useHistory } from 'react-router-dom';
import RequestService from '../../../services/request.service';
import Notification from '../../../shared/lib/notification';
import useCheckRegister from '../../shared/hooks/useCheckRegister';
import NoRequests from '../../features/no-requests';
import Loading from 'components/Loading/Loading';
import clsx from 'clsx';
import { TRequestFetch, TStatusRequest } from '../../../shared/types/request.types';

interface RequestsPageNewProps {
  type: TUserType;
  navs: any;
  mobileNavs: any;
}

export const RequestsTypes: Array<{
  name: string;
  // todo value is nowhere used
  value: string;
  status: TStatusRequest;
}> = [
  {
    name: 'ДОСТУПНЫЕ ЗАЯВКИ',
    value: 'Новый',
    status: 'NEW',
  },
  {
    name: 'Текущие заявки',
    value: 'В работе',
    status: 'PROCESSING',
  },
  {
    name: 'Завершенные заявки',
    value: 'Заверешена',
    status: 'DONE',
  },
  {
    name: 'Непринятые заявки',
    value: 'В рассмотрении',
    status: 'DENIED',
  },
  {
    name: 'Удалённые заявки',
    value: 'Удалено',
    status: 'DELETED',
  },
];

const RequestsPageNew = ({ type, ...props }: RequestsPageNewProps) => {
  useCheckRegister(type);
  const userData = useAppSelector((state) => state.user.userData);
  const history = useHistory();

  const [isOpenOnboarding, setOpenOnboarding] = useLocalStorage('is_open_onboarding_tourist', true);

  const { value: requests, loading } = useAsync(async () => {
    try {
      const requests = await Promise.all(
        RequestsTypes.map(async (type) => {
          const reqData = {
            status: type.status,
            id: userData?.id ?? 0,
            type: userData?.type.toLowerCase() ?? '',
          };
          const data = await RequestService.fetchRequests(reqData);
          return {
            ...type,
            requests: data.data,
          };
        })
      );
      // combining 'DENIED' & 'DELETED' into 'CANCELED'
      const combinedRequests = function(){
        const combinedRequests = requests as Array<{
          requests: TRequestFetch[];
          name: string;
          value: string;
          status: TUiStatusRequest;
        }>;
        const deletedRequestsIdx = combinedRequests.findIndex(it=>it.status==='DELETED');
        const deletedRequests = combinedRequests.splice(deletedRequestsIdx, 1)[0];
        const deniedRequestsIdx = combinedRequests.findIndex(it=>it.status==='DENIED');
        const deniedRequests = combinedRequests[deniedRequestsIdx];
        combinedRequests.splice(deniedRequestsIdx, 1, {
          name: 'Отменённые заявки',
          value: 'Отменено',
          status: 'CANCELED',
          requests: [...deniedRequests.requests, ...deletedRequests.requests]
            .sort((a,b)=>{
              if (a.lastMessage > b.lastMessage) return -1;
              if (a.lastMessage < b.lastMessage) return 1;
              return 0;
            }),
        });
        return combinedRequests;
      }();
      return combinedRequests;
    } catch (error: any) {
      Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
    }
  });
  const noRequests = requests?.every((e) => !e.requests.length);

  return (
    <Layout>
      {isOpenOnboarding && (
        <Onboarding
          onClose={() => setOpenOnboarding(false)}
          slides={[
            {
              title: 'Личный кабинет',
              description:
                'Добро пожаловать в ваш личный кабинет! Здесь доступен широкий спектр возможностей для создания удивительных путешествий!',
            },
            {
              title: 'Заявки',
              description:
                'Выбери свое путешествие! После подтверждения заявки вы сможете ее отслеживать и общаться с организатором путешествия',
            },
            {
              title: 'Отзывы',
              description: 'Поделись своими впечатлениями с организатором, отзывы помогут стать лучше!',
            },
            // todo: Вернуть когда вернем Избранные маршруты
            // {
            //   title: 'Избранные маршруты',
            //   description:
            //     'В конструкторе путешествий есть возможность создать свое собственное путешествие, здесь будут отображаться ваши сохраненные маршруты',
            // },
            {
              title: 'Вперед к открытиям!',
              description:
                'Начни свое путешествие прямо сейчас, переходи на страницу «Туры и экскурсии» или создай свой собственный маршрут «Конструктор путешествий» + кнопка остаться в личном кабинете',
            },
          ]}
        />
      )}
      <PageHead>Мои заявки</PageHead>
      <div className={clsx('md-max:bg-white relative')}>
        {requests && !noRequests ? (
          <ContainerContent className={s.container}>
            <ApplicationList requests={requests} />
          </ContainerContent>
        ) : loading ? (
          <div className={s.full}>
            <Loading />
          </div>
        ) : (
          <div className={s.full}>
            <NoRequests />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default RequestsPageNew;
