import { Edit } from 'react-admin';
import { LocationsForm } from './LocationsForm';
import { TLocation } from 'shared/types/location.types';
import { TRANSFORM_LOCATIONS } from 'shared/constants/locations.constants';

const transform = (data: TLocation): TLocation => {
  if (!data.locationType?.includes(TRANSFORM_LOCATIONS.active.city)) {
    data.city = null;
  }
  const cluster = (!!data.city ? data.city.cluster : data.cluster) || null;
  return {
    ...data,
    cluster,
  };
};

export const LocationsEdit = () => (
  <Edit transform={transform}>
    <LocationsForm />
  </Edit>
);
