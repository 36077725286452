import * as React from 'react';
import { Show, SimpleShowLayout, TextField, FunctionField, Labeled, ReferenceField } from 'react-admin';
import CoordsShow from '../CoordsShow';
import { ATTRACTION_FIELDS, URL_FOR_IMG } from '../../../shared/constants/const';

const AttractionShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={ATTRACTION_FIELDS.title} />
        <CoordsShow />
        <TextField source='description' label={ATTRACTION_FIELDS.description} />
        <TextField source='time' label={ATTRACTION_FIELDS.time} />
        <TextField source='contacts' label={ATTRACTION_FIELDS.contacts} />
        <FunctionField
          label={ATTRACTION_FIELDS.preview}
          render={(record) => {
            return record.preview ? (
              <img
                className='admin-image admin-image__single'
                src={`${URL_FOR_IMG}/${record.preview.path}`}
                alt='preview'
              />
            ) : null;
          }}
        />
        <Labeled label={ATTRACTION_FIELDS.city}>
          <ReferenceField source='city' reference='city'>
            <TextField source='name' />
          </ReferenceField>
        </Labeled>
      </SimpleShowLayout>
    </Show>
  </div>
);

export default AttractionShow;
