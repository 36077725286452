import {
  SimpleForm,
  TextInput,
  required,
  Labeled,
} from 'react-admin';
import { QUESTIONS_FIELDS } from '../../../shared/constants/const';


export const QuestionsForm = () => {
  return (
    <SimpleForm>
      <Labeled label={QUESTIONS_FIELDS.title} fullWidth isRequired>
        <TextInput source='title' validate={[required()]} label='' />
      </Labeled>      
      <Labeled label={QUESTIONS_FIELDS.body} fullWidth isRequired>
        <TextInput source='body' validate={[required()]} multiline={true} minRows='3' maxRows='10' label='' />
      </Labeled>
    </SimpleForm>
  );
};
