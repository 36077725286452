import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  TCategoryTravelGuide,
  TFiltersTravelsGuide,
  TNamesParamsTravelsGuideFilter,
  TNamesTypeTravelsGuideFilter,
} from 'shared/types/travelGuide.types';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { defaultFiltersTravelsGuide, setFilters } from 'manageStore/travelsGuide/travelsGuide.slice';
import { selectFilteredTravelsGuide } from 'manageStore/travelsGuide/travelsGuide.select';
import { TravelsGuideFiltersByType } from './TravelsGuideFiltersByType';
import { TravelsGuideFiltersByTheme } from './TravelsGuideFiltersByTheme';
import { TravelsGuideFiltersByTerritory } from './TravelsGuideFiltersByTerritory';
import { ButtonFilter, PortalFilter } from 'features/shared';

type TTravelsGideFiltersProps = {
  filters: TFiltersTravelsGuide;
  changeFiltersTravelsGuide: (type: TCategoryTravelGuide | null, fieldFilters: TNamesTypeTravelsGuideFilter) => void;
};

export const TravelsGuideFilters = ({ filters, changeFiltersTravelsGuide }: TTravelsGideFiltersProps) => {
  const dispatch = useDispatch();
  const [isOpenPortal, setOpenPortal] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);
  const filtersRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(filtersRef);

  const travelsGuide = useAppSelector((state) => selectFilteredTravelsGuide(state, localFilters));

  const isActiveClearButton = !!localFilters.territory || !!localFilters.theme;
  const isActiveFiltersParams = !!filters.territory || !!filters.theme;

  const togglePortal = () => {
    if (isOpenPortal) {
      setLocalFilters(filters);
    }
    setOpenPortal((prev) => !prev);
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const changeParamsTravelsGuide = <TName extends TNamesParamsTravelsGuideFilter>(
    name: TName,
    value: TFiltersTravelsGuide[TName]
  ) => {
    setLocalFilters((prev) => ({ ...prev, [name]: value }));
  };

  const clearParamsFilters = () => {
    setLocalFilters(({ type }) => ({ ...defaultFiltersTravelsGuide, type }));
  };

  const showTravelsGuide = () => {
    togglePortal();
    dispatch(setFilters(localFilters));
  };

  return (
    <>
      <div ref={filtersRef}></div>
      <div className={classNames('travels-guide-filters', { fixed: !isOnScreen }, { hidden: isOnScreen })}>
        <div className={classNames('travels-guide-filters__container', { fixed: !isOnScreen }, { hidden: isOnScreen })}>
          <div className='travels-guide-filters__content'>
            <div className='travels-guide-filters__types-list'>
              <TravelsGuideFiltersByType filters={filters} clickFilter={changeFiltersTravelsGuide} />
            </div>
            <ButtonFilter openFilters={togglePortal} isActiveFiltersParams={isActiveFiltersParams} />
          </div>
        </div>
      </div>
      {isOpenPortal && (
        <PortalFilter
          togglePortal={togglePortal}
          clearFilters={clearParamsFilters}
          applyFilters={showTravelsGuide}
          countVariants={travelsGuide.length}
          isActiveClearButton={isActiveClearButton}
        >
          <TravelsGuideFiltersByTheme filters={localFilters.theme} onClick={changeParamsTravelsGuide} />
          <TravelsGuideFiltersByTerritory filters={localFilters.territory} onClick={changeParamsTravelsGuide} />
        </PortalFilter>
      )}
    </>
  );
};
