import {Datagrid, EditButton, List, Pagination, TextField} from "react-admin";
import {PAGE_SETTINGS_FIELDS, MENU_ADMIN_LIST} from "../../../shared/constants/const";

export const PageSettingsList = () => (
    <List title={MENU_ADMIN_LIST.pageSettings} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
        <Datagrid sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
            <TextField source='key' sortable={false} label={PAGE_SETTINGS_FIELDS.key} />
            <TextField source='value' sortable={false} label={PAGE_SETTINGS_FIELDS.value} />
            <TextField source='icon' sortable={false} label={PAGE_SETTINGS_FIELDS.icon} />
            <EditButton />
        </Datagrid>
    </List>
);
