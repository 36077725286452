import { URL_FOR_IMG } from '../../shared/constants/const';

export const transformListData: Record<string, (json: any) => any[]> = {
  tours(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        recommendations: el.recommendations ? el.recommendations : [],
        recommendedTours: el.recommendedTours ? el.recommendedTours : [],
      }));
    }
    return data;
  },

  locations(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        preview: el.preview?.path ? `${URL_FOR_IMG}/${el.preview.path}` : null,
      }));
    }
    return data;
  },

  news(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        important: el.important ? 'Да' : 'Нет',
        isPublished: el.isPublished ? 'Да' : 'Нет',
        preview: el.preview?.path ? `${URL_FOR_IMG}/${el.preview.path}` : null,
      }));
    }
    return data;
  },

  beauty(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        preview: el.preview?.path ? `${URL_FOR_IMG}/${el.preview.path}` : null,
      }));
    }
    return data;
  },

  lodging(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        preview: el.preview?.path ? `${URL_FOR_IMG}/${el.preview.path}` : null,
      }));
    }
    return data;
  },

  food(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        preview: el.preview?.path ? `${URL_FOR_IMG}/${el.preview.path}` : null,
      }));
    }
    return data;
  },

  attraction(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el) => ({
        ...el,
        preview: el.preview?.path ? `${URL_FOR_IMG}/${el.preview.path}` : null,
      }));
    }
    return data;
  },

  feedback(json: any) {
    let data = [];
    if (Array.isArray(json)) {
      data = json.map((el, index) => ({
        ...el,
        id: el.id || index,
      }));
    }
    return data;
  },
};
