import React from 'react';
import Tag from '../../../../entities/tag';
import { RequestsTypes } from 'newProject/pages/my-applications';
import { TStatusRequest } from 'shared/types/request.types';

type Props = {
  status: TStatusRequest;
};

export default function RequestStatus({ status }: Props) {
  const variant = (status: TStatusRequest) => {
    switch (status) {
      case 'DELETED':
        return 'red';
      case 'NEW':
        return 'gray';
      case 'PROCESSING':
        return 'blue-dark';
      case 'DONE':
        return 'green';
      case 'DENIED':
        return 'red';
      default:
        return 'gray';
    }
  };
  return <Tag variant={variant(status)}>{RequestsTypes.find((e) => e.status === status)?.value ?? 'Отменена'}</Tag>;
}

type PaidProps = {
  isPaid: boolean;
};

export function RequestStatusPaid({ isPaid }: PaidProps) {
  return <Tag variant={isPaid ? 'green' : 'orange'}>{isPaid ? 'Оплачено' : 'Не оплачено'}</Tag>;
}
