import { useEffect, useState } from 'react';
import { TRANSFORM_TEXT } from 'shared/constants/const';
import { ReactComponent as CardsIcon } from 'assets/images/tours/cards.svg';
import { ReactComponent as SuitcaseIcon } from 'assets/images/partners/suitcase.svg';
import { ReactComponent as BureauIcon } from 'assets/images/partners/bureau.svg';
import { ReactComponent as AgentIcon } from 'assets/images/partners/agent.svg';
import { ReactComponent as GuideIcon } from 'assets/images/partners/guide.svg';
import { ReactComponent as MasterIcon } from 'assets/images/partners/master.svg';
import { ReactComponent as OtherIcon } from 'assets/images/partners/mountain.svg';
import { TCategoryPartner, TFiltersPartners, TNamesTypePartnersFilter } from 'shared/types/partner.types';
import { PartnersTypesRow } from './PartnersTypesRow';
import { PartenrsTypesSelect } from './PartenrsTypesSelect';

type TPartnersFiltersByType = {
  filters: TFiltersPartners;
  clickFilter: (filter: TCategoryPartner, fieldFilters: TNamesTypePartnersFilter) => void;
  withLocations?: boolean;
};

type TItemFilters = {
  id: string;
  label: string;
  value: TCategoryPartner;
  icon: JSX.Element;
  fieldFilters: TNamesTypePartnersFilter;
};

const itemsTypePartner: TItemFilters[] = [
  {
    id: 'all',
    label: 'Все партнеры',
    value: '' as TCategoryPartner,
    icon: <CardsIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: TRANSFORM_TEXT.partners.operator.value,
    label: TRANSFORM_TEXT.partners.operator.label,
    value: TRANSFORM_TEXT.partners.operator.value as TCategoryPartner,
    icon: <SuitcaseIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: TRANSFORM_TEXT.partners.excursionBureau.value,
    label: TRANSFORM_TEXT.partners.excursionBureau.label,
    value: TRANSFORM_TEXT.partners.excursionBureau.value as TCategoryPartner,
    icon: <BureauIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: TRANSFORM_TEXT.partners.guide.value,
    label: TRANSFORM_TEXT.partners.guide.label,
    value: TRANSFORM_TEXT.partners.guide.value as TCategoryPartner,
    icon: <GuideIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: TRANSFORM_TEXT.partners.agent.value,
    label: TRANSFORM_TEXT.partners.agent.label,
    value: TRANSFORM_TEXT.partners.agent.value as TCategoryPartner,
    icon: <AgentIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: TRANSFORM_TEXT.partners.souvenirMaster.value,
    label: TRANSFORM_TEXT.partners.souvenirMaster.label,
    value: TRANSFORM_TEXT.partners.souvenirMaster.value as TCategoryPartner,
    icon: <MasterIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: TRANSFORM_TEXT.partners.otherServices.value,
    label: TRANSFORM_TEXT.partners.otherServices.label,
    value: TRANSFORM_TEXT.partners.otherServices.value as TCategoryPartner,
    icon: <OtherIcon className='partners-filters__type__icon' />,
    fieldFilters: 'types',
  },
];

export const PartnersFiltersByType = ({ filters, clickFilter }: TPartnersFiltersByType) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobile = screenWidth < 1123;

  useEffect(() => {
    const handleResize = ({ target }: UIEvent) => {
      if (!(target instanceof Window)) return;
      setScreenWidth(target.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? (
    <PartenrsTypesSelect filters={filters} itemsFilters={itemsTypePartner} />
  ) : (
    <PartnersTypesRow filters={filters} clickFilter={clickFilter} itemsFilters={itemsTypePartner} />
  );
};
