import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type TTourDesignerPopupProps = {
  title: string;
  annotation?: string;
  onClose: () => void;
  className?: string;
};

export const TourDesignerPopup = ({
  title,
  annotation,
  onClose,
  className,
  children,
}: PropsWithChildren<TTourDesignerPopupProps>) => {
  return (
    <div className={classNames('tour-designer-popup', className)}>
      <div className='tour-designer-popup__header'>
        <button className='tour-designer-popup__close' type='button' onClick={onClose}>
          <CloseIcon className='tour-designer-popup__close__icon' />
        </button>
        {title}
      </div>
      <div className='tour-designer-popup__content'>
        {!!annotation && (
          <div className='tour-designer-popup__inner'>
            <p className='tour-designer-popup__annotation'>{annotation}</p>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
