import React from 'react'
import { Create } from 'react-admin'
import BlogForm from './BlogForm'

const BlogCreate = () => (
  <Create>
    <BlogForm />
  </Create>
)

export default BlogCreate
