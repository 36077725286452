import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckBoxControl = (props) => {
  const { name, label, disabled } = props;
  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={<Checkbox {...field} value={field.value || false} checked={disabled ? true : (field.value || false)} disabled={disabled} />}
            label={label}
          />
        );
      }}
    />
  );
};

export default CheckBoxControl;
