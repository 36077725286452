import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCategoryPartner, TFiltersPartners, TPartner } from 'shared/types/partner.types';

type TInitialState = {
  partners: TPartner[] | null;
  filtersPartners: TFiltersPartners;
  isLoading: boolean;
};

export const defaultFiltersPartners: TFiltersPartners = {
  types: [],
};

const initialState: TInitialState = {
  partners: null,
  filtersPartners: defaultFiltersPartners,
  isLoading: false,
};

export const slice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    setPartners: (state, { payload }: PayloadAction<TPartner[]>) => {
      if (!(!state.partners?.length && payload.length === 0)) {
        state.partners = [...payload];
      }
    },

    setFiltersTypes: (state, { payload }: PayloadAction<TCategoryPartner[]>) => {
      const filters = state.filtersPartners;
      state.filtersPartners = { ...filters, types: payload };
    },

    setFilters: (state, { payload }: PayloadAction<TFiltersPartners>) => {
      state.filtersPartners = { ...payload };
    },

    clearFilters: (state) => {
      state.filtersPartners = { ...defaultFiltersPartners };
    },
  },
});

export const { setPartners, setLoading, setFiltersTypes, setFilters, clearFilters } = slice.actions;

export default slice.reducer;
