import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { setFilters } from 'manageStore/travelsGuide/travelsGuide.slice';
import {
  selectFiltersTravelsGuide,
  selectFilteredTravelsGuide,
  selectLoading,
} from 'manageStore/travelsGuide/travelsGuide.select';
import Loading from 'components/Loading/Loading';
import { TravelGuideCard } from 'entities/travelGuide/TravelGuideCard/TravelGuideCard';
import { TCategoryTravelGuide, TNamesTypeTravelsGuideFilter } from 'shared/types/travelGuide.types';
import { TravelsGuideFilters } from 'features';

export const TravelGuidesMain = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const filters = useSelector(selectFiltersTravelsGuide);
  const travelGuides = useAppSelector((state) => selectFilteredTravelsGuide(state, filters));

  const changeFiltersTravelsGuide = (type: TCategoryTravelGuide | null, fieldFilters: TNamesTypeTravelsGuideFilter) => {
    if (!type) {
      dispatch(setFilters({ ...filters, type: null }));
      return;
    }
    dispatch(setFilters({ ...filters, [fieldFilters]: type }));
  };

  return (
    <main className='travel'>
      <div className='container travel__container'>
        <h1 className='travel__title'>Путеводитель</h1>
        <TravelsGuideFilters filters={filters} changeFiltersTravelsGuide={changeFiltersTravelsGuide} />
        {loading ? (
          <Loading />
        ) : travelGuides?.length ? (
          <div className='travel__list-wrapper'>
            <div className='travel-list'>
              {travelGuides.map((item) => (
                <TravelGuideCard key={item.id} travelGuide={item} />
              ))}
            </div>
          </div>
        ) : (
          <div className='no-results-text'>По выбранным параметрам локации отсутствуют.</div>
        )}
      </div>
    </main>
  );
};
