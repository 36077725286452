import { Helmet } from 'react-helmet';
import { TourDesignerMain } from 'widgets/lib/tourDesignerMain/TourDesignerMain/TourDesignerMain';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

export const TourDesignerPage = () => {
  return (
    <>
      <Helmet>
        <title>Конструктор путешествий - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <TourDesignerMain />
      <AuthPortal />
    </>
  );
};
