import React, { useEffect } from 'react';
import s from './index.module.scss';
import Popup from 'newProject/features/popup/ui';
import Button from 'newProject/shared/ui/button';
import Portal from 'newProject/features/portal/ui';
import clsx from 'clsx';
import { TDocFetchWithUser } from 'shared/types/common.types';
import FileButton from 'newProject/entities/file/ui';
import { useState } from 'react';
import RequestService from 'services/request.service';
import Notification from 'shared/lib/notification';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { cvaFiles, cvaSubtitle } from '../application-actions/ui';
import { getRequestByIdThunk } from '../../../../manageStore/request/request.thunk';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

type Props = {
  onClose: () => void;
  idReq: number;
  files: TDocFetchWithUser[];
};

export default function FileReqPopup({ onClose, files, idReq }: Props) {
  const [filesState, setFilesState] = useState(files);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setFilesState(files);
  }, [files]);

  const onDelete = async (fileId: number) => {
    try {
      const isDeleted = await RequestService.deleteFile(idReq, fileId);
      if (isDeleted.status < 300) {
        setFilesState(filesState.filter((file) => file.id !== fileId));
        dispatch(getRequestByIdThunk(idReq));
      }
    } catch (e) {
      Notification.error('Произошла ошибка');
    }
  };
  const userData = useAppSelector((state) => state.user.userData);
  const operatorPopup = filesState.filter(Boolean).filter((e) => e.user.id !== userData?.id);
  const userPopup = filesState.filter(Boolean).filter((e) => e.user.id === userData?.id);
  return (
    <Portal>
      <Popup classNameInner={s.null} onClose={onClose}>
        <div className={s.popupContainer}>
          <div className='h1'>Вложенные файлы</div>
        </div>
        <div className={clsx(s.popupContainer, s.bb)}>
          {filesState.length ? (
            <>
              {userPopup.length > 0 && (
                <div className={s.scrollBar}>
                  <div className={cvaSubtitle()}>Пользователь</div>
                  <div className={cvaFiles()}>
                    {userPopup.map((e) => (
                      <FileButton
                        className={s.userFiles}
                        isPopup
                        onDelete={() => onDelete(e.id)}
                        key={e.id}
                        file={e}
                        withInfo
                      />
                    ))}
                  </div>
                </div>
              )}
              {operatorPopup.length > 0 && (
                <div>
                  <div className={clsx(cvaSubtitle(), s.oper)}>Оператор</div>
                  <div className={cvaFiles()}>
                    {operatorPopup.map((e) => (
                      <FileButton
                        className={s.operFiles}
                        isPopup
                        onDelete={e.user.role !== 'ROLE_TOURIST' ? () => onDelete(e.id) : undefined}
                        key={e.id}
                        file={e}
                        withInfo
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={s.filesNo}>Вы еще не обменивались файлами</div>
          )}
        </div>
        <div className={s.popupContainer}>
          <Button onClick={onClose} className={s.cancel} stretched>
            Закрыть
          </Button>
        </div>
      </Popup>
    </Portal>
  );
}
