import { TPrivilegeItem } from '../../../../../shared/types/aboutUs.types';

type TTPrivilegeCardProps = {
  privilegeItem: TPrivilegeItem;
};
export const PrivilegeCardMobile = ({ privilegeItem }: TTPrivilegeCardProps) => {
  return (
    <div className='privileges-card-sm'>
      <span className='privileges-card-sm__icon'>
          {privilegeItem.icon}
      </span>
      <p className='privileges-card__label'>{privilegeItem.label}</p>
    </div>
  );
};
