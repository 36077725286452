import { useDispatch } from 'react-redux';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TLocation } from 'shared/types/location.types';
import { setLocation } from 'manageStore/designer/designer.slice';
import classNames from 'classnames';

type TTourDesignerLocationsListProps = {
  locations: TLocation[];
  className?: string;
};

export const TourDesignerLocationsList = ({ locations, className }: TTourDesignerLocationsListProps) => {
  const dispatch = useDispatch();

  const handleSelectLocation = (location: TLocation) => {
    dispatch(setLocation(location));
  };

  return (
    <div className={classNames('designer-locations-list', className)}>
      {locations.map((location) => {
        const { id, name, preview, description } = location;
        const urlPreview = preview?.path ? `${URL_FOR_IMG}/${preview?.path}` : '';
        return (
          <div className='designer-locations-item' key={id} onClick={() => handleSelectLocation(location)}>
            <div className='designer-locations-item__wrapper'>
              <div className='designer-locations-item__content'>
                <div className='designer-locations-item__text'>
                  <div className='designer-locations-item__name'>{name}</div>
                  <div className='designer-locations-item__desription'>{description}</div>
                </div>
                {!!urlPreview && <img className='designer-locations-item__image' src={urlPreview} alt='preview' />}
              </div>
              <div className='designer-locations-item__territory'>
                <span>Территория:</span> {location.cluster?.name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
