import React from 'react'
import { Edit } from 'react-admin'
import BeautyForm from './BeautyForm'

const BeautyEdit = () => (
  <Edit>
    <BeautyForm />
  </Edit>
)

export default BeautyEdit
