import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { ButtonSlider } from '../../../../shared/lib/ButtonSlider/ButtonSlider';
import { AboutClusterNewsCard } from './AboutClusterNewsCard';
import { useMediaQuery } from '../../../../shared/hooks/useMatchMedia';
import { AboutClusterNewsCardMobile } from './AboutClusterNewsCardMobile';
import { NewsBanner } from './NewsBanner';
import { useDispatch, useSelector } from 'react-redux';
import { getPublishedNews, selectNewsList } from '../../../../manageStore/news/newsSlice';
import { NewsGalleryOnMobile } from '../Galery/NewsGalleryOnMobile';
import {useLocation} from "react-router-dom";
import {fetchTapeParticipants} from "../../../../manageStore/common/common.thunk";

const settingsSlider: Settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};

const settingsSliderMobile: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1.25,
  slidesToScroll: 1,
};

const allNewsMobile = {
  id: 0,
  link: '',
  imag: null,
  title: 'Все новости?',
  desc: '',
  content: null,
  important: null,
  isPublished: null,
  date: '',
  views: null,
  metaDesc: null,
  metaKeywords: null,
  preview: null,
  author: null,
};
export const ClusterMemberNews = () => {
  const dispatch = useDispatch();
  const routeParams  = useLocation();
  const [currentSlider, setCurrentSlider] = useState(0);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const news = useSelector(selectNewsList);

  const slickTrackRef = useRef<{
    node: HTMLDivElement | null;
  }>({ node: null });

  const handleChangeSlide = (prev: number, next: number) => {
    setCurrentSlider(next);
  };

  const handleInitSlider = () => {
    slickTrackRef.current.node = document.querySelector('.cluster-member__news__slider .slick-track');
    handleChangeSlide(0, 0);
  };


  const goToNews = ()=> {
    if(routeParams && routeParams.hash == '#news') {
      const element = document.getElementById('news');
      if(element) {
        element.scrollIntoView({ block: "center" });
      }
    }
  }



  useEffect(() => {
    if (!news.length) {
      dispatch(getPublishedNews());
    }
    setTimeout(()=>{goToNews()}, 1000)
  }, [news]);

  return (
    <>
      <div className='cluster-member'>
        <section className='container'>
          <h1 className='cluster-member__title '>Участники кластера</h1>

          <div className='cluster-member-gallery'>
            <div className='cluster-member-gallery__content'>
              <NewsGalleryOnMobile />

              {!isMobile && (
                <div className='cluster-member-gallery__second '>
                  <div className='cluster-member-gallery__imag cluster-member-gallery__imag__4'></div>
                  <div className='cluster-member-gallery__imag cluster-member-gallery__imag__5'></div>
                </div>
              )}
            </div>
          </div>
          <div></div>
        </section>

        <NewsBanner />

        <section className='container'>
          <a
            href='/partners'
            target='_blank'
            className='metrics-content__become-member btn'
          >
            все партнеры
          </a>
        </section>

        {news.length > 0 && (
          <section className='container ' >
            <h1 className='cluster-member__title title_content' id='news'  >Новости</h1>

            {isMobile ? (
              <div className='container-sm'>
                <Slider
                  {...settingsSliderMobile}
                  className={classNames('cluster-member__news__slider')}
                  beforeChange={handleChangeSlide}
                  onInit={handleInitSlider}
                >
                  {[...news].map((newsItem) => (
                    <AboutClusterNewsCardMobile key={newsItem.id} newsItem={newsItem} />
                  ))}
                </Slider>
              </div>
            ) : (
              <Slider
                {...settingsSlider}
                className={classNames('cluster-member__news__slider')}
                beforeChange={handleChangeSlide}
                onInit={handleInitSlider}
              >
                {news.map((newsItem) => (
                  <AboutClusterNewsCard key={newsItem.id} newsItem={newsItem} />
                ))}
              </Slider>
            )}
          </section>
        )}
      </div>
    </>
  );
};
