import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { selectTours } from 'manageStore/tours/tours.select';
import { clearFilters } from 'manageStore/tours/tours.slice';
import { fetchTours } from 'manageStore/tours/tours.thunk';
import Header from 'components/Header/Header';
import { ToursMain } from 'widgets/lib/toursMain/ToursMain/ToursMain';
import Footer from 'widgets/lib/Footer/Footer';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

export const ReadyToursPage = () => {
  const dispatch = useDispatch();
  const tours = useSelector(selectTours);

  useEffect(() => {
    if (tours && !tours.length) {
      dispatch(fetchTours());
    }
  }, [tours]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Туры и экскурсии - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isFixed />
      <AuthPortal />
      <ToursMain />
      <Footer />
    </>
  );
};
