import React, { useEffect } from 'react';

export const ShareBlog = () => {
    useEffect(() => {
        // Создаем и добавляем скрипт в документ
        const script = document.createElement('script');
        script.src = 'https://yastatic.net/share2/share.js';
        script.async = true;
        script.onload = () => {
            // Этот код выполнится после загрузки скрипта
            if (window.YaShare2) {
                window.YaShare2.init(); // Инициализация если требуется
            }
        };

        document.body.appendChild(script);

        // Очистка при размонтировании компонента
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Пустой массив зависимостей, чтобы скрипт загружался только один раз

    return (
        <>
            <div className='shareBlog'>
                <div className='lineBottom'> </div>
                <div className="lineHidden"></div>

                <div className="shareBlog__title">Поделиться:</div>
                <div className="shareBlog__list">
                    <div 
                        className="ya-share2" 
                        data-curtain 
                        data-size="l" 
                        data-color-scheme="blackwhite" 
                        data-limit="3" 
                        data-services="vkontakte,odnoklassniki,telegram,whatsapp,moimir"
                    ></div>
                </div>
            </div>
        </>
    );
};
