import { Show, SimpleShowLayout, TextField, ChipField, FunctionField } from 'react-admin';
import { PARTNERS_FIELDS, TRANSFORM_TEXT, URL_FOR_IMG } from '../../../shared/constants/const';
import { TPartner } from 'shared/types/partner.types';

export const PartnersShow = () => (
  <div style={{ marginBottom: '50px' }}>
    <Show>
      <SimpleShowLayout>
        <TextField source='name' label={PARTNERS_FIELDS.title} />
        <FunctionField
          label={PARTNERS_FIELDS.category}
          render={({ tag }: TPartner) => TRANSFORM_TEXT.partners[tag].label}
        />
        <FunctionField
          label={PARTNERS_FIELDS.photos}
          render={({ partnerPhotos }: TPartner) => {
            return partnerPhotos?.length
              ? partnerPhotos.map((photo) => (
                  <img
                    className='admin-image admin-image__multi'
                    src={`${URL_FOR_IMG}/${photo.path}`}
                    alt='partner'
                    key={photo.id}
                  />
                ))
              : null;
          }}
        />
        <TextField source='description' label={PARTNERS_FIELDS.description} />
        <TextField source='contactPhone' label={PARTNERS_FIELDS.phone} />
        <TextField source='contactEmail' label={PARTNERS_FIELDS.email} />
        <TextField source='contactSite' label={PARTNERS_FIELDS.site} />
        <TextField source='experience' label={PARTNERS_FIELDS.experience} />
        <TextField source='videoLink' label={PARTNERS_FIELDS.video} />
        <FunctionField
          label={PARTNERS_FIELDS.products}
          render={({ partnerTour }: TPartner) => {
            return partnerTour?.length
              ? partnerTour.map((tour, index) => {
                  return <ChipField key={tour.id} source={`partnerTour.${index}.title`} />;
                })
              : null;
          }}
        />
      </SimpleShowLayout>
    </Show>
  </div>
);
