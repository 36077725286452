import { Link } from 'react-router-dom';
import { TUserType } from 'shared/types/types';

type TNoRequestsProps = {
  title: string;
  type: TUserType;
};

export const NoRequests = ({ title, type }: TNoRequestsProps) => (
  <div className='norequests'>
    <h3 className='norequests-title' style={{ marginBottom: type === 'OPERATOR' ? 0 : '15px' }}>
      {title}
    </h3>
    {type !== 'OPERATOR' && (
      <>
        <div className='norequests-divider'></div>
        <p className='norequests-text'>
          Подберите подходящий для вас готовый тур, или составьте собственный. Это совсем не сложно. Туроператоры с
          радостью помогут вам отправиться в незабываемое путешествие по Таймыру!
        </p>
        <div className='norequests-bottom'>
          <Link className='norequests-btn btn' to='/tours'>
            Готовые туры
          </Link>
          <Link className='norequests-btn btn blue' to='/designer'>
            Конструктор
          </Link>
          <Link className='norequests-btn-simple' to='/'>
            Вернуться на главную
          </Link>
        </div>
      </>
    )}
  </div>
);
