import { useState, useRef, useEffect } from 'react';
import Slider, { Settings } from 'react-slick';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TSingleFile } from 'shared/types/common.types';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import classNames from 'classnames';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';

type TTourDesignerGalleryProps = {
  images: TSingleFile[];
  title: string;
  onClose: () => void;
};

const settingsMainSlider: Settings = {
  className: 'gallery-slider',
  dots: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};

const settingsDoubleSlider: Settings = {
  dots: false,
  centerPadding: '0',
  centerMode: true,
  speed: 500,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  variableWidth: true,
  swipeToSlide: true,
  focusOnSelect: true,
};

export const TourDesignerGallery = ({ images, title, onClose }: TTourDesignerGalleryProps) => {
  const [activeImage, setActiveImage] = useState(0);
  const [mainSlider, setMainSlider] = useState<Slider | null>(null);
  const [doubleSlider, setDoubleSlider] = useState<Slider | null>(null);
  const imageItemsRef = useRef<HTMLDivElement[]>([]);

  const [isMobile] = useMediaQuery();

  const handleChangeSlide = (index: number) => {
    setActiveImage(index);
  };

  const setImageItem = (el: HTMLDivElement) => {
    imageItemsRef.current.push(el);
  };

  useEffect(() => {
    if (!isMobile && mainSlider) {
      mainSlider.slickGoTo(activeImage);
      imageItemsRef.current[activeImage]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [activeImage, isMobile, mainSlider]);

  return (
    <div className='location-gallery'>
      {isMobile && <CloseIcon className='location-gallery__close mobile' onClick={onClose} />}
      {!isMobile && (
        <aside className='location-gallery__aside'>
          <div className='location-gallery__header'>
            <h3 className='location-gallery__title'>{title}</h3>
            <CloseIcon className='location-gallery__close' onClick={onClose} />
          </div>
          <div className='location-gallery__list'>
            {images.map(({ id, path }, index) => (
              <div
                key={id}
                className={classNames('location-gallery__item', { active: index === activeImage })}
                onClick={() => handleChangeSlide(index)}
                ref={setImageItem}
              >
                <img src={`${URL_FOR_IMG}/${path}`} alt='' />
              </div>
            ))}
          </div>
        </aside>
      )}
      <Slider
        {...settingsMainSlider}
        infinite={isMobile}
        beforeChange={(currentSlide: number, nextSlide: number) => handleChangeSlide(nextSlide)}
        asNavFor={doubleSlider || undefined}
        ref={(slider) => setMainSlider(slider)}
      >
        {images.map(({ id, path }) => (
          <div className='gallery-slider__wrapper' key={id}>
            <div className='gallery-slider__image'>
              <img src={`${URL_FOR_IMG}/${path}`} alt='slide' />
            </div>
          </div>
        ))}
      </Slider>
      {isMobile && images.length > 1 && (
        <Slider
          asNavFor={mainSlider || undefined}
          ref={(slider) => setDoubleSlider(slider)}
          {...settingsDoubleSlider}
          infinite={isMobile}
          slidesToShow={images.length < 4 ? images.length : 4}
          className={classNames('double-slider', { 'double-slider_flex': images.length < 5 })}
        >
          {images.map(({ id, path }, index) => (
            <div className='double-slider__wrapper' key={id}>
              <div className={classNames('double-slider__image', { active: index === activeImage })}>
                <img src={`${URL_FOR_IMG}/${path}`} alt='slide' />
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};
