import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { reviewsActions } from './reviews.slice';
import { ReviewService } from 'services/review.service';
import { TReviewEditParams, TReviewSendParams } from 'shared/types/review.types';
import { TRootState } from 'store';

type TOptionsThunk = { state: TRootState };

export const newReviewThunk = createAsyncThunk('newReview', async (reviewParams: TReviewSendParams, { dispatch }) => {
  const { setNewReview, setEdittedReview, setIsUploading } = reviewsActions;
  dispatch(setIsUploading(true));
  try {
    const { data } = await Notification.promise(ReviewService.newReview(reviewParams));
    dispatch(setNewReview(data));
    dispatch(setEdittedReview(null));
  } finally {
    dispatch(setIsUploading(false));
  }
});

export const editReviewThunk = createAsyncThunk('editReview', async (reviewParams: TReviewEditParams, { dispatch }) => {
  const { setChangedReview, setEdittedReview, setIsUploading } = reviewsActions;
  dispatch(setIsUploading(true));
  try {
    const { data } = await Notification.promise(ReviewService.editReview(reviewParams));
    dispatch(setChangedReview(data));
    dispatch(setEdittedReview(null));
  } finally {
    dispatch(setIsUploading(false));
  }
});

export const getTouristReviewsListThunk = createAsyncThunk<void, void, TOptionsThunk>(
  'getTouristReviewsList',
  async (_, { dispatch, getState }) => {
    const { setReviewsList, setIsLoading } = reviewsActions;
    const userData = getState().user.userData;
    if (!userData) return;
    dispatch(setIsLoading(true));
    try {
      const { data } = await ReviewService.getReviewsList();
      dispatch(setReviewsList(data));
    } catch (e) {
      const errorMessage = handleError(e);
      Notification.error(errorMessage);
      throw e;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
